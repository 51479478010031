<ng-container *ngIf="!showRedmadCalendar">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="false"
      style="position: fixed; width: 100%; z-index: 9; top:0; left: 0 "
    ></coop-pageheader>
    <section class="calendar" [@parentAnimate]="isParent">
      <full-calendar
        #calendar
        [deepChangeDetection]="true"
        [options]="calendarOptions"></full-calendar>
      <!--  <ng-container *ngIf="showRedmadCalendar">-->
      <!--    <app-quicktogo-calendar></app-quicktogo-calendar>-->
      <!--  </ng-container>-->

      <!-- for filter in calendar -->
      <a class="filter-btn" (click)="showHideFilterBtns()">
        <ion-icon name="funnel"></ion-icon>
      </a>
      <div
        *ngIf="showFilters === 'active'"
        class="overlay"
        (click)="showHideFilterBtns()"
      ></div>
      <div
        class="add-actions-container"
        [@slideUpDown]="showFilters"
        (@slideUpDown.done)="slideDone($event)"
      >
        <div class="close-btn" (click)="showHideFilterBtns()">
          <ion-icon name="close-outline" class="margin-top-5"></ion-icon>
        </div>
        <div class="wrapper action-buttons">
          <h4 style="padding : 13px 16px">Vælg type vare</h4>
          <ul>
            <li *ngIf="userType === '1'">
              <a
                class="btn"
                [class.active]="currentCalendarType === 'god-pris'"
                (click)="loadCalendarData('god-pris')"
              >
                <div>
                  <ion-icon name="pricetags-outline"></ion-icon>
                </div>
                <div class="type">
                  God pris
                </div>
              </a
              >
            </li>
            <li>
              <a
                class="btn"
                [class.active]="currentCalendarType === 'nyheder'"
                (click)="loadCalendarData('nyheder')"
              >
                <div>
                  <ion-icon name="megaphone-outline"></ion-icon>
                </div>
                <div class="type">Nyheder</div>
              </a
              >
            </li>
            <li>
              <a
                class="btn"
                [class.active]="currentCalendarType === 'arrangement'"
                (click)="loadCalendarData('arrangement')"
              >
                <div>
                  <ion-icon name="calendar-outline"></ion-icon>
                </div>
                <div class="type">
                  Arrangement
                </div>
              </a
              >
            </li>
            <li>
              <a
                class="btn"
                [class.active]="currentCalendarType === 'afstemninger'"
                (click)="loadCalendarData('afstemninger')"
              >
                <div>
                  <ion-icon name="stats-chart-outline"></ion-icon>
                </div>
                <div class="type">
                  Afstemninger
                </div>
              </a
              >
            </li>
            <li *ngIf="userType === '1'">
              <a
                class="btn"
                [class.active]="currentCalendarType === 'ja-tak'"
                (click)="loadCalendarData('ja-tak')"
              >
                <div>
                  <ion-icon name="sparkles-outline"></ion-icon>
                </div>
                <div class="type">
                  Ja Tak
                </div>
              </a
              >
              <a
                class="btn"
                [class.active]="currentCalendarType === 'redmad'"
                (click)="loadRedmadCalendarData('redmad')"
                *ngIf="isRedmadScreenAvailable"
              >
                <div>
                  <ion-icon name="bag-check-outline"></ion-icon>
                </div>
                <div class="type">
                  Red Maden
                </div>
              </a
              >
              <a
                class="btn"
                [class.active]="currentCalendarType === 'ja-tak-live'"
                (click)="loadCalendarData('ja-tak-live')"
              >
                <div>
                  <ion-icon name="videocam-outline"></ion-icon>
                </div>
                <div class="type">
                  Facebook Live
                </div>
              </a
              >
              <a
                class="btn"
                [class.active]="currentCalendarType === 'sms'"
                (click)="loadCalendarData('sms')"
              >
                <div>
                  <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                </div>
                <div class="type">
                  SMS
                </div>
              </a
              >
              <a
                class="btn"
                [class.active]="currentCalendarType === 'tilbud'"
                (click)="loadCalendarData('tilbud')"
              >
                <div>
                  <ion-icon name="ticket-outline"></ion-icon>
                </div>
                <div class="type">
                  Tilbud
                </div>
              </a
              >
              <a class="btn" (click)="loadRecurringOffer()"
              >
                <div>
                  <ion-icon name="repeat-outline"></ion-icon>
                </div>
                <div class="type">
                  Ugentlig God Pris
                </div>
              </a>
              <a
                class="btn"
                [class.active]="currentCalendarType === 'ok-screen'"
                (click)="loadCalendarData('ok-screen')"
                *ngIf="isOkScreenAvailable"
              >
                <div>
                  <ion-icon name="pricetags-outline"></ion-icon>
                </div>
                <div class="type">
                  Ok skærme
                </div>
              </a
              >
            </li>
          </ul>
        </div>
      </div>
      <div (click)="navigate()" class="create">
        <ion-icon name="add-outline"></ion-icon>
        <p>
          Opret selv
        </p>
      </div>
    </section>
  </ng-container>


  <ng-container *ngIf="showRedmadCalendar" >
    <div class="app-page page-with-fixed-header">
      <div class="fixed-header">
        <coop-pageheader
          [pageTitle]="pageTitle"
          [isChild]="false"
        ></coop-pageheader>
      </div>

      <div class=" page-container" [@parentAnimate]="isParent">
        <app-quicktogo-calendar></app-quicktogo-calendar>
        <!-- for filter in calendar -->
        <a class="filter-btn" (click)="showHideFilterBtns()">
          <ion-icon name="funnel"></ion-icon>
        </a>
        <div
          class="add-actions-container"
          [@slideUpDown]="showFilters"
          (@slideUpDown.done)="slideDone($event)"
        >
          <div class="close-btn" (click)="showHideFilterBtns()">
            <ion-icon name="close-outline"></ion-icon>
          </div>
          <div class="wrapper">
            <h4>Vælg type vare</h4>
            <ul>
              <li *ngIf="userType === '1'">
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'god-pris'"
                  (click)="loadCalendarData('god-pris')"
                >
                  <div>
                    <ion-icon name="pricetags-outline"></ion-icon>
                  </div>
                  <div class="type">
                    God pris
                  </div>
                </a
                >
              </li>
              <li>
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'nyheder'"
                  (click)="loadCalendarData('nyheder')"
                >
                  <div>
                    <ion-icon name="megaphone-outline"></ion-icon>
                  </div>
                  <div class="type">Nyheder</div>
                </a
                >
              </li>
              <li>
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'arrangement'"
                  (click)="loadCalendarData('arrangement')"
                >
                  <div>
                    <ion-icon name="calendar-outline"></ion-icon>
                  </div>
                  <div class="type">
                    Arrangement
                  </div>
                </a
                >
              </li>
              <li>
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'afstemninger'"
                  (click)="loadCalendarData('afstemninger')"
                >
                  <div>
                    <ion-icon name="stats-chart-outline"></ion-icon>
                  </div>
                  <div class="type">
                    Afstemninger
                  </div>
                </a
                >
              </li>
              <li *ngIf="userType === '1'">
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'ja-tak'"
                  (click)="loadCalendarData('ja-tak')"
                >
                  <div>
                    <ion-icon name="sparkles-outline"></ion-icon>
                  </div>
                  <div class="type">
                    Ja Tak
                  </div>
                </a
                >
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'redmad'"
                  (click)="loadRedmadCalendarData('redmad')"
                  *ngIf="isRedmadScreenAvailable"
                >
                  <div>
                    <ion-icon name="bag-check-outline"></ion-icon>
                  </div>
                  <div class="type">
                    Red Maden
                  </div>
                </a
                >
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'ja-tak-live'"
                  (click)="loadCalendarData('ja-tak-live')"
                >
                  <div>
                    <ion-icon name="videocam-outline"></ion-icon>
                  </div>
                  <div class="type">
                    Facebook Live
                  </div>
                </a
                >
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'sms'"
                  (click)="loadCalendarData('sms')"
                >
                  <div>
                    <ion-icon name="chatbubble-ellipses-outline"></ion-icon>
                  </div>
                  <div class="type">
                    SMS
                  </div>
                </a
                >
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'tilbud'"
                  (click)="loadCalendarData('tilbud')"
                >
                  <div>
                    <ion-icon name="ticket-outline"></ion-icon>
                  </div>
                  <div class="type">
                    Tilbud
                  </div>
                </a
                >
                <a class="btn" (click)="loadRecurringOffer()"
                >
                  <div>
                    <ion-icon name="repeat-outline"></ion-icon>
                  </div>
                  <div>
                    >Ugentlig God Pris
                  </div>
                </a>
                <a
                  class="btn"
                  [class.active]="currentCalendarType === 'ok-screen'"
                  (click)="loadCalendarData('ok-screen')"
                  *ngIf="isOkScreenAvailable"
                >
                  <div>
                    <ion-icon name="pricetags-outline"></ion-icon>
                  </div>
                  <div class="type">
                    Ok skærme
                  </div>
                </a
                >
              </li>
            </ul>
          </div>

        </div>
        <div (click)="navigateRedmad()" class="create">
          <ion-icon name="add-outline"></ion-icon>
          <p>
            Opret selv
          </p>
        </div>
      </div>

    </div>
  </ng-container>


  <router-outlet></router-outlet>

