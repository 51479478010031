import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import { DefaultSharedValue } from 'app/core/data/defaultValues';
import { EventsService } from 'app/features/events/services';
import { FacebookEventsService } from 'app/features/facebookconnect/services';
import { JatakService } from 'app/features/jatak/services';
import { JatakLive } from 'app/features/jatak-live/interface';
import { JatakLiveService } from 'app/features/jatak-live/services';
import { Promo } from 'app/features/offer/interface';
import { OfferService } from 'app/features/offer/services';
import { OkScreenCalendar } from 'app/features/ok-screen/interface';
import { OkScreenService } from 'app/features/ok-screen/services';
import { PollService } from 'app/features/poll/services';
import { SmsService } from 'app/features/sms/service';
import { CreateOption, Shared } from 'app/shared/interface';
import {
  CalendarAfstemningers,
  CalendarArrangements,
  CalendarEvent,
  CalendarEventDates,
  Events,
  Polls
} from 'app/shared/interface';
import {
  AppEventsService,
  ClientService,
  CreateOptionDataService,
  ErrorhandlerService,
  NotificationsService,
  TokenService,
  WindowService
} from 'app/core/services';
import { animateParent, slideAnimation } from 'app/shared/animations';
import { DateToString } from 'app/shared/pipes';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
  selector: 'coop-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [slideAnimation, animateParent],
  providers: [DateToString]
})
export class CalendarComponent implements OnInit, OnDestroy {
  @ViewChild('calendar') calendar: FullCalendarComponent;
  parentUrl: string;
  pageTitle: string;
  dates = {
    startDate: '',
    endDate: ''
  };
  calendarRendered: boolean = false;
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridWeek',
    headerToolbar: {
      start: 'prev',
      center: 'uge title',
      end: 'next'
    },
    views: {
      dayGridWeek: {
        titleFormat: {
          month: 'short',
          day: 'numeric'
        },
        dayHeaderFormat: {
          weekday: 'short'
        }
      }
    },
    firstDay: 1,
    locale: 'da',
    weekNumberCalculation: 'ISO',
    editable: false,
    displayEventTime: false,
    height: '100%',
    weekText: 'Uge',
    events: [],
    datesSet: (datesSet) => this.handleWeekChange(datesSet),
    viewDidMount: () => this.viewDidMount(),
    windowResize: () => this._windowResize(),
    eventClick: (event: any) => this._eventClick(event),
    eventDidMount: (event) => this._afterRender(event)
  };
  currentCalendarData: any;
  isParent: string = 'visible';
  navigateTo: string;
  showFilters: string = 'inactive';
  currentCalendarType: string = 'ja-tak';
  initialLoad: boolean = true;
  fromOptions: boolean = false;
  orderShared = ['coop', 'facebook', 'sms', 'mail', 'web', 'screen', 'dit'];
  filterDates: CalendarEventDates[] = [];
  userType: string;
  isOkScreenAvailable: boolean;
  isRedmadScreenAvailable: boolean
  calendarArrangements: CalendarArrangements = {};
  calendarAfstemningers: CalendarAfstemningers = {};
  selectedOption: CreateOption;
  private _subscriptionState: boolean = true;
  public showRedmadCalendar: boolean = false;
  offerType:string;
  constructor(
    private _router: Router,
    private _windowService: WindowService,
    private _title: Title,
    private _errorHandlerService: ErrorhandlerService,
    private _activatedRoute: ActivatedRoute,
    private _appEvents: AppEventsService,
    private _smsService: SmsService,
    private _clientService: ClientService,
    private _offerService: OfferService,
    private _eventsService: EventsService,
    private _pollService: PollService,
    private _jatakService: JatakService,
    private _jatakLiveService: JatakLiveService,
    private _okScreenService: OkScreenService,
    private _facebookEvents: FacebookEventsService,
    private _dateToString: DateToString,
    private _createOptionService: CreateOptionDataService
  ) {
  }

//** initialize component */
  //subscribe to events => loop through events
  //subscribe to onResize() => calendarOptions height is set
  //_init()
  //subscribe to reloadCalendar => if res is true then _refreshCalendar() to refresh calendar
  ngOnInit() {
    this._router.events
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((events) => {
        if (events instanceof NavigationEnd) {
          this._checkCurrentCalendarType();
        }
      });

    this._initComp();
    this._appEvents.$reloadCalendar
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res) {
            this._refreshCalendar();
          }
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** destroy component */
//set _subscriptionState to unsubscribe observable
//setTitle sets the title of the current HTML document 'QuickCoop'.
  ngOnDestroy(): void {
    this._subscriptionState = false;
    this._createOptionService.clearCreateOption();
    this._title.setTitle('QuickCoop');
  }

//toggle the value of showFilters => if inactive set it to active and vice-versa
  showHideFilterBtns(): void {
    this.showFilters = this.showFilters === 'inactive' ? 'active' : 'inactive';
  }

//** load the calendar according to their type **//
  //@params[type] is the type of calendar to be loaded
  // set the boolean value of fromOptions to true todo
  //set showFilters to 'inactive' state
  //_loadCalendar() to load the respective content of calendar
  loadCalendarData(type: string) {
    this.currentCalendarType = type;
    this.fromOptions = true;
    this.showFilters = 'inactive';
    // this.setPageTitle(this.currentCalendarType);
    this._loadCalendar(type);
  }
   // setPageTitle(pageTitle:string){
   //  this.pageTitle = 'Kalendar - ' + pageTitle;
   // }
  loadRedmadCalendarData(type: string) {
    this.fromOptions = true;
    this.showFilters = 'inactive';
    this.showRedmadCalendar = true;
    this.currentCalendarType = 'redmad';
    this.pageTitle = 'Red Maden';
    this._router
      .navigate([`/${this.parentUrl}/kalendar/${type}`])
      .then(() => (this.initialLoad = false));
  }

//** load content for weekly recurring offer //
  //navigate to the provided url

  loadRecurringOffer() {
    this._router
      .navigate(['/butikker/campaign'], {queryParams : {type :'offer'}})
      .then();
  }

//*** navigate to the respective page to create new offer
  //local variable type is set with the currentCalendarType
  // if type is 'ja-tak-live' then fbSelect() => check the connectivity of facebook
  //if type is 'sms' then set type to 'sms/sms-message'
  //set local variable navigateTo to provided url
  //if type is equal to any of these 'tilbud','god-pris','nyheder','ja-tak','tilbagevendende-tilbud' then set variable navigateTo to provided url
  //if type is equal to 'ok-screen' then set navigateTo to provided url
//else navigate to the url of navigateTo

  navigate() {
    let type = this.currentCalendarType;
    if (type === 'ja-tak-live') {
      this.fbSelect();
      return;
    }

    if (type === 'sms') {
      type = 'sms/sms-message';
    }

    let navigateTo = `/${this.parentUrl}/${type}/skab`;
    if (
      type === 'ja-tak'
    ) {
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      let routeUrl = `/${this.parentUrl}/${type}/skab`;
      this._router.navigate([routeUrl], { queryParams: { origin: 'kalender' } }).then();
      return;
    }
    if (type === 'nyheder') {
      this.selectedOption = {
        mode: 'skab',
        type: 'nyheder',
        share: {
          coop: true,
          facebook: false,
          sms: false,
          mail: false,
          web: false,
          screen: false,
          dit: false
        },
        //if currentCalendarType is present set step to 3 else 1
        step: 3
      };
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      navigateTo = `${this.parentUrl}/indlaeg/${type}/skab`;
    }

    if (type === 'god-pris') {
      this.selectedOption = {
        mode: 'skab',
        type: 'god-pris',
        share: {
          coop: true,
          facebook: false,
          sms: false,
          mail: false,
          web: false,
          screen: false,
          dit: false
        },
        //if currentCalendarType is present set step to 3 else 1
        step: 3
      };
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      navigateTo = `${this.parentUrl}/indlaeg/${type}/skab`;
    }


    if (type === 'tilbud') {
      this.selectedOption = {
        mode: 'skab',
        type: 'tilbud',
        share: {
          coop: true,
          facebook: false,
          sms: false,
          mail: false,
          web: false,
          screen: false,
          dit: false
        },
        //if currentCalendarType is present set step to 3 else 1
        step: 3
      };
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      navigateTo = `${this.parentUrl}/indlaeg/${type}/skab`;
    }

    if (type === 'tilbagevendende-tilbud') {
      this.selectedOption = {
        mode: 'skab',
        type: 'tilbagevendende-tilbud',
        share: {
          coop: true,
          facebook: false,
          sms: false,
          mail: false,
          web: false,
          screen: false,
          dit: false
        },
        //if currentCalendarType is present set step to 3 else 1
        step: 3
      };
      this.selectedOption.isSet = true;
      this._createOptionService.setCreateOption(this.selectedOption);
      navigateTo = `${this.parentUrl}/indlaeg/${type}/skab`;
    }

    if (type === 'ok-screen') {
      navigateTo = `/${this.parentUrl}/ok-screen/skabeloner`;
    }
    this._router
      .navigate([navigateTo], {
        state: { type: this.currentCalendarType, origin: 'calendar' }
      })
      .then();
  }

  navigateRedmad() {
    this.selectedOption.isSet = true;
    this._createOptionService.setCreateOption(this.selectedOption);
    this._router.navigate(['butikker/redmad/skab']).then();
  }

//** checks the facebook connection status //
  //set local variable facebookConnected to the value of userDetail facebook connected from local storage
  //if facebookConnected is false then notify with message 'Connect to facebook'
  //.connectFacebook() Listen to any event asking to connect facebook and fetch facebook login url
  //subscribe to getFbTokenStatus() which returns token
  // if token is present navigate to provided url
  // else notify Invalid Facebook Token.Reconnect the page and popup facebook login
  fbSelect() {
    const facebookConnected = TokenService.getUserDetail().facebook.connected;
    if (!facebookConnected) {
      NotificationsService.notify(
        'Opret forbindelse til facebook',
        'error',
        'top'
      );
      this._facebookEvents.connectFacebook();
      return;
    }
    this._jatakService
      .getFbTokenStatus()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          if (res) {
            this._router
              .navigate([`/${this.parentUrl}/ja-tak-live/skab`], {
                state: { origin: 'calendar' }
              })
              .then();
          } else {
            NotificationsService.notify(
              'Ugyldigt Facebook Token.<br>Forbind siden igen',
              'error',
              'top'
            );
            this._facebookEvents.connectFacebook();
          }
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** animation cycle completes //
  //params[e] => event object
  //if navigateTo and event object toState is equal to 'inactive' => navigate to the url present in navigateTo
  slideDone(e: { toState: string }): void {
    if (this.navigateTo && e.toState === 'inactive') {
      this._router.navigate([this.navigateTo]).then();
    }
  }

//** get calendar details//
  //params[promo] contains the promoOffer data
  //local variables are created
  //const share is set with the value of promo shared
  //set sharedChannel variable with the value returned by getSharedChannel()
  //const sharedChannel is set with the value of sharedChannel [0]
  //loop through sharedChannel and set channel with the value of sharedChannel
  //if channel is not equal to 'sms' then push start and end variable with the startDate and endDate of promo to filterDates
  //if channel is equal  to 'sms' then push startDate ,sendDate and endDate, sendDate of promo to filterDates
  //set maxminDate with the value returned by getStartEndDate()
  //set startDate with _dateToString transformed value
  //set endDate with _dateToString transformed value
  // const eventDetail is set with the promo detailOfChannel
  //return an given values
  getCalendarDetails(promo: any): CalendarEvent {
    let startDate: string;
    let endDate: string;
    let maxminDate: CalendarEventDates;
    const shared = promo.shared;
    const sharedChannel = this.getSharedChannel(shared);
    const detailOfChannel = sharedChannel[0];
    let channel: any;
    for (const key in sharedChannel) {
      channel = sharedChannel[key];
      if (channel !== 'sms') {
        this.filterDates.push({
          start: promo[channel].publishDate,
          end: promo[channel].expirationDate
        });
      }
      if (channel === 'sms') {
        this.filterDates.push({
          start: promo[channel].publishDate
            ? promo[channel].publishDate
            : promo[channel].sendDate,
          end: promo[channel].expirationDate
            ? promo[channel].expirationDate
            : promo[channel].sendDate
        });
      }
    }

    maxminDate = this.getStartEndDate();
    startDate = this._dateToString.transform(maxminDate.start);
    endDate = this._dateToString.transform(maxminDate.end);
    const eventDetail = promo[detailOfChannel.toString()];
    return {
      id: promo.id,
      category: promo.type,
      title: eventDetail.title,
      start: startDate,
      end: endDate, // TODO: moment fix required
      display: 'block',
      borderColor: 'transparent',
      backgroundColor: '#282F36',
      classNames: ['quick-event'],
      shared: promo.shared ? promo.shared : DefaultSharedValue
    };
  }

//** provide start and end Date//
  //const startDates is set with mapped value of dates
  //const endDates is set with the mapped value of dates
  //return start and end date
  getStartEndDate(): CalendarEventDates {
    const startDates = this.filterDates.map((dates) => moment(dates.start));
    const endDates = this.filterDates.map((dates) => moment(dates.end));
    return {
      start: moment.min(startDates).toDate(),
      end: moment.max(endDates).toDate()
    };
  }

//** provide valid Channels *//
  //@params[shared] => list of shared channel
  //loop through orderShared and assign local variable channel with the orderShared value
  //if shared channel is true => push channel to validChannels
  //return validChannels

  getSharedChannel(shared: Shared): Array<[]> {
    const validChannels = [];
    let channel: any;
    for (const key in this.orderShared) {
      channel = this.orderShared[key];
      // @ts-ignore
      if (shared[channel]) {
        validChannels.push(channel);
      }
    }
    return validChannels;
  }

  //set the title of document to 'Kalender - QuickCoop'
  //set isOkScreenAvailable with the value of scopes okScreen
  //_checkCurrentCalendarType() to check the current calendar type
  //resize the calendarOptions height
  //set parentUrl with the value of data parentUrl
  //set userType with the value of getUserType()
  private _initComp() {
    this._title.setTitle('Calendar');
    this.isOkScreenAvailable = TokenService.getUserDetail().scopes.okScreen;
    this.isRedmadScreenAvailable = TokenService.getUserDetail().scopes.quick2Go;
    this._checkCurrentCalendarType();
    this.pageTitle = 'Kalendar - ' + this.currentCalendarType;

    // *** we donot load calendar here. It is loaded when _viewRender of calendar is called***
    // this.calendarOptions.height =
    //   this._windowService.window.document.documentElement.clientHeight -
    //   15 -
    //   20; // 60 for footer
    this.parentUrl = this._activatedRoute.snapshot.data['parentUrl'];
    this.userType = TokenService.getUserType();
    this.selectedOption = {
      mode: 'skab',
      type: 'ja-tak',
      share: {
        coop: true,
        facebook: true,
        sms: false,
        mail: false,
        web: false,
        screen: false,
        dit: false
      },
      //if currentCalendarType is present set step to 3 else 1
      step: 3
    };
  }

  // *** we check the current calendar type and update the calendar title ***
  //set local variable currentType with the value of .pramas 'type'
  //if currentType is not equal to any of these 'arsmode', 'special','forsamling-event' or 'forsampling-poll' then currentCalendarType is set with the value of currentType
  //set pageTitle to provided value
  //if currentType is equal to 'arsmode' or 'forsamling-event' => set currentCalendarType to 'arrangement' and set pageTitle
  //if currentType is equal to 'special' or 'forsamling-poll' => set currentCalendarType to 'afstemninger' and set pageTitle

  private _checkCurrentCalendarType() {
    const currentType = this._activatedRoute.snapshot.params['type'];
    this.currentCalendarType = currentType;
    if (currentType === 'redmad') {
      this.showRedmadCalendar = true;
      this.pageTitle = 'Red Maden';
      this.currentCalendarType = currentType;
      return;
    }
    if (
      currentType !== 'arsmode' ||
      currentType !== 'special' ||
      currentType !== 'forsamling-event' ||
      currentType === 'forsamling-poll'
    ) {
      this.currentCalendarType = currentType;
      this.pageTitle =
        'Kalender' +
        ' - ' +
        (this.currentCalendarType.charAt(0).toUpperCase() +
          this.currentCalendarType.slice(1));
    }
    if (currentType === 'arsmode' || currentType === 'forsamling-event') {
      this.currentCalendarType = 'arrangement';
      this.pageTitle = 'Kalender' + ' - ' + 'Arrangement';
    }
    if (currentType === 'special' || currentType === 'forsamling-poll') {
      this.currentCalendarType = 'afstemninger';
      this.pageTitle = 'Kalender' + ' - ' + 'Afstemninger';
    }
  }

//** load Calendar */
  //@params[type] => is the type of calendar to be loaded
  //if type is equal to 'god-pris' then _loadCalendarGoodPrice()
  //if type is equal to 'tilbud' then _loadCalendarPromo()
  //if type is equal to 'nyhder' then _loadCalendarNews()
  //if type is equal to 'arrangement' then _loadCalendarEvent()
  //if type is equal to 'afstemninger' then _loadCalendarPoll()
  //if type is equal to 'ja-tak' then _loadCalendarJatak()
  //if type is equal to 'ja-tak-live' then _loadCalendarJatakLive()
  //if type is equal to 'sms' then _loadCalendarSMS()
  //if type is equal to 'ok-screen' then _loadCalendarOkScreen()
  private _loadCalendar(type: string) {
    this.showRedmadCalendar = false;
    if (type === 'god-pris') {
      this._loadCalendarGoodPrice();
    }
    if (type === 'tilbud') {
      this._loadCalendarPromo();
    }
    if (type === 'nyheder') {
      this._loadCalendarNews();
    }
    if (type === 'arrangement') {
      this._loadCalendarEvent();
    }
    if (type === 'afstemninger') {
      this._loadCalendarPoll();
    }
    if (type === 'ja-tak') {
      this._loadCalendarJatak();
    }
    if (type === 'ja-tak-live') {
      this._loadCalendarJatakLive();
    }
    if (type === 'sms') {
      this._loadCalendarSMS();
    }
    if (type === 'ok-screen') {
      this._loadCalendarOkScreen();
    }

  }

//** refreshes the calendar */
  //checkCurrentCalendarType() check the current calendar type
  //_loadCalendar() to load the calendar
  private _refreshCalendar() {
    this._checkCurrentCalendarType();
    this._loadCalendar(this.currentCalendarType);
  }

//** load calendar of good Price */
  //subscribe to getPromos() => set currentCalendarData with the response promos
  //                            _createCalendarTimeLine()=> Add each offer into the Calendar as event
  //                            _sucess() => called once the calendar is loaded
  private _loadCalendarGoodPrice() {
    this._offerService
      .getPromos(this.dates.startDate, this.dates.endDate, 4)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (promos: Promo[]) => {
          this.currentCalendarData = promos;
          this._createCalendarTimeLine(promos, 'god-pris');
          this._success('god-pris');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** load calendar of promo */
  //subscribe to getPromos() => set currentCalendarData to the response promos
  //                            _createCalendarTimeLine()=> Add each offer into the Calendar as event
  //                            _success()
  private _loadCalendarPromo() {
    this._offerService
      .getPromos(this.dates.startDate, this.dates.endDate, 3)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (promos: Promo[]) => {
          this.currentCalendarData = promos;
          this._createCalendarTimeLine(promos, 'tilbud');
          this._success('tilbud');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** load calendar of News */
  // subscribe to getPromos() => set currentCalendarData to response news
  //                             _createCalendarTimeLine()=> Add each offer into the Calendar as event
  //                             _success()
  private _loadCalendarNews() {
    this._offerService
      .getPromos(this.dates.startDate, this.dates.endDate, 2)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (news: Promo[]) => {
          this.currentCalendarData = news;
          this._createCalendarTimeLine(news, 'nyheder');
          this._success('nyheder');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** load calendar event */
  //combineLatest() returns observable from combination of latest values of _getEvents(), _getSpecialEvents() and _getAsseblyEvents()
  //subscribe to combineLatest() => set calendarArrangements events with the value of response events
  //                                set calendarArrangements arsmode with the value of response specialEvents
  //                                set calendarArrangements forsamling-event with the value of response assemblyEvents
  //                                _createCalendarTimeline() => Add each offer into the Calendar as event
  //                                _success()
  private _loadCalendarEvent() {
    combineLatest([
      this._getEvents(),
      this._getSpecialEvents(),
      this._getAssemblyEvents()
    ])
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: ([events, specialEvents, assemblyEvents]) => {
          this.calendarArrangements['events'] = events;
          this.calendarArrangements['arsmode'] = specialEvents;
          this.calendarArrangements['forsamling-event'] = assemblyEvents;
          this.currentCalendarData = this.calendarArrangements;
          this._createCalendarTimeLine(
            this.calendarArrangements,
            'arrangement'
          );
          this._success('arrangement');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** load calendar of poll
  //subscribe to an observable returned by combineLatest() => set response polls to calendarAfstemningers afstemninger
  //                                                          set response specialPolls to calendarAfstemningers special
  //                                                          set response assemblyPolls to calendataAfstemningers forsamling-poll
  //                                                          createCalendarTimeLine() => Add each offer into the Calendar as event
  //                                                          _success()
  private _loadCalendarPoll() {
    combineLatest([
      this._getPolls(),
      this._getSpecialPolls(),
      this._getAssemblyPolls()
    ])
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: ([polls, specialPolls, assemblyPolls]) => {
          this.calendarAfstemningers['afstemninger'] = polls;
          this.calendarAfstemningers['special'] = specialPolls;
          this.calendarAfstemningers['forsamling-poll'] = assemblyPolls;
          this.currentCalendarData = this.calendarAfstemningers;
          this._createCalendarTimeLine(
            this.calendarAfstemningers,
            'afstemninger'
          );
          this._success('afstemninger');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** load calendar of Jatak
  // susbcribe to getJatak() => set response jataks to currentCalendarData
  //                            _createCalendarTimeLine()=> Add each offer into the Calendar as event
  //                            _success()

  private _loadCalendarJatak() {
    this._jatakService
      .getJatak(this.dates.startDate, this.dates.endDate)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (jataks) => {
          this.currentCalendarData = jataks;
          this._createCalendarTimeLine(jataks, 'ja-tak');
          this._success('ja-tak');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** load calendar of JatakLive
  // subscribe to _jatakLiveService => set response jataksLive to currentCalendarData
  //                                   _createCalendarTimeLine()=> Add each offer into the Calendar as event
  //                                   _success()
  private _loadCalendarJatakLive() {
    this._jatakLiveService
      .getJatakLive(this.dates.startDate, this.dates.endDate)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (jataksLive) => {
          this.currentCalendarData = jataksLive;
          this._createCalendarTimeLine(jataksLive, 'ja-tak-live');
          this._success('ja-tak-live');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** load Calendar of Sms //
  // subscribe to _clientService => set response smsList to currentCalendarData
  //                                _createCalendarTimeLine() => Add each offer into the Calendar as event
  //                                _success()
  private _loadCalendarSMS() {
    this._clientService
      .getClients()
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((clients) => {
          const solId = clients[0].id;
          return this._smsService.getSingleSmsLists(
            solId,
            this.dates.startDate,
            this.dates.endDate,
            1,
            10
          );
        })
      )
      .subscribe({
        next: (smsList) => {
          this.currentCalendarData = smsList;
          this._createCalendarTimeLine(smsList, 'sms');
          this._success('sms');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

// ** load calendar of OkScreen()
  //subscribe to getOkScreens() => set response okScreens to currentCalendarData
  //                               _createCalendarTimeLine() => Add each offer into the Calendar as event
  //                               _success()
  private _loadCalendarOkScreen() {
    this._okScreenService
      .getOkScreens(this.dates.startDate, this.dates.endDate)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (okScreens) => {
          this.currentCalendarData = okScreens;
          this._createCalendarTimeLine(okScreens, 'ok-screen');
          this._success('ok-screen');
        },
        error: (err) => this._errorHandlerService.handleError(err)
      });
  }

//** get events //
  // returns events lists within provided startDate and endDate
  private _getEvents(): Observable<Events[]> {
    return this._eventsService.getEvents(
      this.dates.startDate,
      this.dates.endDate
    );
  }

//** get special events //
  // if userType is 2 => return special events lists within provided startDate and endDate
  //else return empty array
  private _getSpecialEvents(): Observable<Events[]> {
    if (this.userType === '2') {
      return this._eventsService.getSpecialEvents(
        this.dates.startDate,
        this.dates.endDate
      );
    }
    return of([]);
  }

//**get assembly events //
  //if userType is 2 return assembly events lists within provided startDate and endDate
  //else return empty array
  private _getAssemblyEvents(): Observable<Events[]> {
    if (this.userType === '2') {
      return this._eventsService.getAssemblyEvents(
        this.dates.startDate,
        this.dates.endDate
      );
    }
    return of([]);
  }

//** get poll list
  //returns poll list within provided startDate and endDate
  private _getPolls(): Observable<Polls[]> {
    return this._pollService.getPollList(
      this.dates.startDate,
      this.dates.endDate
    );
  }

//** get special polls
  // if userType is 2 return poll list within the provided startDate and endDate
  //else return empty array
  private _getSpecialPolls(): Observable<Polls[]> {
    if (this.userType === '2') {
      return this._pollService.getSpecialPolls(
        this.dates.startDate,
        this.dates.endDate
      );
    }
    return of([]);
  }

// ** get assembly poll
  // if userType is 2 return poll list within the provided startDate and endDate
  //else return empty array
  private _getAssemblyPolls(): Observable<Polls[]> {
    if (this.userType === '2') {
      return this._pollService.getAssemblyPolls(
        this.dates.startDate,
        this.dates.endDate
      );
    }
    return of([]);
  }

  // ** Add each offer into the Calendar as event
  // if calendarRendered is true => removeAllEvents()
  //if type is 'tilbud' or 'god-pris' or 'nyheder' then loop through posts => local variable promoData is set with value return by                                                                                getCalendarDetails()
  //                                                                           addEvent() to add event
  //                                                                           set filterDates to empty array
  //if type is equal to 'arrangement' or 'afstemninger' then loop through posts => if posts has property eventType then loo[ through                    posts eventType => set variable event with the values of post
  //                                    addEvent() to add event
  //if type is equal to 'ja-tak' then loop through posts => set the variable event with the post values
  //                      addEvent() to add event
  //if type is equal to 'ja-tak-live' => set variable event with values of post
  //                                     addEvent() to add event

  private _createCalendarTimeLine(posts: any, type: string) {
    if (this.calendarRendered) {
      this.calendar.getApi().removeAllEvents();
    }
    if (type === 'tilbud' || type === 'god-pris' || type === 'nyheder') {
      posts.forEach((promo: any) => {
        const promoData: CalendarEvent = this.getCalendarDetails(promo);
        this.calendar.getApi().addEvent(promoData);
        this.filterDates = [];
      });
    }
    if (type === 'arrangement' || type === 'afstemninger') {
      for (const eventType in posts) {
        if (posts.hasOwnProperty(eventType)) {
          posts[eventType].forEach((post: Events) => {
            const event: CalendarEvent = {
              id: post.id,
              title: post.title,
              category: post.type,
              start: post.publishDate,
              end: post.expirationDate,
              display: 'block',
              borderColor: 'transparent',
              classNames: ['quick-event'],
              backgroundColor: '#282F36',
              shared: DefaultSharedValue,
              type: eventType
            };
            this.calendar.getApi().addEvent(event);
          });
        }
      }
    }

    if (type === 'afstemninger') {
      for (const eventType in posts) {
        if (posts.hasOwnProperty(eventType)) {
          posts[eventType].forEach((post: Events) => {
            const event: CalendarEvent = {
              id: post.id,
              category: post.type,
              title: post.title,
              start: post.startDate,
              end: post.endDate,
              display: 'block',
              borderColor: 'transparent',
              backgroundColor: '#282F36',
              shared: DefaultSharedValue,
              classNames: ['quick-event'],
              type: eventType
            };
            this.calendar.getApi().addEvent(event);
          });
        }
      }
    }

    if (type === 'ja-tak') {
      posts.forEach((post: any) => {
        const event: CalendarEvent = {
          id: post.id,
          category: post.type,
          title: post.title,
          start: post.startDate,
          end: post.endDate,
          display: 'block',
          borderColor: 'transparent',
          backgroundColor: '#282F36',
          shared: post.shared,
          classNames: ['quick-event']
        };
        this.calendar.getApi().addEvent(event);
      });
    }
    if (type === 'ja-tak-live') {
      posts.forEach((post: JatakLive) => {
        const event: CalendarEvent = {
          id: post.id as string,
          category: 'JA TAK Live',
          title: post.title,
          start: post.startDate,
          end: post.endDate,
          display: 'block',
          borderColor: 'transparent',
          classNames: ['quick-event'],
          backgroundColor: 'rgba(190, 31, 36, 0.4)',
          shared: DefaultSharedValue
        };
        this.calendar.getApi().addEvent(event);
      });
    }
    //if type is equal to 'sms' then loop through posts => event is set with the post values
    //                                                     addEvent() to add event
    //if type is 'ok-screen' then loop through posts => set event with the value of post
    //                                                  addEvent() to add event
    if (type === 'sms') {
      posts.forEach((post: { id: any; message: string; publishDate: any; expirationDate: any; }) => {
        const event: CalendarEvent = {
          id: post.id,
          category: 'SMS',
          title: CalendarComponent._processMessage(post.message),
          start: post.publishDate,
          end: post.expirationDate,
          display: 'block',
          borderColor: 'transparent',
          classNames: ['quick-event'],
          backgroundColor: 'rgba(190,31,36,0.4)',
          shared: {
            facebook: false,
            coop: false,
            sms: false,
            mail: false
          }
        };
        this.calendar.getApi().addEvent(event);
      });
    }
    if (type === 'ok-screen') {
      posts.forEach((post: OkScreenCalendar) => {
        const event: CalendarEvent = {
          id: post.id,
          category: 'Ok Screen',
          title: post.title,
          start: post.startDate,
          end: post.endDate,
          display: 'block',
          borderColor: 'transparent',
          backgroundColor: 'rgba(190, 31, 36, 0.4)',
          shared: {
            facebook: false,
            coop: false,
            sms: false,
            mail: false
          }
        };
        this.calendar.getApi().addEvent(event);
      });
    }
  }

  //** called when window resizes

  private _windowResize() {
    this.updateCalendarSize();
    this._createCalendarTimeLine(
      this.currentCalendarData,
      this.currentCalendarType
    );
  }

// ** handles week changes
  //@params[datesSet] provided by API
  //renderCalendarWithData() to render calendar with data
  private handleWeekChange(datesSet) {
    this.renderCalendarWithData(datesSet.start, datesSet.end);
  }

//** update Calendar size
  //updateSize() to update the size of calendar
  private updateCalendarSize() {
    this.calendar.getApi().updateSize();
  }

//** renders calendar with data
  //@params[start ,end] start and end date
  //const from is set with the transformed value of dataToString
  //const to is set with the transformed value of dateToString
  //object dates is created
  //_loadCalendar() to load the calendar
  private renderCalendarWithData(start: string, end: string) {
    const from = this._dateToString.transform(new Date(start));
    const to = this._dateToString.transform(
      moment(end).subtract(1, 'seconds').toDate()
    );
    this.dates = { startDate: from, endDate: to };
    this._loadCalendar(this.currentCalendarType);
  }

  // *** Add Shared Information to Event in Calendar ***
  //@params[eventInfo] => event object
  //const event is set with the eventInfo event
  //const el is set with the eventInfo el
  // const sharedList is the 'ul' element from window
  //'shared' and 'clearfix' class is added to sharedList
  //if event extendedProps shared coop is true then _renderShateData() => create Shared information and add to the Event Shared list
  //if event extendedProps shared facebook is then _renderShateData()
  //if event extendedProps shared sms is then _renderShateData()
  //if event extendedProps shared mail is then _renderShateData()
  //if event extendedProps shared web is then _renderShateData()
  //if event extendedProps shared screen is then _renderShateData()
  //if event extendedProps shared dit is then _renderShateData()
  private _eventRender(eventInfo: any): any {
    const el = eventInfo.el;
    const categoryName = eventInfo.event.extendedProps.category;
    const categoryElem = window.document.createElement('p');
    categoryElem.style.margin = '0';
    categoryElem.style.color = '#fff';
    categoryElem.style.fontSize = '13px';
    categoryElem.style.fontWeight = "400"
    categoryElem.innerText = categoryName;
    el.prepend(categoryElem);
    const event = eventInfo.event;
    const sharedList = this._windowService.window.document.createElement('ul');
    sharedList.classList.add('shared', 'clearfix');
    el.appendChild(sharedList);
    if (event.extendedProps.shared.coop) {
      this._renderSharedData(sharedList, 'coop', 'Coop App');
    }
    if (event.extendedProps.shared.facebook) {
      this._renderSharedData(sharedList, 'fb', 'Facebook');
    }
    if (event.extendedProps.shared.sms) {
      this._renderSharedData(sharedList, 'sms', 'SMS');
    }
    if (event.extendedProps.shared.mail) {
      this._renderSharedData(sharedList, 'mail', 'Mail');
    }
    if (event.extendedProps.shared.web) {
      this._renderSharedData(sharedList, 'web', 'Web');
    }
    if (event.extendedProps.shared.screen) {
      this._renderSharedData(sharedList, 'screen', 'Butiksskærme');
    }
    if (event.extendedProps.shared.dit) {
      this._renderSharedData(sharedList, 'dit', 'Digital avis');
    }
   if(event.extendedProps.shared.admin) {
     this._renderSharedData(sharedList, 'push', 'Skubbet af Admin');
   }

  }

  // *** Create Shared information and add to the Event Shared list
  //@params[list , classname , text] are the sharedList , classname of shared channel and text for the respective channel
  //const sharedItem is set with the window element 'li'
  //window text is appended to the list of sharedItem
  //classname is added to sharedItem classList
  //sharedItem is appended to the list i.e sharedList
  private _renderSharedData(list: any, classname: string, text: string) {
    const sharedItem = this._windowService.window.document.createElement('li');
    // sharedItem.appendChild(
    //   this._windowService.window.document.createTextNode(text)
    // );
    sharedItem.classList.add(classname);
    const icon = this._windowService.window.document.createElement('span');
    icon.classList.add('icon');
    switch (classname) {
      case 'coop':
        icon.innerHTML = '<ion-icon name="phone-portrait"></ion-icon>';
        break;
      case 'fb':
        icon.innerHTML = '<ion-icon name="logo-facebook"></ion-icon>';
        break;
      case 'sms':
        icon.innerHTML = '<ion-icon name="chatbubble-ellipses"></ion-icon>';
        break;
      case 'mail':
        icon.innerHTML = '<ion-icon name="mail"></ion-icon>';
        break;
      case 'web':
        icon.innerHTML = '<ion-icon name="globe"></ion-icon>';
        break;
      case 'screen':
        icon.innerHTML = '<ion-icon name="location"></ion-icon>';
        break;
      case 'dit':
        icon.innerHTML = '<ion-icon name="location"></ion-icon>';
        break;
    }
    sharedItem.appendChild(icon);
    sharedItem.appendChild(
      this._windowService.window.document.createTextNode(text)
    );

    list.appendChild(sharedItem);
  }

  // *** Change shared information to colored dots if the event width is small
  //@params[eventInfo] => event object
  //eventRender()=> add Shared Information to Event in Calendar
  //const el is set with the value of eventInfo el
  //if el clientWidth is less than or equal to 150 then add 'fc-col-small' to el classList
  private _afterRender(eventInfo: any) {
    this._eventRender(eventInfo);
    const el = eventInfo.el;
    if (el.clientWidth <= 150) {
      el.classList.add('fc-col-small');
    }
  }

//*** called right after the view has been added to the DOM
  private viewDidMount() {
    this.calendarRendered = true;
  }

//** process message
  //@params[message] is the post message
  // returns message in new form
  private static _processMessage(message: string) {
    return message.split('\n')[0];
  }

//@params[eventInfo] event object
  //const event is set with the eventInfo event
  //if event extendedProps type is present => if event extendedProps type is equal to 'arsmode' then navigate to provided url along with event id
  //                                          if event extendedProps type is equal to 'forsamling-event' then navigate to provided url along with event id
  //                                           if event extendedProps type is equal to 'special' then navigate to the provided url along with event id
  //                                           if event extendedProps type is equal to 'forsamling-poll' then navigate to the provided url along with event id
  //else navigate to the provided url along with event id
  private _eventClick(eventInfo: any) {
    const event = eventInfo.event;
    if (event.extendedProps.type) {
      if (event.extendedProps.type === 'arsmode') {
        this._router
          .navigate([`/${this.parentUrl}/kalendar/arsmode/` + event.id])
          .then();
        return;
      }
      if (event.extendedProps.type === 'forsamling-event') {
        this._router
          .navigate([
            `/${this.parentUrl}/kalendar/forsamling-event/` + event.id
          ])
          .then();
        return;
      }
      if (event.extendedProps.type === 'special') {
        this._router
          .navigate([`/${this.parentUrl}/kalendar/special/` + event.id])
          .then();
        return;
      }
      if (event.extendedProps.type === 'forsamling-poll') {
        this._router
          .navigate([`/${this.parentUrl}/kalendar/forsamling-poll/` + event.id])
          .then();
        return;
      }
    }
    this._router
      .navigate([
        `/${this.parentUrl}/kalendar/${this.currentCalendarType}/` + event.id
      ])
      .then();
  }

//** called once the calendar is loaded
  //@params[type] is the type of calendar loaded
  //if initialLoad is false => navigate to the provided url and set initialLoad to false
  //else initialLoad is set to false
  private _success(type: string) {
    // *** we donot navigate anywhere on initial load ***
    if (!this.initialLoad) {
      this._router
        .navigate([`/${this.parentUrl}/kalendar/${type}`])
        .then(() => (this.initialLoad = false));
    } else {
      this.initialLoad = false;
    }
  }
}
