import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {Observable} from 'rxjs';

import {Jatak, PriceInfo} from 'app/features/jatak/interface';
import {Client, Modes, UserDetail} from 'app/shared/interface';
import {DateFormatDDMMYY, DateToString, NumberPad} from 'app/shared/pipes';
import {SmsData, SmsJatakData, SmsPromoData, SmsService} from 'app/features/sms';
import {HelperService, TokenService} from 'app/core/services';

@Component({
  selector: 'coop-sendsms',
  templateUrl: './sendsms.component.html',
  styleUrls: ['./sendsms.component.scss'],
  providers: [DateToString, NumberPad, DateFormatDDMMYY]
})
export class SendsmsComponent implements OnChanges, AfterViewInit {
  @Input() modes: Modes;
  @Input() offerType: string;
  @Input() clients: Client[];
  @Input() promo: SmsData;
  @Input() tempSms: boolean;
  @Input() smsText: string;
  @Input() jatak: Jatak;
  @Input() smsMsg: string;
  @Input() sendDate: any;
  @Input() showErrorInput: boolean;
  @Input() editMode: boolean = false;
  @Input() readOnly: boolean;
  @Input() newsMode: boolean;
  @Input() goodPriceMode = false;
  @Input() jatakMode = false;
  @Input() templateMode = false;
  @Output() patchEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  client: Client;
  promoData: SmsPromoData;
  jatakData: SmsJatakData;
  smsMessage: string = '';
  msgLength: number = 0;
  linkLength: number = 0;
  staticMessage: string;
  promoMessage = 'Klik på følgende link:';
  jatakMessage: string = 'Afgiv din ordre her:';
  jatakSMSInfo: string = 'DENNE SMS-TEKST KAN IKKE BESVARES.';
  sms: {
    price: string;
    quantity: number;
  };
  transformText: any;
  showError: boolean;
  userDetail: UserDetail;
  message: string;


  constructor(
    private _smsService: SmsService,
    private _dateToStringPipe: DateToString,
    private _numberPadPipe: NumberPad,
    private _dateFormatPipe: DateFormatDDMMYY
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.userDetail = TokenService.getUserDetail();
    if (simpleChanges['smsMsg'] && !simpleChanges['smsMsg'].firstChange) {
      return;
    }

    if (simpleChanges['clients']) {
      this.client = simpleChanges['clients'].currentValue[0];
    }
    if (simpleChanges['promo']) {
      this.promo = simpleChanges['promo'].currentValue;
      this.transformText = this._transformValues();
      this._setUpSmsMessage();
    }
    if (simpleChanges['jatak']) {
      this.jatak = simpleChanges['jatak'].currentValue;

      this.transformText = this._transformJatakValues();
      this._setUpJatakMessage();
      this.checkForEmoji(this.smsMessage);
    }
    if (simpleChanges['readOnly']) {
      this.readOnly = simpleChanges['readOnly'].currentValue;
    }
  }

  ngAfterViewInit(): void {
    this.checkForEmoji(this.smsMessage);
  }

  private _transformValues() {
    // transforming the values
    let smsPrice: string;
    let smsPriceDescription: string;
    let smsOfferPrice: string;
    if (this.promo.inAppParameters.price) {
      smsPrice = this.newsMode
        ? ''
        : `${this.goodPriceMode ? 'God' : 'Før'} pris: ` +
        `${this._numberPadPipe.transform(
          this.promo.inAppParameters.price,
          'da-DA'
        )} kr. pr. ${this.promo.inAppParameters.priceDescription !== '1' ? this.promo.inAppParameters.priceDescription : ''} ${this.promo.inAppParameters.uom}`;
    } else {
      smsPrice = '';
    }
    if (this.promo.inAppParameters.price) {
      smsPriceDescription = this.newsMode
        ? ''
        : `${this.promo.inAppParameters.priceDescription}\n`;
    } else {
      smsPriceDescription = '';
    }
    if (this.promo.inAppParameters.offerPrice) {
      smsOfferPrice =
        this.newsMode || this.goodPriceMode
          ? ''
          : 'Tilbudspris: ' +
          `${this._numberPadPipe.transform(
            this.promo.inAppParameters.offerPrice,
            'da-DA'
          )} kr`;
    } else {
      smsOfferPrice = '';
    }

    return {
      title: `${SendsmsComponent._removeEmojis(this.promo.title)}\n`,
      price: smsPrice,
      offerPrice: smsOfferPrice,
      description: `${SendsmsComponent._removeEmojis(this.promo.longDescription)}\n`,
      publishdate: this.sendDate
        ? this._dateToStringPipe.transform(this.sendDate)
        : 'N/A',
      startDate: this.promo.startDate
        ? this._dateFormatPipe.transform(this.promo.startDate, true, true)
        : 'N/A',
      endDate: this.promo.endDate
        ? this._dateFormatPipe.transform(this.promo.endDate, true, true)
        : 'N/A',
      sms: `${this.smsText}\n`,
      clientStdText: this.client.standardText
        ? `${this.client.standardText.replace(/^\s+|\s+$/g, '')}\n\n`
        : '',
      clientCancelText: this.client.afmeldingText
        ? `${this.client.afmeldingText.replace(/^\s+|\s+$/g, '')}`
        : ''
    };
  }

  private _transformJatakValues() {
    // Helper function to transform a single price info
    const transformPriceInfo = (priceInfo: PriceInfo) => {
      const price = parseFloat(priceInfo.price); // Convert price to number
      if (price) {
        return `${this._numberPadPipe.transform(price, 'da-DA')} kr. pr. ${priceInfo.quantity !== '1' ? priceInfo.quantity : ''} ${priceInfo.uom}\n`;
      }
      return '';

    };
    return {
      title: this.jatak.title
        ? `${SendsmsComponent._removeEmojis(this.jatak.title)}\n`
        : '',
      price: !this.jatak.isVariant && this.jatak.prices.length > 0
        ? this.jatak.prices.map(transformPriceInfo).join('')
        : '',
      description: this.jatak.description ? `${this.jatak.description}\n` : '',
      publishdate: this._dateToStringPipe.transform(this.jatak.startDate),
      clientStdText: this.client.standardText
        ? `${this.client.standardText.replace(/^\s+|\s+$/g, '')}\n\n`
        : '',
      clientCancelText: this.client.afmeldingText
        ? `${this.client.afmeldingText.replace(/^\s+|\s+$/g, '')}`
        : ''
    };
  }

  private _setUpSmsMessage() {
    let parts;
    let length;
    this.promoData = {
      title: this.transformText['title'],
      price: this.transformText['price'],
      offerPrice: this.transformText['offerPrice'],
      priceDescription: this.transformText['priceDescription'],
      description: this.transformText['description'],
      publishdate: this.transformText['publishdate'],
      startDate: this.transformText['startDate'],
      endDate: this.transformText['endDate'],
      sms: this.transformText['sms'],
    };
    // setting up values
    if (this.tempSms && (this.smsText !== null || this.smsText === '')) {
      this.smsMessage = this.promoData.sms;
    }
    if (this.smsMsg !== '') {
      this._setSmsMessage();
    }

    this.staticMessage =
      this.transformText['clientStdText'] +
      this.transformText['clientCancelText'];

    if (!this.tempSms) {
      let fullmessage;
      let link = this.client.url
      let date = `Gælder fra ${this.promoData.startDate} till ${this.promoData.endDate}`
      let message = this.editMode || this.templateMode ? this.smsMsg : (this.smsMsg ? this.smsMsg : this.offerType === 'tilbud' ? `${this.promoData.title.substring(0, 99)}\n${this.promoData.offerPrice}\n${this.promoData.price}` : `${this.promoData.title.substring(0, 99)}\n${this.promoData.price}`)
      if (!this.newsMode || this.goodPriceMode) {
        fullmessage = `${message}\n${date}\n${link}\n\n${this.staticMessage}`
      } else {
        fullmessage = `${message}\n${link}\n\n${this.staticMessage}`
      }
      const {text: converted} = HelperService.smsParser(message)
      parts = HelperService.smsParser(fullmessage).parts
      length = HelperService.smsParser(fullmessage).length
      this.smsMessage = converted;
      this._buildFinalSmstext(link)
    }
    this.msgLength = length;
    this.getPriceandQuantity(parts)
  }


  setUpSendDate(date: Date) {
    this.sendDate = this._dateToStringPipe.transform(date);
  }

  private _buildFinalSmstext(link: string) {
    this.smsMessage = this.smsMessage.replace(link, '');
    this.smsMessage = this.smsMessage.replace('#LINK#', '');
    this.smsMessage = this.smsMessage.replace('Afgiv din ordre her:', '').trim();
    this.smsMessage = this.smsMessage.replace('DENNE SMS-TEKST KAN IKKE BESVARES.', '').trim();
    this.smsMessage = this.smsMessage.replace('For at afgiv din ordre, klik på følgende link:', '').trim();
    this.smsMessage = this.smsMessage.replace('Klik på følgende link:', '').trim();
    this.smsMessage = this.smsMessage.replace(this.client.standardText, '');
    this.smsMessage = this.smsMessage.replace(this.client.afmeldingText, '');
    this.smsMessage = this.smsMessage.trimEnd();
    // Split the text into lines
    let lines: string[] = this.smsMessage.split('\n');

    // Filter out the line that starts with "Gælder"
    lines = lines.filter(line => !line.startsWith('Gælder'));

    // Join the lines back into a single string
    this.smsMessage = lines.join('\n');

    // Final trim to clean up any extra whitespace
    this.smsMessage = this.smsMessage.trim();

  }

  private _setUpJatakMessage() {
    let parts;
    let length;
    this.jatakData = {
      title: this.transformText['title'],
      price: this.transformText['price'],
      description: this.transformText['description'],
      start_date: this.transformText['publishdate'],
    };
    if (this.smsMsg !== '') {
      this._setSmsMessage();
    }
    this.staticMessage =
      this.transformText['clientStdText'] +
      this.transformText['clientCancelText'];

    if (!this.tempSms) {
      let fullmessage;
      let link = `https://qcapi.quickinfo.dk/jatak/single/1234567`;
      let staticText = `${this.jatakMessage}\n\n${link}\n\n${this.staticMessage}`
      let message = this.editMode || this.templateMode ? this.smsMsg : (this.smsMsg ? this.smsMsg : `${this.jatakData.title.substring(0, 99)}\n${this.jatakData.price}`);
      fullmessage = this.editMode || this.templateMode ? this.smsMsg : message + staticText
      const {text: converted} = HelperService.smsParser(message)
      parts = HelperService.smsParser(fullmessage).parts
      length = HelperService.smsParser(fullmessage).length
      this.smsMessage = converted;
      this._buildFinalSmstext(link)
    }
    this.msgLength = length;
    this.getPriceandQuantity(parts)
  }


  // send used by promo crud to send sms
  send(mobiWebId: string, promoId: string): Observable<any> {
    const message =
      this.smsMessage + this.client.standardText + this.client.afmeldingText;
    return this._smsService.sendSms({
      content: message,
      'solutions[]': this.client.id,
      mobiwebid: mobiWebId,
      offerId: promoId,
      sendTime: this.promoData.publishdate
    });
  }

  getSmsText(): string {
    if (!this.newsMode && !this.goodPriceMode && !this.jatakMode) {
      let text = `Gælder fra ${this.promoData.startDate} til ${this.promoData.endDate}`;
      return `${this.smsMessage}\n\n${text}\n\n${this.promoMessage}\n\n#LINK#\n\n${this.client.standardText ? this.client.standardText : ''}${this.client.afmeldingText}`;
    } else if (this.jatakMode) {
      return `${this.smsMessage}\n\n${this.jatakMessage}\n\n#LINK#\n\n${this.client.standardText ? this.client.standardText : ''}${this.client.afmeldingText}`;
    }
    return `${this.smsMessage}\n\n${this.promoMessage}\n\n#LINK#\n\n${this.client.standardText ? this.client.standardText : ''}${this.client.afmeldingText}`;
  }


  // sms parse


  // on model change , calculates the sms price
  processSms(text: string) {
    const userInput = text;
    let link = `https://qcapi.quickinfo.dk/jatak/single/1234567`;
    const staticText = `${this.jatakMessage}\n\n${link}\n\n${this.staticMessage}`;
    let fullMessage: string;
    if (userInput) {
      fullMessage = `${userInput}\n${staticText}`;
    } else {
      fullMessage = staticText;
    }
    const {text: abc, length, parts} = HelperService.smsParser(fullMessage);
    setTimeout(() => {
      this.smsMessage = HelperService.smsParser(userInput).text;
    }, 0);

    this.msgLength = length;
    this.getPriceandQuantity(parts);
    text.replace(link, '#LINK#');
    this.patchEvent.emit(text);
  }


  processSmsForPromo(text: string) {
    const userInput = text;
    let date = `Gælder fra ${this.promoData.startDate} till ${this.promoData.endDate}`;
    let link = this.client.url;
    let staticText: string;

    if (this.goodPriceMode || !this.newsMode) {
      staticText = `${date}\n${link}\n\n${this.staticMessage}`;
    } else {
      staticText = `${this.promoMessage}\n\n${link}\n\n${this.staticMessage}`;
    }
    let fullMessage: string;
    if (userInput) {
      fullMessage = `${userInput}\n\n${staticText}`;
    } else {
      fullMessage = staticText;
    }
    const {length, parts} = HelperService.smsParser(fullMessage);
    setTimeout(() => {
      this.smsMessage = HelperService.smsParser(userInput).text;
    }, 0);
    this.msgLength = length;
    this.getPriceandQuantity(parts);
    text.replace(link, '#LINK#');
    this.patchEvent.emit(text);
  }

  // tocheck for emoji
  checkForEmoji(text: string) {
    const regex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    if (text.match(regex)) {
      this.showError = true;
    }
    if (!text.match(regex)) {
      this.showError = false;
    }
    this.valid.emit(this.showError);
  }

  private _setSmsMessage() {

    this.smsMessage = this.smsMsg;
    this.smsMessage = this.smsMessage.replace('\n#LINK#', '');
    this.smsMessage = this.smsMessage.replace('\n\nFor at afgiv din ordre, klik på følgende link:', '').trim();
    this.smsMessage = this.smsMessage.replace(this.client.standardText, '');
    this.smsMessage = this.smsMessage.replace(this.client.afmeldingText, '');

    this.smsMessage = this.smsMessage.trim();
  }

  // calculate the total length of sms message
  // TODO: change linklength  in case of jatak
  private _getMessageLength() {
    this.linkLength = this.jatak ? 33 : this.client.url.length;
    const stdTxtLength = this.client.standardText
      ? this.client.standardText.length
      : 0;
    const afmTxtLength = this.client.afmeldingText
      ? this.client.afmeldingText.length
      : 0;
    const staticMessageLength = this.jatak ? this.jatakMessage.length + this.jatakSMSInfo.length : this.promoMessage.length + this.jatakSMSInfo.length;
    return (
      this.smsMessage.length + this.linkLength + stdTxtLength + afmTxtLength + staticMessageLength
    );
  }

  // calculate the price of sms
  // min length for 1 sms is 165 and 1sms price is 0.12
  // private _calculatePrice(msgLen: number) {
  //   const noOfSMS = Math.ceil(msgLen / 165);
  //   const smsPrice = this._numberPadPipe.transform(
  //     noOfSMS * this.client.members * this.userDetail.sms.cost,
  //     'da-DA'
  //   );
  //   return {
  //     price: smsPrice,
  //     quantity: noOfSMS,
  //   };
  // }
  // calculate the price of sms
  // min length for 1 sms is 165 and 1sms price is 0.12
  getPriceandQuantity(part: number) {
    let price = part * this.userDetail.sms.cost * this.client.members;
    this.sms = {
      price: this._numberPadPipe.transform(price, 'da-DA'),
      quantity: part
    };
  }

  // to remove any  kind of emojis in title for sms
  private static _removeEmojis(str: string): string {
    return str;
    // const regex =
    //   /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    // return str.replace(regex, '');
  }
}
