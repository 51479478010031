import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeWhile} from 'rxjs/operators';

import {
  ClientService,
  ErrorhandlerService,
  FormFunctionsService,
  HelperService,
  NotificationsService
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate,
  checkIfExpDateIsLessThanPublishDate,
  checkIfExpireEndMismatch,
  checkIfOfferPriceLessThanReal, checkMaxLength,
  checkPriceMinimum,
  checkPriceRegex,
  checkWhiteSpace, customMinValidator
} from 'app/core/validators';
import {
  COMMON_DATETIMEPICKER_SETTINGS,
  COMMON_SELECT_SETTINGS
} from 'app/shared/data';
import { DatepickerData, Modes, ValidationMessage } from 'app/shared/interface';
import {DateToString} from 'app/shared/pipes';
import {
  INITIAL_FORM_DATA,
  EmailData,
  NyhedTilbud,
  EmailList,
  OfferDateTimeService,
  OfferStateService, OfferDataService
} from 'app/features/offer';
import * as moment from 'moment';
import * as _ from 'lodash';
import {MbscDatepickerOptions} from '@mobiscroll/angular';
import {DatepickerBase} from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';

@Component({
  selector: 'coop-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
  providers: [DateToString]
})
export class EmailFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() modes: Modes;
  @Input() tab: string = ''
  @Input() validationMessages: ValidationMessage;
  @Input() userType: string;
  @Input() validateModes = [];
  @Input() currentTab = 'coop';
  @Input() offerType: string = 'tilbud';
  @Output() formReady = new EventEmitter<FormGroup>();
  formData?: NyhedTilbud = INITIAL_FORM_DATA;
  emailForm: FormGroup;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.png',
    thumbnail: ''
  };
  hasImage = false;
  isProcessing = false;
  panel: any;
  selMailId: number;
  mailSent = false;
  mail: EmailData;
  mailList: EmailList[];
  emailSave = false;
  mailSelectCounter = false;
  isPostedToFb: boolean = false;
  currentDateTime: string;
  visible: boolean = false;
  numbers = [];
  priceDescSelectSettings = {
    ...COMMON_SELECT_SETTINGS, ...{
      data: [
        'stk',
        'kg',
        'g',
        'liter',
        'ml',
        'pakke',
        'pakker',
        'sæt',
        'kasse',
        'kasser',
        'ramme',
        'rammer'
      ]
    }
  };
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setUpDateTime(inst),
      onClose: (_, inst) => this._closeDatePicker(inst)
    }
  };
  isAccordion: boolean = true
  showMailList: boolean = false
  titleCount: any = 0
  showPriceSection: boolean = false
  private _subscriptionState = true;
  startEndDatePicker: DatepickerData;
  publishStartEndPicker: DatepickerData;
  constructor(
    private _formBuilder: FormBuilder,
    private _dateToString: DateToString,
    private _clientService: ClientService,
    private _formStateService: OfferStateService,
    private _errorHandlerService: ErrorhandlerService
  ) {
    this.currentDateTime = moment().format('YYYY-MM-DD HH:mm');

  }

  ngOnInit() {
    this.initializeForms();

    this.startEndDatePicker = {
      titles: {
        start: 'Gyldig fra - nu',
        end: 'Gyldig til',
      },
      startEndDateTime: {
        start: this.emailForm.get('startDate').value,
        end: this.emailForm.get('endDate').value,
      },
      disable: {
        end: false,
        start: false,
      },
      modes: {
        isTemplate: this.modes.templateMode,
        isEditMode: this.modes.editMode,
        isDuplicate: false,
      },
    };

    this.publishStartEndPicker = {
      titles: {
        start: 'Udsendingstidspunkt - nu',
        end: 'Afslutningstidspunkt',
      },
      startEndDateTime: {
        start: this.emailForm.get('publishDate').value,
        end: this.emailForm.get('expirationDate').value,
      },
      disable: {
        end: false,
        start: false,
      },
      modes: {
        isTemplate: this.modes.templateMode,
        isEditMode: this.modes.editMode,
        isDuplicate: false,
      },
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['offerType']) {
      this.offerType = changes['offerType'].currentValue;
    }
    if (changes['modes']) {
      this.modes = changes['modes'].currentValue;
    }
    if (changes['currentTab']) {
      if (changes['currentTab'].currentValue === 'mail') {
        this.initializeForms();
      }
    }
  }

  initializeForms() {
    if (!this.emailForm) {
      this._setupEmailForm();
    }
    if (!this.modes.newsMode && (this.offerType === 'tilbud')) {
      this._setPriceValidators();
    }
    this.formData = this._formStateService.formState.value;
    if (
      this.formData.mail?.isSet ||
      this.modes.editMode ||
      this.modes.templateMode
    ) {
      this._editModeSetup(this.formData);
    }
    if (!this.modes.editMode && !this.modes.templateMode) {
      // OfferDateTimeService.setDefaultTimeOnCreate(this.emailForm);
      this.createEmailForm();
    }
    if (this.formData.mail?.isSet || this.modes.editMode) {
      this.setEmail();
    }
    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this.showPriceSection = true
    }
    this.formReady.emit(this.emailForm);

    this.emailForm.get('title').valueChanges.subscribe((res) => {
      this.titleCount = this.calculateLength(res);
    });
  }

  calculateLength(str: string) {
    return _.size(str);
  }

  onPreventDefault(event: any) {
    if (this.titleCount >= 60 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }


  ngOnDestroy() {
    this._subscriptionState = false;
  }

  public get getForm() {
    return this.emailForm;
  }

  createEmailForm() {
    const firstValidOption = this.validateModes[0];
    const parentForm = this.formData[firstValidOption];
    if (firstValidOption !== 'mail') {
      if (FormFunctionsService.checkparentFormEmpty(parentForm)) {
        return;
      }
      // should not patch anything if its the only selected tab
      if (FormFunctionsService.checkFormEmpty(this.getForm.value)) {
        this.getForm.patchValue({
          title: parentForm.title,
          shortDescription: OfferDataService.descriptionChanges(firstValidOption, this.modes.newsMode, parentForm.longDescription),
          longDescription: '',
          inAppParameters: {
            price: parentForm.inAppParameters.price,
            offerPrice: parentForm.inAppParameters.offerPrice,
            priceDescription: parentForm.inAppParameters.priceDescription,
            uom: parentForm.inAppParameters.uom
          },
          publishDate: parentForm.publishDate,
          expirationDate: parentForm.expirationDate,
          startDate: parentForm.startDate,
          endDate: parentForm.endDate,
          thumbnail: this.modes.newsMode && this.offerType !== "tilbud" ? '' : parentForm.thumbnail,
          alcohol: parentForm.alcohol,
        });
        if (firstValidOption === 'sms') {
          // sms doesnt have other dates, so we set to default
          OfferDateTimeService.setDefaultTimeOnCreate(this.emailForm);
        }
        this._patchFormMedias();
        this.startEndDatePicker = FormFunctionsService.populateDateRangePicker(
          this.startEndDatePicker,
          this.emailForm.get('startDate').value,
          this.emailForm.get('endDate').value
        );

        this.publishStartEndPicker =
          FormFunctionsService.populateDateRangePicker(
            this.publishStartEndPicker,
            this.emailForm.get('publishDate').value,
            this.emailForm.get('expirationDate').value
          );
      }

      if (!this.modes.newsMode && this.offerType === 'good-price') {
        this.showPriceSection = this.checkInAppParametersValues(parentForm)
        this.getForm.get('inAppParameters').patchValue({
          price: parentForm.inAppParameters.price,
          priceDescription: parentForm.inAppParameters.priceDescription,
          uom: parentForm.inAppParameters.uom,
        })
      }
    }
    if (this.getForm) {

      if (FormFunctionsService.checkFormEmpty(this.getForm.value)) {
        OfferDateTimeService.setDefaultTimeOnCreate(this.emailForm);
      }
      this.getMail();
    }
  }

  getImagesUrl(images: any) {
    this.emailForm.patchValue({
      thumbnail: images.thumbnail
    });
    Object.assign(this.imageUrl, images);
    this.hasImage = true;
  }

  imageProcessing(e: boolean) {
    this.isProcessing = e;
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  emailToggle() {
    this.showMailList = !this.showMailList;
    const promoForm = this.emailForm;
    if (!promoForm.value.expirationDate) {
      NotificationsService.notify('Vælg venligst slutdatoen', 'error', 'top');
      return;
    }
  }

  saveRad(id: number) {
    if (this.selMailId === id) {
      this.emailUnset();
    } else {
      this.selMailId = id;
      this.emailForm.patchValue({
        mailId: this.selMailId.toString()
      });
    }
  }

  // get the mail list
  // @params [enddate] => end date of the offer
  // @params [id] => id of the offer
  getMail() {
    let id;
    // runs when epxirationDate value is not recevied on component load at first
    if (!this.getForm.value.expirationDate) {
      return;
    }
    const endDate = this._dateToString.transform(
      this.getForm.value.expirationDate
    );
    if (this.modes.editMode || this.modes.templateMode) {
      id = this.formData.id;
    } else {
      id = '';
    }
    this._clientService
      .getMailList({
        endDate: endDate,
        offerId: id,
        board: this.userType === '2' ? 1 : 0
      })
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => this._mailSuccess(res),
        error: (err) => {
          this._handleError(err);
          this.mailList = [];
        }
      });
  }

  setEmail() {
    if (+this.formData.mail.mailId > 0) {
      this.selMailId = +this.formData.mail.mailId;
      this.getForm.value.mailId = this.selMailId;
    } else {
      this.selMailId = null;
    }
  }

  emailUnset() {
    this.selMailId = null;
    this.emailForm.patchValue({
      mailId: this.selMailId
    });
  }

  private _setupEmailForm() {
    const validators = [
      checkIfExpDateIsLessThanPublishDate
    ];
    if (!this.modes.newsMode) {
      validators.push(checkIfExpireEndMismatch);
      validators.push(checkIfEndDateIsLessThanStartDate);
    }
    this.emailForm = this._formBuilder.group(
      {
        title: [
          '',
          [Validators.required, checkWhiteSpace, checkMaxLength]
        ],
        thumbnail: ['', Validators.required],
        startNow: [],
        publishNow: [],
        publishDate: ['', Validators.required],
        expirationDate: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        shortDescription: [
          '',
          [Validators.required, checkWhiteSpace, Validators.maxLength(250)]
        ],
        longDescription: [''],
        inAppParameters: this._formBuilder.group(
          {
            price: [''],
            offerPrice: [''],
            priceDescription: [''],
            uom: ['']
          },
          {validators: checkIfOfferPriceLessThanReal}
        ),
        mailId: ["", Validators.required],
        alcohol: [],

      },
      {
        validators: validators
      }
    );
  }

  checkInAppParametersValues(parentForm): boolean {
    if (parentForm) {
      const price = parentForm.inAppParameters.price
      const priceDescription = parentForm.inAppParameters.priceDescription;
      const uom = parentForm.inAppParameters.uom;
      return price !== '' && priceDescription !== '' && uom !== '';
    }
    return false;
  }

  private updateValidation() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    validators.push(Validators.required);
    this._updateValidators("inAppParameters.priceDescription", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex
    ]);
    this._updateValidators("inAppParameters.price", [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex
    ]);
  }

  addPriceField() {
    this.showPriceSection = true
    this.updateValidation()
    this.emailForm.get('inAppParameters').patchValue({
      uom: 'stk'
    })
  }

  removePrices() {
    this.showPriceSection = false
    this.removePriceValidators()
  }

  removePriceValidators(): void {
    this._updateValidators("inAppParameters.priceDescription", null);
    this._updateValidators("inAppParameters.price", null);
    this.emailForm.get('inAppParameters').patchValue({
      price: '',
      priceDescription: '',
      uom: ''
    })
  }

  private _updateValidators(formControl: string, validators = []): void {
    this.emailForm.get(formControl).setValidators(validators);
    this.emailForm.get(formControl).updateValueAndValidity();
  }

  private _editModeSetup(promo: NyhedTilbud) {
    this.getForm.patchValue(promo.mail);
    this.emailForm.get('inAppParameters').patchValue({
      uom: promo.mail.inAppParameters.uom.toLowerCase()
    })
    if (promo.mail.longDescription === null) {
      this.emailForm.patchValue({
        longDescription: ''
      })
    }
    this._patchFormMedias();
    this.getMail();
    if (promo.mail && promo.mail.startDate) {
      if (this.currentDateTime > promo.mail.startDate) {
        this.isPostedToFb = true;
      }
    }

    if (!this.modes.newsMode && this.offerType === 'good-price' && promo.mail.inAppParameters.price !== '') {
      this.showPriceSection = true
      this.updateValidation()
    }
  }

  onSelect() {
    this.visible = true;
  }

  ondeselect() {
    this.visible = false;
  }

  private _patchFormMedias() {
    let firstSharedChannel = this.validateModes[0];

    if (this.modes.editMode || this.modes.templateMode) {
      this.hasImage = true;
      this.imageUrl.largeImage = this.getForm.value.thumbnail;
      return;
    }
    if (this.modes.newsMode && this.offerType === 'tilbud' && firstSharedChannel === 'coop') {
      this.hasImage = true;
      this.imageUrl.largeImage = this.getForm.value.thumbnail;
      if (!this.imageUrl.largeImage) {
        this.hasImage = false;
      }
    } else {
      this.hasImage = false;

    }
  }

  private _mailSuccess(res) {
    this.mail = res;
    this.mailList = this.mail['mails'];
    if (+this.mail['mailUsed'] > 0) {
      this.emailSave = true;
      if (this.mailList.length === 0) {
        this.mailSent = true;
        if (this.mailSelectCounter) {
          // in edit mode if the back date has no email template
          this.mailSent = false;
          this.emailUnset();
        }
      }
      if (this.mailList.length !== 0) {
        this.mailSent = true;
        for (let i = 0; i < this.mailList.length; i++) {
          if (this.mailList[i].id === this.mail['mailUsed']) {
            this.mailSent = false;
            if (!this.mailSelectCounter) {
              this.mailSelectCounter = true; // in editmode if selected mail is not sent
            }
            break;
          }
          if (this.mailList[i].id !== this.mail['mailUsed']) {
            if (this.mailSelectCounter) {
              this.mailSent = false;
            }
          }
        }
      }
    }
    if (+this.mail['mailUsed'] < 0) {
      if (this.mailList.length === 0) {
        this.emailSave = false;
        this.emailUnset();
      }
      if (this.mailList.length > 0) {
        this.emailSave = true;
        this.emailToggle();
      }
    }
    this._checkIfSelectedMailIsInList();
    this.formReady.emit(this.emailForm);
  }

  private _checkIfSelectedMailIsInList() {
    let mailAvailable = false;
    const mailId = this.getForm.value.mailId;
    if (this.mailList.length > 0) {
      for (let i = 0; i < this.mailList.length; i++) {
        if (this.mailList[i].id === mailId) {
          mailAvailable = true;
          break;
        }
      }
      if (!mailAvailable && !this.mailSent) {
        this.emailUnset();
      }
    }
  }

  private _setPriceValidators() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    if (this.offerType === "tilbud") {
      validators.push(Validators.required);
      this._updateValidators('inAppParameters.priceDescription', [
        customMinValidator(1),
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex
      ]);
      this._updateValidators('inAppParameters.price', [
        customMinValidator(1),
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex
      ]);
    }
    if (this.offerType === 'tilbud') {
      this._updateValidators('inAppParameters.offerPrice', validators);
    }
  }

  // date time value set for mobiscroll date-time
  private _setUpDateTime(inst: DatepickerBase) {
    if (!this.modes.editMode) {
      OfferDateTimeService.setUpDateTime(this.emailForm, inst._el.id, 'mail');
    }
  }

  // on close of datetime
  private _closeDatePicker(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (dateId === 'expiremail') {
      this.getMail();
    }
    if (this.modes.newsMode) {
      this._patchStartEndDatesForNewsMode(dateId);
    }
  }

  private _patchStartEndDatesForNewsMode(dateId) {
    const promoForm = this.getForm;
    if (dateId.indexOf('publishmail') === 0) {
      promoForm.patchValue({
        startDate: promoForm.value.publishDate
      });
    }
    if (dateId.indexOf('expiremail') === 0) {
      promoForm.patchValue({
        endDate: promoForm.value.expirationDate
      });
    }
  }

  getStartDateTime(event: any) {
    this.emailForm.patchValue({
      startDate: event.startDateTime,
      endDate: event.endDateTime,
      startNow: event.start_now
    }, {
      onlySelf: true
    })
  }

  getPublishExpirationDateTime(event: any) {
    this.emailForm.patchValue({
      publishDate: event.startDateTime,
      expirationDate: event.endDateTime,
      publishNow: event.start_now
    }, {
      onlySelf: true
    })
  }

  private _handleError(error): void {
    this._errorHandlerService.handleError(error || {code: -400}, 'promo');
    this.isProcessing = false;
  }
}
