import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { EditorOptions } from "app/shared/interface";
import {
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_decorate,
  plugin_decorate_locale_en_gb,
  markup_editor_defaults,
  plugin_sticker,
  plugin_sticker_locale_en_gb,
  markup_editor_locale_en_gb,
  createMarkupEditorShapeStyleControls,
  createMarkupEditorToolbar,
  createMarkupEditorToolStyles,
  createDefaultImageOrienter
} from "@pqina/pintura";
import { da_locale, DEFAULT_DOKA_OPTIONS } from "app/shared/data";
import { AppEventsService } from "app/core/services";

const STICKER_URL = "assets/vendor/pintura-image-editor/stickers/";
const PRESET_URL = "assets/vendor/pintura-image-editor/presets/";

@Component({
  selector: "coop-image-editor",
  templateUrl: "./image-editor.component.html",
  styleUrls: ["./image-editor.component.scss"]
})
export class ImageEditorComponent implements OnInit {
  @Output() imageProcessed: EventEmitter<string> = new EventEmitter<string>();
  @Output() imageLoaded: EventEmitter<null> = new EventEmitter();
  @Output() closeEditor: EventEmitter<null> = new EventEmitter();

  @Input() mediaFile: any;
  @Input() aspectRatio: number;
  @Input() customOptions: EditorOptions = {};
  @Input() currentLanguage: string;

  dokaOptions: EditorOptions = DEFAULT_DOKA_OPTIONS;
  isVisible = true;
  redFrame = `${STICKER_URL}800x800-red.png`;
  whiteFrame = `${STICKER_URL}800x800-white.png`;
  rectangleRedFrame = `${STICKER_URL}1200x600-red.png`;
  rectangleWhiteFrame = `${STICKER_URL}1200x600-white.png`;
  emoji = `${STICKER_URL}loveFace.png`;
  googlesEmoji = `${STICKER_URL}goggles.png`;
  musclesEmoji = `${STICKER_URL}muscle-emoji.png`;
  omgEmoji = `${STICKER_URL}OMG-Emoji.png`;
  okEmoji = `${STICKER_URL}ok-hand-emoji.png`;
  partyEmoji = `${STICKER_URL}party-emoji.png`;
  partyPopperEmoji = `${STICKER_URL}party-popper-emoji.png`;
  laughingEmoji = `${STICKER_URL}laughing-emoji.png`;
  starEyesEmoji = `${STICKER_URL}star-eyes-emoji.png`;
  winkEmoji = `${STICKER_URL}wink-emoji.png`;
  droolingEmoji = `${STICKER_URL}drooling-emoji.png`;
  kissEmoji = `${STICKER_URL}kiss-emoji.png`;
  fireEmoji = `${STICKER_URL}fire-emoji.png`;
  shopCartEmoji = `${STICKER_URL}shopping-trolley-emoji.png`;
  burgerEmoji = `${STICKER_URL}burger-emoji.png`;
  friesEmoji = `${STICKER_URL}fries-emoji.png`;
  pizzaEmoji = `${STICKER_URL}pizza-emoji.png`;
  breadEmoji = `${STICKER_URL}bread-emoji.png`;
  cakeSliceEmoji = `${STICKER_URL}cake-slice-emoji.png`;
  plateEmoji = `${STICKER_URL}plate-emoji.png`;
  steakEmoji = `${STICKER_URL}steak-emoji.png`;
  poultryLegEmoji = `${STICKER_URL}poultry-leg-emoji.png`;

  fullRedSquarePreset = `${PRESET_URL}full_red_square.svg`;
  yellowCirclePreset = `${PRESET_URL}yellow_circle.svg`;
  yellowStarPreset = `${PRESET_URL}yellow_star.svg`;
  doubleRedCircle = `${PRESET_URL}double_red_circle.svg`;
  doubleSquare = `${PRESET_URL}double_square.svg`;
  fullRedCicle = `${PRESET_URL}full_red_circle.svg`;

  src: any = "assets/images/camera@3x.jpg";

  //** editor state
  //The user interface and plugin locale objects
  locale = {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...plugin_sticker_locale_en_gb,
    ...markup_editor_locale_en_gb
  };
  //** editor configuration
  options: any = {
    imageOrienter: createDefaultImageOrienter(),//the object to use to correctly orient preview image data.
    imageReader: createDefaultImageReader({ // This will read the image data
      orientImage: true
    }),
    ...markup_editor_defaults,//The markup editor default options, tools, shape style controls
    ...this.closeEditorSettings()
  };

  constructor(private _appEvents: AppEventsService) {
  }
//** initialize component
  //set options with provided values
  ngOnInit() {
    this.initDoka();
    this.src = this.mediaFile;//set the initial image source.
    this._appEvents.showLoader(false); // hide loaded
    this.options = {
      ...this.options,
      ...this.localSettings(),
      ...this.imageWriterSettings(this.aspectRatio),
      ...this.decorateSettings(),
      ...this.cropSettings(),
      ...this.stickersSettings(),
      ...this.markupSettings()
    };
  }
//set danish local
  danishLocal(): any {
    return da_locale;
  }
//** handle Modal process
  //@params[event] event object
  handleModalProcess($event: any) {
    this.emitImageProcessedEvent($event.dest);
  }
//** handle modal load
  handleModalLoad() {
    this.imageLoaded.emit(); //emit data for parent component
  }
//** close editor
  onCloseEditor(): void {
    this.closeEditor.emit();
  }
//**emit image processed event
  //@params[image] event dest data that contains data of media file
  private emitImageProcessedEvent(image: string): void {
    this.imageProcessed.emit(image);
  }

  //dokaOptions is set with the concatinated value of DEFAULT_DOKA_OPTIONS AND customOptions
  private initDoka(): void {
    this.dokaOptions = { ...DEFAULT_DOKA_OPTIONS, ...this.customOptions };
    setPlugins(...this.getPlugins());
  }
//**get Image output config
//@params[aspectRatio] defines the ratio of file uploaded
  //if aspectRatio is 2 => return makeImageOutputObject with the given height and width
  //                  else return makeImageOutputObject with the given height and width
  private getImageOutputConfig(aspectRatio: number): any {
    if (aspectRatio === 2) {
      return this.makeImageOutputObject(600, 1200);
    }
    return this.makeImageOutputObject(800, 800);
  }
//**get frames for media file being uploaded
  //@params[aspectRatio] defines the ratio of file uploaded
  //if aspectRatio is 2 => return rectangle frame
  //else return square frame
  private getFrames(aspectRatio: number): Array<any> {
    if (aspectRatio === 2) {
      return [
        this.makeFrameObject(this.rectangleRedFrame),
        this.makeFrameObject(this.rectangleWhiteFrame)
      ];
    }
    return [
      this.makeFrameObject(this.redFrame),
      this.makeFrameObject(this.whiteFrame)
    ];
  }
//** makes frame object
  //@params[source] source data of uploaded file
  private makeFrameObject(source: string) {
    return {
      src: source,
      width: "100%",
      height: "100%"
    };
  }
//** make image output object
  //@params[height, width] height and width of file uploaded
  private makeImageOutputObject(height: number, width: number) {
    return {
      width,
      height,
      upscale: true,
      fit: "force"
    };
  }
  //**image writer setting for editor configuration
  //@params[aspectRatio] ratio of file uploaded
  private imageWriterSettings(aspectRatio: number) {

    return {
      imageWriter: createDefaultImageWriter({
        targetSize: this.getImageOutputConfig(aspectRatio)
      })
    };
  }
//** set local settings
  private localSettings() {
    return {
      locale: {
        ...this.locale,
        ...this.danishLocal()
      }
    };
  }
//**stickers setting for editor configuration
  //if dokaOptions sticker is false return empty
  //else return stickers
  private stickersSettings() {
    if (!this.dokaOptions.sticker) {
      return {};
    }
    return {
      stickers: this.getStickers()
    };
  }
//** close editor setting
  private closeEditorSettings() {
    return {
      enableButtonClose: true
    };
  }
//** decorate settings for editor configuration
  //if dokaOptions decorate is false => return
  // else return provided value
  private decorateSettings() {
    if (!this.dokaOptions.decorate) {
      return {};
    }
    return {
      decorateActiveTool: this.getActiveTool(),
      decoratePresets: [
        this.fullRedSquarePreset,
        this.yellowCirclePreset,
        this.yellowStarPreset,
        this.doubleRedCircle,
        this.doubleSquare,
        this.fullRedCicle
      ]
    };
  }
//crop settings for editor configuration
  //return => imageCropAspectRatio  1 if aspectRatio is 1 else return 2
  //          set cropEnableSelectPreset false
  private cropSettings() {
    return {
      imageCropAspectRatio: this.aspectRatio === 1 ? 1 : 2,
      cropEnableSelectPreset: false
    };
  }
//** markupSettings for editor configuration
  private markupSettings() {
    return {
      markupEditorToolbar: createMarkupEditorToolbar( //Create tools available in the markup editor
        this.getMarkupEditorToolbar()
      ),
      markupEditorToolStyles: createMarkupEditorToolStyles(),//Create default shape styles for each tool, optionally add custom shape styles
      markupEditorShapeStyleControls: createMarkupEditorShapeStyleControls({ //Create default shape style controls
        fontFamilyOptions: [["COOP", "COOP"]]
      })
    };
  }
//** get plugins
  //if dokaOptions decorate is true => push plugin_decorate to pluginArr
  //if dokaOptions sticker is true => push plugin_sticker to pluginArr
  //return pluginArr
  private getPlugins(): Array<any> {
    const pluginArr = [plugin_crop];
    if (this.dokaOptions.decorate) {
      pluginArr.push(plugin_decorate);
    }
    if (this.dokaOptions.sticker) {
      pluginArr.push(plugin_sticker);
    }
    return pluginArr;
  }
//
  private getActiveTool(): string {
    return this.dokaOptions.text
      ? "text"
      : this.dokaOptions.rectangle
        ? "rectangle"
        : this.dokaOptions.priceTag
          ? "preset"
          : "";
  }
//**Create tools available in the markup editor
  //if dokaOptions text is present => push text to tollbarArr
  //if dokaOptions rectangle is present => push rectangle to tollbarArr
  //if dokaOptions priceTage is present => set preset to toolbarArr
  //return tollbarArr
  private getMarkupEditorToolbar(): Array<string> {
    const toolbarArr = [];
    if (this.dokaOptions.text) {
      toolbarArr.push("text");
    }
    if (this.dokaOptions.rectangle) {
      toolbarArr.push("rectangle");
    }
    if (this.dokaOptions.priceTag) {
      toolbarArr.push("preset");
    }
    return toolbarArr;
  }
//** get stickers
  //if dokaOptions frame is true => push ["Frame", this.getFrames(this.aspectRatio)] to stickerArr
  //if dokaOptions emoji is true => push ["Emoji", this.getStickersEmoji()] to stickerArr
  //return stickerArr
  private getStickers(): Array<any> {
    const stickerArr = [];
    if (this.dokaOptions.frame) {
      stickerArr.push(["Frame", this.getFrames(this.aspectRatio)]);
    }
    if (this.dokaOptions.emoji) {
      stickerArr.push(["Emoji", this.getStickersEmoji()]);
    }
    return stickerArr;
  }
//** get Stickers Emoji
  private getStickersEmoji(): Array<string> {
    return [
      this.emoji,
      this.googlesEmoji,
      this.omgEmoji,
      this.partyEmoji,
      this.partyPopperEmoji,
      this.laughingEmoji,
      this.starEyesEmoji,
      this.winkEmoji,
      this.droolingEmoji,
      this.kissEmoji,
      this.okEmoji,
      this.musclesEmoji,
      this.fireEmoji,
      this.shopCartEmoji,
      this.burgerEmoji,
      this.friesEmoji,
      this.pizzaEmoji,
      this.breadEmoji,
      this.cakeSliceEmoji,
      this.plateEmoji,
      this.steakEmoji,
      this.poultryLegEmoji
    ];
  }
}
