import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, mergeMap, takeWhile } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as dayjs from 'dayjs';
import 'dayjs/locale/da';

import {
  AppEventsService,
  ErrorhandlerService,
  NotificationsService,
  TokenService,
  UserService,
  WindowService,
} from 'app/core/services';
import { AnalyticsService } from 'app/core/services/analytics.service';
import { environment } from 'environments/environment';
import { JatakLiveService } from 'app/features/jatak-live';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Store } from '@ngxs/store';
import { ClearCreateOptions } from 'app/states';
import { IonContent, Platform } from '@ionic/angular';

declare let addToHomescreen: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Quick Coop';
  appVersion = environment.appversion;
  userAgent: any;
  showPopOver: boolean = false;
  isMobileApp = false;
  @HostBinding('attr.app-version') appversionattr = this.appVersion;
  @ViewChild(IonContent) content: IonContent;
  private _window: Window;

  constructor(
    private _userService: UserService,
    private _errorHandlerService: ErrorhandlerService,
    private _windowRef: WindowService,
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private _jatakLiveService: JatakLiveService,
    private swUpdate: SwUpdate,
    private _store: Store,
    private _notificationService: NotificationsService,
    private _platform: Platform,
    private _appEventService: AppEventsService
  ) {
    dayjs.locale('da');
    this.isMobileApp = this._platform.is('ios') || this._platform.is('android');
    if (!this.isMobileApp) {
      this.swUpdate.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          )
        )
        .subscribe(() => {
          document.location.reload();
        });
    }

    this._appEventService.$scrollToTOp.subscribe({
      next: (state) => {
        if (state) {
          this.content.scrollToTop(10).then();
        }
      },
    });
    this._appEventService.$scrollToBottom.subscribe({
      next: (state) => {
        if (state) {
          this.content.scrollToBottom(10).then();
        }
      },
    });

    this._router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.content.scrollToTop(10).then();
          if (
            event.url.includes('kalendar') ||
            event.url.includes('indstillinger') ||
            event.url.includes('instrumentbraet')
          ) {
            // clear createoptions state
            this._store.dispatch(new ClearCreateOptions());
          }
        }
      },
    });

    this._router.events
      .pipe(
        takeWhile(() => sessionStorage.getItem('fetchUser') !== 'true'),
        filter((ev) => ev instanceof NavigationEnd),
        mergeMap((event) => this._checkVerifyURL(event))
      )
      .subscribe({
        next: (userDetail) => {
          if (userDetail) {
            TokenService.setUserDetail(userDetail);
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
    this._router.events
      .pipe(
        takeWhile(() => sessionStorage.getItem('checkFbToken') !== 'true'),
        filter((ev) => ev instanceof NavigationEnd),
        mergeMap(() => this._checkFbToken())
      )
      .subscribe({
        next: (valid) => {
          sessionStorage.setItem('checkFbToken', 'true');
          if (!valid) {
            this.showPopOver = true;
          }
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
    // TODO: certificate is invalid as of jan 18 2023
    if (!this.isMobileApp && environment.production) {
      this._analyticsService.initAdobeLaunchDataLayer();
      this._analyticsService.injectAdobeLaunchScript().then();
    }
  }

  ngOnInit(): void {
    this._window = this._windowRef.window;
    this.userAgent = this._window.navigator.userAgent;
    this._notificationService.init();

    let addedToHomeScreen = false;
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this._window.scrollTo(0, 0);
        if (this.userAgent.match(/iPad/i) || this.userAgent.match(/iPhone/i)) {
          addToHomescreen({
            skipFirstVisit: true,
            maxDisplayCount: 1,
            appID: '_qC_auth_',
            displayPace: 20,
            detectHomeScreen: 'queryString',
            onAdd: () => {
              addedToHomeScreen = true;
            },
          });
        }
      }
    });
  }

  onNavigate() {
    this.showPopOver = false;
    const route =
      TokenService.getUserType() === '1'
        ? '/butikker/indstillinger'
        : '/bestyrelse/indstillinger';
    this._router.navigate([route]).then();
  }

  private _checkVerifyURL(event: any) {
    if (event.url.indexOf('verify') === -1) {
      if (
        TokenService.checkAuthenticatedState() &&
        !sessionStorage.getItem('fetchUser')
      ) {
        sessionStorage.setItem('fetchUser', 'true');
        return this._userService.getUserDetail();
      }
      return EMPTY;
    }
    return EMPTY;
  }

  private _checkFbToken() {
    if (
      TokenService.checkAuthenticatedState() &&
      !sessionStorage.getItem('checkFbToken')
    ) {
      return this._jatakLiveService.checkFbTokenValidity();
    }
    return EMPTY;
  }
}
