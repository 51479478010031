import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { Notice } from '../../shared/interface';
import { map } from 'rxjs/operators';

interface CreateOptionsState {
  step: number;
  mode: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppEventsService {
  // Show Hide Loader
  private _showLoader = new Subject<boolean>();
  $showLoader = this._showLoader.asObservable();

  // Close Component
  private _showComp = new Subject<boolean>();
  $componentState = this._showComp.asObservable();

  // User Detail Updated
  private _userUpdated = new Subject<boolean>();
  $userupdated = this._userUpdated.asObservable();

  // re-load calendar on deletion of offer/nyhed

  private _reloadCalendar = new Subject<boolean>();
  $reloadCalendar = this._reloadCalendar.asObservable();

  // show loader msg
  private _loaderMsg = new Subject<string>();
  $loaderMsg = this._loaderMsg.asObservable();

  // create Options state

  private _createOptionsState = new BehaviorSubject<CreateOptionsState>({
    step: 1,
    mode: 'skab',
  });
  $createOptionsState = this._createOptionsState.asObservable();

  private _scrollToTop = new Subject<boolean>();
  $scrollToTOp = this._scrollToTop.asObservable();

  private _scrollToBottom = new Subject<boolean>();
  $scrollToBottom = this._scrollToBottom.asObservable();

  constructor() {}

  // Show Loaded
  showLoader(state: boolean) {
    this._showLoader.next(state);
  }

  // Close Component
  showComponent(state: boolean) {
    this._showComp.next(state);
  }

  // Update User Detail
  updatedUser(state: boolean) {
    this._userUpdated.next(state);
  }

  // reload Calendar
  reloadCalendar(state: boolean) {
    this._reloadCalendar.next(state);
  }

  // set loader message
  setLoaderMsg(msg: string): void {
    this._loaderMsg.next(msg);
  }

  scrollToTop(state: boolean) {
    this._scrollToTop.next(state);
  }
  scrollToBottom(state: boolean) {
    this._scrollToBottom.next(state);
  }

  // change create options state
  setCreatOptionState(state: number, mode: string): void {
    this._createOptionsState.next({ step: state, mode: mode });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppEventApiService {
  constructor(private _apiService: ApiService) {}

  getNotice(): Observable<Notice> {
    return this._apiService.get('alert').pipe(map((res) => res.data));
  }
}
