<div class="app-page poll-create-edit page-with-fixed-header-and-tab">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [isBackGroundWhite]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="flex-display tab-container-wrap">
          <ul class="tab-list">
            <li class="tab-option active">COOP App</li>
          </ul>
          <div>
            Ingen andre kanaler
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="form-container styled-two">
      <form [formGroup]="pollForm" (submit)="save()">
        <div class="wrapper">
          <div
            class="form-group"
            formGroupName="imageUrl"
            controlErrorContainer
          >
            <coop-image-handler
              [imageUrl]="imageUrl"
              [hasImages]="hasImageUrl"
              [singleCropper]="true"
              [isRectangle]="true"
              [disableUpload]="false"
              [offerType]="'afstemning'"
              (processing)="imageProcessing($event)"
              (image)="getImagesUrl($event)"
            ></coop-image-handler>
            <input
              formControlName="largeImage"
              [customErrors]="validationMessages['largeImage']"
              hidden
            />
          </div>
        </div>
        <div class="poll-preview" *ngIf="!isEditable">
          <div class="poll-preview-detail">
            <div class="wrapper">
              <h2 class="poll-preview-title text-align-center">
                {{ poll.title }}
              </h2>
              <p class="poll-preview-desc text-align-center">
                {{ poll.description }}
              </p>
            </div>
            <div class="poll-results">
              <ul>
                <li *ngFor="let answer of poll.answers">
                  <span
                    class="bg {{ answer.shade }}"
                    [style.width]="answer.percentage + '%'"
                  ></span>
                  <div class="row">
                    <span class="answer">{{ answer.answer }}</span>
                    <span class="percentage">{{ answer.percentage }}%</span>
                    <span>({{ answer.count }})</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="wrapper">
          <div *ngIf="isEditable">
            <div class="section-wrapper">
              <div class="title">
                Post tekst
              </div>
              <div class="form-group" controlErrorContainer>
                <label for="poll-title" class="margin-bottom-8">Overskrift*</label>
                <input
                  id="poll-title"
                  type="text"
                  (focusin)="showCounter()"
                  placeholder="Indsæt overskrift"
                  (focusout)="showCounter()"
                  [customErrors]="validationMessages['title']"
                  formControlName="title" />
                <div *ngIf="showLengthCounter" class="remaining"> Brugte
                  tegn: {{ pollForm.get('title').value.length }} / 99
                </div>
              </div>
            </div>
            <div class="hr"></div>

            <div class="section-wrapper">
              <div class="title">
                Detaljer
              </div>
              <div class="row form-group" controlErrorContainer>
                <label for="poll-text" class="margin-bottom-8">Spørgsmål*</label>
                <textarea
                  name=""
                  id="poll-text"
                  formControlName="description"
                ></textarea>
              </div>
              <div formArrayName="answers" class="answers-list">
                <div
                  *ngFor="
                  let answer of pollForm.controls['answers']['controls'];
                  let i = index
                "
                  class="answer"
                >
                  <div
                    class="row form-group"
                    [formGroupName]="i"
                    controlErrorContainer
                  >
                    <ion-icon name="close-circle-outline"
                              class="icon icon-close-circle remove-answer"
                              *ngIf="pollForm.controls['answers']['controls'].length > 2"
                              (click)="removeAnswer(i)"></ion-icon>
                    <!--                    <i-->
                    <!--                      class="icon icon-close-circle remove-answer"-->
                    <!--                      *ngIf="pollForm.controls['answers']['controls'].length > 2"-->
                    <!--                      (click)="removeAnswer(i)"-->
                    <!--                    ></i>-->
                    <label class="margin-bottom-8">Svarmulighed {{ i + 1 }}</label>
                    <poll-answer [answerForm]="answer"></poll-answer>
                  </div>
                </div>
                <div
                  style="margin-top: 10px ; display: flex; justify-content: center"
                  *ngIf="answerCount <= 5"
                  (click)="addAnswer('')"
                  class="login-btn btn-white-login "
                >
                  <ion-icon name="add" class="font-18" style="margin-right: 10px"></ion-icon>
                  Tilføj svarmulighed
                </div
                >


              </div>
            </div>

            <div class="form-group">
              <label class="margin-bottom-8">Flere svar</label>
              <div class="flex-display switch-container">
                <div class="toggle-title">
                  Mulighed for flere svar
                </div>
                <div class="height-27">
                  <label class="switch">
                    <input type="checkbox" formControlName="isMultiple" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="hr"></div>


          <div class="section-wrapper">
            <div class="title">
              Planlægning
            </div>


            <div>
              <p *ngIf="!isEditable" class="text-align-center">
                Denne afstemning kan ikke længere redigeres da en eller flere svar
                er blevet registreret.
              </p>
              <h2 class="form-subtitle">Hvornår skal beskeden vises</h2>
              <div class="col-two form-group" controlErrorContainer>
                <coop-date-time-range-picker
                  [datePickerData]="startEndDatePicker"
                  [editMode]="editMode"
                  [templateMode]="isTemplate"
                  (onDateTimeChange)="getStartDateTime($event)"
                ></coop-date-time-range-picker>
              </div>
            </div>

          </div>
          <div class="form-group">
            <label class="margin-bottom-8">Webkanal</label>
            <div class="flex-display switch-container">
              <div class="toggle-title">Oprette webkanal:</div>
              <div class="height-27">
                <label class="switch">
                  <input
                    type="checkbox"
                    (click)="toggleWebChannel()"
                    formControlName="web"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="btn-wrapper">
            <div class="row form-submit-field">
              <input type="submit" value="Aktivér"
                     class="login-btn btn-black-login" />
            </div>
            <div class="row" *ngIf="editMode && isEditable">
              <a
                (click)="deletePoll(pollForm.value.id)"
                class="login-btn btn-white-login"
              >Slet</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
