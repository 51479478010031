import { MbscDatepickerOptions, MbscSelectOptions } from '@mobiscroll/angular';

export const COMMON_SELECT_SETTINGS: MbscSelectOptions = {
  touchUi: true,
  dropdown: false,
  display: 'center',
  buttons : ['', '']
};

export const COMMON_DATEPICKER_SETTINGS: MbscDatepickerOptions ={
  controls: ['date'],
  buttons : [],
  touchUi: true,
  display: 'center'
}

export const COMMON_DATETIMEPICKER_SETTINGS: MbscDatepickerOptions ={
  controls: ['datetime'],
  touchUi: true,
  display: 'center'
}
export const COMMON_TIMEPICKER_SETTINGS: MbscDatepickerOptions ={
  controls: ['time'],
  touchUi: true,
  display: 'center'
}
