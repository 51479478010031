import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CreateOption, PopupText, Shared, UserDetail } from 'app/shared/interface';
import {
  CreateOptionDataService,
  ErrorhandlerService,
  NotificationsService,
  TokenService
} from 'app/core/services';
import { takeWhile } from 'rxjs/operators';
import { FacebookEventsService } from 'app/features/facebookconnect';
import { JatakService } from 'app/features/jatak';
import { Router } from '@angular/router';

import { EventsService } from 'app/features/events/services';
import { PollService } from 'app/features/poll';
import { SetJatakRouteStates } from 'app/features/jatak/states';
import { Store } from '@ngxs/store';

@Component({
  selector: 'create-option-channel-select',
  templateUrl: './create-option-channel-select.component.html',
  styleUrls: ['./create-option-channel-select.component.scss']
})
export class CreateOptionChannelSelectComponent implements OnInit, OnChanges {

  @Input() type: string;
  @Input() user: string;
  @Input() selectedOption: CreateOption;
  @Input() parentUrl: string;
  @Input() showGodPris: boolean;
  @Input() showNyheder: boolean;
  private _subscriptionState: boolean = true;
  userDetail: UserDetail;
  @Input() userType: string;
  popupText: PopupText;
  showDuplicateError: boolean = false;
  showScreenOption = false;
  isBtnSwitchColor = false;

  constructor(
    private _facebookEvents: FacebookEventsService,
    private _createOptionService: CreateOptionDataService, private _router: Router,
    private _jatakService: JatakService,
    private _eventsService: EventsService,
    private _pollService: PollService,
    private _errorHandlerService: ErrorhandlerService,
    private _store: Store
  ) {
  }

  ngOnInit() {
    this.userDetail = TokenService.getUserDetail();
    this.showScreenOption = this.userDetail.scopes.screen;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedOption']) {
      this.selectedOption = changes['selectedOption'].currentValue;
    }

    if (changes['type']) {
      this.type = changes['type'].currentValue;
    }
  }

//toggles the value of selectedOption share coop
  coopSelect() {
    this.selectedOption.share.coop = !this.selectedOption.share.coop;
    this.allChannelSelection(this.selectedOption.type);
  }

  checkfbSelect() {
    if (this.selectedOption.share.facebook) {
      this.selectedOption.share.facebook = false;
      return;
    }
    if (!this.selectedOption.share.facebook) {
      this.fbSelect();
      return;
    }

  }

  //** checks if facebook is connected */
  //set variable facebookConnected to facebook connected value from local storage
  //if facebookConnected is false => notify with notificationMessage:'connect to facebook' , type: 'error', position:'top' and
  // connectFacebook() => Show facebook Login Popup to connect
  //subscribe to getFbTokenStatus() which returns token
  //if token is present then => toggle the value of selectedOption facebook(select to unselect and vice-versa)
  //                         => if selectedOption type is equal to 'ja-tak' then => resetWebSelection()
  //                         => if selectedOption type is equal to ja-tak-live then => _setCreateOptionAndRoute()
// else notify with notificationMessage 'Invalid Facebook Token.Reconnect the page' , type:'error', position:'top' & connectFacebook() => Show facebook Login Popup to connect

  fbSelect() {
    const facebookConnected = TokenService.getUserDetail().facebook.connected;
    if (!facebookConnected) {
      NotificationsService.notify(
        'Opret forbindelse til facebook',
        'error',
        'top'
      );
      this._facebookEvents.connectFacebook();
      return;
    }
    this._jatakService
      .getFbTokenStatus()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res: any) => {
          if (res) {
            this.selectedOption.share.facebook =
              res;
            if (this.selectedOption.type === 'ja-tak') {
              this.resetWebSelection();
            }
            if (this.selectedOption.type === 'ja-tak-live') {
              this._setCreateOptionAndRoute();
            }
            this.allChannelSelection(this.selectedOption.type);
          } else {
            NotificationsService.notify(
              'Ugyldigt Facebook Token.<br>Forbind siden igen',
              'error',
              'top'
            );
            this._facebookEvents.connectFacebook();
          }
        },
        error: (err: any) => this._errorHandlerService.handleError(err)
      });
  }

  //toggle the value of selectedOption share coop for ja-tak
  //resetWebSelection() => to reset web of selectedOption web
  jatakCoopSelect() {
    this.selectedOption.share.coop = !this.selectedOption.share.coop;
    this.resetWebSelection();
    this.allChannelSelection(this.selectedOption.type);
  }

  //toggle the value of selectedOption share sms
  jatakSmsSelect() {
    this.selectedOption.share.sms = !this.selectedOption.share.sms;
    this.allChannelSelection(this.selectedOption.type);
  }

  //if selectedOption share coop and selectedOption share facebook are false then => notify notificationMessage   'Coop or Facebook must be   selected' , type: 'info' , position:'top'
  //        return
  // else toggle the value of selectedOption share web
  jatakWebSelect() {
    if (
      !this.selectedOption.share.coop &&
      !this.selectedOption.share.facebook
    ) {
      NotificationsService.notify(
        'Coop eller Facebook skal vælges',
        'info',
        'top'
      );
      return;
    }
    this.selectedOption.share.web = !this.selectedOption.share.web;
    this.allChannelSelection(this.selectedOption.type);
  }

  //toggle the value of selectedOption share sms
  smsSelect() {
    this.selectedOption.share.sms = !this.selectedOption.share.sms;
    this.allChannelSelection(this.selectedOption.type);
  }

  // toggle the value of selectedOption share web
  webSelect() {
    this.selectedOption.share.web = !this.selectedOption.share.web;
    this.allChannelSelection(this.selectedOption.type);
  }

  //toggle the value of selectedOption share dit
  ditSelect() {
    this.selectedOption.share.dit = !this.selectedOption.share.dit;
    this.allChannelSelection(this.selectedOption.type);
  }

  //toggle the value of selectedOption share screen
  screenSelect() {
    this.selectedOption.share.screen = !this.selectedOption.share.screen;
    this.allChannelSelection(this.selectedOption.type);
  }

  // toggle the value of selectedOption share mail
  emailSelect() {
    this.selectedOption.share.mail = !this.selectedOption.share.mail;
    this.allChannelSelection(this.selectedOption.type);
  }

  //** reset web of selectedOption */
  //if selectedOption share coop or selectedOption share facebook is false => set selectedOption share web false
  resetWebSelection() {
    if (
      !(this.selectedOption.share.coop || this.selectedOption.share.facebook)
    ) {
      this.selectedOption.share.web = false;
    }
  }

  //** allows to select all channel and change the select all button color
  //@params[type] type of offer
  //if type is 'god-pris' or 'nyheder' or 'tilbud' and selected channels are mail, coop,facebook and web then set isBtnSwitchColor to true to change 'Vaelg alle' button color
  //if type is ja-tak and selected channels are facebook , web , coop and sms then set isBtnSwitchColor to true
  //if type is tilbagevendende-tilbud and selected channels are coop and facebook then set isBtnSwitchColor to true
  allChannelSelection(type: any) {
    if (type === 'nyheder' && this.userType === '2' && this.selectedOption.share.coop && this.selectedOption.share.facebook && this.selectedOption.share.web
      && this.selectedOption.share.mail) {
      this.isBtnSwitchColor = true;
      return;
    }


    if ((type === 'god-pris' || type === 'nyheder') && (this.selectedOption.share.mail && this.selectedOption.share.coop && this.selectedOption.share
      .facebook && this.selectedOption.share.web && this.selectedOption.share.sms && this.selectedOption.share.dit)) {
      this.isBtnSwitchColor = true;
      return;
    }
    if ((type === 'tilbud') && (this.selectedOption.share.mail && this.selectedOption.share.coop && this.selectedOption.share
      .facebook && this.selectedOption.share.web && this.selectedOption.share.sms)) {
      this.isBtnSwitchColor = true;
      return;
    }

    if ((type === 'ja-tak') && (this.selectedOption.share.facebook && this.selectedOption.share.web && this.selectedOption.share.coop && this.selectedOption.share.sms)) {
      this.isBtnSwitchColor = true;
      return;
    }

    if ((type === 'tilbagevendende-tilbud') && (this.selectedOption.share.coop && this.selectedOption.share.facebook)) {
      this.isBtnSwitchColor = true;
      return;
    }


    this.isBtnSwitchColor = false;
  }

//** selects all the channel
  //if isBtnSwitchColor is true then set all the provided channels to true
  //else set all the provided channels to false
  selectAllChannel() {
    this.isBtnSwitchColor = !this.isBtnSwitchColor;
    if (this.isBtnSwitchColor && this.type === 'nyheder' && this.userType == '2') {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      this.fbSelect();
      this.selectedOption.share.coop = true;
      this.selectedOption.share.facebook = facebookConnected;
      this.selectedOption.share.web = true;
      this.selectedOption.share.mail = true;
      this.selectedOption.share.sms = false;
      this.selectedOption.share.screen = false;
      return;
    }

    if (!this.isBtnSwitchColor && this.type === 'nyheder' && this.userType == '2') {

      this.selectedOption.share.facebook = false;
      this.selectedOption.share.web = false;
      this.selectedOption.share.mail = false;
      this.selectedOption.share.sms = false;
      this.selectedOption.share.screen = false;
      return;
    }


    if (this.isBtnSwitchColor && this.type === 'tilbud') {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      this.fbSelect();
      this.selectedOption.share.coop = true;
      this.selectedOption.share.facebook = facebookConnected;
      this.selectedOption.share.web = true;
      this.selectedOption.share.mail = true;
      this.selectedOption.share.sms = true;
      this.selectedOption.share.screen = false;
      return;
    }

    if (!this.isBtnSwitchColor && this.type === 'tilbud') {
      this.selectedOption.share.facebook = false;
      this.selectedOption.share.web = false;
      this.selectedOption.share.mail = false;
      this.selectedOption.share.sms = false;
      this.selectedOption.share.screen = false;
      return;
    }

    if (this.isBtnSwitchColor && this.type === 'tilbagevendende-tilbud') {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      this.fbSelect();
      this.selectedOption.share.coop = true;
      this.selectedOption.share.facebook = facebookConnected;
      return;
    }

    if (!this.isBtnSwitchColor && this.type === 'tilbagevendende-tilbud') {
      this.selectedOption.share.facebook = false;
      return;
    }


    if (this.isBtnSwitchColor && this.type === 'redmad') {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      this.fbSelect();
      this.selectedOption.share.coop = true;
      this.selectedOption.share.facebook = facebookConnected;
      return;
    }

    if (!this.isBtnSwitchColor && this.type === 'redmad') {
      this.selectedOption.share.facebook = false;
      return;
    }

    if (this.isBtnSwitchColor) {
      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      this.fbSelect();
      this.selectedOption.share.coop = true;
      this.selectedOption.share.facebook = facebookConnected;
      this.selectedOption.share.web = true;
      this.selectedOption.share.mail = true;
      this.selectedOption.share.sms = true;
      this.selectedOption.share.dit = true;
      this.selectedOption.share.screen = false;
    }
    if (!this.isBtnSwitchColor) {
      this.selectedOption.share.facebook = false;
      this.selectedOption.share.web = false;
      this.selectedOption.share.mail = false;
      this.selectedOption.share.sms = false;
      this.selectedOption.share.dit = false;
      this.selectedOption.share.screen = false;
    }

  }

  //** set create option and route */
  //set selectedOption isSet to true
  //setCreateOption to share in different selected channels
  //_routeToTarget() => route to the respective url
  private _setCreateOptionAndRoute() {
    this.selectedOption.isSet = true;
    this._createOptionService.setCreateOption(this.selectedOption);
    console.log(this.selectedOption);
    this._routeToTarget(this.selectedOption.type);
  }

  //** routes to the respective url /
  //@params[type] => is the type of selectionOption
  //if type is equal to 'tilbud' or 'nyheder' or 'god-pris' or 'tilbagevendende-tilbud' => navigate() and .then() returns a Promise.
  private _routeToTarget(type: string) {
    if (
      type === 'tilbud' ||
      type === 'nyheder' ||
      type === 'god-pris' ||
      type === 'tilbagevendende-tilbud'
    ) {
      this._router
        .navigate([
          this.parentUrl + '/indlaeg/' + type + '/' + this.selectedOption.mode
        ])
        .then();
    } else if (type === 'arsmode' || type === 'forsamling-event') {
      this._router
        .navigate([
          this.parentUrl +
          '/' +
          'arrangement' +
          '/' +
          type +
          '/' +
          this.selectedOption.mode
        ])
        .then();
    } else if (type === 'special' || type === 'forsamling-poll') {
      this._router
        .navigate([
          this.parentUrl +
          '/' +
          'afstemninger' +
          '/' +
          type +
          '/' +
          this.selectedOption.mode
        ])
        .then();
    } else if (type === 'ja-tak') {
      this._router
        .navigate([
          this.parentUrl + '/' + type + '/' + this.selectedOption.mode
        ])
        .then(() => {
          this._saveOrderListRouteState();
        });
    } else {
      this._router
        .navigate([
          this.parentUrl + '/' + type + '/' + this.selectedOption.mode
        ])
        .then();
    }
  }

  private _saveOrderListRouteState() {
    this._store.dispatch(new SetJatakRouteStates({
      routeUrl: this.parentUrl
    }));
  }

//if _isValid() is false then return
  //if selectedOption type is equal to 'arsmode' then subscribe to checkCreateSpecialEvent =>
  //                                                                next()=>_setCreateOptitonAnRoute()
  //                                                                error()=> setTextInPopup()
  //if selectedOption type is equal to 'special' then subscribe to checkCreateSpecialPoll() =>
  //                                                                    next()=>_setCreateOptionAndRoute()
  //                                                                    error()=> setTextInPopup()
  //if selectedOption type is equal to 'forsamling-event' then subscribe to checkCreateAssembly() =>                                                                          next()=>_setCreateOptionAndRoute()
  //                                                                   error()=> setTextInPopup()
  //if selectedOption type is equal to 'forsamling-poll' then subscribe to checkCreateAssemblyPoll()=>
  //                                                                  next()=>_setCreateOptionAndRoute()
  //                                                                  error()=> setTextInPopup()
  //if selectedOption type is equal to 'ja-tak-live' then fb.select()
  //_setCreateOptionAndRoute()=> set create option to share in channels and route them
  private _isValid() {
    const share = this.selectedOption.share;
    if (this.selectedOption.mode === 'skab') {
      if (
        this.selectedOption.type === 'tilbud' ||
        this.selectedOption.type === 'nyheder' ||
        this.selectedOption.type === 'god-pris' ||
        this.selectedOption.type === 'ja-tak' ||
        this.selectedOption.type === 'tilbagevendende-tilbud' ||
        this.selectedOption.type === 'redmad'
      ) {
        if (!this._shareSelected(share)) {
          NotificationsService.notify('Vælg en indstilling', 'error', 'top');
          return false;
        }
      }
    }
    return true;
  }

  //@params[share] =>  boolean value of selected channel
  //loop through all share value of channel => if value of share is true return true
  //                                      => else return false
  _shareSelected(share: Shared): boolean {
    for (const key in share) {
      if (share[key]) {
        return true;
      }
    }
    return false;
  }

  //if _isValid() is false then return
  //if selectedOption type is equal to 'arsmode' then subscribe to checkCreateSpecialEvent =>
  //                                                                next()=>_setCreateOptitonAnRoute()
  //                                                                error()=> setTextInPopup()
  //if selectedOption type is equal to 'special' then subscribe to checkCreateSpecialPoll() =>
  //                                                                    next()=>_setCreateOptionAndRoute()
  //                                                                    error()=> setTextInPopup()
  //if selectedOption type is equal to 'forsamling-event' then subscribe to checkCreateAssembly() =>                                                                          next()=>_setCreateOptionAndRoute()
  //                                                                   error()=> setTextInPopup()
  //if selectedOption type is equal to 'forsamling-poll' then subscribe to checkCreateAssemblyPoll()=>
  //                                                                  next()=>_setCreateOptionAndRoute()
  //                                                                  error()=> setTextInPopup()
  //if selectedOption type is equal to 'ja-tak-live' then fb.select()
  //_setCreateOptionAndRoute()=> set create option to share in channels and route them
  next() {
    if (!this._isValid()) {
      return;
    }
    if (this.selectedOption.type === 'arsmode') {
      this._eventsService
        .checkCreateSpecialEvent()
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => {
            this._setCreateOptionAndRoute();
          },
          error: (error) => {
            this.setTextInPopup(error['code']);
            return;
          }
        });
      return;
    }
    if (this.selectedOption.type === 'special') {
      this._pollService
        .checkCreateSpecialPoll()
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => {
            this._setCreateOptionAndRoute();
          },
          error: (error) => {
            this.setTextInPopup(error['code']);
            return;
          }
        });
      return;
    }
    if (this.selectedOption.type === 'forsamling-event') {

      this._eventsService
        .checkCreateAssembly()
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => {
            this._setCreateOptionAndRoute();
          },
          error: (error) => {
            this.setTextInPopup(error['code']);
            return;
          }
        });
      return;
    }
    if (this.selectedOption.type === 'forsamling-poll') {

      this._pollService
        .checkCreateAssemblyPoll()
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => {
            this._setCreateOptionAndRoute();
          },
          error: (error) => {
            this.setTextInPopup(error['code']);
            return;
          }
        });
      return;
    }
    if (this.selectedOption.type === 'ja-tak-live') {

      this.fbSelect();
      return;
    }

    if (this.selectedOption.share.facebook) {

      const facebookConnected = TokenService.getUserDetail().facebook.connected;
      if (!facebookConnected) {
        NotificationsService.notify(
          'Opret forbindelse til facebook',
          'error',
          'top'
        );
        this._facebookEvents.connectFacebook();
        return;
      }
    }

    this._setCreateOptionAndRoute();
  }

  //** set PopUpText */
  //@params[title, info] => title and info of the error generated
  //object popupText is created with title and info property
  //showDuplicateError is set to pop up window with title and info
  setPopUpText(title: string, info: string) {
    this.popupText = {
      title,
      info
    };
    this.showDuplicateError = true;
  }

  //** sets Text in PopUp */
  //@params[code] => code returned by backend
  //variable errorForm is set with the selectedOption type
  //if errorForm is equal to 'arsmode' and code is 600 then pop up the text with title =>
  //                                            'Unfortunately, only one annual meeting can be held at a time.'
  //                                            and info 'If you wish to create a new annual meeting, please delete the one already created.'
  //if errorForm is equal to special => if code is 600 then set pop up text with title=>
  //                                           'There is no annual meeting, so voting cannot be created'
  //                                           and info 'If you want to create a new vote, you must first create an annual meeting.'
  //                                => if code is 606 then set pop up text with title =>
  //                                            'There are no online annual meetings.'
  //                                            and info 'If you want to create a new vote, you must first create an annual meeting.'
  //if errorForm is equal to 'forsamling-event' and code is 700 then set the pop up text with title =>
  //                                           'Unfortunately, only one general meeting can be held at a time.' and
  //                                           info 'If you wish to create a new general meeting, please delete the one already created.'
  //if errorForm is equal to 'forsamling-poll' => if code is 700 then set pop up text with title =>
  //                                          'There is no general meeting, so a vote cannot be set up.'
  //                                          and info 'If you want to create a new vote, you must first create a general meeting.'
  //                                           => if code is 706 then set pop up text with title =>
  //                                           'There is no online general meeting.'
  //                                           and info 'If you want to create a new vote, you must first create a general meeting.'
  setTextInPopup(code: number): void {
    const errorFrom = this.selectedOption.type;
    if (errorFrom === 'arsmode' && code === 600) {
      this.setPopUpText(
        'Der kan desværre kun oprettes et årsmøde af gangen.',
        'Ønsker du at oprette et nyt årsmøde bedes du slette det allerede oprettede.'
      );
    }
    if (errorFrom === 'special') {
      if (code === 600) {
        this.setPopUpText(
          'Der er ingen årsmøde, så afstemning kan ikke oprettes.',
          'Hvis du vil oprette en ny afstemning, skal du først oprette et årsmøde.'
        );
      }
      if (code === 606) {
        this.setPopUpText(
          'Der er ingen online årsmøder.',
          'Hvis du vil oprette en ny afstemning, skal du først oprette et årsmøde.'
        );
      }
    }
    if (errorFrom === 'forsamling-event' && code === 700) {
      this.setPopUpText(
        'Der kan desværre kun oprettes et generalforsamling af gangen.',
        'Ønsker du at oprette et nyt generalforsamling bedes du slette det allerede oprettede.'
      );
    }
    if (errorFrom === 'forsamling-event' && code === 710) {
      this.setPopUpText(
        'Der er oprettet en tværgående generalforsamling.',
        'Ændringer til denne skal udføres af den, der har oprettet den tværgående generalforsamling.'
      );
    }

    if (errorFrom === 'forsamling-poll') {
      if (code === 700) {
        this.setPopUpText(
          'Der er oprettet en tværgående generalforsamling.',
          'Afstemninger til denne skal oprettes af dem der har oprettet den tværgående generalforsamling.'
        );
      }
      if (code === 706) {
        this.setPopUpText(
          'Der er ingen online generalforsamling.',
          'Hvis du vil oprette en ny afstemning, skal du først oprette en generalforsamling.'
        );
      }
      if (code === 711) {
        this.setPopUpText(
          'Der er ingen generalforsamling, så afstemning kan ikke oprettes.',
          'Hvis du vil oprette en ny afstemning, skal du først oprette en generalforsamling.'
        );
      }
    }
  }

  // if (type === "tilbud" || type === "nyheder" || type === "god-pris")

}
