import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {finalize, mergeMap, take, takeWhile, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

import {
  OrderListData, Summary, Customer
} from 'app/features/jatak/interface';
import {JatakService} from 'app/features/jatak/services';
import {
  ErrorhandlerService,
  NotificationsService,
  FileReaderService,TokenService
} from 'app/core/services';
import {
  animateChild,
  animateParent,
  slideAnimation,
} from 'app/shared/animations';
import {Store} from "@ngxs/store";

@Component({
  selector: 'coop-jatak-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
  animations: [animateChild, animateParent, slideAnimation],
})
export class JatakOrderListComponent implements OnInit, OnDestroy {
  // *** resetSearch() method of orderListFilterComponent is used during orderList tab change ***
  @ViewChild('orderListFilterComponent') orderListFilterComponent;
  pageTitle: string = 'Ordreliste';
  showParent: string = 'visible';
  stockOrder: number = 0;
  order: any;
  offerId: string;
  fbPostId: string;
  limit: number = 1;
  numbers = {};
  currentUrl: string;
  previousUrl: string = '/';
  parentUrl: string = ''
  tab: number = 0;
  tabName = ['facebook', 'coop', 'sms'];
  currentTab = this.tabName[this.tab];
  emptyOrder: boolean = false;
  system: string;
  downloadFile = {
    name: '',
    file: null,
  };
  activeCustomers = {
    fb: 0,
    coop: 0,
    sms: 0,
  };

  showVaraint: boolean = false
  showFacebookOversight: boolean = false
  summaryList: Summary
  customerList: Customer[]
  varaintList: any
  orderQuantity: any
  successReplyMessage = {}
  retryorderId: any
  objectKeys = Object.keys;
  searchList: Customer[]
  userNmae = ''
  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _fbcommentService: JatakService,
    private _errorHandlerService: ErrorhandlerService,
    private _store: Store,

  ) {
  }

  ngOnInit() {
    document.body.classList.add('overlay');
    this._initComp();
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  // *** Approves order when approveOrder event is fired from child coop-order-list-accordion-facebook ***
  // @param [order: OrderList] => orderAmount verified by the user
  approve(order: any): void {
    this.approveOrder(order);
  }

  orderQuanitity(orderquanity:any) {
    this.orderQuantity = orderquanity
  }

  // *** It is evoked when user manually rejects an order in child coop-order-list-accordion-facebook ***
  // @param [orderId: string] => id of the OrderList object to reject
  reject(orderId: number): void {
    NotificationsService.confirmWithCallback('Er du sikker?', (accept) => {
      // if 'accept' updating the order amount for given order id
      if (accept) {
        this.onRejectsingleOrder(orderId);
      }
    });
  }

  retry(orderId: number) {
    this.retryorderId = orderId
    this._fbcommentService
      .retryOrder(this.offerId, orderId)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (res) => {
          this.successReplyMessage = {
            ...this.successReplyMessage,
            [this.retryorderId]: res.message,
          }
          this._refreshPage();
        },
        error: (err) => this._handleError(err),
      });
  }

  // *** update the number of order amounts remaining ***
  stockUpdate() {
    this.stockOrder = this.order.total - this.order.request;
  }

  // *** to check whether stock is remaining or not ***
  // @param [quantity: number] => total quantity requested by customer
  isStockLeft(quantity: number): boolean {
    return !(this.stockOrder - quantity < 0);
  }

  // *** To print the orderlist ***
  // downloads after getting Jatak Order Lists information in a pdf file from server
  onPrintOrder() {
    this._fbcommentService
      .getFbCommentOrderlistPdf(this.offerId)
      .pipe(
        take(1),
        mergeMap((file) => this.loadFile(file))
      )
      .subscribe({
        next: () => this.download(),
        error: (err) => this._handleError(err),
      });
  }

// *** It reads the pdf file in buffer before downloading ***
  // @params [file: Blob] => the file to be read
  // @returns [Observable] => file in array_buffer(for pdf)
  private loadFile(file) {
    this.downloadFile.file = file;
    return FileReaderService.readFile(file, file['type']);
  }

// *** Downloads file ***
  private download() {
    FileReaderService.downloadFile(
      this.downloadFile,
      'Offer_' + this.offerId + '_' + new Date().getTime()
    );
  }

  _window(): any {
    return window;
  }

  get window(): any {
    return this._window();
  }

// *** It updates orderList ***
  // Used after those orders which require manual verification by the user
  // @param [orderId: number] => id of orderLists
  updateOrderList(orderId) {
    let data = {
      quantity: this.orderQuantity
    }
    this._fbcommentService
      .updateOrderList(this.offerId, orderId, data)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => {
          NotificationsService.notify(
            'Ordren opdateret med succes',
            'success',
            'top'
          );
          this._success();
        },
        error: (err) => {
          const cmtNotFound = err.code === -309 && !err.success;
          const message = err.message
          if (cmtNotFound) {
            const errmsg = message
            NotificationsService.notify(errmsg, 'error', 'top');
          } else {
            this._handleError('');
          }
        },
      });
  }

  // ** to approve the orders requested by customer and verified by the user **
  // @param [order: OrderList] => particular order that is being approved
  approveOrder(order: any) {
    const orderAmt = +order.quantity;

    // don't approve if order amount is not valid
    // if (isNaN(orderAmt) || orderAmt <= 0) {
    //   NotificationsService.notify(
    //     'Ordre beløb skal være nummer',
    //     'error',
    //     'top'
    //   );
    //   return;
    // }
    // don't approve if no stock is left
    // if (this.orderQuantity > this.summaryList.remainingStock) {
    //   NotificationsService.notify(
    //     `Utilstrækkeligt lager, det er kun ${this.stockOrder} tilbage.`,
    //     'error',
    //     'top'
    //   );
    //   return;
    // }
    NotificationsService.confirmWithCallback('Er du sikker?', (accept) => {
      // if 'accept' updating the order amount for given order id
      if (accept) {

        this.updateOrderList(order);
      }
    });
  }

// *** It is evoked when all the orders by a customer in any particular channel is marked as picked-up ***
  // @param [order: string|OrderList] =>string: id that uniquely identifies each customer who ordered
  //                                    OrderList: order.id is taken from the OrderList
  onCollectOrder(order) {
    let orderId: string;
    if (typeof order === 'string') {
      orderId = order;
    } else {
      orderId = order.userId;
    }

    if (order.approvalRemaining) {
      NotificationsService.notify(
        'Godkend alle afventende ordrer for denne kunde',
        'info',
        'top'
      );
      return false;
    }
    this.updateOrderCollected(orderId)
    // NotificationsService.confirmWithCallback(
    //         'Er du sikker? Husk ordren bliver ikke slettet fra Facebook.',
    //         (accept) => {
    //           if (accept) {
    //             this.updateOrderCollected(orderId);
    //           }
    //         }
    //       );
  }

// *** It is evoked when all the orders by a customer is marked as delete ***
  // @param [order: OrderListData] => Whole orders by a particular user in any channel
  onCancelOrder(order: any) {
    NotificationsService.confirmWithCallback(
      'Er du sikker? Husk ordren bliver ikke slettet fra Facebook.',
      (accept) => {
        if (accept) {
          this.cancelOrder(order, this.tab);
        }
      }
    );
  }

// *** Any particular order with provided orderId is rejected ***
  // @param [orderId: string] => id of the OrderList object from child coop-order-list-accordion-facebook
  onRejectsingleOrder(orderId) {
    this._fbcommentService
      .rejectOrder(this.offerId, orderId)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => {
          NotificationsService.notify('Ordren afvist', 'success', 'top');
          this._refreshPage();
        },
        error: (err) => this._handleError(err),
      });
  }

// *** It deletes all the orders by a customer in a particular channel ***
  // @param [system: number] => 0 facebook
  //                            1 coopApp
  //                            2 sms
  // @param [orderId: string] => id of a particular customer representing all his orders in a particular system
  cancelOrder(orderId, system) {
    this._fbcommentService
      .cancelOrder(this.offerId, orderId)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => {
          NotificationsService.notify(
            'Ordren opdateret med succes',
            'success',
            'top'
          );
          this._success();
        },
        error: () => this._handleError(''),
      });
  }

// *** Filters those customers from orderList whose name matches in coop-order-list-filter ***
  // @param [customerName: string] => Any search string that might match with the username of customer
  onSearch(customerName: string) {
    if (customerName === undefined || customerName === '' ) {
      // reset
      this.fetchCustomersList(this.tab)
    }
    const allList = this.searchList

    let filteredList = []
    for (const customer in allList) {
      if (allList.hasOwnProperty(customer)) {
        const custName = allList[customer].name.toLowerCase()
        const orderIdList = allList[customer].orders.map(element => element.orderId.toLowerCase())
        if (custName.includes(customerName.toLowerCase())
          || orderIdList.some(element => element.includes(customerName.toLowerCase()))) {
          filteredList = [...filteredList, allList[customer]]

        }
      }
    }
    this.customerList = filteredList
  }

  // *** Updating orders after it has been marked as picked up ***
  // @param [orderId: string] => id of a particular customer representing all his orders in a particular system
  // @param [system: number] => 0 facebook
  //                            1 coopApp
  //                            2 sms
  updateOrderCollected(orderId) {
    this._fbcommentService
      .updateJatakOrderCollected(this.offerId, orderId)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => {
          NotificationsService.notify(
            'Ordren opdateret med succes',
            'success',
            'top'
          );
          this._success();
        },
        error: () => this._handleError(''),
      });
  }

// Selecting particular tab to view its orderlists
  // @param [tab: number] => 0 facebook
  //                         1 coopApp
  //                         2 sms
  tabSelect(tab) {
    this.emptyOrder = false;
    this.tab = tab;
    this.fetchSummaryList(tab)
    this.fetchCustomersList(tab)
    this.orderListFilterComponent?.resetSearch();
  }

  fetchCustomersList(tab) {
    if (this.tab === 0) {
      this.customerList = this.order.FACEBOOK.customers
      this.searchList = this.customerList
    }
    if (this.tab === 1) {
      this.customerList = this.order.COOP_APP.customers
      this.searchList = this.customerList
    }
    if (this.tab === 2) {
      this.customerList = this.order.SMS.customers
      this.searchList = this.customerList
    }
  }

  fetchSummaryList(tab) {
    if (this.tab === 0) {
      this.summaryList = this.order.FACEBOOK.summary
    }
    if (this.tab === 1) {
      this.summaryList = this.order.COOP_APP.summary
    }
    if (this.tab === 2) {
      this.summaryList = this.order.SMS.summary
    }
  }

// *** Used to update values after cancelling or approving or marking as picked up ***
  private _success() {
    // this.fetchSummaryList(this.tab)
    this._refreshPage();
  }

  // *** Assigns fields from resolvers and updates the number of order amounts remaining ***
  private _initComp() {
    this.assignOrderData(this._activatedRoute.snapshot.data['orderList']);
    this.fbPostId = this.order.fbPostId;
    this.offerId =
      this._activatedRoute.snapshot.params['id'] ||
      this._activatedRoute.snapshot.parent.params['id'];
    this.currentUrl = this._router.url;
    this.parentUrl = this._activatedRoute.snapshot.parent.data['parentUrl']
    // this.previousUrl = '/butikker/ja-tak'
    this.summaryList = this.order.FACEBOOK.summary;
    this.customerList = this.order.FACEBOOK.customers
    this.stockUpdate();
    this._seletecQuantityRange()
    this.tabSelect(this.tab)
    this.searchList = this.customerList
    this.userNmae = TokenService.getUserDetail().name
  }

  private _seletecQuantityRange() {
    if (this.order.variants.length) {
      this.order.variants.forEach((item) => {
        this.numbers[item.id] = this.getRange(item.remaining)
      })
    } else {
      this.numbers['0'] = this.getRange(this.summaryList.remainingStock)
    }
  }

  getRange(till) {
    let range = [];

    let limit = 1;

    while (limit <= till) {
      range.push(limit);
      limit++;
    }

    return range
  }

// *** Assigns orderData from resolver to its respective fields in the component ***
// @param [orderData: OrderData] => Whole order lists
  private assignOrderData(orderData: OrderListData) {
    this.order = orderData;
    this.varaintList = this.order.variants
    this._showOverSightData()
    this.activeCustomers = {
      fb: this.order.FACEBOOK.summary.orderCount,
      coop: this.order.COOP_APP.summary.orderCount,
      sms: this.order.SMS.summary.orderCount,
    };
  }

  private _showOverSightData() {
    if (this.order.shared.FACEBOOK && !this.order.shared.COOP_APP && !this.order.shared.SMS) {
      this.tab = 0
    }
    if (!this.order.shared.FACEBOOK && this.order.shared.COOP_APP && !this.order.shared.SMS) {
      this.tab = 1
    }
    if (!this.order.shared.FACEBOOK && !this.order.shared.COOP_APP && this.order.shared.SMS) {
      this.tab = 2
    }
  }

  // refreshing page by updating all values
  private _refreshPage() {
    this._fbcommentService
      .getOrderList(this.offerId)
      .pipe(
        takeWhile(() => this._subscriptionState),
        finalize(() => {
          this.stockUpdate();
        })
      )
      .subscribe({
        next: (res: any) => {
          this.assignOrderData(res);
          this.fetchCustomersList(this.tab)
          this.fetchSummaryList(this.tab)
        },
        error: (err) => this._handleError(err),
      });
  }

  toggleVaraint() {
    this.showVaraint = !this.showVaraint
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || {code: -400}, 'fbcomment');
  }
}
