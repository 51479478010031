<section class="app-sub-page app-promo-detail" *ngIf="promo">
  <article
    clickOutside
    (clickOutside)="handleClickOutside()"
    class="promo-detail"
    [@slideUpDown]="pAnimate"
    (@slideUpDown.done)="slideDone($event)"
  >
    <div class="offer-detail">
      <div class="time-details">
        <div>
          <div *ngIf="offerType !== 'sms' && offerType !== 'arsmode'" class="flex align-item-center">
            <ion-icon class="timer" name="timer-outline"></ion-icon>
            <p
              class="promo-start"
              [innerHTML]="formattedDate.startDate"
            >
            </p>
          </div>
          <div *ngIf="formattedDate?.endDate" class="flex align-item-center" style="margin-top: 5.5px">
            <ion-icon class="timer" name="timer"></ion-icon>
            <p class="promo-end" [innerHTML]="formattedDate.endDate"></p>
          </div>
          <div *ngIf="offerType === 'sms'" class="flex align-item-center">
            <ion-icon class="timer" name="timer-outline"></ion-icon>
            <p class="promo-start">
              {{ promo.expirationDate | dateformat }}
            </p>
          </div>
        </div>
        <div (click)="close()" class="close-btn">
          <ion-icon name="close"></ion-icon>
        </div>
      </div>
      <div class="offer-image"
           [class.imageLoading]="!imageLoaded"
           [class.has-video]="promo.isVideo"
           *ngIf="offerType !== 'sms' && offerType !== 'ja-tak-live'">
        <img
          src="{{ promo.largeImage }}"
          alt="Image-{{ promo.title }}"
          (load)="loadImage()"
          (error)="imgError()"
          [class.imageLoaded]="imageLoaded"
          class="image"
        />
      </div>
      <div class="offer-title">
        <p>{{ promo.title }}</p>
      </div>
      <p style="margin-top: 10px; margin-bottom: 10px" *ngIf="promo.alcohol">
        Sælges kun til personer over 18 år. Bemærk at du skal opfylde alders- og ID-kravene i butikken.
      </p>
      <div
        class="promo-content"
        [ngClass]="offerType === 'arsmode' ? 'special-event-content' : ''"
      >
        <div
          class="promo-desc editor-content"
          [ngClass]="offerType === 'arsmode' ? 'special-event-desc' : ''"
          [innerHtml]="promo.longDescription"
        ></div>
      </div>
      <footer>
        <a
          class="login-btn btn-black-login"
          (click)="edit(promo.id)"
          *ngIf="offerType !== 'sms' && offerType !== 'arsmode'"
        >
          Rediger
        </a>
        <a
          (click)="deleteOkScreen()"
          class="login-btn btn-white-login"
          style="margin-top: 10px"
          *ngIf="offerType == 'ok-screen'"
        >
          Slet
        </a>
        <a
          class="login-btn btn-white-login"
          style="margin-top: 10px"
          (click)="goToOrderList(promo.id)"
          *ngIf="offerType === 'ja-tak'"
        >
          Ordreliste
        </a>
        <a
          class="login-btn btn-black-login btn-bottom"
          style="margin-top: 10px"
          (click)="goToReport(promo.id)"
          *ngIf="offerType === 'ja-tak-live'"
        >
          Vis rapport
        </a>
        <a
          class="login-btn btn-black-login btn-bottom"
          (click)="edit(promo.id)"
          *ngIf="offerType === 'arsmode'"
        >
          Vis
        </a>
        <a
          class="login-btn btn-black-login btn-bottom"
          (click)="edit(promo.id)"
          *ngIf="offerType === 'sms' && promoOffer.sent === '0'"
        >
          Rediger
        </a>
        <a
          (click)="deletePromo()"
          class="login-btn btn-white-login "
          style="margin-top: 5px"
          *ngIf="
            offerType === 'tilbud' ||
            offerType === 'nyheder' ||
            offerType === 'god-pris'
          "
        >
          Slet alle kanaler
        </a>
        <a
          (click)="deleteSms()"
          class="login-btn btn-white-login "
          style="margin-top: 10px"
          *ngIf="offerType === 'sms'"
        >
          Slet
        </a>
        <a
          (click)="onDeleteJatak()"
          class="login-btn btn-white-login "
          style="margin-top: 10px"
          *ngIf="offerType === 'ja-tak-live'"
        >
          Slet
        </a>
      </footer>
    </div>
  </article>
</section>
