import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DatepickerData,
  DatepickerDateTimeRange,
  DatePickerOutput,
} from '../../interface';
import {localeDa, MbscDatepicker, MbscLocale} from '@mobiscroll/angular';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

@Component({
  selector: 'coop-date-time-range-picker',
  templateUrl: './date-time-range-picker.component.html',
  styleUrls: ['./date-time-range-picker.component.scss'],
})
export class DateTimeRangePickerComponent
  implements OnInit, OnChanges, OnDestroy {
  @ViewChild('myDatepicker', {static: false}) myDatepicker: MbscDatepicker;
  @Input() datePickerData: DatepickerData;
  // @Input() editMode: boolean = false;
  @Input() duplicateMode: boolean = false;
  @Input() templateMode: boolean = false;
  dateTime: DatepickerDateTimeRange = {
    start: {
      date: dayjs().format('YYYY-MM-DD'),
      time: dayjs().format('HH:mm'),
    },
    end: {
      date: dayjs().add(6, 'days').format('YYYY-MM-DD'),
      time: dayjs().format('HH:mm'),
    },
  };
  @Input() defaultStartDate = dayjs().format('YYYY-MM-DD');
  dateRange = [this.dateTime.start.date, this.dateTime.end.date];
  onStartNow: boolean = true;
  @Output() onDateTimeChange = new EventEmitter<DatePickerOutput>();
  showCalendar: boolean = false;
  locale: MbscLocale;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['datePickerData']) {
      this.datePickerData = changes['datePickerData'].currentValue;
      this.setupCalendar();
    }

    if(changes['defaultStartDate']){
      this.defaultStartDate = changes['defaultStartDate'].currentValue;
    }
    if (changes['templateMode']) {
      this.templateMode = changes['templateMode'].currentValue
    }
  }

  ngOnDestroy() {
    if (this.myDatepicker) {
      this.myDatepicker.destroy();
    }
  }

  ngOnInit() {
    this.locale = localeDa;
    this.setupCalendar();
    if(this. datePickerData.modes.isEditMode || this.datePickerData.modes.isTemplate ){
      this.showCalendar = true;
      this.onStartNow = false;
    }
  }



  private setupCalendar() {
    const currentDate = dayjs();

    this.dateTime = {
      start: {
        date: dayjs(this.datePickerData.startEndDateTime.start).format(
          'YYYY-MM-DD'
        ),
        time: dayjs(this.datePickerData.startEndDateTime.start).format('HH:mm'),
      },
      end: {
        date: dayjs(this.datePickerData.startEndDateTime.end).format(
          'YYYY-MM-DD'
        ),
        time: dayjs(this.datePickerData.startEndDateTime.end).format('HH:mm'),
      },
    };

    if (currentDate.isAfter(this.dateTime.end.date)) {
      this._setDefaultDateTime();
    }
    this.dateRange = [this.dateTime.start.date, this.dateTime.end.date];
    this.checkIfNow();
  }

  private _setDefaultDateTime() {
    this.dateTime = {
      start: {
        date: dayjs().format('YYYY-MM-DD'),
        time: dayjs().format('HH:mm'),
      },
      end: {
        date: dayjs().add(6, 'days').format('YYYY-MM-DD'),
        time: dayjs().format('HH:mm'),
      },
    };
  }

  onTimeChange(event: any, type: 'start' | 'end') {
    if (type === 'start') {
      this.dateTime.start.time = event.valueText;
    } else {
      this.dateTime.end.time = event.valueText;
    }
    this.concatDateTime();
  }

  onActiveDateChange(event: any) {
    this.onStartNow
      ? event.inst.setActiveDate('end')
      : event.inst.setActiveDate('start');
  }

  getDateTime(event: any) {
    if (this.onStartNow) {
      this.myDatepicker.setActiveDate('end');
      this.dateTime.end.date = dayjs(event.value[1]).format('YYYY-MM-DD');
    }
    if (!this.onStartNow) {
      this.dateTime.start.date = this.datePickerData.disable.start ? this.dateTime.start.date : dayjs(event.value[0]).format('YYYY-MM-DD');
      this.dateTime.end.date = this.dateTime.start.date;
      if (event.value[1]) {
        this.dateTime.end.date = dayjs(event.value[1]).format('YYYY-MM-DD');
      }

    }
    this.dateRange = [this.dateTime.start.date, this.dateTime.end.date];
    this.concatDateTime();
  }

  onStartNowToggle(event: any) {
    this.onStartNow = event.target.checked;
    if (this.onStartNow) {
      this.dateTime.start.date = dayjs().format('YYYY-MM-DD');
      this.dateTime.start.time = dayjs().format('HH:mm');
      this.dateRange = [dayjs().format('YYYY-MM-DD'), this.dateTime.end.date];
      if (this.myDatepicker) {
        this.myDatepicker.setActiveDate('end');
      }
    }
    if (!this.onStartNow) {
      this.showCalendar = true
      if (this.myDatepicker) {
        this.myDatepicker.setActiveDate('start');
      }
      this.dateRange = [null, null];
    }

    this.concatDateTime();
  }

  formatDate(date: string, format?: string) {
    dayjs.locale('da');
    if (format) {
      return dayjs(date).format(format);
    }
    return dayjs(date).format('D. MMMM');
  }

  concatDateTime() {
    let startDateTime =
      this.dateTime.start.date + ' ' + this.dateTime.start.time;
    let endDateTime = this.dateTime.end.date + ' ' + this.dateTime.end.time;
    let start_now = this.onStartNow;
    this.onDateTimeChange.emit({startDateTime, endDateTime, start_now});
  }

  toggleCalendar() {
    this.showCalendar = !this.showCalendar;
  }

  private checkIfNow() {
    this.onStartNow = dayjs().isBetween(
      this.dateTime.start.date,
      this.dateTime.end.date
    );
  }
}
