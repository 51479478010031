import {AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import * as moment from "moment";
import {DisplaySlot} from "../../features/ok-screen";
import * as _ from 'lodash';
// *** Check if Available Seats is less than Max Number of Seats that can be bought ***
// @params [control: AbstracControl/FormGroup]
// @return [Object or Null] => If Max Seat is more than Available Seat return Error Object else return NUll

let invalid = false
export const checkIfMaxBuyIsMoreThanSeats = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const seats = control.get("seatCount").value;
  const buynumber = control.get("maxCanBuy").value;
  if (buynumber > seats) {
    return {
      boughtmorethanseats: {
        error: true
      }
    };
  }
  return null;
};


export const checkWhiteSpace = (
  c: AbstractControl
): { [key: string]: boolean } | null => {
  if (c.value) {
    const textlength = c.value.trim().length;
    if (textlength === 0) {
      return {
        required: true
      };
    }
  }
  return null;
};


export const checkWhiteSpaceForKeyword = (
  c: AbstractControl
): { [key: string]: boolean } | null => {
  if (c.value) {
    let regex = /\s/
    const text = c.value;
    if (regex.test(text)) {
      return {
        whitespace: true
      }
    }
  }
  return null;
};


export const checkNumberOnly = (
  c: AbstractControl
): { [key: string]: boolean } | null => {
  if (c.value) {
    let regex = /[a-zA-ZæåøÆÅØ]/
    const text = c.value;
    if (!regex.test(text)) {
      return {
        containsAlphabet: true
      }
    }
  }
  return null;
};

export const uniqueKeyword = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  if (control.value.length === 0) {
    return;
  }
  const keywordFormGroups = control.parent.parent["controls"];
  for (let index1 in keywordFormGroups) {
    const data1 = keywordFormGroups[index1]
    for (let index2 in keywordFormGroups) {
      if (index1 === index2) {
        continue;
      }
      const data2 = keywordFormGroups[index2];
      if (data1['controls']['keyword'].value === data2['controls']['keyword'].value) {
        return {
          duplicate: true
        };
      }
    }
  }
  return null;
};


export const uniquePriceQantity = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  if (!control.parent || !control.parent.parent) {
    return null;
  }
  const formArray = control.parent.parent as FormArray;
  const formGroupControls = formArray.controls;

  for (let i = 0; i < formGroupControls.length; i++) {
    const formGroup = formGroupControls[i];
    if (control !== formArray.at(i).get('quantity') && formGroup.get('quantity').value === control.value) {
      return {duplicate: true};
    }
  }

  return null;
};


export const minSeatNumber = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const seats = control.get("seatCount").value;
  if (seats < 0) {
    return {
      seatMin: {
        error: true
      }
    };
  }
  return null;
};
export const minTicketNumber = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const tickets = control.get("maxCanBuy").value;
  if (tickets < 0) {
    return {
      ticketMin: {
        error: true
      }
    };
  }
  return null;
};
export const minOffer = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const offer = control.get("offers").value;
  if (offer < 0) {
    return {
      offerMin: {
        min: true
      }
    };
  }
  return null;
};

// *** Compare Start Date and End Date *** //
// @params [c : FormGroup] => The entire Form
// @return [Object or null] => If Start Date is greater than End date return Error Object else return NULL
export const checkIfEndDateIsLessThanStartDate = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const start = control.get("startDate")
    ? moment(control.get("startDate").value).toDate()
    : moment(control.get("StartDate").value).toDate();
  const end = control.get("endDate")
    ? moment(control.get("endDate").value).toDate()
    : moment(control.get("EndDate").value).toDate();
  if (start >= end) {
    return {
      dateMismatchStartEnd: {
        mismatch: true
      }
    };
  }
  return null;
};
// *** Compare Start Date and End Date *** //
// @params [c : FormGroup] => The entire Form
// @return [Object or null] => If Start Date is greater than End date return Error Object else return NULL
export const checkIfEndDateIsLessThanWeekBeforeStartDate = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  const start = moment(control.get("startDate").value).toDate();
  const end = moment(control.get("endDate").value).toDate();
  const isStartBefore = moment(end).diff(start, "days") < 7;
  if (isStartBefore) {
    return {
      dateMismatchStartEndWeek: true
    };
  }
  return null;
};
// *** Validates whether provided date range falls on used time slots *** //
// @params [usedTimeSlots : DisplaySlots[]] => ValidatorFn
// @return [control:AbstractControl] => { [key: string]: boolean } | null

export const isEndDateGreaterthanStartDate = (
  control: AbstractControl
): { [key: string]: boolean } | null => {

  let slots: DisplaySlot[] = control["controls"].displaySlots.value;
  const controls = control["controls"].displaySlots.controls;

  return null

}


export const okScreenUsedSlots = (usedTimeSlots: DisplaySlot[]): ValidatorFn => {
  invalid = false
  return (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    if (!control.value) {
      return null;
    }
    const parentControl = control.parent;
    if (parentControl) {
      const controls = parentControl.controls;
      const start = controls["startDate"].value || controls["endDate"].value;
      const end = controls["endDate"].value || controls["startDate"].value;
      if (usedTimeSlots?.length) {
        for (let i = 0; i < usedTimeSlots?.length; i++) {
          if (moment(control.value).isBetween(usedTimeSlots[i]?.startDate, usedTimeSlots[i]?.endDate)) {
            invalid = true
            return {
              used: true
            };
          }
          if (usedTimeSlots[i]?.startDate === start && usedTimeSlots[i]?.endDate === end) {
            invalid = true
            controls["endDate"].setErrors({"same": true});
            controls["startDate"].setErrors({"same": true});
            return {
              same: true
            };
          }
          if (moment(usedTimeSlots[i]?.startDate).isBetween(start, end) || moment(usedTimeSlots[i]?.endDate).isBetween(start, end)) {
            invalid = true

            controls["endDate"].setErrors({"range": true});
            controls["startDate"].setErrors({"range": true});
            return {
              range: true
            };
          }
          let endDateError = {...controls["endDate"].errors};
          let startDateError = {...controls["startDate"].errors};
          delete endDateError["range"];
          delete startDateError["range"];
          delete endDateError["same"];
          delete startDateError["same"];
          controls["startDate"].setErrors(Object.keys(startDateError).length ? startDateError : null);
          controls["endDate"].setErrors(Object.keys(endDateError).length ? endDateError : null);
        }
      }
    }
    return null;
  };
};
// *** Validates whether provided date range falls on displayed time slots *** //
// @params [control:AbstractControl] => Display slot form group as control
// returns { [key: string]: boolean } | null => Validation error object or null
export const okScreenDisplaySlots = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  let slots: DisplaySlot[] = control["controls"].displaySlots.value;
  const controls = control["controls"].displaySlots.controls;
  if (slots?.length) {
    for (let i = 0; i < slots.length; i++) {
      const start = controls[i].value["startDate"];
      const end = controls[i].value["endDate"];
      const isEndDateBeforeStartDate = moment(end).isBefore(start);
      if (!start.length || !end.length) {
        return null;
      }
      // to check whether end date is before start date
      if (isEndDateBeforeStartDate) {
        controls[i].controls["startDate"]?.setErrors({"incorrect": true});
        controls[i].controls["endDate"]?.setErrors({"incorrect": true});
        return {
          "incorrect": true
        }
      }
      if (controls[i].value["startDate"] === controls[i].value["endDate"]) {
        controls[i].controls["startDate"]?.setErrors({"sameSame": true});
        controls[i].controls["endDate"]?.setErrors({"sameSame": true});
        return {"sameSame": true};
      }
      let endDateError = {...controls[i]["endDate"]?.errors};
      let startDateError = {...controls[i]["startDate"]?.errors};
      delete endDateError["incorrect"];
      delete startDateError["incorrect"];
      delete startDateError["sameSame"];
      delete endDateError["sameSame"];
      if (!invalid) {
        controls[i].controls["startDate"]?.setErrors(Object.keys(startDateError).length ? startDateError : null);
        controls[i].controls["endDate"]?.setErrors(Object.keys(endDateError).length ? endDateError : null);
      }

      if (i === slots.length - 1) {
        continue;
      }
      for (let j = i + 1; j < slots?.length; j++) {
        let endDateError = {...controls[j]["endDate"]?.errors};
        let startDateError = {...controls[j]["startDate"]?.errors};
        if (start.length && end.length && slots[j]?.startDate.length && slots[j]?.endDate.length) {
          if (moment(start).isBetween(slots[j]?.startDate, slots[j]?.endDate) || moment(end).isBetween(slots[j]?.startDate, slots[j]?.endDate)
            || moment(slots[j]?.startDate).isBetween(start, end) || moment(slots[j]?.endDate).isBetween(start, end)
          ) {
            controls[j].controls["startDate"]?.setErrors({"usedDisplay": true});
            controls[j].controls["endDate"]?.setErrors({"usedDisplay": true});
            return {"usedDisplay": true};
          }
          if (slots[j]?.startDate === start && slots[j]?.endDate === end) {
            controls[j].controls["startDate"]?.setErrors({"sameDisplay": true});
            controls[j].controls["endDate"]?.setErrors({"sameDisplay": true});
            return {"sameDisplay": true};
          }
          delete startDateError["usedDisplay"];
          delete endDateError["usedDisplay"];
          delete endDateError["sameDisplay"];
          delete startDateError["sameDisplay"];
          if (!invalid) {
            controls[j].controls["startDate"]?.setErrors(Object.keys(startDateError).length ? startDateError : null);
            controls[j].controls["endDate"]?.setErrors(Object.keys(endDateError).length ? endDateError : null);
          }
        }
      }
    }
  }
  return null;
};
export const checkStartEndTime = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const startTime = moment(control.get("startDate").value).format("HH:mm");
  const endTime = moment(control.get("endDate").value).format("HH:mm");
  const startDay = control.get("recurringData.startDay").value;
  const endDay = control.get("recurringData.endDay").value;
  const isSameDay = startDay === endDay;
  const isStartTimeBeforeEndTime = startTime > endTime;
  // console.log(isSameDay , isStartTimeBeforeEndTime)
  if (isSameDay && isStartTimeBeforeEndTime) {
    return {
      dateMismatchStartEndTime: {
        mismatch: true
      }
    };
  }
  return null;
};
export const checkWeeklyValidity = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  const endDay = moment(control.get("EndDate").value).weekday();
  const selectedEndDay = weekDays.indexOf(
    control.get("recurring_data.EndDay").value
  );
  if (endDay !== selectedEndDay) {
    return {
      weekly_invalid: {
        mismatch: true
      }
    };
  }
  return null;
};
export const checkIfExpDateIsLessThanPublishDate = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const start = moment(control.get("publishDate").value).toDate();
  const end = moment(control.get("expirationDate").value).toDate();
  if (start >= end) {
    return {
      dateMismatchPubExp: {
        mismatch: true
      }
    };
  }
  return null;
};
export const checkIfCommentDateIsLessThanStartDate = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const start = moment(control.get("startDate").value).toDate();
  const end = moment(control.get("endDate").value).toDate();
  const currentDate = moment().format('YYYY-MM-DD HH:mm')
  const convertedDate = moment(currentDate).toDate();
  if (start > end) {
    return {
      dateMismatch: {
        mismatch: true
      }
    };
  }
  if (end < convertedDate) {
    return {
      endmisMatch: {
        mismatch: true
      }
    };
  }
  return null;
};

export const checkOrderDates = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const start = control.get("pickupStart").value;
  const end = control.get("pickupEnd").value;
  const publish = control.get("publishAt").value;
  if (start > end) {
    return {
      dateMismatch: {
        mismatch: true
      }
    }
  }

  if (publish > start) {
    return {
      publishMismatch: {
        mismatch: true
      }
    }
  }

  if (publish == start) {
    return {
      publishMismatch: {
        mismatch: true
      }
    }
  }
  return null;
};

export const checkStartEndDateForOrderPickup = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const start = moment(control.get("StartDate").value).toDate();
  const end = moment(control.get("EndDate").value).toDate();
  if (start > end) {
    return {
      dateMismatch: {
        mismatch: true
      }
    };
  }
  return null;
};
export const checkEmailDateWithPubExpDate = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const end = moment(control.get("ExpirationDate").value).toDate();
  const email = moment(control.get("EmailDate").value).toDate();
  if (email) {
    if (email > end) {
      return {
        dateEmail: {
          mismatch: true
        }
      };
    }
  }
  return null;
};
export const checkIfExpireEndMismatch = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  // for deprecated warning due to empty string being passed from control to moment
  if (
    control.get("endDate").value === "" ||
    control.get("endDate").value === null ||
    control.get("expirationDate").value === "" ||
    control.get("expirationDate").value === null
  ) {
    return null;
  }
  const end = moment(
    moment(control.get("endDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  const expire = moment(
    moment(control.get("expirationDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  if (expire > end) {
    return {
      dateMismatchExpireEnd: {
        mismatch: true
      }
    };
  }
  return null;
};
export const checkIfExpireStartMismatch = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  // for deprecated warning due to empty string being passed from control to moment
  if (
    control.get("startDate").value === "" ||
    control.get("startDate").value === null ||
    control.get("expirationDate").value === "" ||
    control.get("expirationDate").value === null
  ) {
    return null;
  }
  const start = moment(
    moment(control.get("startDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  const expire = moment(
    moment(control.get("expirationDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  if (start > expire) {
    return {
      dateMismatchExpireStart: {
        mismatch: true
      }
    };
  }
  return null;
};
export const checkIfSmsDateIsInRange = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  // for deprecated warning due to empty string being passed from control to moment
  if (
    control.get("sendDate").value === "" ||
    control.get("sendDate").value === null ||
    control.get("endDate").value === "" ||
    control.get("endDate").value === null
  ) {
    return null;
  }
  const endDate = moment(
    moment(control.get("endDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  const smsSendDate = moment(
    moment(control.get("sendDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  if (smsSendDate > endDate) {
    return {
      smsDateNotInRange: {
        invalid: true
      }
    };
  }
  return null;
};
export const checkifTimeSelected = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  if (control.value === "" || !control.value) {
    return {
      TurnaroundTime: {
        required: true
      }
    };
  }
  return null;
};
export const maxLengthOfEight = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  const value = control.value;
  if (!value) {
    return null;
  }
  if (value.length > 8) {
    return {
      invalid: true
    };
  }
  return null;
};
export const checkPhone = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  let phone = control.value;
  if (phone === null) {
    return {
      invalid: true
    };
  }
  phone = parseInt(phone, 10);
  if (isNaN(phone) || phone.toString().length !== 8) {
    return {
      invalid: true
    };
  }
  return null;
};


export const checkPhoneNumber = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  let phone = control.value;
  const isNotPhoneNumber = () => !phone?.toString().replace(/ /g, '').match(/^\d{8}$/)
  const isInvalidFormat = () => phone?.toString().startsWith('0')

  if ((phone && phone?.length !== 12 && isNotPhoneNumber()) || isInvalidFormat()) {
    return {
      invalid: true
    }
  }
  return null;
};

export const checkPhoneNumberDynamically = (options: { requiredLength?: number } = {}) => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const phone = control.value;
    const requiredLength = options.requiredLength || 12; // Default length is 12 if not provided
    const digitLength = requiredLength == 12 ? 8 : 6
    const isNotPhoneNumber = () => !phone?.toString().replace(/ /g, '').match(new RegExp(`^\\d{${digitLength}}$`));
    const isInvalidFormat = () => phone?.toString().startsWith('0');

    if (
      (phone && phone?.length !== requiredLength && isNotPhoneNumber()) ||
      isInvalidFormat()
    ) {
      return {invalid: true};
    }

    return null;
  };
};
export const checkMaxLength = (control: AbstractControl): { [key: string]: boolean } | null => {
  let maxLength = 99
  let inputlength = control.value.length
  if (inputlength > maxLength) {
    return {
      maxlength: true
    }
  }
  return null;
}

export const checkMaxLengthForTitle = (control: AbstractControl): { [key: string]: boolean } | null => {
  let maxLength = 100
  let inputlength = _.size(control.value)
  if (inputlength > maxLength) {
    return {
      maxlength: true
    }
  }
  return null;
}

export const checkMaxLengthForSoldOutTitle = (control: AbstractControl): { [key: string]: boolean } | null => {
  let maxLength = 60
  let inputlength = _.size(control.value)
  if (inputlength > maxLength) {
    return {
      maxlength: true
    }
  }
  return null;
}

export const checkMaxLengthForSoldOutReply = (control: AbstractControl): { [key: string]: boolean } | null => {
  let maxLength = 100
  let inputlength = _.size(control.value)
  if (inputlength > maxLength) {
    return {
      maxlength: true
    }
  }
  return null;
}

export const checkMaxLengthForSMSClub = (control: AbstractControl): { [key: string]: boolean } | null => {
  let maxLength = 100
  let inputlength = control.value.length

  if (inputlength > maxLength) {
    return {
      maxlength: true
    }
  }
  return null;
}


export const checkMaxLengthForVataintTitle = (control: AbstractControl): { [key: string]: boolean } | null => {
  let maxLength = 47
  let inputlength = _.size(control.value)
  if (inputlength > maxLength) {
    return {
      maxlength: true
    }
  }
  return null;
}


export function validatePhone(control: AbstractControl): { [key: string]: any } | null {
  // Regular expression to validate a phone number (e.g., +45 followed by 8 digits)
  const phoneRegex = /^\d{8}$/;

  if (control.value && !phoneRegex.test(control.value)) {
    // Validation failed, return an object with the error
    return {'invalid': true};
  }

  // Validation passed, return null
  return null;
}

export const checkEmojiCharacters = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  if (!control.value["message"]) {
    return;
  }
  const regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  if (control.value["message"].match(regex)) {
    return {
      emoji: {
        invalid: true
      }
    };
  }
  return null;
};

export const checkEmojiCharactersForSMSMessage = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  if (!control.value) {
    return;
  }
  const regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  if (control.value.match(regex)) {
    return {
      emoji: {
        invalid: true
      }
    };
  }
  return null;
}


export const checkIfExpDateIsLessThanStartDate = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  // for deprecated warning due to empty string being passed from control to moment
  if (
    control.get("startDate").value === "" ||
    control.get("startDate").value === null ||
    control.get("expirationDate").value === "" ||
    control.get("expirationDate").value === null
  ) {
    return null;
  }
  const start = moment(
    moment(control.get("startDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  const expire = moment(
    moment(control.get("expirationDate").value).format("YYYY-MM-DD HH:mm")
  ).toDate();
  if (expire >= start) {
    return {
      dateMismatchExpStart: {
        mismatch: true
      }
    };
  }
  return null;
};

export const checkIfXinDescription = (
  control: AbstractControl
): { [key: string]: boolean } | null => {
  const description = control.value;
  if (
    description.indexOf("[X]") !== -1 ||
    description.indexOf("[") !== -1 ||
    description.indexOf("]") !== -1
  ) {
    return {
      invalid: true
    };
  }
  return null;
};

export const checkStartEndDate = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const startDate = moment(control.get("startDate").value).format("L");
  const endDate = moment(control.get("endDate").value).format("L");
  const isSameDate = startDate === endDate;
  if (!isSameDate) {
    return {
      dateMismatchStartEndDate: {
        mismatch: true
      }
    };
  }
  return null;
};

export const checkIfTotalOfferLessThanPerDay = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const totalOffer = control.get("totalOffer").value;
  const offerPerDay = control.get("offerPerDay").value;
  const infiniteTotalOffer = control.get("totalOfferUnlimited").value;
  if (totalOffer < offerPerDay && !infiniteTotalOffer) {
    return {
      offerPerDay: {
        invalid: true
      }
    };
  }
  return null;
};

export const minOrderPerDay = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  const offerPerDay = control.get("offerPerDay").value;
  const minPurchase = control.get("minimumOrder").value;
  const offerPerDayUnlimited = control.get("offerPerDayUnlimited").value;
  if (offerPerDayUnlimited) {
    // do not use validator if offer per day is unlimited
    return null;
  }
  if (minPurchase > offerPerDay) {
    return {
      minimumOrder: {
        invalid: true
      }
    };
  }
  return null;
};

export const maxOrderLessThanTotal = (
  control: AbstractControl
):
  | { [key: string]: { [key: string]: boolean } }
  | { [key: string]: boolean }
  | null => {
  if (!control.value) {
    return null;
  }
  const totalQuantity = control.get("quantity").value;
  const maxOrder = control.get("maxOrderCount").value;
  const maxOrderSet = control.get("maxOrder").value;
  if (!maxOrderSet) {
    // do not use validator if max order not set
    return null;
  }
  if (maxOrder > totalQuantity) {
    // control.get('maxOrderCount').setErrors({
    //   maxOrderLessThanTotal: true
    // })
    return {
      maxOrderLessThanTotal: {
        invalid: true
      }
    };
  }
  return null;
};
export const maxOrderGreaterThanZero = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  if (!control.value) {
    return null;
  }
  const totalQuantity = control.get("quantity").value;
  const maxOrder = control.get("maxOrderCount").value;
  const maxOrderSet = control.get("maxOrder").value;
  if (!maxOrderSet) {
    // do not use validator if max order not set
    return null;
  }
  if (totalQuantity > 0 && maxOrder === 0) {
    return {
      maxOrderGreaterThanZero: {
        invalid: true
      }
    };
  }
  return null;
};
export const checkIfEventTypeEmpty = (
  control: FormGroup
): { [key: string]: boolean } | null => {
  const eventType = control.value;
  if (!eventType.physical && !eventType.online) {
    return {
      invalid: true
    };
  }
  return null;
};

export const checkIfOfferPriceLessThanReal = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  // for deprecated warning due to empty string being passed from control to moment
  const price = String(control.get("price").value);
  if (!price) {
    return null;
  }
  const floatPrice = convertDanishIntoFloat(price);
  const floatOfferPrice = convertDanishIntoFloat(
    String(control.get("offerPrice").value)
  );
  if (floatOfferPrice >= floatPrice) {
    return {
      offerPriceInvalid: {
        invalid: true
      }
    };
  }
  return null;
};

export const checkIfOfferPriceLessThanRealScreen = (
  control: AbstractControl
): { [key: string]: { [key: string]: boolean } } | null => {
  // for deprecated warning due to empty string being passed from control to moment
  const price = String(control.get("price").value);
  if (!price) {
    return null;
  }
  const floatPrice = convertDanishIntoFloat(price);
  const floatOfferPrice = convertDanishIntoFloat(
    String(control.get("offerPrice").value)
  );
  if (floatOfferPrice >= floatPrice) {
    return {
      offerPriceInvalid: {
        invalid: true
      }
    };
  }
  return null;
};

export function customMinValidator(minValue: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    // Convert value to a number, accounting for comma as decimal separator
    if (value === "0" || value === "0,00") {
      return {
        min: true
      };
    }
    return null;
  };
}

export const checkPriceRegex = (
  control: FormControl
): { [key: string]: boolean } | null => {
  const price = control.value ? String(control.value) : null;
  if (!price) {
    return;
  }
  const regex = /^([1-9]\d{0,2}(\.\d{3}){0,3}|0)(,\d{1,2})?$/;
  if (!price.match(regex)) {
    return {
      invalid: true
    };
  }
  return null;
};

export const checkPriceMinimum = (
  control: FormControl
): { [key: string]: boolean } | null => {
  const price = control.value ? String(control.value) : null;
  if (!price) {
    return;
  }
  const floatPrice = convertDanishIntoFloat(price);
  if (floatPrice <= 0) {
    return {
      min: true
    };
  }
  return null;
};

export const checkPriceMaximum = (
  control: FormControl
): { [key: string]: boolean } | null => {
  const price = String(control.value);
  if (!price) {
    return;
  }
  const floatPrice = convertDanishIntoFloat(price);
  if (floatPrice > 50000) {
    return {
      max: true
    };
  }
  return null;
};

export const checkMaximumPriceProduct = (
  control: FormControl
): { [key: string]: boolean } | null => {
  const price = String(control.value);
  if (!price) {
    return;
  }
  const floatPrice = convertDanishIntoFloat(price);
  if (floatPrice >= 10000) {
    return {
      max: true
    };
  }
  return null;
};

const convertDanishIntoFloat = (price: string): number => {
  // convert 123.456,50 into 123456,50
  // const priceWithoutDots = price.replace('.', '');
  const regex = /^([1-9]\d{0,2}(\.\d{3}){0,3}|0)(,\d{1,2})?$/;
  if (!price.match(regex)) {
    return;
  }
  const priceWithoutDots = replaceAll(price, ".", "");
  // convert 123456,50 into 123456.50
  return parseFloat(replaceAll(priceWithoutDots, ",", "."));
};

const escapeRegExp = (str: string): string => {
  return str.replace(/[.^$*+?()[{|\\]/g, "\\$&"); // $& means the whole matched string
};

const replaceAll = (str: string, find: string, replace: string): string => {
  return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
};
