import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  HelperService,
  NotificationsService,
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate,
  checkIfExpDateIsLessThanPublishDate,
  checkIfExpireEndMismatch,
  checkIfOfferPriceLessThanReal,
  checkMaxLength,
  checkPriceMinimum,
  checkPriceRegex,
  checkWhiteSpace,
  customMinValidator,
} from 'app/core/validators';
import { COMMON_SELECT_SETTINGS } from 'app/shared/data';
import {
  DatepickerData,
  Modes,
  ValidationMessage,
  VideoUrls,
} from 'app/shared/interface';
import { LeftPad } from 'app/shared/pipes';
import {
  INITIAL_FORM_DATA,
  Coop,
  NyhedTilbud,
  PushWeekData,
  OfferDateTimeService,
  OfferService,
  OfferStateService,
} from 'app/features/offer';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';
import { Store } from '@ngxs/store';

@Component({
  selector: 'coop-coop-form',
  templateUrl: './coop-form.component.html',
  styleUrls: ['./coop-form.component.scss'],
  providers: [LeftPad],
})
export class CoopFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() modes: Modes;
  @Input() tab: string = '';
  @Input() validationMessages: ValidationMessage;
  @Input() userType: string;
  @Input() offerType: string = 'tilbud';
  @Output() formReady = new EventEmitter<FormGroup>();
  @Input() submitForm = false;
  formData?: NyhedTilbud = INITIAL_FORM_DATA;
  coopForm: FormGroup;
  imageUrl = {
    largeImage: '',
    thumbnail: '',
  };
  dummyImages = {
    largeImage: '',
    thumbmail: '',
    video: '',
    videoThumbnail: '',
  };
  defaultImageData = {
    largeImage: 'assets/images/camera@3x.png',
    thumbnail: '',
  };
  defaultVideoData = {
    url: '',
    thumbnail: 'assets/images/video.png',
  };
  videoUrl: VideoUrls = {
    url: '',
    thumbnail: 'assets/images/video.png',
  };
  hasImage = false;
  hasVideo = false;
  isGif = false;
  isVideo = false;
  isProcessing = false;
  stk = 0;
  availablePushCount: number;
  togglePush = false;
  pushAvailable = false;
  errorCounter = false;
  isPostedToFb: boolean = false;
  currentDateTime: string;
  weekPushData: PushWeekData[] = [];
  visible: boolean = false;
  numbers = [];
  priceDescSelectSettings = {
    ...COMMON_SELECT_SETTINGS,
    ...{
      data: [
        'stk',
        'kg',
        'g',
        'liter',
        'ml',
        'pakke',
        'pakker',
        'sæt',
        'kasse',
        'kasser',
        'ramme',
        'rammer',
      ],
    },
  };
  startEndPickerData: DatepickerData;
  publishStartEndPickerData: DatepickerData;

  testData = {
    title: 'Explicabo Sunt at i',
    largeImage:
      'https://qcmobapi.quickinfo.dk/storage/images/HRPWkfLozJ_1731301235_thumb.jpg',
    thumbnail:
      'https://qcmobapi.quickinfo.dk/storage/images/HRPWkfLozJ_1731301235_thumb.jpg',
    video: {
      url: '',
      thumbnail: '',
    },
    startNow: true,
    publishNow: true,
    publishDate: '2024-11-11 10:45',
    expirationDate: '2024-11-15 10:45',
    startDate: '2024-11-11 10:45',
    endDate: '2024-11-16 10:45',
    longDescription: 'Quas a blanditiis om',
    inAppParameters: {
      price: '12',
      offerPrice: '',
      priceDescription: '21',
      uom: 'stk',
    },
    push: false,
  };

  titleCount: number = 0;
  private _subscriptionState = true;
  showPriceSection: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _offerService: OfferService,
    private _formStateService: OfferStateService,
    private _leftPad: LeftPad,
    private _errorHandlerService: ErrorhandlerService,
    private _store: Store
  ) {
    this.currentDateTime = moment().format('YYYY-MM-DD HH:mm');
  }

  ngOnInit() {
    this._setupCoopForm();
    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this._setPriceValidators();
    }
    this._fetchPushWeekData();
    OfferDateTimeService.setDefaultTimeOnCreate(this.coopForm);
    this.formData = this._formStateService.formState.value;
    if (
      this.formData.coop?.isSet ||
      this.modes.editMode ||
      this.modes.templateMode
    ) {
      this._editModeSetup(this.formData.coop);
    }
    this.coopForm.get('title').valueChanges.subscribe((res) => {
      this.titleCount = this.calculateLength(res);
    });

    if (!this.modes.newsMode && this.offerType === 'tilbud') {
      this.showPriceSection = true;
      this.coopForm.get('inAppParameters').patchValue({
        uom: 'stk',
      });
    }
    // this.coopForm.patchValue(this.testData)
    this.startEndPickerData = {
      titles: {
        start: 'Gyldig fra - nu',
        end: 'Gyldig til',
      },
      startEndDateTime: {
        start: this.coopForm.get('startDate').value,
        end: this.coopForm.get('endDate').value,
      },
      disable: {
        start: false,
        end: false,
      },
      modes: {
        isEditMode: this.modes.editMode,
        isTemplate: this.modes.templateMode,
        isDuplicate: false,
      },
    };

    this.publishStartEndPickerData = {
      titles: {
        start: 'Udsendingstidspunkt - nu',
        end: 'Afslutningstidspunkt',
      },
      startEndDateTime: {
        start: this.coopForm.get('publishDate').value,
        end: this.coopForm.get('expirationDate').value,
      },
      disable: {
        start: false,
        end: false,
      },
      modes: {
        isEditMode: this.modes.editMode,
        isTemplate: this.modes.templateMode,
        isDuplicate: false,
      },
    };
    this.formReady.emit(this.coopForm);


  }

  calculateLength(str: string) {
    return _.size(str);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['offerType']) {
      this.offerType = changes['offerType'].currentValue;
    }
    if (changes['submitForm']) {
      this.submitForm = changes['submitForm'].currentValue;
    }
    if (changes['validationMessages']) {
      this.validationMessages = changes['validationMessages'].currentValue;
    }

  }

  ngOnDestroy() {
    this._subscriptionState = false;
  }

  public get getForm() {
    return this.coopForm;
  }

  getVideoUrl(videoUrl: any) {
    this.videoUrl = {
      thumbnail: videoUrl.thumbnail,
      url: videoUrl.url,
    };
    this.hasVideo = true;
    this.coopForm.patchValue({
      video: {
        url: videoUrl.url,
        thumbnail: videoUrl.thumbnail,
      },
    });
  }

  getImagesUrl(images: any) {
    this.coopForm.patchValue({
      largeImage: images.largeImage,
      thumbnail: images.thumbnail,
    });
    Object.assign(this.imageUrl, images);
    this.hasImage = true;
  }

  imageProcessing(e: boolean) {
    this.isProcessing = e;
  }

  uploadTypeSelect(type: boolean) {
    this.clearingMediaOnTabChange(type);
  }

  clearingMediaOnTabChange(type: boolean) {
    let isPrompt;
    if (type) {
      isPrompt = this.hasImage;
    }
    if (!type) {
      isPrompt = this.hasVideo;
    }
    if (isPrompt) {
      this.clearMedia(type);
    }
    if (!isPrompt) {
      this.changeUploadType(type);
    }
  }

  clearMedia(isVideo: boolean) {
    let clearFunction;
    let notificationMsg;
    if (isVideo) {
      notificationMsg =
        'Alle uploadede billeder kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearImageUrl;
    }
    if (!isVideo) {
      notificationMsg =
        'Den uploadede video kasseres ved at skifte faner. Er du sikker?';
      clearFunction = this.clearVideo;
    }
    NotificationsService.confirmWithCallback(notificationMsg, (accept) => {
      if (accept) {
        clearFunction(this);
        this.changeUploadType(isVideo);
        return;
      }
      if (!accept) {
        return;
      }
    });
  }

  changeUploadType(isVideo: any) {
    this.isVideo = isVideo;
    this.changeMediaType(isVideo);
  }

  changeMediaType(isVideo: any) {
    if (isVideo && !!!this.getForm.value.video.url) {
      NotificationsService.notify(
        'Det er vigtigt, at videoen optages med mobiltelefonen holdt vertical/oprejst',
        'info',
        'top'
      );
    }
    this.changeMediaValidators(isVideo);
    this.setMediaToNull(isVideo);
  }

  changeMediaValidators(isVid: boolean) {
    let videoValidity;
    let largeImageValidity;
    if (isVid) {
      videoValidity = Validators.required;
      largeImageValidity = null;
    }
    if (!isVid) {
      videoValidity = null;
      largeImageValidity = Validators.required;
    }
    this._updateValidators('video.url', videoValidity);
    this._updateValidators('video.thumbnail', videoValidity);
    this._updateValidators('largeImage', largeImageValidity);
    this._updateValidators('thumbnail', largeImageValidity);
  }

  setMediaToNull(isVideo: boolean) {
    if (isVideo) {
      this.coopForm.patchValue({
        largeImage: null,
        thumbnail: null,
      });
    }
    if (!isVideo) {
      this.coopForm.patchValue({
        video: {
          url: null,
          thumbnail: null,
        },
      });
    }
  }

  clearImageUrl(context: any) {
    context.imageUrl = JSON.parse(JSON.stringify(context.imageUrl));
    context.hasImage = false;
    context.coopForm.patchValue({
      imageUrl: {
        largeImage: null,
        thumbnail: null,
      },
    });
  }

  clearVideo(context: any) {
    context.videoUrl = JSON.parse(JSON.stringify(context.defaultVideoData));
    context.hasVideo = false;
    context.coopForm.patchValue({
      video: {
        url: '',
        thumbnail: '',
      },
    });
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  // ** toggle for push notification
  toggle(e) {
    if (this.modes.editMode || this.modes.templateMode) {
      if (this.formData.coop?.push.toString() === '1') {
        e.preventDefault();
        NotificationsService.notify(
          'Push-meddelelse er allerede sendt',
          'error',
          'top'
        );
        return;
      }
    }
    this.pushToggle();
    if (this.pushAvailable) {
      this.errorCounter = false;
      this.togglePush = !this.togglePush;
    } else {
      e.preventDefault();
      this.errorCounter = true;
    }
  }

  pushToggle() {
    const hoursDifferenceFromNow = moment
      .duration(moment(this.coopForm.value.publishDate).diff(moment.now()))
      .asHours();
    if (
      (this.stk === 0 && this.availablePushCount) ||
      hoursDifferenceFromNow < -6
    ) {
      this.pushAvailable = false;
    }
  }

  private _setupCoopForm() {
    const validators = [checkIfExpDateIsLessThanPublishDate];
    if (!this.modes.newsMode) {
      validators.push(checkIfExpireEndMismatch);
      validators.push(checkIfEndDateIsLessThanStartDate);
    }

    this.coopForm = this._formBuilder.group(
      {
        title: ['', [Validators.required, checkWhiteSpace, checkMaxLength]],
        // dummy added so that no need to upload each time in Development mode
        largeImage: [
          environment.production ? '' : this.dummyImages.largeImage,
          Validators.required,
        ],
        thumbnail: [
          environment.production ? '' : this.dummyImages.thumbmail,
          Validators.required,
        ],
        video: this._formBuilder.group({
          url: [environment.production ? '' : this.dummyImages.video],
          thumbnail: [
            environment.production ? '' : this.dummyImages.videoThumbnail,
          ],
        }),
        startNow: [true],
        publishNow: [true],
        publishDate: ['', Validators.required],
        expirationDate: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        longDescription: ['', [Validators.required, checkWhiteSpace]],
        inAppParameters: this._formBuilder.group(
          {
            price: [''],
            offerPrice: [''],
            priceDescription: [''],
            uom: [''],
          },
          {
            validators: [checkIfOfferPriceLessThanReal],
          }
        ),
        alcohol:[],
        push: [false]
      },
      {
        validators: validators,
      }
    );
  }

  private updateValidation() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    validators.push(Validators.required);
    this._updateValidators('inAppParameters.priceDescription', [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex,
    ]);
    this._updateValidators('inAppParameters.price', [
      Validators.required,
      checkWhiteSpace,
      checkPriceRegex,
    ]);
  }

  addPriceField() {
    this.showPriceSection = true;
    this.updateValidation();
    this.coopForm.get('inAppParameters').patchValue({
      uom: 'stk',
    });
  }

  removePrices() {
    this.showPriceSection = false;
    this.removePriceValidators();
  }

  removePriceValidators(): void {
    this._updateValidators('inAppParameters.priceDescription', null);
    this._updateValidators('inAppParameters.price', null);
    this.coopForm.get('inAppParameters').patchValue({
      price: '',
      priceDescription: '',
      uom: '',
    });
  }

  private _updateValidators(formControl: string, validators = []): void {
    this.coopForm.get(formControl).setValidators(validators);
    this.coopForm.get(formControl).updateValueAndValidity();
  }

  private _setPriceValidators() {
    const validators = [checkPriceMinimum, checkPriceRegex];
    if (this.offerType === 'tilbud') {
      validators.push(Validators.required);
      this._updateValidators('inAppParameters.priceDescription', [
        customMinValidator(1),
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex,
      ]);
      this._updateValidators('inAppParameters.price', [
        customMinValidator(1),
        Validators.required,
        checkWhiteSpace,
        checkPriceRegex,
      ]);
    }
    if (this.offerType === 'tilbud') {
      // this._updateValidators("inAppParameters.price", validators);
      this._updateValidators('inAppParameters.offerPrice', validators);
    }
  }

  private _editModeSetup(promo: Coop) {
    this.getForm.patchValue(promo);
    this.coopForm.get('inAppParameters').patchValue({
      uom: promo.inAppParameters.uom.toLowerCase(),
    });
    this.getForm.patchValue({
      largeImage: promo.largeImage,
      thumbnail: promo.thumbnail,
    });
    this._patchFormMedias();
    if (promo.push.toString() === '0') {
      this.getForm.patchValue({
        push: false,
      });
    }
    if (promo.push.toString() === '1') {
      this.getForm.patchValue({
        push: true,
      });
    }
    if (
      !this.modes.newsMode &&
      this.offerType === 'good-price' &&
      promo.inAppParameters.price !== ''
    ) {
      this.showPriceSection = true;
      this.updateValidation();
    }
    this.getForm.patchValue({
      push: false
    });
    if (this.currentDateTime > promo.startDate) {
      this.isPostedToFb = true;
    }
  }

  private _patchFormMedias() {
    this.isVideo = this.formData.coop.largeImage === null;
    this._patchMultipleMedia(this.isVideo, this.formData.coop);
  }

  private _patchMultipleMedia(isVideo, values: Coop) {
    const currForm = this.getForm.value;
    let imgUrl;
    if (!isVideo) {
      this.imageUrl.largeImage = currForm.largeImage;
      this.imageUrl.thumbnail = currForm.thumbnail;
      this.hasImage = true;
      imgUrl = this.imageUrl.largeImage;
      const lastIndexOfDot = imgUrl.lastIndexOf('.');
      const fileFormat = imgUrl.slice(lastIndexOfDot);
      if (fileFormat === '.gif') {
        this.isGif = true;
      }
    }
    if (isVideo) {
      this.videoUrl = values.video;
      this.hasVideo = true;
      this.changeUploadType(isVideo);
      // we need to clear image in form because while patching , form array forms a formControl and hence largeImage is not empty and valid
      this.clearImageUrl(this);
    }
  }

  // date time value set for mobiscroll date-time
  private _setUpDateTime(inst: DatepickerBase) {
    if (!this.weekPushData.length) {
      this._fetchPushWeekData();
    }
    if (!this.modes.editMode) {
      OfferDateTimeService.setUpDateTime(this.coopForm, inst._el.id, 'coop');
    }
  }

  // on close of datetime
  private _closeDatePicker(inst: DatepickerBase) {
    const dateId = inst._el.id;
    if (dateId === 'publishcoop') {
      const promoForm = this.getForm;
      this.errorCounter = false;
      this._pushChecker();
      this.pushToggle();
      if (!this.pushAvailable) {
        promoForm.patchValue({
          push: false,
        });
        this.togglePush = false;
      }
    }
    if (this.modes.newsMode) {
      this._patchStartEndDatesForNewsMode(dateId);
    }
    this._pushChecker();
  }

  // ** Get Push Week Data **//
  private _fetchPushWeekData(): void {
    this._offerService
      .getWeekPushData()
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (availablePushCountData) => {
          this.availablePushCount = availablePushCountData.availablePushCount;
          this.weekPushData = availablePushCountData.pushWeekData;
          this._pushChecker();
        },
        error: (err) => this._handleError(err),
      });
  }

  // ** Check of the Publish date's Push count is more than available push of the user ** //
  // if is less allow push
  private _pushChecker(): void {
    let pushWeek;
    const promoForm = this.getForm;
    if (promoForm.value.publishDate) {
      pushWeek = `${moment(promoForm.value.publishDate)
        .year()
        .toString()}-${this._leftPad.transform(
        moment(promoForm.value.publishDate).isoWeek().toString(),
        2
      )}`;
    } else {
      pushWeek = `${moment(moment.now())
        .year()
        .toString()}-${this._leftPad.transform(
        moment(moment.now()).isoWeek().toString(),
        2
      )}`;
    }
    const selectedWeekCount = this.weekPushData
      .filter((data) => data['week'] === pushWeek)
      .map((obj) => obj['count']);
    if (this.availablePushCount) {
      const count = this.availablePushCount - (selectedWeekCount[0] || 0);
      if (count <= 0) {
        this.stk = 0;
      }
      if (count > 0) {
        this.stk = count;
        this.pushAvailable = true;
      }
    }
  }

  private _patchStartEndDatesForNewsMode(dateId) {
    const promoForm = this.getForm;
    if (dateId.indexOf('publishcoop') === 0) {
      promoForm.patchValue({
        startDate: promoForm.value.publishDate,
      });
    }
    if (dateId.indexOf('expirecoop') === 0) {
      promoForm.patchValue({
        endDate: promoForm.value.expirationDate,
      });
    }
  }

  onSelect() {
    this.visible = true;
  }

  ondeselect() {
    this.visible = false;
  }

  getStartDateTime(event: any) {
    this.coopForm.patchValue(
      {
        startDate: event.startDateTime,
        endDate: event.endDateTime,
        startNow: event.start_now,
      },
      {
        onlySelf: true,
      }
    );
  }

  getPublishExpirationDateTime(event: any) {
    this.coopForm.patchValue(
      {
        publishDate: event.startDateTime,
        expirationDate: event.endDateTime,
        publishNow: event.start_now,
      },
      {
        onlySelf: true,
      }
    );
  }

  private _handleError(error): void {
    this._errorHandlerService.handleError(error || { code: -400 }, 'promo');
    this.isProcessing = false;
  }
}
