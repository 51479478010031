<section
  class="app-page promo-create-edit page-with-fixed-header-and-tab"
  [@parentAnimate]="showParent"
  [class.anim-fix-enter]="showParent === 'visible'"
>
  <div class="fixed-header-bug-fix" id="fixed">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [routeTo]="previousUrl"
      [isBackGroundWhite]="true"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="flex-display tab-container-wrap">
          <div class="flex-display">
            <ul class="tab-list">
              <ng-container *ngIf="!showChannelSelection">
                <li
                  class="tab-option"
                >
                <span class="tab">{{
                  tabName[tab]
                  }}</span>
                </li>
              </ng-container>
              <ng-container *ngIf="showChannelSelection">
                <!-- pseudo list -->
                <li
                  class="tab-option"
                >
                  <span class="tab">{{ tabName[tab] }} </span>
                </li>
              </ng-container>
            </ul>
            <div
              *ngIf="nextTabArray.length > 1"
              class="next-prev flex-display">
              <div (click)="onPrevTab(getTabIndex(nextTab, 'prev'))">
                <ion-icon name="chevron-back-outline" ></ion-icon>
              </div>
              <div (click)="onNextTab(getTabIndex(nextTab,'next'))">
                <ion-icon
                  style="margin-left: 5px" name="chevron-forward-outline"></ion-icon>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <div
            class="channel-selection-btn"
            (click)="toggleChannelSelection()"
            *ngIf="!editMode"
          >
            <span class='channel-margin-5'>Kanal</span>
            <ion-icon name="chevron-down-outline" class="font-18"
                      [class.show-channel-selection]="showChannelSelection"></ion-icon>
<!--            <i-->
<!--              class="icon icon-arrow-down"-->
<!--              [class.show-channel-selection]="showChannelSelection"-->
<!--            ></i>-->
          </div>
        </div>
         <coop-channel-select
           *ngIf="showChannelSelection"
           class="channel-selection-box"
           [selectedChannel]="shared"
           [mode]="channelSelectionModes"
           [allowedChannels]="allowedChannels"
           [offerType]="'good-price'"
           (channelConfirmed)="confirmedShareOptions($event)"
           (channelState)="updateChannelState($event)"
         ></coop-channel-select>
      </div>
    </div>
  </div>
  <div class="page-container" id="page-content">
    <div>
      <form [formGroup]="promoForm">
        <div class="multistep-window">
          <div class="tab">
            <div class="coop" [hidden]="tab !== 'coop'" *ngIf="shared.coop">
              <coop-coop-form
                [modes]="modes"
                [tab]="tab"
                [validationMessages]="validationMessages"
                [userType]="userType"
                [offerType]="'good-price'"
                (formReady)="addChildForm('coop', $event)"
                [submitForm]="submitForm"
              ></coop-coop-form>
            </div>
            <div
              class="fb"
              [hidden]="tab !== 'facebook'"
              *ngIf="shared.facebook"
            >
              <coop-fb-form
                [modes]="modes"
                [tab]="tab"
                [validationMessages]="validationMessages"
                [validateModes]="validateModes"
                [offerType]="'good-price'"
                (formReady)="addChildForm('facebook', $event)"
                [currentTab]="tab"
                [submitForm]="submitForm"
              ></coop-fb-form>
            </div>
            <div class="sms" [hidden]="tab !== 'sms'" *ngIf="shared.sms">
              <coop-sms-form
                [modes]="modes"
                [tab]="tab"
                [validationMessages]="validationMessages"
                [validateModes]="validateModes"
                [offerType]="'good-price'"
                (emojiErrorcheck)="checkEmojiError($event)"
                (formReady)="addChildForm('sms', $event)"
                [currentTab]="tab"
              ></coop-sms-form>
            </div>
            <div class="email" [hidden]="tab !== 'mail'" *ngIf="shared.mail">
              <coop-email-form
                [modes]="modes"
                [tab]="tab"
                [validationMessages]="validationMessages"
                [validateModes]="validateModes"
                [offerType]="'good-price'"
                [userType]="userType"
                [currentTab]="tab"
                (formReady)="addChildForm('mail', $event)"
              ></coop-email-form>
            </div>
            <div class="web" [hidden]="tab !== 'web'" *ngIf="shared.web">
              <coop-web-form
                [modes]="modes"
                [tab]="tab"
                [validationMessages]="validationMessages"
                [validateModes]="validateModes"
                [offerType]="'good-price'"
                (formReady)="addChildForm('web', $event)"
                [currentTab]="tab"
                [submitForm]="submitForm"
              ></coop-web-form>
            </div>
            <div
              class="screen"
              [hidden]="tab !== 'screen'"
              *ngIf="shared.screen"
            >
              <coop-screen-form
                [modes]="modes"
                [validationMessages]="validationMessages"
                [validateModes]="validateModes"
                [offerType]="'good-price'"
                (formReady)="addChildForm('screen', $event)"
                [currentTab]="tab"
                [submitForm]="submitForm"
              ></coop-screen-form>
            </div>
            <div class="dit" [hidden]="tab !== 'dit'" *ngIf="shared.dit">
              <coop-dit-form
                [modes]="modes"
                [validationMessages]="validationMessages"
                [validateModes]="validateModes"
                [offerType]="'good-price'"
                (formReady)="addChildForm('dit', $event)"
                [currentTab]="tab"
              ></coop-dit-form>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">

          <div
            class="row preview"
            *ngIf="
              tab === 'coop' ||
              tab === 'facebook' ||
              tab === 'screen'
            "
          >
            <button type="submit" (click)="openPreview()" class="login-btn btn-white-login button-wrapper "
                    *ngIf="tab==='coop'">
<!--              <i class="icon icon-mobile"></i>-->
              <p>Preview on COOP App</p>
            </button>
            <button type="submit" (click)="openPreview()" class="login-btn btn-white-login button-wrapper"
                    *ngIf="tab==='facebook'">
<!--              <i class="icon icon-facebook"></i>-->
              <p>Preview on Facebook</p>
            </button>

          </div>
          <div
            class="row preview"
            *ngIf="
              tab === 'dit'
            "
          >
            <button type="submit" (click)="openPreview()" class="login-btn btn-white-login button-wrapper">
<!--              <i class="icon icon-location"></i>-->
              <p>Preview</p>
            </button>
          </div>
          <div class="row">
            <button
              type="submit"
              (click)="onNextTab(getTabIndex(nextTab,'next'))"
              [class.btn-hide]="currentTabIndex + 1 >= nextTabArray.length"
              class="login-btn btn-black-login button-wrapper "
              id="next-tab"
            >
              Næste
            </button>
          </div>
          <div class="row form-submit-field">
            <input
              type="submit"
              value="Aktivér"
              *ngIf="nextTabArray[nextTabArray.length - 1] === tab"
              class="login-btn btn-black-login"
              (click)="save()"
            />
          </div>
          <div
            class="row"
            *ngIf="
              !editMode &&
              tab === 'mail' &&
              !templateMode &&
              validateModes.length > 1 &&
              emailFormComp?.mailList &&
              emailFormComp?.mailList?.length == 0 &&
              !emailFormComp?.mailSent
            "
          >
            <a
              (click)="deleteChannelOnCreate('mail')"
              class="login-btn btn-white-login "
            >Slet kanal</a
            >
          </div>
          <div class="row" *ngIf="templateMode && validateModes.length > 1">
            <a
              (click)="deleteChannelOnCreate(tab)"
              class="login-btn btn-white-login "
            >Slet kanal</a
            >
          </div>
          <div class="row" *ngIf="editMode && validateModes.length > 1">
            <a
              (click)="deleteChannel(tab)"
              class="login-btn btn-white-login "
            >Slet kanal</a
            >
          </div>
          <div class="row" *ngIf="editMode && validateModes.length === 1">
            <a (click)="deletePromo()" class="login-btn btn-white-login "
            >Slet</a
            >
          </div>
          <div class="row">
            <a
              (click)="saveTemplate(false)"
              class="login-btn btn-white-login"
              *ngIf="nextTabArray[nextTabArray.length - 1] === tab"
            >Gem som skabelon</a
            >
          </div>
          <div class="row">
            <a
              (click)="saveTemplate(true)"
              class="login-btn btn-white-login"
              *ngIf="
                nextTabArray[nextTabArray.length - 1] === tab &&
                ownTemplate &&
                templateMode
              "
            >Gem skabelon</a
            >
          </div>
          <div class="row" *ngIf="modes.templateMode && ownTemplate">
            <a (click)="deleteTemplate()" class="login-btn btn-white-login"
            >Slet skabelon</a
            >
          </div>
          <!--          <div class="row">-->
          <!--            <a (click)="back()" class="btn btn-border-red btn-delete"-->
          <!--              >Tilbage</a-->
          <!--            >-->
          <!--          </div>-->
        </div>
      </form>
      <ng-container *ngIf="showPreview">
        <coop-offer-preview
          [formData]="promoForm.value"
          [modes]="modes"
          [offerType]="offertype"
          [tab]="tab"
          [selectedPriceTag]="
            screenFormComp ? screenFormComp.selectedPriceTag : null
          "
          (showPreview)="closePreview($event)"
        ></coop-offer-preview>
      </ng-container>
    </div>
  </div>
</section>
<coop-mfl-popup
  *ngIf="showMFLPopup && !modes.editMode"
  (showPopup)="togglePopup($event)"
></coop-mfl-popup>
<coop-no-price-popup
  *ngIf="showPricePopup"
  (showPopup)="togglePricePopup($event)"
  (saveWithoutPrice)="saveWithoutPrice($event)"
></coop-no-price-popup>
