export interface SmsPromoData {
  title: string;
  price: any;
  offerPrice: any;
  priceDescription: string;
  description: string;
  publishdate: string;
  sms: string;
  startDate: string;
  endDate: string;
}

export interface SmsJatakData {
  title: string;
  price: any;
  description: string;
  start_date: string;
}

export interface SingleSms {
  id: string;
  created_time: any;
  message: string;
  send_time: any;
  sent: string;
  sms_sent_at: any;
  solution_id: string;
  user_id: string;
}

export interface SingleSmsList {
  ExpirationDate: any;
  PublishDate: any;
  clubId: string;
  created_time: any;
  id: string;
  message: string;
  send_time: any;
  sent: string;
  sms_send_at: any;
  user_id: string;
}

export interface SmsData {
  title: string;
  longDescription: string;
  inAppParameters: InAppParameters;
  publishDate: any;
  startDate: any;
  endDate: any;
  alcohol?:boolean
}

export interface InAppParameters {
  price?: any;
  priceDescription?: string;
  offerPrice?: any;
  uom : string
}
