import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  NotificationsService,
  TokenService,
} from 'app/core/services';
import { checkWhiteSpace } from 'app/core/validators';
import { ValidationMessage } from 'app/shared/interface';
import {
  Website_Validation_Messages,
  Website,
  WebsiteList,
  WebsiteService,
} from 'app/features/website';

@Component({
  selector: 'coop-website-create-edit',
  templateUrl: './website-create-edit.component.html',
  styleUrls: ['./website-create-edit.component.scss'],
})
export class WebsiteCreateEditComponent implements OnInit, OnDestroy {
  pageTitle: string = 'Opret ny Website';
  previousUrl: string;
  currentUrl: string;
  parentUrl: string;
  isProcessing: boolean = false;
  userType: string;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.jpg',
    thumbnail: '',
  };
  hasImage: boolean = false;
  editMode: boolean = false;
  websiteForm: FormGroup;
  website: WebsiteList;
  websiteFormValue: Website;
  formSaved: boolean = false;
  validationMessage: ValidationMessage = Website_Validation_Messages;

  private _subscriptionState: boolean = true;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _errorHandlerService: ErrorhandlerService,
    private _websiteService: WebsiteService
  ) {}

  ngOnInit() {
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
    document.body.classList.add('overlay');
    this._initComp();
    this.websiteForm = this._formBuilder.group({
      id: [],
      board: parseInt(this.userType, 10) === 2 ? 1 : 0,
      title: ['', [Validators.required, checkWhiteSpace]],
      description: ['', [Validators.required, checkWhiteSpace]],
      image: ['', Validators.required],
    });
    if (this.userType === '1') {
      // this.imageUrl = {
      //   largeImage: 'assets/images/camera@3x.png',
      //   thumbnail: 'assets/images/camera@3x.png',
      // };
      // this.hasImage = true;
      this.websiteForm.patchValue({
        title: 'Om butik',
      });
      this.websiteForm.controls['title'].disable();
    }
    if (this.userType === '2') {
      // this.imageUrl = {
      //   largeImage: 'assets/images/camera@3x.png',
      //   thumbnail: 'assets/images/camera@3x.png',
      // };
      // this.hasImage = true;
      this.websiteForm.patchValue({
        title: 'Om bestyrelse',
      });
    }
    if (this.website) {
      this.editMode = true;
      this.pageTitle = 'Rediger Website';
      this.websiteForm.patchValue(this.website);
      this.imageUrl = {
        largeImage: this.website.image,
        thumbnail: this.website.image,
      };
      this.hasImage = true;
    }
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  imageProcessing(e) {
    this.isProcessing = e;
  }

  getImagesUrl(images) {
    this.websiteForm.patchValue({
      image: images.largeImage,
    });
    this.imageUrl = {
      largeImage: images.largeImage,
      thumbnail: images.thumbnail,
    };
  }

  save() {
    if (this.websiteForm.status === 'INVALID') {
      this._handleError('');
      return;
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      this.websiteFormValue = this.websiteForm.getRawValue();
      if (this.editMode) {
        this.websiteFormValue.board = this.websiteForm.value.board;
      }
      this._websiteService
        .saveWebsite(this.websiteFormValue, this.editMode)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => this._success(),
          error: (err) => this._handleError(err),
        });
    }
  }

  back() {
    this._router.navigate([this.previousUrl]).then();
  }

  remove(id: string) {
    NotificationsService.confirm(`Er du sikker?`)
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((state) => {
          if (state) {
            return this._deleteWebsite(state, id);
          }
          return EMPTY;
        })
      )
      .subscribe({
        next: () => this._success(),
        error: (err) => this._handleError(err),
      });
  }

  private _initComp() {
    this.website = this._activatedRoute.snapshot.data['website'];
    this.currentUrl = this._router.url;
    this.previousUrl = this.parentUrl + '/campaign';
    this.userType = TokenService.getUserType();
  }

  private _success(): void {
    this.isProcessing = false;
    this.formSaved = true;
    if (!this.editMode) {
      NotificationsService.notify('Website er oprettet', 'success', 'top');
    } else {
      NotificationsService.notify('Med succes afsluttet', 'success', 'top');
    }
    this._router.navigate([this.previousUrl],{queryParams: {type: 'website'}}).then();
  }

  private _deleteWebsite(state: boolean, id: string) {
    if (state) {
      return this._websiteService.deleteWebsite(id);
    }
    return EMPTY;
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(error || { code: -400 }, 'website');
    this.isProcessing = false;
  }
}
