<link rel="stylesheet" href="jatak-form.component.scss">
<section
  class="app-page fbcomment-create-edit page-with-fixed-header-and-tab"
  [@parentAnimate]="showParent"
>
  <div class="fixed-header-bug-fix" id="fixed">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isChild]="true"
      [isBackGroundWhite]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">

        <div class="flex-display tab-container-wrap">
          <ul class="tab-list">
            <ng-container *ngIf="!showChannelSelection">
              <li (click)="toggleChannelSelection()" class="tab-option active">{{ tabName }}</li>
            </ng-container>
            <ng-container *ngIf="showChannelSelection">
              <!-- psuedo list -->
              <li (click)="toggleChannelSelection()" class="tab-option active">{{ tempTabName }}</li>
            </ng-container>
          </ul>
          <div class="clearfix"></div>
          <div
            class="channel-selection-btn"
            (click)="toggleChannelSelection()"
            *ngIf="!editMode"
          >
            <span class="channel-margin-5">Kanal</span>
            <ion-icon name="chevron-down-outline" class="font-18"
                      [class.show-channel-selection]="showChannelSelection"></ion-icon>
            <!--            <i-->
            <!--              class="icon icon-arrow-down"-->
            <!--              [class.show-channel-selection]="showChannelSelection"-->
            <!--            ></i>-->
          </div>
        </div>

        <coop-channel-select
          *ngIf="showChannelSelection"
          class="channel-selection-box"
          [selectedChannel]="shared"
          [mode]="channelSelectionModes"
          [allowedChannels]="allowedChannels"
          [requiredChannel]="'none'"
          [offerType]="'jatak'"
          (channelConfirmed)="confirmedShareOptions($event)"
          (channelState)="updateChannelState($event)"
        >
        </coop-channel-select>

      </div>


    </div>
  </div>
  <div class="page-container">
    <form [formGroup]="jatakForm">
      <div class="fb">

        <div class="upload-options"
             [ngClass]="{ disabled: (formData && formData.ended || formData && formData.started) && this.editMode }">
          <div
            (click)="uploadTypeSelect(false)"
            [class.active]="!isVideo" class="options-list">
            Billeder
          </div>
          <div
            (click)="uploadTypeSelect(true)"
            [class.active]="isVideo" class="options-list">
            Video
          </div>
        </div>
        <div
          class="form-group"
          formArrayName="images"
          style="margin-bottom: 0"
        >
          <coop-image-handler
            [imageArray]="imageUrl"
            [hasImages]="hasImage"
            [singleCropper]="true"
            [disableUpload]="hasBeenPosted"
            [isRectangle]="false"
            [multipleImage]="true"
            [videoUrl]="videoUrl"
            [isPostedToFb]="isPostedToFb"
            [editMode]="editMode || templateMode"
            [isVideo]="isVideo"
            [hasVideo]="hasVideo"
            [isGif]="isGif"
            [offerType]="'jatak'"
            (panelType)="changeUploadType($event)"
            (video)="getVideoUrl($event)"
            (imageArr)="getImagesUrl($event)"
            (processing)="imageProcessing($event)"
            *ngIf="!destroyImageHandler"
          ></coop-image-handler>
          <span class="error-message" *ngIf="imageError">Upload venligst billede</span>
        </div>
        <div class="form-group" formGroupName="video" controlErrorContainer>
          <input
            formControlName="url"
            [customErrors]="validationMessage['url']"
            hidden
          />
        </div>

        <div class="section-wrapper">
          <div class="title">
            Varianter
          </div>

          <div
            class="form-group varient-toggle "
            [class.default-disabled]="disabledDefaultText">
            <div class="text">
              <p>Flere varianter af produktet</p>
            </div>
            <div class="height-27" [ngClass]="disabled || disabledToggle ? 'toggle-btn disabled' : 'toggle-btn' ">
              <label class="switch">
                <input (click)="toggleVarient($event)" type="checkbox" [checked]="showVarient">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>


        <div *ngIf="showVarient" formArrayName="variants">
          <div *ngIf="jatakForm.controls['isVariant'].value">
            <div class="varient-list" *ngFor="let email of jatakForm.get('variants')['controls']; let i = index"
                 [formGroupName]="i">
              <div class="title-wrapper">
                <div class="title"><p>Variant {{ i + 1 }}</p></div>
                <div [ngClass]="disabledToggle || disabled ? 'toggle-btn disabled': ''"
                     *ngIf="varientArray.length  >1 && i>0 "
                     (click)="removeVarient(i)" class="delete">
                  <ion-icon name="close-outline" class="font-13 close-square"></ion-icon>
                </div>
              </div>
              <hr />
              <div class="form-group" controlErrorContainer>
                <label>Variant navn</label>
                <input
                  type="text"
                  formControlName="title"
                  [ngClass]="disabledToggle || disabled ? 'toggle-btn disabled': ''"
                  (focusin)="onTitleSelect(i)"
                  placeholder="Fx. str. 37, farve rød mm."
                  [customErrors]="validationMessage['varaintTitle']"
                />
                <div *ngIf="showVaraintTitle == i" class="remaining"> Brugte
                  tegn: {{ jatakForm.controls['variants']['controls'][i].controls['title'].value.length }}
                  / 47
                </div>
              </div>
              <div class="form-group" controlErrorContainer>
                <label>Unik variant nøgleord</label>
                <div class="row">
                  <input
                    type="text"
                    formControlName="keyword"
                    [customErrors]="validationMessage['keyword']"
                    style="padding-left: 75px"
                    [ngClass]="disabledToggle || disabled ? 'toggle-btn disabled': ''"
                    (focusin)="onKeywordSelect(i)"
                    #keywordBox
                    (input)="onInputChange($event, i)"
                    placeholder="Dit unikke søgeord"
                  />
                  <span class="jatak-prefix">JA TAK</span>
                </div>
                <div
                  *ngIf="showKeywordWarning == i || jatakForm.controls['variants']['controls'][i].controls['keyword'].value.length "
                  class="warning">
                  <p>Dine kunder skal bruge denne nøjagtige sætning for at bestille denne JA TAK variant.</p>
                </div>
                <div *ngIf="jatakForm.controls['variants']['controls'][i].controls['keyword'].invalid &&
                 (jatakForm.controls['variants']['controls'][i].controls['keyword'].dirty || jatakForm.controls['variants']['controls'][i].controls['keyword'].touched ) "></div>

                <div class="errors"
                     *ngIf="jatakForm.controls['variants']['controls'][i].controls['keyword'].errors?.['whitespace']">
                  <p>Det unikke nøgleord skal være et enkelt ord. Mellemrum er ikke tilladt.</p>
                </div>

                <div class="errors"
                     *ngIf="jatakForm.controls['variants']['controls'][i].controls['keyword'].errors?.['duplicate']">
                  <p>Dette nøgleord er allerede brugt i denne JA TAK.</p>
                </div>

                <div class="errors"
                     *ngIf="jatakForm.controls['variants']['controls'][i].controls['keyword'].errors?.['containsAlphabet']">
                  <p>Nøgleordet skal have mindst ét bogstav fra A til Z.</p>
                </div>

              </div>

              <div class="form-group" controlErrorContainer>
                <label>Antal varer til salg</label>
                <input
                  type="number"
                  formControlName="quantity"
                  [ngClass]="disabledPriceandQuantity ? 'toggle-btn disabled': ''"
                  (keydown)="validateNumber($event)"
                  [customErrors]="validationMessage['quantity']"
                  placeholder="Hvor mange varer på denne variant sælger du?"
                />
              </div>
              <div class="form-group" controlErrorContainer>

                <div class="label-wrapper">
                  <div>
                    <label for="fb-replyTextEnd">
                      Prisoplysninger:
                    </label>
                  </div>
                  <div class="tooltip-icon">
                    <span
                      tooltip="På Facebook vil prisen og mængden blive indsat sidst i opslaget."
                      placement="top"
                      delay="500"><ion-icon name="information-circle" style="color:#C5C5C7 ;"
                                            class="font-18"></ion-icon></span>
                  </div>
                </div>
                <div class="price-description-wrapper">
                  <div class="price-container">
                    <div>
                      <input

                        type="text"
                        formControlName="price"
                        min="0"
                        placeholder="Pris"
                        (keydown)="validateNumber($event)"
                        [customErrors]="validationMessage['price']"
                      />
                    </div>
                    <div style="margin:10px;min-width: fit-content">
                      kr. pr.
                    </div>
                  </div>

                  <div class="price-description-container">
                    <div style="margin-right: 10px;flex-basis: 65%">
                      <input
                        type="text"
                        formControlName="priceDescription"
                        [customErrors]="validationMessage['priceDescription']"
                        min="0"
                        placeholder="vægt/mængde"
                        (keydown)="validateNumber($event)"
                      />
                    </div>

                    <div class="price-limit">
                      <select
                        formControlName="uom"
                        mbsc-select
                        [mbscOptions]="priceDescSelectSettings"
                        class="form-select">
                      </select>
                      <div class="dropdown-icon">
                        <ion-icon name="chevron-down" class="font-13"></ion-icon>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showVarient" class="row addvarient">
          <a [ngClass]="disabledToggle || disabled ? 'toggle-btn disabled': ''" (click)="addVarientFormGroup()"
             class="login-btn btn-white-login"
             style="display: flex;justify-content: center;align-items: center"
          >
            <div style="margin-right: 10px">
              <ion-icon name="add" class="font-18"></ion-icon>
            </div>
            <div>Tilføj variant</div>
          </a
          >
        </div>

        <div class="hr">

        </div>

        <div class="section-wrapper">
          <div class="title">
            Post tekst
          </div>

          <div class=" form-group" controlErrorContainer>
            <label style="cursor: pointer" for="fb-title" class="margin-bottom-8">Overskrift</label>
            <input
              id="fb-title"
              type="text"
              formControlName="title"
              (focusin)="onSelect('title')"
              placeholder="Indsæt JA TAK overskrift"
              [customErrors]="validationMessage['']"
            />
            <div *ngIf="this.visible['title']" class="remaining"> Brugte
              tegn: {{ jatakForm.get('title').value.length }} / 99
            </div>
            <span *ngIf="titleValidation" class="error-message">
                     Overskrift må højst være 99 tegn
                      </span>
          </div>

          <div class="form-group flex-column tekst" controlErrorContainer>
            <label for="fb-desc" class="margin-bottom-8">JA TAK tekst*</label>
            <!--            <coop-mfl-text [offerType]="'jatak'"></coop-mfl-text>-->
            <div
              class="description-wrapper"
              [class.default-disabled]="disabledDefaultText">
                                <textarea
                                  name=""
                                  id="fb-desc"
                                  (focusin)="onSelect('description')"
                                  formControlName="description"
                                  placeholder="Indsæt JA TAK tilbudstekst"
                                  [customErrors]="validationMessage['']"
                                ></textarea>
              <div
                class="default-text"
                [class.default-disabled]="disabledDefaultText">
                <p> {{ infoText }} </p>
                <p>🤝 BESTILLINGER ER BINDENDE. 🤝</p>
                <p style="margin-bottom: 0">❗👇🏻🛒 BEMÆRK 🛒👇🏻❗ </p>
                <p *ngIf="!showVarient"> {{ defaultText }} </p>
                <p *ngIf="showVarient"> {{ defaultTextVariant }} </p>
                <p *ngIf="varaintInfo" [innerHTML]="varaintInfo"></p>
                <p> {{ defaultMessage }} </p>
              </div>
            </div>
            <span *ngIf="descriptionValidation" class="error-message">
                       Tekst må højst være 2500 tegn
                      </span>
            <div *ngIf="this.visible['description']" class="remaining"> Brugte
              tegn: {{ jatakForm.get('description').value.length }} / 2500
            </div>
          </div>
        </div>
        <div class="hr"></div>

        <div *ngIf=" !showVarient " class="section-wrapper">

          <div class="title">
            Lageroplysninger
          </div>

          <div
            class="row form-group tooltip" controlErrorContainer>
            <div class="label-wrapper">
              <div>
                <label for="fb-offerno" class="margin-bottom-8"
                >Antal varer*
                </label>
              </div>
              <div class="tooltip-icon">
                            <span
                              tooltip="Opdelt til ordrer, der kommer fra alle brugte kanaler"
                              placement="top"
                              delay="500">
                              <ion-icon name="information-circle" class="tooltip-info-icon"></ion-icon>
                            </span>
              </div>
            </div>

            <input
              id="fb-offerno"
              type="number"
              formControlName="quantity"
              min="0"
              placeholder="Indsæt hvor mange JA TAK varer der er til salg"
              (keydown)="validateNumber($event)"
              [customErrors]="validationMessage['offers']"
            />
          </div>

          <div [class.disabled]="disabled" class="form-group" formArrayName="prices">
            <div class="label-wrapper">
              <label for="fb-replyTextEnd" class="margin-bottom-8">Prisoplysninger*</label>
              <div class="tooltip-icon">
                          <span tooltip="På Facebook vil prisen og mængden blive indsat sidst i opslaget."
                                placement="top"
                                delay="500"><ion-icon name="information-circle"
                                                      class="tooltip-info-icon"></ion-icon></span>
              </div>
            </div>
            <div *ngFor="let priceInfo of priceInfos.controls; let i=index"
                 [formGroupName]="i">
              <div class="form-group" controlErrorContainer>
                <div class="price-description-wrapper">
                  <div class="price-container">
                    <div class="form-group" controlErrorContainer>
                      <input id="fb-price" type="text" formControlName="price" min="0" placeholder="Pris"
                             (keydown)="validateNumber($event)" [customErrors]="validationMessage['price']" />
                    </div>
                    <div style="margin:10px;min-width: fit-content">kr. pr.</div>
                  </div>
                  <div class="price-description-container">
                    <div style="margin-right: 10px;flex-basis: 55%" class="form-group price-description"
                         controlErrorContainer>
                      <input
                        id="fb-priceDescription"
                        type="text"
                        formControlName="quantity"
                        min="0"
                        placeholder="vægt/mængde"
                        (keydown)="validateNumber($event)"
                        [customErrors]="validationMessage['quantity']" />
                    </div>
                    <div #input class="price-limit">
                      <select formControlName="uom"
                              mbsc-select [mbscOptions]="priceDescSelectSettings"
                              class="form-select">

                      </select>
                      <div class="dropdown-icon">
                        <ion-icon name="chevron-down-outline" class="font-13"></ion-icon>
                      </div>
                    </div>
                    <div *ngIf="priceInfos.length  >1" style="cursor: pointer;margin-top: 8px"
                         (click)="removePrices(i)">
                      <ion-icon name="trash-outline" class="delete-price-icon"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row addvarient" *ngIf="canAddMorePriceInfos">
              <a
                class="login-btn btn-white-login"
                style="display: flex;justify-content: center;align-items: center"
                (click)="addPriceInfo()">
                <div style="margin-right: 10px">
                  <ion-icon name="add" class="font-18"></ion-icon>
                </div>
                <div>Tilføj en prislinje mere</div>
              </a>
            </div>
          </div>

        </div>
        <div class="divider"></div>
        <div class="section-wrapper">
          <div class="label-wrapper" style="margin-bottom: 10px">
            <div>
              <label class="title">Alkohol</label>
            </div>
            <div class="tooltip-icon">
                    <span
                      tooltip='Hvis du promoverer alkohol, vil en lovpligtig tekst automatisk blive tilføjet under titlen på dit indlæg:
"Sælges kun til personer over 18 år. Du reserverer varen ved at følge JA TAK anvisningerne i opslaget. Bemærk at du skal opfylde alders- og ID-kravene i butikken.”'
                      placement="top"
                      delay="500">  <ion-icon name="information-circle"
                                              class="tooltip-info-icon"></ion-icon></span>
            </div>

          </div>
          <div class="alkohol-bg flex-display  restrictions ">
            <p class="toggle-label title">Promoverer du alkohol?</p>

            <label class="switch">
              <input type="checkbox" formControlName="alcohol">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="hr"></div>

        <div class="section-wrapper">
          <div class="title">
            Robot svar
          </div>

          <div class="form-group" controlErrorContainer>
            <label for="fb-comment"
            >Robot svaret slutter med
            </label>

            <div class="robot-sub-description">
              Skriv kun den afsluttende linje her. Robotten tilføjer resten.Tjek Preview-sektionen nedenfor for at se
              det endelige svar.
            </div>

            <div class="replyinfowrapper">
              <div (click)="onToggleReplyInfo()" class="replyinfosection">
                <div class="replyinfotitle">
                  <ion-icon name="warning" style="font-size: 24px; color:#1C84EE"></ion-icon>
                  <div>Facebook spam forebyggelse</div>
                </div>
                <div>
                  <ion-icon name="chevron-up" *ngIf="showReplyInfo" style="color:#1C84EE" class="font-20"></ion-icon>
                  <ion-icon name="chevron-down" *ngIf="!showReplyInfo" style="color:#1C84EE" class="font-20"></ion-icon>
                </div>
              </div>
              <div [ngClass]="!showReplyInfo ? 'hide' : 'show'" style="margin-top: 10px;">
                <ul style="list-style: disc; margin: 0 0 20px 25px">
                  <li>
                    Det er vigtigt, at denne tekst er forskellig på hver JA TAK for at forhindre Facebook i at blokere
                    robotten på grund af spam-adfærd.
                  </li>
                  <br />
                  <li>
                    For eksempel kan du her skrive specifik information om denne JA TAK, som afhentningsstedet eller
                    afhentningstider.
                  </li>
                </ul>
              </div>
            </div>

            <textarea
              id="fb-comment"
              (focusin)="onSelect('robotReply')"
              placeholder="Skriv en unik afsluttende linje eller signatur for dette JA TAK-robotsvar."
              formControlName="replyText"
              class="robotreply"
              [customErrors]="validationMessage['replyText']"
            ></textarea>
            <div style="margin-bottom: 25px ; margin-top: -5px; position: relative " class="response-wrapper">
              <div (click)="toggleResponse()" class="title-wrapper">
                <div>
                  <p>Preview Facebook svarformatet</p>
                </div>
                <div>
                  <ion-icon *ngIf="showResponse" name="chevron-up" class="font-24"></ion-icon>
                  <ion-icon *ngIf="!showResponse" name="chevron-down" class="font-24"></ion-icon>
                </div>
              </div>
              <div [ngClass]="showResponse ? 'show' : 'hide'">
                <!--                <hr class="seperator"/>-->
                <div class="divider"></div>
                <div *ngIf="jatakDefault.doPrivateReply">
                  <div class="public">
                    <div class="title">
                      <p>Offentlig</p>
                    </div>
                    <div class="message">
                      <p>{{ greeting }}</p><br />
                      <p> {{ showVarient ? finaltextVaraint : finaltext }} </p><br />
                      <p>{{ textAdded }}</p><br />
                      <p>{{ replyTextMessage }}
                    </div>
                  </div>
                  <div class="public">
                    <div class="title">
                      <p>Privat</p>
                    </div>
                    <div class="message">
                      <p>{{ greeting }}</p><br />
                      <p> {{ showVarient ? finaltextVaraint : finaltext }} </p><br />
                      <p>{{ textAddedPrivate }}</p><br />
                      <p>{{ replyTextMessage }}</p><br />
                      <p>Hvis du vil bestille flere produkter, bedes du indsende en ny kommentar.</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="!jatakDefault.doPrivateReply">
                  <div class="public">
                    <div class="title">
                      <p>Offentlig</p>
                    </div>
                    <div class="message">
                      <p>{{ greeting }}</p><br />
                      <p> {{ showVarient ? finaltextVaraint : finaltext }} </p><br />
                      <p>{{ textAdded }}</p><br />
                      <p>{{ replyTextMessage }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="this.visible['robotReply'] " class="replytextremaining"> Brugte
                tegn: {{ jatakForm.get('replyText').value.length }} / 100
              </div>
            </div>
            <span *ngIf="robotReplyValidation" class="error-message">
                         Tekst må højst være 100 tegn
                        </span>
            <div class="response-wrapper">
              <div (click)="toggleReply()" class="title-wrapper">
                <div>
                  <p>Preview besvar tekst for sidste ordre </p>
                </div>
                <div>
                  <ion-icon *ngIf="showReply" name="chevron-up" class="font-24"></ion-icon>
                  <ion-icon *ngIf="!showReply" name="chevron-down" class="font-24"></ion-icon>
                </div>
              </div>
              <div [ngClass]="showReply ? 'show' : 'hide'">
                <div class="divider"></div>
                <div *ngIf="jatakDefault.doPrivateReply">
                  <div class="public">
                    <div class="title">
                      <p>Offentlig</p>
                    </div>
                    <div class="message">
                      <p>{{ greeting }}</p><br />
                      <p> {{ showVarient ? finaltextPreviewVaraint : finaltextPreview }} </p><br />
                      <p>{{ textAdded }}</p><br />
                      <p>{{ replyTextMessage }}</p>
                    </div>
                  </div>
                  <div class="public">
                    <div class="title">
                      <p>Privat</p>
                    </div>
                    <div class="message">
                      <p>{{ greeting }}</p><br />
                      <p>{{ showVarient ? finaltextPreviewVaraint : finaltextPreview }} </p><br />
                      <p>{{ textAddedPrivate }}</p><br />
                      <p>{{ replyTextMessage }}</p><br />
                      <p>Hvis du vil bestille flere produkter, bedes du indsende en ny kommentar.</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="!jatakDefault.doPrivateReply">
                  <div class="public">
                    <div class="title">
                      <p>Offentlig</p>
                    </div>
                    <div class="message">
                      <p>{{ greeting }}</p><br />
                      <p> {{ showVarient ? finaltextPreviewVaraint : finaltextPreview }}</p><br />
                      <p>{{ textAdded }}</p><br />
                      <p>{{ replyTextMessage }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hr"></div>


        <div class="section-wrapper ">
          <div class="flex-display">
            <div class="title">
              “Udsolgt” tekster
            </div>
            <div class="jatak-setting">
              Rediger standardindstillinger
            </div>
          </div>

          <div class=" form-group" controlErrorContainer>
            <div class="label-wrapper">
              <div>
                <label for="fb-alttitle" class="margin-bottom-8"
                >Overskrift udsolgt

                </label>
              </div>
              <div class="tooltip-icon">
                             <span
                               tooltip="Bliver tilføjet i starten af indlægget  Du kan ændre standardteksten (forudfyldt) i Quick Coop-indstillinger -> JA TAK indstillinger"
                               placement="top"
                               delay="500"><ion-icon name="information-circle"
                                                     class="tooltip-info-icon"></ion-icon></span>
              </div>
            </div>

            <input
              id="fb-alttitle"
              type="text"
              (focusin)="onSelect('soldOutTitle')"
              formControlName="soldOutTitle"
              [customErrors]="validationMessage['']"
            />
            <span *ngIf="soldOutTitleValidation" class="error-message">
                       Tekst må højst være 60 tegn
                      </span>
            <div *ngIf="this.visible['soldOutTitle']  " class="remaining"> Brugte
              tegn: {{ jatakForm.get('soldOutTitle').value.length }} / 60
            </div>
          </div>

          <div
            class="form-group"
            controlErrorContainer
            *ngIf="shared.facebook"
          >
            <div class="label-wrapper">
              <div>
                <label for="fb-replyTextEnd" class="margin-bottom-8">
                  Besvar tekst for udsolgt Ja Tak
                </label>
              </div>
              <div class="tooltip-icon">
                            <span
                              tooltip="Du kan ændre standardteksten (forudfyldt) i Quick Coop-indstillinger -> JA TAK indstillinger"
                              placement="top"
                              delay="500"><ion-icon name="information-circle"
                                                    class="tooltip-info-icon"></ion-icon></span>
              </div>
            </div>

            <input
              id="fb-replyTextEnd"
              type="text"
              (focusin)="onSelect('soldOutReply')"
              formControlName="soldOutReply"
              [customErrors]="validationMessage['']"
            />
            <span *ngIf="soldOutReplyValidation" class="error-message">
                       Tekst må højst være 100 tegn
                      </span>
            <div *ngIf="this.visible['soldOutReply']" class="remaining"> Brugte
              tegn: {{ jatakForm.get('soldOutReply').value.length }} / 100
            </div>
          </div>
        </div>

        <div class="hr"></div>

        <div *ngIf="shared.facebook" class="section-wrapper">
          <div class="flex-display">
            <div class="title">
              Restriktioner
            </div>
            <div class="jatak-setting">
              Rediger standardindstillinger
            </div>
          </div>

          <div class="restrictions ">
            <div class="flex-display">
              <div class="title">
                Indstil max. antal styk pr. kunde
              </div>
              <div>
                <label class="switch">
                  <input formControlName="hasCustomerLimit" (click)="setMaxOrder($event)" type="checkbox">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div *ngIf="showMaxOrder" class="hr"></div>
            <div *ngIf="showMaxOrder" class=" flex-display">
              <div class="quantity-text">
                Hver kunde kan bestille max.
              </div>
              <div
                class="limit"
                [class.disabled]="disabledDefaultText"
              >
                <select
                  mbsc-select
                  [mbscOptions]="alertQuantitySelectSettings"
                  formControlName="customerLimit"
                  class="form-select"
                >
                </select>
                <div class="dropdown-icon">
                  <ion-icon name="chevron-down" class="font-13"></ion-icon>
                </div>
              </div>
            </div>
          </div>


          <div style="margin-top: 15px" class="restrictions ">
            <div class="flex-display">
              <div class="title">
                Godkend ordrer manuelt
              </div>
              <div>
                <label class="switch">
                  <input
                    (click)="onManualApproval($event)"
                    formControlName="hasAlertQuantity"
                    type="checkbox">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div *ngIf="showMaualApproval" class="hr"></div>
            <div *ngIf="showMaualApproval" class="flex-display">
              <div class="quantity-text">
                Når en ordre overstiger (stk.)
              </div>
              <div
                class="limit"
                [class.disabled]="disabledDefaultText"
              >
                <select
                  mbsc-select
                  [mbscOptions]="alertQuantitySelectSettings"
                  formControlName="alertQuantity"
                  class="form-select"
                >
                </select>
                <div class="dropdown-icon">
                  <ion-icon name="chevron-down" class="font-13"></ion-icon>
                </div>
              </div>
            </div>

            <div *ngIf="showMaualApproval" class="margin-top-20 flex-display">
              <div class="quantity-text">
                Dette nr. modtaget underretning.
              </div>

              <div class="phone-field">
                <div>
                  +45
                </div>
                <div class="phone">
                  <input
                    id="phone"
                    type="text"
                    formControlName="phone"
                    placeholder="Indsæt mobilnummer"
                    (keydown)="validateNumber($event)"
                    (keyup)="formatPhoneNumber($event)"
                    [customErrors]="validationMessage['phone']"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="shared.sms" class="hr"></div>
        <div *ngIf="shared.sms" class="section-wrapper">

          <div class="sms" *ngIf="shared.sms">
            <div
              class="form-group"
              formGroupName="smsText"
              controlErrorContainer
            >
              <div>
                <coop-sendsms
                  [ngClass]="{ disabled: formData && (formData.ended || formData.smsSent) && editMode}"
                  *ngIf="clients && shared.sms"
                  [clients]="clients"
                  [jatak]="jatakForm.value"
                  [smsMsg]="smsText"
                  [editMode]="editMode"
                  [templateMode]="templateMode"
                  [jatakMode]="jatakMode"
                  [tempSms]="tempSms"
                  [showErrorInput]="showEmojiError"
                  (patchEvent)="smsInit($event)"
                  (valid)="checkSmsValidity($event)"
                ></coop-sendsms>
                <span class="error-message" *ngIf="showEmojiError">
                              Feltet kan ikke indeholde emoji-tegn
                            </span>
              </div>
            </div>
          </div>

        </div>

        <div class="hr"></div>

        <div class="section-wrapper">

          <div class="title">
            Hvornår skal beskeden vises
          </div>

          <div>
            <div class="date-time-text">
              Hvis der bliver udsolgt før afslutningstidspunktet vil robotten
              nævne det i svarene og stoppe med at tage imod ordre.
              <br />
              Efter afslutningstidspunktet er JaTak lukket og robotten vil nævne
              det i svarene.
            </div>

            <div class="col-two form-group" controlErrorContainer>
              <coop-date-time-range-picker
                [datePickerData]="datePickerData"
                (onDateTimeChange)="getStartEndTime($event)"
                [editMode]="editMode"
                [templateMode]="templateMode"
              ></coop-date-time-range-picker>
            </div>

          </div>

        </div>


        <div class="btn-wrapper">
          <div class="row" *ngIf="editMode">
            <a class="login-btn btn-white-login"
               (click)="goToOrderList(jatakForm.value.id)"
            >Ordreliste</a>
          </div>

          <!--          duplicate jatak -->
          <div class="row" *ngIf="editMode">
            <a class="login-btn btn-white-login"
               (click)="duplicate(jatakForm.value.id)"
            >Duplikere</a>
          </div>
          <!-- save as a template disabled for now -->

          <div class="row" *ngIf=" !editMode || (jatak && jatak.canSaveAsTemplate)">
            <input type="submit" value="Gem som skabelon" (click)="saveJatakTemplate(false)"
                   class="login-btn btn-white-login"
            />
          </div>

          <div class="row" *ngIf="templateMode && jatak.own ">
            <input type="submit" value="Gem skabelon" (click)="saveJatakTemplate(true)"
                   class="login-btn btn-black-login"
            />
          </div>
          <div class="row" *ngIf="templateMode && jatak.own">
            <a (click)="remove('template')"
               class="login-btn btn-white-login"
            >Slet skabelon</a
            >
          </div>
          <div class="row form-submit-field">
            <input
              type="submit"
              *ngIf="!(formData && formData['ended']) &&  editMode  "
              value="Gem"
              (click)="save()"
              class="login-btn btn-black-login"
            />
            <input
              type="submit"
              *ngIf=" !editMode"
              value="Aktiver"
              (click)="save()"
              class="login-btn btn-black-login"
            />
          </div>

          <div class="row" *ngIf="editMode">
            <a
              (click)="remove('post', jatakForm.value.id)"
              class="login-btn btn-white-login"
            >Slet</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
<coop-mfl-popup
  *ngIf="showMFLPopup && !editMode"
  (showPopup)="togglePopup($event)"
></coop-mfl-popup>
<router-outlet></router-outlet>
