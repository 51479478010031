import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { COMMON_SELECT_SETTINGS } from '../../data';
import * as moment from 'moment';
import { QuicktogoService } from 'app/features/quick-to-go/services';
import { ErrorhandlerService } from 'app/core/services';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MbscSelectOptions } from '@mobiscroll/angular';

@Component({
  selector: 'app-quicktogo-calendar',
  templateUrl: './quicktogo-calendar.component.html',
  styleUrls: ['./quicktogo-calendar.component.scss']
})
export class QuicktogoCalendarComponent implements OnInit, OnDestroy {
  activeTab = 'active';
  limit: number = 1;
  today: string = moment().format('DD.MM.YYYY HH:mm');
  alertQuantitySelectSettings: MbscSelectOptions = {
    ...COMMON_SELECT_SETTINGS, ...{
      data: []
    }
  };

  currentDate: moment.Moment;
  weekDays: moment.Moment[];
  mobileDays: moment.Moment[];
  start_date: string = '';
  end_date: string = '';
  offerData: any;
  organizedData: any[] = [];
  debounceFlag: boolean = false;
  page = 1;
  last_page: number = 1;

  constructor(private _router: Router,
              private _errorHandler: ErrorhandlerService,
              private _service: QuicktogoService) {
  }

  @HostListener('document:scroll', ['$event'])
  onScroll() {
    const windowHeight = window.innerHeight;
    const totalHeight = document.documentElement.scrollHeight;
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (currentScrollPosition + windowHeight >= totalHeight) {
      this.loadMoreBags();
    }
  }


  loadMoreBags() {
    if (this.page === this.last_page) {
      return;
    }
    this.page++;
    this.loadCalendarData();

  }

  ngOnInit() {
    this.currentDate = moment();
    if (window.innerWidth <= 600) {
      this.generateMobileDays();
    } else {
      this.generateWeek();
    }
    while (this.limit <= 99) {
      this.alertQuantitySelectSettings.data.push(this.limit);
      this.limit++;
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }

  getCalendarData() {
    this.offerData = [];
    this.organizedData = [];
    this.loadCalendarData();
  }

  private loadCalendarData() {
    this._service.getOfferList(this.start_date, this.end_date, this.activeTab, this.page).subscribe({
      next: (res) => {
        this.offerData = res['data']
        this.last_page = res['meta']['last_page']

        if (!this.isMobileView()) {
          this.organizeDataByWeek();
        } else {
          this.organizeDataByDays();
        }
      },
      error: (err) => {
        this.handleError(err);
      }
    });
  }

  organizeDataByWeek() {
    // Clear previously organized data
    this.organizedData = [];

    this.offerData.forEach(item => {
      const bagsByWeek: any[] = [];

      // Loop through each day of the generated week
      this.weekDays.forEach(day => {
        const dateString = day.format('YYYY-MM-DD');

        // Check if the bags data contains information for the current date
        if (item.bags[dateString]) {
          bagsByWeek.push(item.bags[dateString]); // Push bag data if available
        } else {
          bagsByWeek.push(null); // Push null for missing data
        }
      });

      // Push the item with organized bag data into organizedData
      this.organizedData.push({ ...item, bagsByWeek });
    });
  }

  organizeDataByDays() {
    this.offerData.forEach(item => {
      const bagsByWeek: any[] = [];

      // Loop through each day in mobileDays
      this.mobileDays.forEach(day => {
        const dateString = day.format('YYYY-MM-DD');

        // Check if the bags data contains information for the current date
        if (item.bags[dateString]) {
          bagsByWeek.push(item.bags[dateString]); // Push bag data if available
        } else {
          bagsByWeek.push(null); // Push null for missing data
        }
      });

      this.organizedData.push({ ...item, bagsByWeek });
    });
  }

  isMobileView() {
    return window.innerWidth <= 600;
  }

  generateWeek() {
    this.weekDays = [];

    // Calculate start of the week by subtracting 3 days from the current day
    const startOfWeek = this.currentDate.clone().subtract(3, 'days');

    // Calculate days from startOfWeek to 6 days after
    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, 'days');
      this.weekDays.push(day);
    }

    // Find the index of today in the array
    const todayIndex = this.weekDays.findIndex(day => day.isSame(this.currentDate, 'day'));

    // Reorder the array so that today's date is in the middle
    const middleIndex = Math.floor(this.weekDays.length / 2);
    const temp = this.weekDays.splice(todayIndex, 1)[0];
    this.weekDays.splice(middleIndex, 0, temp);
    this.start_date = this.weekDays[0].format('YYYY-MM-DD');
    this.end_date = this.weekDays[6].format('YYYY-MM-DD');
    this.getCalendarData();
  }


  isToday(day: moment.Moment): boolean {
    return day.isSame(moment(), 'day');
  }


  generateMobileDays() {
    this.mobileDays = [
      this.currentDate.clone().subtract(1, 'day'), // Yesterday
      this.currentDate.clone(),                    // Today
      this.currentDate.clone().add(1, 'day')       // Tomorrow
    ];
    this.start_date = this.mobileDays[0].format('YYYY-MM-DD');
    this.end_date = this.mobileDays[2].format('YYYY-MM-DD');
    this.getCalendarData();
  }

  onTabSelect(tab: string) {
    this.activeTab = tab;
    this.page = 1;
    this.getCalendarData();
  }


  previousWeek() {
    this.offerData = [];
    this.organizedData = [];
    if (!this.isMobileView()) {
      this.currentDate.subtract(1, 'week');
      this.generateWeek();
    } else {
      this.currentDate.subtract(1, 'days');
      this.generateMobileDays();
    }
  }

  nextWeek() {
    this.offerData = [];
    this.organizedData = [];
    if (!this.isMobileView()) {
      this.currentDate.add(1, 'week');
      this.generateWeek();
    } else {
      this.currentDate.add(1, 'days');
      this.generateMobileDays();
    }


  }

  onEdit(id) {
    this._router.navigate(['butikker/redmad/edit/', id], { queryParams: { origin: 'calendar' } }).then();
  }

  onNavigatetoOrderList(id) {
    this._router.navigate(['butikker/redmad/orderlist/', id]).then();
  }

  onChange(event, item, id) {
    let data = {
      'id': item.id,
      'publish': event.target.checked,
      'date': item.date
    };
    this._service.updateBag(id, data).subscribe({
      next: () => {
      },
      error: (err) => {
        this.handleError(err);
      }
    });
  }

  onStockChange(event, item, id) {
    if (!this.debounceFlag) {
      this.debounceFlag = true;
      let data = {
        'id': item.id,
        'stocks': event,
        'date': item.date
      };
      this._service.updateBag(id, data).subscribe({
        next: () => this.debounceFlag = false,// Reset the flag after successful execution,
        error: (err) => {
          this.handleError(err);
          this.debounceFlag = false; // Reset the flag after error
        }
      });
    }
  }

  handleError(err) {
    this._errorHandler.handleError(err, '');
  }


}
