<div [class.disabled]="datePickerData.disable.end" class="datetime-wrapper">
  <div [class.disabled]="datePickerData.disable.start" class="startsfrom flex-display">
    <div class="flex-display">
      <div>
        <ion-icon name="timer-outline"></ion-icon>
      </div>
      <div class="picker-title">
        {{ datePickerData.titles.start }}
      </div>
    </div>
    <div *ngIf="datePickerData.disable.start">
      {{ formatDate(dateTime.start.date,'D.MMMM.YYYY') }} - {{ dateTime.start.time }}
    </div>
    <div class="height-27" *ngIf="!datePickerData.disable.start">
      <label class="switch">
        <input [checked]="onStartNow" (click)="onStartNowToggle($event)" type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
  <div *ngIf="!onStartNow" class="start-datetime">

    <div class="datetime-title">
      Gyldighedstarttidspunkt
    </div>

    <div class="flex-display">
      <div (click)="toggleCalendar()" class="date padding flex-display">
        <div>
          {{ formatDate(dateTime.start.date) }}
        </div>
        <div style="height: 13px">
          <ion-icon name="chevron-down-outline"></ion-icon>
        </div>
      </div>
      <div (click)="starttime.click()" class="time">
        {{ dateTime.start.time }}
        <input
          (onChange)="onTimeChange($event,'start')"
          #starttime
          mbsc-datepicker
          [controls]="['time']"
          readonly
          display="center"
          timeFormat="HH:mm"
          [touchUi]="false" dateFormat="YYYY-MM-DD"
          class="date-input" hidden />
      </div>


    </div>
  </div>

  <div class="hr"></div>

  <div class="flex-display start-datetime">
    <div class="flex-display">
      <div>
        <ion-icon name="timer"></ion-icon>
      </div>
      <div class="picker-title">
        {{ datePickerData.titles.end }}
      </div>
    </div>

    <div class="flex-display">
      <div (click)="toggleCalendar()" class="padding flex cursor-pointer" style="margin-right: 5px">
        <span>{{ formatDate(dateTime.end.date) }}</span>
        <ion-icon *ngIf="!showCalendar" name="chevron-down-outline" class="calendar-expand-icon"></ion-icon>
        <ion-icon *ngIf="showCalendar" name="chevron-up-outline" class="calendar-expand-icon"></ion-icon>
      </div>
      <div (click)="endtime.click()" class="time cursor-pointer">
        {{ dateTime.end.time }}

      </div>

      <input
        (onChange)="onTimeChange($event,'end')"
        mbsc-datepicker
        #endtime
        [controls]="['time']"
        readonly
        display="center"
        timeFormat="HH:mm"

        hidden
        [touchUi]="false" dateFormat="YYYY-MM-DD"
        class="date-input" />
    </div>
  </div>
  <div *ngIf="showCalendar" class="calendar-wrapper">
    <mbsc-datepicker
      #myDatepicker
      [(ngModel)]="dateRange"
      (onActiveDateChange)="onActiveDateChange($event)"
      (onChange)="getDateTime($event)"
      [controls]="['calendar']"
      [showRangeLabels]="false"
      [defaultSelection]="'end'"
      [locale]="locale"
      [min]=" datePickerData.modes.isEditMode && datePickerData.disable.start  ? datePickerData.startEndDateTime.start : defaultStartDate"
      [endInput]="onStartNow"
      [select]="'range'"
      [display]="'inline'"></mbsc-datepicker>

  </div>
</div>
