<div class="form-group" [formGroup]="fbForm">
  <div class="wrapper">
    <div class="upload-options"
         [ngClass]="{disabled : modes.editMode && isPostedToFb }">
      <div
        (click)="uploadTypeSelect(false)"
        [class.active]="!isVideo" class="options-list">
        Billeder
      </div>
      <div
        (click)="uploadTypeSelect(true)"
        [class.active]="isVideo" class="options-list">
        Video
      </div>
    </div>
    <div
      class="form-group"
      formArrayName="largeImage"
      *ngIf="currentTab === 'facebook'"
    >
      <coop-image-handler
        [imageArray]="imageUrl"
        [hasImages]="hasImage"
        [singleCropper]="true"
        [disableUpload]="false"
        [isRectangle]="false"
        [multipleImage]="true"
        [editMode]="modes.editMode || modes.templateMode"
        [videoUrl]="videoUrl"
        [isVideo]="isVideo"
        [hasVideo]="hasVideo"
        [source]="'fb'"
        [isGif]="isGif"
        [isPostedToFb]="isPostedToFb"
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
        (panelType)="changeUploadType($event)"
        (video)="getVideoUrl($event)"
        (imageArr)="getImagesUrl($event)"
        (processing)="imageProcessing($event)"
      ></coop-image-handler>
      <span
        class="error-message"
        *ngIf="displayMessage['largeImage'] || displayMessage['url']"
      >
        {{ displayMessage['largeImage'] || displayMessage['url'] }}
      </span>
    </div>

    <div class="section-wrapper">
      <div class="title">
        Post tekst
      </div>

      <div class="form-group" controlErrorContainer>
        <label class="margin-bottom-8">Overskrift</label>
        <input
          type="text"
          formControlName="title"
          (focusin)="onSelect()"
          (focusout)="ondeselect()"
          [customErrors]="validationMessages['title']" />
        <div *ngIf="visible" class="remaining"> Brugte tegn: {{ fbForm.get("title").value.length }} / 99</div>

      </div>
      <div class="row form-group flex-column" controlErrorContainer>
        <label class="margin-bottom-8">Tekst</label>
        <coop-mfl-text
          [offerType]="modes.newsMode ? 'nyhed' : offerType"
        ></coop-mfl-text>
        <textarea name="" formControlName="longDescription"
                  [customErrors]="validationMessages['longDescription']"></textarea>
      </div>
    </div>

    <div class="hr"></div>

    <div *ngIf="!modes.newsMode" class="section-wrapper">
      <div class="flex-display">
        <div class="title">
          Prisoplysninger
        </div>

        <div class="tooltip-icon">
                    <span
                      tooltip="Pris og mængde bliver indsat lige under overskriften."
                      placement="top"
                      delay="500"><i class="icon-info-circle-filled"></i></span>
        </div>
      </div>


      <div
        class="row form-group"
        formGroupName="inAppParameters"
        controlErrorContainer
        *ngIf="!modes.newsMode && offerType !== 'good-price'"
      >
        <div class="label-wrapper">
          <div>
            <label>Tilbudspris</label>
          </div>

          <div class="tooltip-icon">
                    <span
                      tooltip="På Facebook vil prisen og mængden blive indsat sidst i opslaget."
                      placement="top"
                      delay="500"><i class="icon-info-circle-filled"></i></span>
          </div>
        </div>
        <input
          type="text"
          formControlName="offerPrice"
          placeholder="0.00"
          (keydown)="validateNumber($event)"
          [customErrors]="validationMessages['offerPrice']"
          [checkParent]="true"
        />
      </div>


      <div *ngIf="!modes.newsMode" class="form-group" controlErrorContainer>

        <div *ngIf="offerType !== 'good-price'" class="label-wrapper">
          <div>
            <label class="margin-bottom-8">
              Før pris <span>(vejl. udsalgspris)</span>
            </label>
          </div>
          <div class="tooltip-icon">
                    <span
                      tooltip="På Facebook vil prisen og mængden blive indsat sidst i opslaget."
                      placement="top"
                      delay="500"><i class="icon-info-circle-filled"></i></span>
          </div>
        </div>
        <div *ngIf="showPriceSection" class="price-description-wrapper">
          <div class="price-container">
            <div formGroupName="inAppParameters">
              <input

                type="text"
                formControlName="price"
                [customErrors]="validationMessages['price']"
                min="0"
                placeholder="Pris"
                (keydown)="validateNumber($event)"
              />
            </div>
            <div style="margin:10px;min-width: fit-content">
              kr. pr.
            </div>
          </div>

          <div class="price-description-container">
            <div style="margin-right: 10px" class="price-description" formGroupName="inAppParameters">
              <input
                id="fb-price"
                type="text"
                formControlName="priceDescription"
                min="0"
                placeholder="vægt/mængde"
                [customErrors]="validationMessages['offerPrice']"
                (keydown)="validateNumber($event)"
              />
            </div>

            <div #input class="price-limit" formGroupName="inAppParameters">
              <select
                formControlName="uom"
                mbsc-select
                [mbscOptions]="priceDescSelectSettings"
                class="form-select">
              </select>
              <div class="dropdown-icon"><i class="icon-arrow-down"></i></div>
            </div>
            <div *ngIf=" offerType === 'good-price' && showPriceSection" style="cursor: pointer;margin-top: 8px"
                 (click)="removePrices()">
              <ion-icon name="trash-outline" class="delete-price-icon"></ion-icon>
            </div>
          </div>

        </div>

        <div *ngIf="!showPriceSection" class="row addvarient" (click)="addPriceField()">
          <a class="login-btn btn-white-login button-wrapper">
            <div style="margin-right: 10px"><i class="icon-add-circle"></i></div>
            <div>Tilføj pris</div>
          </a>
        </div>
      </div>

    </div>
    <div class="divider"></div>
    <div class="section-wrapper" *ngIf="!modes.newsMode">
      <div class="label-wrapper">
        <div>
          <label class="title">Alkohol</label>
        </div>
        <div class="tooltip-icon">
                   <span
                     tooltip='Hvis du promoverer alkohol, vil en lovpligtig tekst automatisk blive tilføjet under titlen på dit indlæg:
"Sælges kun til personer over 18 år. Bemærk at du skal opfylde alders- og ID-kravene i butikken."'
                     placement="top"
                     delay="500"> <ion-icon name="information-circle"
                                            class="tooltip-info-icon"></ion-icon></span>
        </div>
      </div>
      <div class="flex-display restrictions alkohol-bg">
        <p class="toggle-label title">Promoverer du alkohol?</p>

        <label class="switch">
          <input type="checkbox" formControlName="alcohol">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="hr"></div>

    <div class="section-wrapper">
      <div class="title">
        Planlægning
      </div>


      <div *ngIf="!modes.newsMode">
        <h2 class="form-subtitle">
          {{
            offerType === 'good-price'
              ? 'God Pris Gyldighedsperiode'
              : 'Tilbudsperiode'
          }}
        </h2>
        <div *ngIf="startEndDatePicker.startEndDateTime.start && startEndDatePicker.startEndDateTime.end" class="form-group">
          <coop-date-time-range-picker
            [datePickerData]="startEndDatePicker"
            [editMode]="this.modes.editMode"
            [templateMode]="this.modes.templateMode"
            (onDateTimeChange)="getStartDateTime($event)"
          ></coop-date-time-range-picker>

        </div>

      </div>

      <div>
        <h2 class="form-subtitle">Hvornår skal beskeden vises</h2>
        <div class="form-group" *ngIf="publishStartEndPicker.startEndDateTime.start && publishStartEndPicker.startEndDateTime.end">
          <coop-date-time-range-picker
            [datePickerData]="publishStartEndPicker"
            [templateMode]="this.modes.templateMode"
            [editMode]="this.modes.editMode"
            (onDateTimeChange)="getPublishExpirationDateTime($event)"
          ></coop-date-time-range-picker>
        </div>
      </div>

    </div>
  </div>
</div>
