<div class="form-group" [formGroup]="emailForm">
  <div class="wrapper">
    <div class="form-group" controlErrorContainer>
      <coop-image-handler
        [imageUrl]="imageUrl"
        [hasImages]="hasImage"
        [singleCropper]="true"
        [disableUpload]="false"
        [isPostedToFb]="isPostedToFb"
        [editMode]="modes.editMode || modes.templateMode"
        [isEmail]="true"
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
        (image)="getImagesUrl($event)"
        (processing)="imageProcessing($event)"
      ></coop-image-handler>
      <input
        formControlName="thumbnail"
        [customErrors]="validationMessages['largeImage']"
        hidden
      />
    </div>
    <div class="form-group" controlErrorContainer>
      <label for="mail-title" class="margin-bottom-8">Overskrift</label>
      <input
        id="mail-title"
        type="text"
        formControlName="title"
        (focusin)="onSelect()"
        (focusout)="ondeselect()"
        [customErrors]="validationMessages['title']"
      />
      <div *ngIf="visible" class="remaining"> Brugte tegn: {{ emailForm.get("title").value.length }} / 99</div>

    </div>
    <div class=" form-group flex-column" controlErrorContainer>
      <label for="short-desc" class="margin-bottom-8">Indsæt den tekst, der skal vises i e-mailen</label>
      <coop-mfl-text
        [offerType]="modes.newsMode ? 'nyhed' : offerType"
        [isAccordion]="isAccordion"
      ></coop-mfl-text>
      <textarea
        id="short-desc"
        name=""
        class="short-desc"
        formControlName="shortDescription"
        maxlength="250"
        placeholder="Max 250 tegn inkl. mellemrum."
        [customErrors]="validationMessages['shortDescription']"
      ></textarea>
      <div class="remaining"> Brugte tegn: {{ emailForm.get("shortDescription").value.length }} / 250</div>
    </div>
    <div class="row form-group" controlErrorContainer>
      <label for="long-desc" class="margin-bottom-8">Ekstratekst <span class="info">(valgfrit - vises ikke i e-mailen, <br/> men er synlig bag 'Læs mere'-knappen på hjemmesiden)</span>
      </label>
      <textarea
        id="long-desc"
        name=""
        class="long-desc"
        formControlName="longDescription"
        placeholder='Tekst i dette felt udløser en "læs mere" funktion i opslaget i kædemailen.'
        [customErrors]="validationMessages['longDescription']"
      ></textarea>
    </div>

    <div
      class="row form-group"
      formGroupName="inAppParameters"
      controlErrorContainer
      *ngIf="!modes.newsMode && offerType !== 'good-price'"
    >
      <div class="label-wrapper">
        <div>
          <label>Tilbudspris</label>
        </div>

<!--        <div class="tooltip-icon">-->
<!--                    <span-->
<!--                      tooltip="Pris og mængde bliver indsat lige under overskriften."-->
<!--                      placement="top"-->
<!--                      delay="500"><i class="icon-info-circle-filled"></i></span>-->
<!--        </div>-->
      </div>
      <input
        type="text"
        formControlName="offerPrice"
        placeholder="0.00"
        (keydown)="validateNumber($event)"
        [customErrors]="validationMessages['offerPrice']"
        [checkParent]="true"
      />
    </div>


    <div *ngIf="!modes.newsMode" class="form-group" controlErrorContainer>

      <div class="label-wrapper">
        <div>
          <ng-container *ngIf="offerType === 'good-price'; else show">
            <label class="margin-bottom-20">
              Prisoplysninger:
            </label>
          </ng-container>
          <ng-template #show>
            <label class="margin-bottom-8">
              Før pris <span>(vejl. udsalgspris)</span>
            </label>
          </ng-template>
        </div>
<!--        <div class="tooltip-icon">-->
<!--                    <span-->
<!--                      tooltip="Pris og mængde bliver indsat lige under overskriften."-->
<!--                      placement="top"-->
<!--                      delay="500"><i class="icon-info-circle-filled"></i></span>-->
<!--        </div>-->
      </div>
      <div *ngIf="showPriceSection" class="price-description-wrapper">
        <div class="price-container">
          <div formGroupName="inAppParameters">
            <input
              type="text"
              formControlName="price"
              [customErrors]="validationMessages['price']"
              min="0"
              placeholder="Pris"
              (keydown)="validateNumber($event)"
            />
          </div>
          <div style="margin:10px;min-width: fit-content">
            kr. pr.
          </div>
        </div>

        <div class="price-description-container">
          <div style="margin-right: 10px" class="price-description" formGroupName="inAppParameters">
            <input
              id="fb-price"
              type="text"
              formControlName="priceDescription"
              [customErrors]="validationMessages['priceDescription']"
              min="0"
              placeholder="vægt/mængde"
              (keydown)="validateNumber($event)"
            />
          </div>

          <div #input class="price-limit" formGroupName="inAppParameters">
            <select
              formControlName="uom"
              mbsc-select
              [mbscOptions]="priceDescSelectSettings"
              class="form-select">
            </select>
            <div class="dropdown-icon"><i class="icon-arrow-down"></i></div>
          </div>
          <div *ngIf=" offerType === 'good-price' && showPriceSection" style="cursor: pointer;margin-top: 8px"
               (click)="removePrices()">
            <ion-icon name="trash-outline" class="delete-price-icon"></ion-icon>
          </div>
        </div>

      </div>

      <div *ngIf="!showPriceSection" class="row addvarient" (click)="addPriceField()">
        <a class="login-btn btn-white-login button-wrapper">
          <div style="margin-right: 10px"><i class="icon-add-circle"></i></div>
          <div><p>Tilføj pris</p></div>
        </a>
      </div>
    </div>
    <div class="divider"></div>
    <div class="section-wrapper" *ngIf="!modes.newsMode">
      <div class="label-wrapper">
        <div>
          <label class="title">Alkohol</label>
        </div>
        <div class="tooltip-icon">
                    <span
          tooltip='Hvis du promoverer alkohol, vil en lovpligtig tekst automatisk blive tilføjet under titlen på dit indlæg:
"Sælges kun til personer over 18 år. Bemærk at du skal opfylde alders- og ID-kravene i butikken."'
          placement="top"
          delay="500"> <ion-icon name="information-circle"
                                 class="tooltip-info-icon"></ion-icon></span>
        </div>
      </div>
      <div class="flex-display restrictions alkohol-bg">
        <p class="toggle-label title">Promoverer du alkohol?</p>

        <label class="switch">
          <input type="checkbox" formControlName="alcohol">
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="hr"></div>

    <div class="section-wrapper">
      <div class="title">
        Planlægning
      </div>

      <div *ngIf="!modes.newsMode">
        <h2 class="form-subtitle">
          {{
          offerType === 'good-price'
            ? 'God Pris Gyldighedsperiode'
            : 'Tilbudsperiode'
          }}
        </h2>
        <div *ngIf="startEndDatePicker.startEndDateTime.start && startEndDatePicker.startEndDateTime.end" class="form-group" controlErrorContainer>
          <coop-date-time-range-picker
            [datePickerData]="startEndDatePicker"
            [editMode]="this.modes.editMode"
            [templateMode]="this.modes.templateMode"
            (onDateTimeChange)="getStartDateTime($event)"
          ></coop-date-time-range-picker>
        </div>

      </div>


      <div>
        <h2 class="form-subtitle">
          Find e-mailkampagner hvor beskeden kan vises – angiv periode
        </h2>
        <div *ngIf="publishStartEndPicker.startEndDateTime.start && publishStartEndPicker.startEndDateTime.end" class="col-two form-group" controlErrorContainer>
          <coop-date-time-range-picker
            [datePickerData]="publishStartEndPicker"
            [editMode]="this.modes.editMode"
            [templateMode]="this.modes.templateMode"
            (onDateTimeChange)="getPublishExpirationDateTime($event)"
          ></coop-date-time-range-picker>
        </div>

      </div>

    </div>


    <div class="share-container">
      <h3 class="form-subtitle form-group">Vælg en e-mail-kampagne</h3>
      <div class="share-list" controlErrorContainer>
        <ul>
          <li class="email-container" (click)="emailToggle()">
            E-mailkampagne
            <div class="check">
              <i class="icon icon-arrow-down" *ngIf="!showMailList"></i>
              <i class="icon icon-arrow-up" *ngIf="showMailList"></i>
            </div>
          </li>
          <div [ngClass]="showMailList ? 'show panel' : 'hide'">
            <ul class="mail-list" *ngIf="mailList && !mailSent">
              <li
                *ngFor="let mlist of mailList; let i = index"
                (click)="saveRad(mlist.id)"
              >
                {{ mlist.navn }} - {{ mlist.week }} (send dato:
                {{ mlist.sendDate }})
                <div
                  class="check"
                  [ngClass]="mlist.id === selMailId ? 'green' : ''"
                >
                  <i class="icon icon-stop" *ngIf="mlist.id !== selMailId"></i>
                  <i class="icon icon-tick-square" *ngIf="mlist.id === selMailId"></i>
                </div>
              </li>
            </ul>
            <ul *ngIf="mailList && mailList.length === 0 && !mailSent">
              <li class="text-align-center">Ingen email tilgængelig</li>
            </ul>
            <ul *ngIf="mailSent">
              <li class="text-align-center">
                Tidligere valgt e-mail skabelon er blevet sendt
              </li>
            </ul>
          </div>
        </ul>
      </div>
      <input formControlName="mailId" hidden [customErrors]="validationMessages['mailId']"/>
    </div>

  </div>
</div>
