<section class="image-component">
  <input type="file" [attr.accept]="isVideo ? '.mp4,.webm' : 'image/*'" #galleryimageuploadbutton
         (change)="fileChangeEvent($event, galleryimageuploadbutton)" hidden>

  <input type="file" accept="image/*" id="picture" capture="" #cameraimageuploadbutton
         (change)="fileChangeEvent($event, cameraimageuploadbutton)" hidden>
  <ng-container *ngIf="!isVideo">
    <div class="initial-image" *ngIf="!hasImages && !imageUploaded">
      <figure class="dummy-image">
        <div class="img-box">
          <div
            class="img-dummy-box"
            [class.single-box]="!multipleImage"
            [class.multiple-img-box]="multipleImage"
            [ngClass]="offerType"
          >
            <div class="image-wrapper">
              <ng-container *ngIf="showMessage">
                <div class="notification-section">
                  <div style="display: flex; align-items: center ; margin-bottom: 10px">
                    <div>
                      <ion-icon name="warning-outline" class="font-20"></ion-icon>
                    </div>
                    <div class="husk" *ngIf="offerType !== 'jatak'">Vigtig</div>
                    <div *ngIf="offerType === 'jatak'" class="husk">Husk</div>

                  </div>
                  <div *ngIf="offerType === 'jatak' " class="message">
                    <p>Ophavsret og persondata</p>
                    <p>At det kun er discount kan må benytte gule farver mm.</p>
                    <p>Du gerne må benytte tilbuds indikator som KUN, sparegris emojis mm,
                      så længe det er et til oprigtigt tilbud, som overholder betingelserne.</p>
                  </div>
                  <div *ngIf="offerType ===  'tilbud' " class="message">
                    <p> Husk ophavsret og persondata når du anvender billeder</p>
                  </div>
                  <div *ngIf="offerType !==  'tilbud' && offerType !== 'jatak'" class="message">
                    <p> Husk ophavsret og persondata når du anvender billeder</p>
                  </div>
                  <div (click)="showNotification($event)" style="display: flex; align-items: center">
                    <div style="padding-top:2px">
                      <ion-icon name="stop-outline" class="font-18"></ion-icon>
                      <!--                                  <i *ngIf="!showNotificationMessage" class="isax isax-tick-square"></i>-->
                    </div>
                    <div
                      style="margin-left: 5px ; font-size: 12px ;color: #666666;line-height: 16.74px;letter-spacing: 0.12px">
                      <p>Skjul denne note</p></div>
                  </div>
                </div>

              </ng-container>
              <ion-icon name="image" *ngIf="!showMessage" class="img-placeholder-icon"></ion-icon>
              <!--              <img *ngIf="!showMessage" src="assets/images/camera_with_text.svg" alt="No Image" />-->
              <div (click)="showMessageSection($event)" *ngIf="!showMessage" class="notification">
                <!--                <i class="icon icon-info-circle"></i>-->
                <ion-icon name="information-circle-outline"></ion-icon>
              </div>
            </div>
          </div>
          <div class="flex justify-content-center">
            <div class="image-container-actions" (click)="openUploadOptions()">
              <span>Tilføj billede</span>
              <ion-icon *ngIf="!showUploadOption" name="chevron-down-outline"></ion-icon>
              <ion-icon *ngIf="showUploadOption" name="chevron-up-outline"></ion-icon>

            </div>

            <div *ngIf="showUploadOption" class="upload-option">
              <div class="upload-type" (click)="cameraimageuploadbutton.click()">
                Tag billede
              </div>
              <div class="upload-type" (click)="galleryimageuploadbutton.click()">
                Fra galleriet
              </div>
            </div>
          </div>
        </div>

      </figure>
    </div>


    <!--    image preview for solo image-->
    <ng-container *ngIf="!multipleImage">

      <div
        class="slider"
        *ngIf="hasImages || imageUploaded"
        [class.white-bg]="multipleImage"
        [class.no-border]="noBorder"
      >
        <div class="single-info">
          <p> 1 / 1</p>
        </div>

        <div

          class="slider-container"
          [class.processing]="!imgLoaded"
          [class.single-img]="true"
        >
          <img
            (click)="disableImageUpload($event)"
            src="{{ imageUrl.largeImage }}"
            id="large-image"
            alt="background-image"
            [crossOrigin]="!forShop ? 'anonymous' : null"
            [class.background-img]="!singleCropper"
            (load)="imageLoaded()"
            *ngIf="!multipleImage"
          />
          <div
            class="flex "
            *ngIf="!isPostedToFb && !multipleImage && canEdit "

          >
            <!--            <div class="flex">-->
            <div>
              <div class="image-container-actions img-add" (click)="openUploadOptions()">
                <span>Tilføj billede</span>
                <ion-icon name="chevron-down-outline" *ngIf="!showUploadOption"></ion-icon>
                <ion-icon name="chevron-up-outline" *ngIf="showUploadOption"></ion-icon>
              </div>

              <div *ngIf="showUploadOption" class="upload-option upload-option-edit">
                <div class="upload-type" (click)="cameraimageuploadbutton.click()">
                  Tag billede
                </div>
                <div class="upload-type" (click)="galleryimageuploadbutton.click()">
                  Fra fotogalleri
                </div>
              </div>
            </div>
            <div class="delete-img" (click)="imageRemoveLogic($event)">
              <ion-icon name="trash-outline"></ion-icon>
            </div>
            <!--            </div>-->
            <!--            <i class="icon icon-gallery-edit"></i>-->
            <!--            <p> Rediger billede</p>-->
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="multipleImage">
      <div
        class="slider"
        *ngIf="hasImages || imageUploaded"
        [class.white-bg]="multipleImage"
        [class.no-border]="noBorder"
      >
        <div class="info" *ngIf="imgSliderArray.length > 0">
          <p> {{ activeSliderImageIndex + 1 }} / {{ imgSliderArray.length }}</p>
        </div>

        <div
          (click)="disableImageUpload($event)"
          class="slider-container"
          [class.processing]="!imgLoaded"
          [class.single-img]="true"
          [class.multi-img-container]="multipleImage"
        >
          <swiper-container init="false" navigation="true"></swiper-container>

          <ng-container *ngIf="multipleImage">
            <div class="swiper-nav-button swiper-button-next"></div>
            <div class="swiper-nav-button  swiper-button-prev"></div>
          </ng-container>
        </div>
        <div *ngIf="!isPostedToFb && multipleImage" class="jatak-buttons">
          <ng-container>
            <div class="flex">
              <div>
                <div class="image-container-actions img-add" (click)="openUploadOptions()">
                  <span>Tilføj billede</span>
                  <ion-icon *ngIf="!showUploadOption" name="chevron-down-outline"></ion-icon>
                  <ion-icon *ngIf="showUploadOption" name="chevron-up-outline"></ion-icon>

                </div>

                <div *ngIf="showUploadOption" class="upload-option upload-option-edit upload-option-mupltiple-img">
                  <div class="upload-type" (click)="cameraimageuploadbutton.click()">
                    Tag billede
                  </div>
                  <div class="upload-type" (click)="galleryimageuploadbutton.click()">
                    Fra fotogalleri
                  </div>
                </div>
              </div>
              <div class="delete-img" (click)="imageRemoveLogic($event)">
                <ion-icon name="trash-outline"></ion-icon>
              </div>
            </div>
            <!--            <div-->
            <!--              class="btn jatak-image-edit"-->
            <!--              [class.upload-button]="imgSliderArray.length >= imageArray.length"-->
            <!--              (click)="imageRemoveLogic($event)"-->
            <!--            >-->
            <!--              <i class="icon icon-gallery-remove"></i>-->
            <!--              <p> Fjern billede</p>-->
            <!--            </div>-->
            <!--            <div-->
            <!--              *ngIf="imgSliderArray.length < imageArray.length"-->
            <!--              class="jatak-image-delete"-->
            <!--              (click)="openFileBrowser($event)"-->
            <!--            >-->
            <!--              <i class="icon icon-gallery-add"></i>-->
            <!--            </div>-->
          </ng-container>
        </div>
        <ng-container *ngIf="multipleImage">
          <div *ngIf="imgSliderArray.length === imageArray.length" class="upload-message">
            <i class="icon icon-info-circle"></i>
            <p>Du kan maksimalt uploade {{ imgSliderArray.length }} billeder</p>
          </div>
        </ng-container>
      </div>
    </ng-container>


  </ng-container>
  <ng-container *ngIf="isVideo">
    <div (click)="openFileBrowser($event)" class="video-wrapper">
      <coop-imageloader
        [showLoader]="showImageLoader"
        [progress]="progress"
        [uploadFailed]="uploadFailed"
      >
      </coop-imageloader>
      <div class="initial-image" *ngIf="!hasVideo && !videoUploaded">


        <!--        video upload section for jatak-->

        <figure class="dummy-image">
          <div class="no-video-box">
            <div
              class="video-dummy-image"
              *ngIf="!showImageLoader"
              [ngClass]="offerType"
            >
              <div class="video-wrap">
                <ng-container *ngIf="showMessage">
                  <div class="notification-section">
                    <div style="display: flex; align-items: center ; margin-bottom: 10px">
                      <!--                      <div><i class="icon icon-danger"></i></div>-->
                      <div>
                        <ion-icon name="warning-outline" class="font-20"></ion-icon>
                      </div>
                      <div class="husk" *ngIf="offerType !== 'jatak'"><p>Vigtig</p></div>
                      <div *ngIf="offerType === 'jatak'" class="husk"><p>Husk</p></div>
                    </div>
                    <div *ngIf="offerType === 'jatak'" class="message">
                      <p>Ophavsret og persondata</p>
                      <p>At det kun er discount kan må benytte gule farver mm.</p>
                      <p>Du gerne må benytte tilbuds indikator som KUN, sparegris emojis mm,
                        så længe det er et til oprigtigt tilbud, som overholder betingelserne.</p>
                    </div>
                    <div *ngIf="offerType ===  'tilbud' " class="message">
                      <p> Husk ophavsret og persondata når du anvender billeder</p>
                    </div>
                    <div *ngIf="offerType !==  'tilbud' && offerType !== 'jatak'" class="message">
                      <p> Husk ophavsret og persondata når du anvender billeder</p>
                    </div>
                    <div (click)="showNotification($event)"
                         style="display: flex; align-items: center ">
                      <div style="padding-top:2px">
                        <ion-icon name="stop-outline" *ngIf="showMessage" class="font-18"></ion-icon>
                      </div>
                      <div
                        style="margin-left: 5px ; font-size: 12px ;color: #666666;line-height: 17px;">
                        <p>Skjul denne note</p></div>
                    </div>
                  </div>

                </ng-container>
                <ion-icon name="play" *ngIf="!showMessage" class="img-placeholder-icon"></ion-icon>
                <div (click)="showMessageSection($event)" *ngIf="!showMessage" class="notification">
                  <i class="icon icon-info-circle"></i>
                </div>
              </div>

            </div>
            <div
              class="btn upload-button jatak-video-upload-btn"
              *ngIf="!isPostedToFb"
            >
              <!--              <i class="icon icon-gallery-add"></i>-->
              <p>Tilføj video</p>
            </div>

          </div>
        </figure>
      </div>
      <div class="video-container" *ngIf="hasVideo || videoUploaded">
        <div class="no-video-edit-box" *ngIf="showImageLoader"></div>
        <div
          class="video-box"
          [class.processing]="!videoLoaded"
          *ngIf="!showImageLoader"
        >
          <video
            id="video"
            (load)="vidLoaded()"
            controls
            disablePictureInPicture
            playsinline
            muted
            autoplay
            name="media"
            #video
          >
            <source src="{{videoUrl.url}}">
          </video>
        </div>
        <div
          class="btn  jatak-video-edit"
          *ngIf="!isPostedToFb"
        >
          <i class="icon icon-video-play"></i>
          <p>Rediger video</p>

        </div>
      </div>
    </div>
  </ng-container>
</section>
<section class="image-cropper" [@fadeInOut]="cropperStatus" *ngIf="showCropper">
  <div (click)="stopPropagation($event)">
    <coop-image-editor
      [mediaFile]="imageChangedEvent"
      [aspectRatio]="aspectRatio"
      (closeEditor)="closeEditor()"
      (imageProcessed)="imageProcessed($event)"
      (imageLoaded)="cropperLoaded()"
      [customOptions]="editorOptions"
    >
    </coop-image-editor>
  </div>
  <ng-container *ngIf="showSaveOptions">
    <div class="overlay">
      <div class="media-save-option-dialog">
        <div class="dialog-header-container">
          <div class="dialog-header">Gem indstillinger</div>
        </div>
        <div class="dialog-button-container">
          <a class="login-btn btn-black-login" (click)="uploadImageForAllChannels()"
          >Gem billede for alle kanaler</a
          >
          <a class="login-btn btn-black-login" (click)="uploadImage()"> Gem billede</a>
        </div>
      </div>
    </div>
  </ng-container>
</section>
