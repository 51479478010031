<ion-content>
  <section class="app-page page-with-fixed-header">
    <div class="fixed-header">
      <coop-pageheader
        [isBackGroundWhite]="true"
        [pageTitle]="pageTitle"
        [isChild]="true"
        [routeTo]="parentUrl"
        [section]="section"
      ></coop-pageheader>
    </div>

    <div class="page-container">
      <div class="campaign-overview">
        <div class="offertype" (clickOutside)="handleClickOutside()">
          <div (click)="toggleOfferOption()" class="offerlist">
            <div class="offer-title">
              {{ offerTitle }}
            </div>
            <div>
              <ion-icon *ngIf="!showOfferOption" name="chevron-down-outline"></ion-icon>
              <ion-icon *ngIf="showOfferOption" name="chevron-up-outline"></ion-icon>
            </div>
          </div>
          <div *ngIf="showOfferOption" class="listsection">
            <div *ngFor="let offer of itemList;let last= last" (click)="onSelect(offer)">
              <div class="list" [class.noborder]="last">
                <div class="offer-name">
                  {{ offer.name }}
                </div>
                <div>
                  <ion-icon *ngIf="selectedId === offer.id" name="radio-button-on-outline" class="margin-top-2"></ion-icon>
                  <ion-icon *ngIf="selectedId !== offer.id" name="radio-button-off-outline" class="margin-top-2"></ion-icon>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div *ngIf="selectedId === 1 || selectedId === 2 || selectedId === 4" class="tab-options">
          <ion-segment class="tab-options-wrapper" mode="md">
            <ion-segment-button class="tab-options-title"
                                value="1"
                                [ngClass]="currentTab=='active' ? 'segment-button-checked' : 'segement-button-unchecked'"
                                (click)="setTabState('1')">
              <ion-label class="text-label"
                         [class]="currentTab == 'active' ? 'tab-sub-label-bold': 'tab-sub-label'">Aktive
              </ion-label>
            </ion-segment-button>

            <ion-segment-button class="tab-options-title"
                                value="2"
                                [ngClass]="currentTab=='scheduled' ? 'segment-button-checked' : 'segement-button-unchecked'"
                                (click)="setTabState('2')">
              <ion-label class="text-label"
                         [class]="currentTab == 'scheduled' ? 'tab-sub-label-bold': 'tab-sub-label'">Fremtidige
              </ion-label>
            </ion-segment-button>
            <ion-segment-button
              class="tab-options-title"
              value="0"
              [ngClass]="currentTab=='archived' ? 'segment-button-checked' : 'segement-button-unchecked'"
              (click)="setTabState('0')">
              <ion-label class="text-label"
                         [class]="currentTab == 'archived' ? 'tab-sub-label-bold': 'tab-sub-label'">Arkiv
              </ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>

        <div class="searchsection">
          <div class="searchinput">
            <input type="search"
                   #searchBox
                   placeholder="Søg"
                   class="search-input"
                   (keyup.enter)="search(searchBox.value)"
            />
            <div *ngIf="searchString" class="close-search" (click)="closeSearch()">
              <ion-icon name="close-outline"></ion-icon>
            </div>
          </div>
          <div class="searchicon">
            <ion-icon name="search-outline"></ion-icon>
          </div>
        </div>

        <div>
          <div *ngIf="selectedId === 1 || selectedId === 2|| selectedId === 4" class="searchsection">
            <div class="filter-type">Most recent</div>
            <!--          <p *ngIf="sortOptionValue === 'turnover'"-->
            <!--             style="font-size: 16px; font-weight: 600">{{"_Highest_turn_over_" |translate}}</p>-->
            <!--          <p *ngIf="sortOptionValue === 'sold'"-->
            <!--             style="font-size: 16px; font-weight: 600">{{"_most_sold_" |translate}}</p>-->
            <!--          <p *ngIf="sortOptionValue === 'favourite'"-->
            <!--             style="font-size: 16px; font-weight: 600">{{"_favourite_first_" |translate}}</p>-->

            <div style="display: flex;align-items: center">
              <div *ngIf="selectedId === 1 || selectedId === 4" (click)="displayCalendarToggle()" class="filtericon">
                <ion-icon name="calendar"></ion-icon>
              </div>
              <div class="filtericon"
                   (click)="onSortOptionToggle()" style="margin-left: 10px">
                <ion-icon name="swap-vertical-outline"></ion-icon>
              </div>
            </div>
          </div>


          <div *ngIf="showSortOptions" class="sortoptions">
            <div (click)="onSortClicked(1)" class="options1">
              <div>Seneste</div>
              <ion-icon *ngIf="sortBy != 1" name="radio-button-off-outline"></ion-icon>
              <ion-icon *ngIf="sortBy == 1" name="radio-button-on-outline"></ion-icon>
            </div>
            <div (click)="onSortClicked(2)" class="options1">
              <div>Højeste omsætning</div>
              <ion-icon *ngIf="sortBy != 2" name="radio-button-off-outline"></ion-icon>
              <ion-icon *ngIf="sortBy == 2" name="radio-button-on-outline"></ion-icon>
            </div>
            <div (click)="onSortClicked(3)" class="options1">
              <div>Mest solgte</div>
              <ion-icon *ngIf="sortBy != 3" name="radio-button-off-outline"></ion-icon>
              <ion-icon *ngIf="sortBy == 3" name="radio-button-on-outline"></ion-icon>
            </div>

          </div>

          <ng-container *ngIf="displayCalendar">
            <div class="margin-top-15">
              <mbsc-datepicker
                #myDatepicker
                [controls]="['calendar']"
                select="range"
                rangeStartLabel="Start"
                rangeEndLabel="End"
                rangeStartHelp="Please select"
                rangeEndHelp="Please select"
                (valueChange)="getDateTime($event)"
                display="inline"></mbsc-datepicker>

            </div>
            <div *ngIf="displayCalendar" style="position: relative;padding: 10px; margin: 10px 10px 40px 10px">
              <div (click)="onResetDateRange()" class="resetrange">
                Reset date
              </div>
            </div>
            <!--          <div  style="position: relative;padding: 10px; margin: 10px 10px 40px 10px">-->
            <!--            <div (click)="onResetDateRange()" class="resetrange">-->
            <!--              {{"_reset_date_" |translate}}-->
            <!--            </div>-->
            <!--          </div>-->
          </ng-container>


          <!--          for jatak list-->

          <ng-container *ngIf="selectedId === 1">
            <div class="no-item-available" *ngIf="jatakList && !jatakList.length">Beklager, ingen varer tilgængelige</div>
           <div *ngIf="jatakList && jatakList.length">
             <div *ngFor="let item of jatakList" class="item-list-wrapper">
               <div class="item-title">
                 {{ item.title }}
               </div>
               <div *ngIf="isMobileScreen()" class="item-details">
                 <div class="fb-info">
                   <div>
                     <ion-icon class="ion-icon-font" name="cash-outline"></ion-icon>
                   </div>
                   <div style="margin-left: 10px;font-size: 13px"><span class="infos"> {{item.turnover}} </span> kr.
                   </div>
                 </div>

                 <div class="fb-info  sold-info">
                   <div>
                     <ion-icon name="card-outline"></ion-icon>
                   </div>
                   <div style="margin-left: 10px;font-size: 13px"><span
                     class="infos">{{item.sold}}</span> Solgte
                   </div>
                 </div>
                 <div (click)="handleInfo(item?.id)" class="info-arrow">
                   <ion-icon name="chevron-down-outline"></ion-icon>
                 </div>
               </div>

               <div *ngIf="isMobileScreen()">
                 <div class="item-details" *ngIf="showInfoOptionBYId == item.id">


                   <div *ngIf="item.shared.facebook" class="fb-info  sold-info">
                     <div>
                       <ion-icon name="radio-outline"></ion-icon>
                     </div>
                     <div style="margin-left: 10px;font-size: 13px"><span
                       class="infos">{{item.facebookReach > 0 ? item.facebookReach : 'Pending' }}</span>
                     </div>
                   </div>

                   <div class="fb-info ">
                     <div>
                       <ion-icon name="people-outline"></ion-icon>
                     </div>
                     <div style="margin-left: 10px;font-size: 13px;">
                       <span class="infos"> 6</span>
                       Customers
                     </div>
                   </div>

                   <div class="fb-info">
                     <div>
                       <ion-icon name="bag-outline"></ion-icon>
                     </div>
                     <div style="margin-left: 10px;font-size: 13px"><span
                       class="infos">10</span> Orders
                     </div>
                   </div>

                   <div class="fb-info">
                     <div>
                       <ion-icon name="bag-check-outline"></ion-icon>
                     </div>
                     <div style="margin-left: 10px;font-size: 13px"><span
                       class="infos">5</span> Picked up
                     </div>
                   </div>


                   <div class="fb-info">
                     <div>
                       <ion-icon name="pie-chart-outline"></ion-icon>
                     </div>
                     <div style="margin-left: 10px;;font-size: 13px"><span
                       class="infos">10</span> Remaining
                     </div>
                   </div>
                 </div>
               </div>


               <div *ngIf="!isMobileScreen()" class="item-details">
                 <div class="fb-info">
                   <div>
                     <ion-icon class="ion-icon-font" name="cash-outline"></ion-icon>
                   </div>
                   <div style="margin-left: 10px;font-size: 13px"><span class="infos"> {{item.turnover}} </span> kr.
                   </div>
                 </div>

                 <div class="fb-info  sold-info">
                   <div>
                     <ion-icon name="card-outline"></ion-icon>
                   </div>
                   <div style="margin-left: 10px;font-size: 13px"><span
                     class="infos">{{item.sold}}</span> Solgte
                   </div>
                 </div>

                 <div *ngIf="item.shared.facebook" class="fb-info  sold-info">
                   <div>
                     <ion-icon name="radio-outline"></ion-icon>
                   </div>
                   <div style="margin-left: 10px;font-size: 13px"><span
                     class="infos">{{item.facebookReach > 0 ? item.facebookReach : 'Pending' }}</span>
                   </div>
                 </div>


                 <div class="fb-info ">
                   <div>
                     <ion-icon name="people-outline"></ion-icon>
                   </div>
                   <div style="margin-left: 10px;font-size: 13px;">
                     <span class="infos"> {{item.customer}} </span>
                     kunder
                   </div>
                 </div>


                 <div class="fb-info">
                   <div>
                     <ion-icon name="bag-outline"></ion-icon>
                   </div>
                   <div style="margin-left: 10px;font-size: 13px"><span
                     class="infos">{{item.orders}}</span> ordre
                   </div>
                 </div>

                 <div (click)="handleNextClick(item?.id)" class="info-arrow">
                   <ion-icon *ngIf="showMobileScreenInfoById == item.id" name="chevron-up-outline"></ion-icon>
                   <ion-icon *ngIf="showMobileScreenInfoById !== item.id" name="chevron-down-outline"></ion-icon>
                 </div>
               </div>

               <div *ngIf="showMobileScreenInfoById == item.id">
                 <div class="iteminfo-details">

                   <div class="fb-info">
                     <div>
                       <ion-icon name="bag-check-outline"></ion-icon>
                     </div>
                     <div style="margin-left: 10px;font-size: 13px"><span
                       class="infos">{{item.pickedUp}} </span> afhentet
                     </div>
                   </div>

                   <div style="margin-left: 15px" class="fb-info">
                     <div>
                       <ion-icon name="pie-chart-outline"></ion-icon>
                     </div>
                     <div style="margin-left: 10px;;font-size: 13px"><span
                       class="infos">{{item.stockLeft}}</span> tilbage
                     </div>
                   </div>

                 </div>
               </div>


               <div class="details">
                 <div style="cursor: pointer" class="imagewrapper">
                   <div class="item-image">
                     <img
                       [alt]=""
                       [src]="item.image"
                       (load)="onImageLoad($event)"
                       class="result-img"
                     />
                     <div (click)="onFavClick(1)" class="fav">
                       <ion-icon name="heart-outline"></ion-icon>
                       <!--                      <ion-icon *ngIf="fav.includes(offer.id)" name="heart"></ion-icon>-->
                     </div>
                   </div>

                 </div>
                 <div class="item-info">
                   <div class="timewrapper">
                     <div class="time-info">
                       <ion-icon name="timer-outline"></ion-icon>
                       <div style="margin-left: 5px;">
                         <p>
                           {{ item.startDate | dateformat : false }}
                         </p>
                       </div>
                     </div>
                     <div class="time-info endtime">
                       <ion-icon name="timer"></ion-icon>
                       <div style="margin-left: 5px;">
                         <p>
                           {{ item.endDate | dateformat : false }}
                         </p>
                       </div>
                     </div>
                     <div
                       *ngIf="item.actionRequired"
                       class="manual-approve-wrap">
                       <ion-icon name="alert-circle-outline"></ion-icon>
                       <div style="flex-basis: 70%">
                         <p class="manual-approve-text">Handling påkrævet</p>
                       </div>
                     </div>
                   </div>
                   <div *ngIf="!isMobileScreen()" class="edit-duplicate-option">
                     <div (click)="orderList(item.id)" class="order-button">
                       <ion-icon name="reorder-four-outline"></ion-icon>
                       <span style="margin-left: 10px">Ordreliste</span>
                     </div>
                     <div (click)="edit(item.id)" class="edit-duplicate-btn">
                       <ion-icon name="pencil" class="edit-duplicate-icon"></ion-icon>
                       <span class="edit-duplicate-text">Redigere</span>
                     </div>

                     <div class="edit-duplicate-btn" (click)="duplicate(item.id)">
                       <ion-icon name="duplicate" class="edit-duplicate-icon"></ion-icon>
                       <span class="edit-duplicate-text">Duplikere</span>
                     </div>
                   </div>
                   <div *ngIf="isMobileScreen()" class="order-list-button">
                     <div class="order-button" (click)="orderList(item.id)">
                       Ordreliste
                     </div>
                     <div class="toggle-button" (click)="openEditDuplicate()">
                       <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                     </div>
                   </div>
                 </div>
               </div>

               <div class="edit-duplicate-option" *ngIf="showEditDuplicateBtn && isMobileScreen()">
                 <div (click)="edit(item.id)" class="edit-duplicate-btn">
                   <ion-icon name="pencil-outline" class="edit-duplicate-icon"></ion-icon>
                   <span class="edit-duplicate-text">Redigere</span>
                 </div>
                 <div (click)="duplicate(item.id)" class="edit-duplicate-btn">
                   <ion-icon name="duplicate" class="edit-duplicate-icon"></ion-icon>
                   <span class="edit-duplicate-text">Duplikere</span>
                 </div>
               </div>

             </div>
           </div>
          </ng-container>


          <!--          for recurring promo offer list-->

          <ng-container *ngIf="selectedId === 2 ">
            <div class="no-item-available" *ngIf="recurringOfferList && !recurringOfferList.length">Beklager, ingen varer tilgængelige</div>
            <div *ngIf="recurringOfferList && recurringOfferList.length">
              <div *ngFor="let item of recurringOfferList"
                   routerLink="/butikker/indlaeg/tilbagevendende-tilbud/redigere/{{
            item.id
          }}"
                   class="item-list-wrapper">
                <div class="flex-display">
                  <div class="flex-display">
                    <div>
                      <img
                        (load)="onImageLoad($event)"
                        class="item-img"
                        src="{{ item.largeImage }}">
                    </div>

                    <div style="margin-left: 10px">
                      <span>{{ formatDate(item.startDate) }} - {{ formatDate(item.endDate) }}</span>
                      <div class="item-title">
                        {{ item.title }}
                      </div>
                    </div>

                  </div>

                  <div>
                    <ion-icon name="chevron-forward-outline" style="font-size: 20px;"></ion-icon>
                  </div>

                </div>
              </div>
            </div>
          </ng-container>

          <!--          for website list-->

          <ng-container *ngIf="selectedId === 3">
            <div class="no-item-available" *ngIf = "websiteList && !websiteList.length">Beklager, ingen varer tilgængelige</div>
           <div *ngIf = "websiteList && websiteList.length">
             <div *ngFor="let item of websiteList" class="item-list-wrapper"  routerLink="/butikker/website/redigere/{{
            item.id
          }}">
               <div class="flex-display">
                 <div class="flex-display">
                   <div>
                     <img
                       (load)="onImageLoad($event)"
                       class="item-img"
                       src="{{ item.image }}">
                   </div>

                   <div style="margin-left: 10px">
                     <div class="item-title">
                       {{ item.title }}
                     </div>
                     <span>{{ item.description  }}</span>
                   </div>

                 </div>

                 <div>
                   <ion-icon name="chevron-forward-outline" style="font-size: 20px"></ion-icon>
                 </div>

               </div>
             </div>
           </div>
          </ng-container>


          <!--          for jatak live list-->

          <ng-container *ngIf="selectedId === 4">
            <div class="no-item-available" *ngIf="jatakLiveList && !jatakLiveList.length">Beklager, ingen varer tilgængelige</div>
            <div *ngIf="jatakLiveList && jatakLiveList.length">
              <div *ngFor="let item of jatakLiveList" class="item-list-wrapper">
                <div class="item-title">
                  {{ item.title }}
                </div>
                <div *ngIf="isMobileScreen()" class="item-details">
                  <div class="fb-info">
                    <div>
                      <ion-icon class="ion-icon-font" name="cash-outline"></ion-icon>
                    </div>
                    <div style="margin-left: 10px;font-size: 13px"><span class="infos"> {{item.max_items}} </span> dkk
                    </div>
                  </div>

                  <div class="fb-info  sold-info">
                    <div>
                      <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div style="margin-left: 10px;font-size: 13px"><span
                      class="infos">{{item.order}}</span> Ordre
                    </div>
                  </div>

                  <div class="fb-info  sold-info">
                    <div>
                      <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div style="margin-left: 10px;font-size: 13px"><span
                      class="infos">{{item.turnover}}</span> Tilbage
                    </div>
                  </div>
                  <div class="info-arrow">
                    <ion-icon name="chevron-down-outline"></ion-icon>
                  </div>
                </div>

                <div class="details">
                  <div style="cursor: pointer" class="imagewrapper">
                    <div class="item-image">
                      <img
                        [alt]=""
                        src="assets/images/item-dummy.jpg"
                        class="result-img"
                      />
                      <div (click)="onFavClick(1)" class="fav">
                        <ion-icon name="heart-outline"></ion-icon>
                        <!--                      <ion-icon *ngIf="fav.includes(offer.id)" name="heart"></ion-icon>-->
                      </div>
                    </div>

                  </div>
                  <div class="item-info">
                    <div class="timewrapper">
                      <div class="time-info">
                        <ion-icon name="timer-outline"></ion-icon>
                        <div style="margin-left: 5px;">
                          <p>
                            {{ item.start_date | dateformat : false }}
                          </p>
                        </div>
                      </div>
                      <div class="time-info endtime">
                        <ion-icon name="timer"></ion-icon>
                        <div style="margin-left: 5px;">
                          <p>
                            {{ item.end_date | dateformat : false }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!isMobileScreen()" class="edit-duplicate-option">
                      <div (click)="jatakLiveOrderList(item.id)" class="order-button">
                        <ion-icon name="reorder-four-outline"></ion-icon>
                        <span style="margin-left: 10px">Ordreliste</span>
                      </div>
                      <div (click)="onJatakLiveEdit(item.id)" class="edit-duplicate-btn">
                        <ion-icon name="pencil" class="edit-duplicate-icon"></ion-icon>
                        <span class="edit-duplicate-text">Redigere</span>
                      </div>

                      <div (click)="onDelete(item.id)" class="edit-duplicate-btn">
                        <ion-icon name="duplicate" class="edit-duplicate-icon"></ion-icon>
                        <span class="edit-duplicate-text">Slet</span>
                      </div>
                    </div>
                    <div *ngIf="isMobileScreen()" class="order-list-button">
                      <div (click)="jatakLiveOrderList(item.id)" class="order-button">
                        Ordreliste
                      </div>
                      <div class="toggle-button" (click)="openEditDuplicate()">
                        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="edit-duplicate-option" *ngIf="showEditDuplicateBtn && isMobileScreen()">
                  <div (click)="onJatakLiveEdit(item.id)" class="edit-duplicate-btn">
                    <ion-icon name="pencil-outline" class="edit-duplicate-icon"></ion-icon>
                    <span class="edit-duplicate-text">Redigere</span>
                  </div>
                  <div (click)="onDelete(item.id)" class="edit-duplicate-btn">
                    <ion-icon name="duplicate" class="edit-duplicate-icon"></ion-icon>
                    <span class="edit-duplicate-text">Slet</span>
                  </div>
                </div>

              </div>
            </div>
          </ng-container>


        </div>

      </div>
    </div>


    <div (click)="onCreateNew(this.type)" class="create">
      <ion-icon name="add-outline"></ion-icon>
      <p>
        {{ createNew }}
      </p>
    </div>


    <ion-infinite-scroll (ionInfinite)="loadMore($event)" class="ion-margin-top">
      <ion-infinite-scroll-content loadingText="...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

  </section>

</ion-content>
