import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import { NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgxsModule } from "@ngxs/store";
import { CreateOptionsCompState } from "app/states";
import { environment } from 'environments/environment';
import {SetJatakRouteState, SetOrderListCompState} from "./features/jatak/states";
import { IonicModule } from '@ionic/angular';
import {FormStates} from "./store/states/form.state";

@NgModule({
  declarations: [AppComponent],
  imports: [
    MbscModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    IonicModule.forRoot({mode: 'ios', innerHTMLTemplatesEnabled : true, swipeBackEnabled: false}),
    NgxsModule.forRoot([CreateOptionsCompState , SetOrderListCompState, SetJatakRouteState, FormStates],{
      developmentMode: !environment.production
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
