<div
  class="mfl-text-container"
>


  <div (click)="onToggle()" class="title-wrapper">
    <div class="mfl-title">
      <ion-icon name="warning"></ion-icon>
      <div> Markedføringslov</div>
    </div>
    <div>
      <ion-icon name="chevron-up" *ngIf="showNotification" class="font-20" style="color: #5048F2;"></ion-icon>
      <ion-icon name="chevron-down" *ngIf="!showNotification" class="font-20" style="color: #5048F2;"></ion-icon>
<!--      <i *ngIf="!showNotification" class="icon icon-arrow-down"></i>-->
    </div>
  </div>
    <div *ngIf="showNotification" class="notification-wrapper" >
      <div class="separator"></div>
      <ul>
        <li>
          Du gerne må benytte tilbuds indikator som KUN, sparegris emojis mm,
          <span
          >Så længe det er et til oprigtigt tilbud, som overholder
            betingelserne.</span
          >
        </li>
        <li>
          Tilføje tilbudsperioden - sikre at den overholder reglerne for tilbud.
        </li>
      </ul>
      <div class="learnmore">
        <a
          class="learnmore-btn"
          href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring---servicekontor.aspx"
          target="_blank"
        >Læs mere her</a></div>
    </div>


<!--    <ng-container *ngIf="offerType === 'tilbud' || offerType === 'sms'">-->
<!--      <div *ngIf="isAccordion">-->
<!--        <div (click)="onNyhedToggle()" class="title-wrapper" >-->
<!--          <div class="title">-->
<!--            <i class="icon-danger" ></i>-->
<!--            <p> Markedføringslov</p>-->
<!--          </div>-->
<!--          <div  >-->
<!--            <i *ngIf="showNyhedNotification" class="icon icon-arrow-up" ></i>-->
<!--            <i *ngIf="!showNyhedNotification" class="icon icon-arrow-down" ></i>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div   [ngClass]="!showNyhedNotification ? 'hide' : 'show'"   >-->

<!--        </div>-->
<!--      </div>-->
<!--      <div *ngIf="!isAccordion" >-->
<!--        <p>Husk at:</p>-->
<!--        <ul>-->
<!--          <li>-->
<!--            du gerne må benytte tilbuds indikator som KUN, sparegris emojis mm,-->
<!--            <span style="color: #d10a11"-->
<!--            >så længe det er et til oprigtigt tilbud, som overholder-->
<!--            betingelserne.</span-->
<!--            >-->
<!--          </li>-->
<!--          <li>-->
<!--            tilføje tilbudsperioden - sikre at den overholder reglerne for tilbud.-->
<!--          </li>-->
<!--        </ul>-->
<!--        <a-->
<!--          href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
<!--          target="_blank"-->
<!--        >Læs mere her</a-->
<!--        >-->
<!--      </div>-->

<!--    </ng-container>-->

  <!--  <ng-container *ngIf="offerType === 'nyhed'">-->
  <!--    <div *ngIf="isAccordion" >-->
  <!--    <div (click)="onNyhedToggle()" class="title-wrapper" >-->
  <!--      <div class="title">-->
  <!--        <i class="icon-danger" ></i>-->
  <!--        <p> Markedføringslov</p>-->
  <!--      </div>-->
  <!--      <div  >-->
  <!--        <i *ngIf="showNyhedNotification" class="icon icon-arrow-up" ></i>-->
  <!--        <i *ngIf="!showNyhedNotification" class="icon icon-arrow-down" ></i>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div   [ngClass]="!showNyhedNotification ? 'hide' : 'show'"   >-->
  <!--      <div class="notification-wrapper" >-->
  <!--        <ul>-->
  <!--          <li>-->
  <!--            Husk at her kommunikeres der kampagner ud, som ikke har et prisbudskab.-->
  <!--          </li>-->
  <!--        </ul>-->
  <!--        <div class="learnmore">-->
  <!--          <a-->
  <!--            class="btn"-->
  <!--            href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
  <!--            target="_blank"-->
  <!--          >Læs mere her</a></div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!-- </div>-->

  <!--    <div *ngIf="!isAccordion" >-->
  <!--      <p>Husk</p>-->
  <!--      <ul>-->
  <!--        <li>-->
  <!--          Husk at her kommunikeres der kampagner ud, som ikke har et prisbudskab.-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--      <a-->
  <!--        href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
  <!--        target="_blank"-->
  <!--      >Læs mere her</a-->
  <!--      >-->
  <!--    </div>-->
  <!--  </ng-container>-->


  <!--  <ng-container *ngIf="offerType === 'jatak'">-->

  <!--      <div (click)="onToggle()" class="title-wrapper" >-->
  <!--        <div class="title">-->
  <!--          <i class="icon-danger" ></i>-->
  <!--          <p> Markedføringslov</p>-->
  <!--        </div>-->
  <!--        <div  >-->
  <!--          <i *ngIf="showNotification" class="icon icon-arrow-up" ></i>-->
  <!--          <i *ngIf="!showNotification" class="icon icon-arrow-down" ></i>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--    <div  [ngClass]="!showNotification ? 'hide' : 'show'" >-->
  <!--      <ul>-->
  <!--        <li>-->
  <!--          Du gerne må benytte tilbuds indikator som KUN, sparegris emojis mm,-->
  <!--          <span style="color: #d10a11"-->
  <!--          >så længe det er et til oprigtigt tilbud, som overholder-->
  <!--          betingelserne.</span-->
  <!--          >-->
  <!--        </li><br/>-->
  <!--        <li>-->
  <!--          Tilføje tilbudsperioden - sikre at den overholder reglerne for tilbud.-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--      <div class="learnmore">-->
  <!--        <a-->
  <!--          class="btn"-->
  <!--          href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
  <!--          target="_blank"-->
  <!--        >Læs mere her</a></div>-->

  <!--    </div>-->

  <!--  </ng-container>-->


  <!--  <ng-container *ngIf="offerType === 'jatak-live'">-->
  <!--    <p>Husk:</p>-->
  <!--    <ul>-->
  <!--      <li>rene priser og undgå sprog og emojis der kan indikere tilbud.</li>-->
  <!--      <li>de grundlæggende krav ifm. kommunikation af rene priser.</li>-->
  <!--    </ul>-->
  <!--    <a-->
  <!--      href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
  <!--      target="_blank"-->
  <!--      >Læs mere her</a-->
  <!--    >-->
  <!--  </ng-container>-->

  <!--  <ng-container *ngIf="offerType === 'jatak-order-list'">-->
  <!--    <p>-->
  <!--      Bemærk venligst, at Ja-tak robot nu sender ordrebekræftelserne i private-->
  <!--      Messenger-beskeder til hver kunde. Dette er grundet en ændring i-->
  <!--      Facebook-algoritmen, som nu blokerer robotkommentarerne.-->
  <!--    </p>-->
  <!--    <p>&nbsp;</p>-->
  <!--    <p>-->
  <!--      I de kommende uger vil vi forbedre oplevelsen med ordrelistens synlighed.-->
  <!--    </p>-->
  <!--    <p>&nbsp;</p>-->
  <!--  </ng-container>-->

  <!--  <ng-container *ngIf="offerType === 'jatak-new'">-->
  <!--    <p>-->
  <!--      Bemærk venligst, at Ja-tak robot nu sender ordrebekræftelserne i private-->
  <!--      Messenger-beskeder til hver kunde. Dette er grundet en ændring i-->
  <!--      Facebook-algoritmen, som nu blokerer robotkommentarerne.-->
  <!--    </p>-->
  <!--    <p>&nbsp;</p>-->
  <!--    <p>-->
  <!--      Butikkerne kan se alle ordrer i QuickCoop på ordrelisteskærmen for hver-->
  <!--      Ja-tak-kampagne. I de kommende uger vil vi forbedre oplevelsen med-->
  <!--      ordrelistens synlighed.-->
  <!--    </p>-->
  <!--  </ng-container>-->

  <!--  <ng-container *ngIf="offerType === 'web-dit'">-->
  <!--    <p>-->
  <!--      Din besked vil blive vist/opdateret med forsinkelse - det er normalt. I-->
  <!--      tilfælde af at det ikke bliver slået op inden for de næste 24 timer,-->
  <!--      kontakt Coop ServiceDesk.-->
  <!--    </p>-->
  <!--  </ng-container>-->
  <!--  <ng-container-->
  <!--    *ngIf="-->
  <!--      offerType !== 'tilbud' &&-->
  <!--      offerType !== 'jatak' &&-->
  <!--      offerType !== 'nyhed' &&-->
  <!--      offerType !== 'jatak-live' &&-->
  <!--      offerType !== 'sms' &&-->
  <!--      offerType !== 'jatak-new' &&-->
  <!--      offerType !== 'jatak-order-list' &&-->
  <!--      offerType !== 'web-dit'-->
  <!--    "-->
  <!--  >-->

  <!--    <div *ngIf="isAccordion">-->

  <!--      <div (click)="onNyhedToggle()" class="title-wrapper" >-->
  <!--        <div class="title">-->
  <!--          <i class="icon-danger" ></i>-->
  <!--          <p> Markedføringslov</p>-->
  <!--        </div>-->
  <!--        <div  >-->
  <!--          <i *ngIf="showNyhedNotification" class="icon icon-arrow-up" ></i>-->
  <!--          <i *ngIf="!showNyhedNotification" class="icon icon-arrow-down" ></i>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div   [ngClass]="!showNyhedNotification ? 'hide' : 'show'"   >-->
  <!--        <div class="notification-wrapper" >-->
  <!--          <ul>-->
  <!--            <li>rene priser og undgå sprog og emojis der kan indikere tilbud.</li>-->
  <!--            <li>de grundlæggende krav ifm. kommunikation af rene priser.</li>-->
  <!--          </ul>-->
  <!--          <div class="learnmore">-->
  <!--            <a-->
  <!--              class="btn"-->
  <!--              href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
  <!--              target="_blank"-->
  <!--            >Læs mere her</a></div>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--    </div>-->

  <!--    <div *ngIf="!isAccordion" >-->
  <!--      <p>Husk</p>-->
  <!--      <ul>-->
  <!--        <li>rene priser og undgå sprog og emojis der kan indikere tilbud.</li>-->
  <!--        <li>de grundlæggende krav ifm. kommunikation af rene priser.</li>-->
  <!--      </ul>-->
  <!--      <a-->
  <!--        href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
  <!--        target="_blank"-->
  <!--      >Læs mere her</a-->
  <!--      >-->
  <!--    </div>-->

  <!--  </ng-container>-->
</div>
