import { Injectable } from '@angular/core';
import { DatepickerData } from '../../shared/interface';

@Injectable({
  providedIn: 'root',
})
export class FormFunctionsService {
  // *** Checks whether the form is empty or not
  // @params [form]=>object of form value
  // @returns true if any of form value is missing
  static checkFormEmpty(form: object): boolean {
    let empty = true;
    if (Object.keys(form).length === 0) {
      return empty;
    }
    for (const key in form) {
      if (typeof form[key] === 'object') {
        for (const subKey in form[key]) {
          if (form[key][subKey] !== '' && form[key][subKey] !== null) {
            empty = false;
            break;
          }
        }
      } else {
        if (form[key] !== '' && form[key] !== null) {
          empty = false;
          break;
        }
      }
    }
    return empty;
  }

  //**discard the htmlContent
  //@params[htmlContent] html content
  //returns the replaced value
  static stripHTML(htmlContent: string): string {
    return htmlContent.replace(
      /(<([^>]+)>)|(&lt;([^&gt;]+)&gt;)|&nbsp;/gi,
      ` `
    );
  }

  //**replaces with html format
  //@params[htmlContent] htmlContent
  //returns htmlContent
  static stripHTMLMaintainLineBreak(htmlContent: string): string {
    // htmlContent = htmlContent.replace(/<br>/g, "$br$");
    // htmlContent = htmlContent.replace(/(?:\r\n|\r|\n)/g, "$br$");
    // const tmp = document.createElement("DIV");
    // tmp.innerHTML = htmlContent;
    // htmlContent = tmp.textContent || tmp.innerText;
    // htmlContent = htmlContent.replace(/\$br\$/g, "<br>").replace(/(<(br[^>]*)>)/ig, "\n");
    // tmp.remove();

    let strippedHtml = htmlContent.replace(/<\/p>|<\/ul>|<\/ol>/g, '\n');
    let plainText = strippedHtml
      .replace(/<\/p>|<\/li>/g, '\n')
      .replace(/<li[^>]*>/g, '')
      .replace(/<[^>]+>/g, '')
      .replace(/&nbsp;/g, '')
      .trim();

    return plainText;
  }

  //changing old coop pascal case keys to camel case
  static lowerCaseKeys(obj: any): any {
    if (typeof obj !== 'object') {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(this.lowerCaseKeys);
    }
    if (obj === null) {
      return null;
    }
    const entries = Object.entries(obj);
    const mappedEntries = entries.map(
      ([k, v]) =>
        [
          `${k.substring(0, 1).toLowerCase()}${k.substring(1)}`,
          FormFunctionsService.lowerCaseKeys(v),
        ] as const
    );
    return Object.fromEntries(mappedEntries);
  }

  //** check if parent form is empty
  //@params[parentForm] data from parent form i.e first selected channel filled data
  //return the boolean value depending upoun checkFormEmpty()
  static checkparentFormEmpty(parentForm): boolean {
    delete parentForm.isSet;
    return FormFunctionsService.checkFormEmpty(parentForm);
  }

  /**
   * Populates a date range picker with start and end date-time values.
   * @param picker - The datepicker data object to be populated.
   * @param start - The start date-time string.
   * @param end - The end date-time string.
   * @returns A new DatepickerData object with updated start and end date-time.
   */
  static populateDateRangePicker(
    picker: DatepickerData,
    start: string,
    end: string
  ): DatepickerData {
    return { ...picker, startEndDateTime: { start, end } };
  }
}
