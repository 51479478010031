<section class="app-page list-component page-with-fixed-header template-list">
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [showPrinter]="true"
      [isBackGroundWhite]="true"
      [isChild]="true"
      (printOrder)="onPrintOrder()"
      [routeTo]="previousUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <coop-order-list-header
      [title]="order.title"
      [startDate]="order.startDate"
      [endDate]="order.endDate"

    ></coop-order-list-header>
    <div *ngIf="order.variants.length" class="varaint-info">
      <div (click)="toggleVaraint()" class="title-wrapper">
        <div class="title">
          Solgte varianter
        </div>
        <div>
          <i *ngIf="!this.showVaraint" class="icon icon-arrow-down"></i>
          <i *ngIf="this.showVaraint" class="icon icon-arrow-up"></i>
        </div>
      </div>
      <hr [ngClass]="showVaraint ? 'show' : 'hide' "/>
      <div [ngClass]="showVaraint ? 'show' : 'hide' " class="varaint-list">
        <div *ngFor="let items of this.order.variants ; let i =index" class="list">
          <div class="name">
            #{{i + 1}} &nbsp; {{items.title}} <span>- {{items.keyword}}</span>
          </div>
          <div class="infos">
            {{items.sold}} / {{items.quantity}}
          </div>
        </div>
      </div>
    </div>

    <div class="hr"></div>

    <div class="section-wrapper">
      <div class="title">
        Alle ordrer
      </div>

      <div class="channel-list">
        <div class="cursor-pointer"
          [class.disabled]="!order.shared.FACEBOOK"
          [class.active]="tab === 0"
          (click)="tabSelect(0)">
          FB
          <span class="order-count" *ngIf="activeCustomers.fb">{{
            activeCustomers.fb }} </span>
        </div>
        <div class="cursor-pointer"
          [class.disabled]="!order.shared.COOP_APP"
          [class.active]="tab === 1"
          (click)="tabSelect(1)">
          Coop APP <span class="order-count" *ngIf="activeCustomers.coop">{{
          activeCustomers.coop }} </span>
        </div>
        <div class="cursor-pointer"
          [class.disabled]="!order.shared.SMS"
          [class.active]="tab === 2"
          (click)="tabSelect(2)">
          SMS <span class="order-count" *ngIf="activeCustomers.sms">{{
          activeCustomers.sms }} </span>
        </div>
      </div>

      <div class="oversight">
        <coop-order-list-oversight
          [summaryList]="summaryList"
          [tab]="tab"
          [shared]="order.shared"
        >
        </coop-order-list-oversight>
      </div>

      <div class="order-search">
        <coop-order-list-filter
          (search)="onSearch($event)"
          #orderListFilterComponent
        ></coop-order-list-filter>
      </div>

      <ng-container *ngIf="tab === 0">
        <coop-order-list-accordion-facebook
          [customerList]="customerList"
          [varaintList]="varaintList"
          [numbers]="numbers"
          [successReplyMessage]="successReplyMessage"
          (collectOrder)="onCollectOrder($event)"
          (cancelOrder)="onCancelOrder($event)"
          (approveOrder)="approve($event)"
          (orderQuantity)="orderQuanitity($event)"
          (rejectOrder)="reject($event)"
          (retryOrder)="retry($event)"
        ></coop-order-list-accordion-facebook>
      </ng-container>
      <ng-container *ngIf="tab !== 0">
        <coop-order-list-accordion
          (collectOrder)="onCollectOrder($event)"
          (cancelOrder)="onCancelOrder($event)"
          [customerList]="customerList"
        ></coop-order-list-accordion>
      </ng-container>

      <div>
        <h5 class="no-data" *ngIf="customerList.length === 0">
          Ingen ordre til rådighed
        </h5>
      </div>

    </div>


  </div>
</section>


