import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

import { DateFormatService, TokenService } from 'app/core/services';
import { fadeIn } from 'app/shared/animations';
import { NumberPad } from 'app/shared/pipes';
import { Modes, NyhedTilbud, PromoOptions } from 'app/features/offer';

@Component({
  selector: 'coop-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.scss'],
  animations: [fadeIn],
  providers: [NumberPad]
})
export class OfferPreviewComponent implements OnInit, OnChanges {
  @Input() tab: string;
  @Input() modes: Modes;
  @Input() formData: NyhedTilbud;
  @Input() eventData : any
  @Input() selectedPriceTag = 0;
  @Input() offerType ='GOD PRIS'
  @Output() showPreview: EventEmitter<boolean> = new EventEmitter<boolean>();
  previewValue: PromoOptions;
  facebookDetails = {
    name: '',
    profileImage: ''
  };
  shopName: string;
  previewDateText: string;
  coopTimeFormat: string;
  price = [];
  offerPrice = [];
  previewImgLoaded = false;
  previewPriceTagLoaded = false;
  previewCoopLogoLoaded = false;
  previewLoaded = false;
  isCoop365User = false;

  constructor(private _numberPad: NumberPad) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tab']) {
      this.tab = changes['tab'].currentValue;
    }
    if(changes['modes']){
      this.modes = changes['modes'].currentValue;
    }
    if(changes['offerType']){
      this.offerType = changes['offerType'].currentValue;
    }
  }

  ngOnInit(): void {
    console.log(this.eventData);
    console.log(this.formData);
    this.shopName = TokenService.getUserDetail().name;
    this.isCoop365User = TokenService.getUserDetail().coop365;
    if (this.tab === 'facebook') {
      const fb = TokenService.getUserDetail().facebook;
      if (fb.connected) {
        this.facebookDetails.name = fb.name;
        this.facebookDetails.profileImage = fb.profileImage;
      }
      if (!fb.connected) {
        this.facebookDetails.name = 'Your Page';
        this.facebookDetails.profileImage = 'assets/images/camera@3x.jpg';
      }
    }
    if (this.tab === 'dit') {
      this.previewCoopLogoLoaded = true;
    }
    if(this.tab !== 'event' ) {
      this._preparePreviewData(this.tab);
    }
    if (this.tab === 'event') {
      const startDate = this.eventData.startDate;
      this.coopTimeFormat = moment(startDate).format('LT');
    }

    console.log(this.formData);
  }

  closePreview() {
    this.showPreview.emit(false);
  }

  onClickOutside() {
    this.showPreview.emit(false);
  }

  onHandleClick(event) {
    event.stopPropagation();
  }

  formatDate(date: string) {
    return moment(date).format('d.m.Y');
  }

  previewImageLoaded() {
    this.previewImgLoaded = true;
    this.loadPreview();
  }

  previewTagLoaded() {
    this.previewPriceTagLoaded = true;
    this.loadPreview();
  }

  previewLogoLoaded() {
    this.previewCoopLogoLoaded = true;
    this.loadPreview();
  }

  loadPreview() {
    if (this.modes.newsMode) {
      this.previewPriceTagLoaded = true;
    }
    if (
      this.previewImgLoaded &&
      this.previewPriceTagLoaded &&
      this.previewCoopLogoLoaded
    ) {
      this.previewLoaded = true;
    }
  }

  private _preparePreviewData(type: string) {
    this.previewValue = JSON.parse(JSON.stringify(this.formData[this.tab]));
    this._setPreviewTitleDesc(
      this.previewValue.longDescription,
      this.previewValue.title,
      type
    );
    if (type === 'coop') {
      const startDate = this.previewValue.startDate;
      this.coopTimeFormat = moment(startDate).format('LT');
    }
    if (type === 'coop' || type === 'facebook') {
      this.previewDateText = DateFormatService.previewDateFormatting(
        this.previewValue.startDate,
        this.previewValue.endDate,
        type
      );
    }
    this._setPreviewImage(type);

    if (this.modes.newsMode) {
      // news mode have no price
      return;
    }
    if (type !== 'screen' && type !== 'dit') {
      this._setPreviewPrice();
    }
  }
  formatPrice(price:string){
    if (price.includes(",")) {
      return price;
    }
    return price + ",00";

  }

  private _setPreviewTitleDesc(longDescription, title, type) {
    if (longDescription.length > 100 && type !== 'screen' && type !== 'dit') {
      this.previewValue.longDescription =
        longDescription.substring(0, 100) + '...';
    }
    if (
      longDescription.length > 250 &&
      (type === 'screen' || type === 'dit') &&
      !this.modes.newsMode
    ) {
      this.previewValue.longDescription =
        longDescription.substring(0, 250) + '...';
    }
    if (
      longDescription.length > 400 &&
      (type === 'screen' || type === 'dit') &&
      this.modes.newsMode
    ) {
      this.previewValue.longDescription =
        longDescription.substring(0, 400) + '...';
    }
    if (title.length > 50 && (type === 'screen' || type === 'dit')) {
      this.previewValue.title = title.substring(0, 50) + '...';
    }
  }

  private _setPreviewImage(type: string) {
    this.previewValue.hasVideo = !!this.formData[type].video.thumbnail;
    if (type === 'coop') {
      this.previewValue.thumbnail = this.formData[type].video.thumbnail
        ? this.formData[type].video.thumbnail
        : this.formData[type].thumbnail;
    }
    if (type === 'facebook' || type === 'screen') {
      this.previewValue.largeImage = this.formData[type].video.thumbnail
        ? this.formData[type].video.thumbnail
        : this.formData[type].largeImage[0];
    }
    if (type === 'dit') {
      this.previewValue.largeImage = this.formData[type].largeImage;
    }
  }

  private _setPreviewPrice() {
    if (this.previewValue.inAppParameters.price === '') {
      this.previewValue.inAppParameters.price = '';
      this.previewValue.inAppParameters.priceDescription = '';
      return;
    }
    const priceDescription = this.previewValue.inAppParameters.priceDescription;
    if (priceDescription.length > 60) {
      this.previewValue.inAppParameters.priceDescription =
        priceDescription.substring(0, 60) + '...';
    }
    this.price = this.previewValue.inAppParameters.price.toString().split(',');
    this.offerPrice = this.previewValue.inAppParameters.offerPrice
      .toString()
      .split(',');
    if (this.price.length === 2) {
      this.price[1] = this.price[1].substring(0, 2);
    }
    if (this.price.length === 1) {
      this.price[1] = '00';
    }
    this.previewValue.inAppParameters.price = this._numberPad.transform(
      this.previewValue.inAppParameters.price,
      'da-DA'
    );
    if (this.offerPrice.length === 2) {
      this.offerPrice[1] = this.offerPrice[1].substring(0, 2);
    }
    if (this.offerPrice.length === 1) {
      this.offerPrice[1] = '00';
    }
    this.previewValue.inAppParameters.offerPrice = this._numberPad.transform(
      this.previewValue.inAppParameters.offerPrice,
      'da-DA'
    );
  }
}
