import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppEventsService, CreateOptionDataService, TokenService } from 'app/core/services';
import { CreateOption, PopupText, UserDetail } from 'app/shared/interface';
import { takeWhile } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { boardMenuData, CreateMenuItem, MenuData, storeMenuData } from 'app/shared/data';
import { Store } from '@ngxs/store';
import { SetCreateOptions } from 'app/states';

@Component({
  selector: 'coop-create-post-page',
  templateUrl: './create-post-page.component.html',
  styleUrls: ['./create-post-page.component.scss']
})
export class CreatePostPageComponent implements OnInit, OnDestroy {
  userType: string;
  userDetail: UserDetail;
  pageTitle: string = 'Opret selv';
  calendarPreviousUrl = '/';
  parentUrl: string;
  selectedOption: CreateOption;
  currentCalendarType = 'tilbud';
  postEventType: 'create' | 'template' = 'create';
  menuItems: CreateMenuItem[];
  selectedChannel: string = '';
  showDuplicateError: boolean = false;
  popupText: PopupText;

  private _subscriptionState: boolean = true;

  constructor(
    private createOptionService: CreateOptionDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private _appEvents:AppEventsService
  ) {
  }

  ngOnInit() {
    this.userType = TokenService.getUserType();
    this.userDetail = TokenService.getUserDetail();
    this.currentCalendarType = window.history.state.type;
    this.parentUrl = this.activatedRoute.snapshot.data['parentUrl'];

    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({ next: (res) => this.postEventType = res['type'] });

    this.createOptionService.currentSetting
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: (settings) => {
          if (settings) {
            this.selectedOption = settings;
          }
        }
      });

    this._init();

  }

  ngOnDestroy() {
    this._subscriptionState = false;
  }

  selectChannel(menuItem: CreateMenuItem) {
    if (!menuItem.hasSubMenu || this.postEventType === 'template') {
      this.routeToTarget(menuItem.type);
      return;
    }

    this.selectedChannel = this.selectedChannel === menuItem.type ? '' : menuItem.type;

    this.selectedOption.type = menuItem.type;
    if(this.selectedOption.type === 'tilbud' || this.selectedOption.type === 'tilbagevendende-tilbud' || this.selectedOption.type === 'redmad'){
      this._appEvents.scrollToBottom(true);

    }
  }

  updateStep(step: number) {
    this.selectedOption.step = step;
  }

  private _init() {
    this.selectedOption = {
      mode: this.postEventType === 'template' ? 'skabeloner' : 'skab',
      type: 'tilbud',
      share: {
        coop: true,
        facebook: true,
        sms: false,
        mail: false,
        web: false,
        screen: false,
        dit: false
      },
      step: this.currentCalendarType ? 3 : 1
    };
    this.assignMenuItems();

  }


  private assignMenuItems() {
    const menuData: MenuData = this.userType === '1' ? storeMenuData : boardMenuData;
    if (this.postEventType === 'create') {
      this.menuItems = menuData.create;
    }
    if (this.postEventType === 'template') {
      this.menuItems = menuData.template;
    }
  }

  private routeToTarget(type: string) {
    if (
      type === 'tilbud' ||
      type === 'nyheder' ||
      type === 'god-pris' ||
      type === 'tilbagevendende-tilbud'
    ) {
      this.router
        .navigate([
          this.parentUrl + '/indlaeg/' + type + '/' + this.selectedOption.mode
        ])
        .then(() => this._saveSelectedOptionState());
      return;
    }
    if (type === 'sms') {
      this.router.navigate([this.parentUrl + '/sms/sms-message/skab']).then(() => this._saveSelectedOptionState());
      return;
    }
    if (type === 'arsmode' || type === 'forsamling-event') {
      this.router
        .navigate([`${this.parentUrl}/arrangement/${type}/${this.selectedOption.mode}`])
        .then(() => this._saveSelectedOptionState());
      return;
    }
    if (type === 'special' || type === 'forsamling-poll') {
      this.router
        .navigate([`${this.parentUrl}/afstemninger/${type}/${this.selectedOption.mode}`])
        .then(() => this._saveSelectedOptionState());
      return;
    }
    if (type === 'ja-tak') {
      this.router
        .navigate([`${this.parentUrl}/${type}/${this.selectedOption.mode}`])
        .then(() => this._saveSelectedOptionState());
    }

    if (type === 'redmad') {
      this.router
        .navigate([`${this.parentUrl}/${type}/${this.selectedOption.mode}`])
        .then(() => this._saveSelectedOptionState());
    }
    this.router
      .navigate([`${this.parentUrl}/${type}/${this.selectedOption.mode}`])
      .then(() => this._saveSelectedOptionState());
  }

  private _saveSelectedOptionState() {
    this.store.dispatch(new SetCreateOptions(this.selectedOption));
  }


}
