import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { takeWhile } from 'rxjs/operators';
import * as XLSX from 'xlsx';

import {
  AppEventsService,
  ErrorhandlerService,
  NotificationsService,
  CreateOptionDataService,
  DateFormatService,
  HelperService
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate,
  checkPriceMaximum,
  checkPriceMinimum,
  checkPriceRegex,
  maxOrderGreaterThanZero,
  maxOrderLessThanTotal
} from 'app/core/validators/custom.validator';
import { DatepickerData, Modes } from 'app/shared/interface';
import {
  animateParent,
  fadeIn,
  slideAnimation,
  listAnimate,
  listItemFade
} from 'app/shared/animations';
import { COMMON_DATETIMEPICKER_SETTINGS, COMMON_SELECT_SETTINGS } from 'app/shared/data';
import { ValidationMessage } from 'app/shared/interface';
import { DateToString } from 'app/shared/pipes';
import {
  Jatak_Live_Validation_Messages,
  JatakLive,
  JatakLiveExcel,
  JatakLiveProduct,
  JatakLiveService
} from 'app/features/jatak-live';
import { MbscDatepickerOptions, MbscSelectOptions } from '@mobiscroll/angular';

@Component({
  selector: 'coop-jatak-live-create-edit',
  templateUrl: './jatak-live-create-edit.component.html',
  styleUrls: ['./jatak-live-create-edit.component.scss'],
  providers: [DateToString],
  animations: [
    animateParent,
    slideAnimation,
    listAnimate,
    listItemFade,
    fadeIn
  ]
})
export class JatakLiveCreateEditComponent implements OnInit, OnDestroy {
  @ViewChild('excelInput') excelInput: ElementRef;
  jatakLiveForm: FormGroup;
  jatakLive: JatakLive;
  numberOfProducts = 1;
  pageTitle: string = 'Opret Facebook Live kampagne';
  previousUrl = '/';
  showParent: string = 'visible';
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15
    }
  };
  amountQuantity: MbscSelectOptions = {
    ...COMMON_SELECT_SETTINGS,
    ...{
      data: [],
    },
  };
  limit: number = 1;
  editMode = false;
  templateMode = false;
  modes: Modes;
  isProcessing = false;
  formSaved = false;
  successUrl = '';
  parentUrl: string;
  routeId: string;
  origin: string;
  fromCalendar = false;
  currentUrl: string;
  showReport = false;
  initialImport = true;
  isLive = false;
  showMFLPopup = false;
  validationMessages: ValidationMessage = Jatak_Live_Validation_Messages;
  private _subscriptionState: boolean = true;
  startEndPickerData: DatepickerData;
  constructor(
    private _formBuilder: FormBuilder,
    private _errorHandlerService: ErrorhandlerService,
    private _jatakLiveService: JatakLiveService,
    private _activatedRoute: ActivatedRoute,
    private _createOptionSettings: CreateOptionDataService,
    private _router: Router,
    private _dateToString: DateToString,
    private _appEventService: AppEventsService
  ) {
  }

  ngOnInit() {
    JatakLiveCreateEditComponent._addOverlayContainer();
    this._initComponent();
    this._routeDataHandling();
    this._buildForm();
    this._setComponentType();
    this.startEndPickerData = {
      titles: {
        start: 'Gyldig fra - nu',
        end: 'Gyldig til',
      },
      startEndDateTime: {
        start: this.jatakLiveForm.get('startDate').value,
        end: this.jatakLiveForm.get('endDate').value,
      },
      disable: {
        start: false,
        end: false,
      },
      modes: {
        isEditMode: this.editMode,
        isTemplate: this.templateMode,
        isDuplicate: false,
      },
    };
  }

  ngOnDestroy(): void {
    this._subscriptionState = false;
    this._createOptionSettings.clearCreateOption();
    document.body.classList.remove('overlay');
  }

  initProduct(newFlag: number) {
    return this._formBuilder.group(
      {
        productId: [''],
        templateProductId: [''],
        productTitle: ['', Validators.required],
        productInfo: [''],
        maxOrder: [true],
        maxOrderCount: [1],
        price: [
          '',
          [
            Validators.required,
            checkPriceRegex,
            checkPriceMinimum,
            checkPriceMaximum
          ]
        ],
        quantity: ['', [Validators.required]],
        keyword: ['', [Validators.required]],//TODO: unique validator was using @rxweb/reactive-form-validators unique
        private: [true],
        new: [newFlag]
      },
      {
        validators: [maxOrderLessThanTotal, maxOrderGreaterThanZero]
      }
    );
  }

  addProduct(newFlag: number): void {
    const products = this.jatakLiveForm.controls['products'] as FormArray;
    products.push(this.initProduct(newFlag));
    this.numberOfProducts++;
    event.preventDefault();
  }

  removeProduct(index: number): void {
    const products = this.jatakLiveForm.controls['products'] as FormArray;
    products.removeAt(index);
    this.numberOfProducts--;
  }

  addItems(index: number) {
    const products = this.jatakLiveForm.controls['products'] as FormArray;
    const currentValue = products.controls[index].value.maxOrderCount;
    if (currentValue >= products.controls[index].value.quantity) {
      return;
    }
    products.controls[index].patchValue({
      maxOrderCount: currentValue + 1
    });
  }

  subtractItems(index: number) {
    const products = this.jatakLiveForm.controls['products'] as FormArray;
    const currentValue = products.controls[index].value.maxOrderCount;
    if (currentValue !== 1) {
      products.controls[index].patchValue({
        maxOrderCount: currentValue - 1
      });
    }
  }

  changeMaxOrderValidation(index: number) {
    const products = this.jatakLiveForm.controls['products'] as FormArray;
    const currentValue = products.controls[index].value.maxOrder;
    const validators = currentValue
      ? []
      : [Validators.required, Validators.min(1)];
    products.controls[index].get('maxOrderCount').setValue(1);
    products.controls[index].get('maxOrderCount').setValidators(validators);
    products.controls[index].get('maxOrderCount').updateValueAndValidity();
  }

  onOpenReporting(): void {
    this._appEventService.showLoader(true);
    this._router
      .navigate([
        `${this.parentUrl}/ja-tak-live/redigere/${this.routeId}/reporting`
      ])
      .then();
  }

  onFileChange(evt: any) {
    const target: DataTransfer = evt.target as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Multiple files not supported');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const excelData = this.formatExcelData(data);
      excelData.forEach((product) => {
        Object.keys(product).forEach((key) => {
          if (product[key] === undefined) {
            product[key] = null;
          }
        });
      });
      this._patchProductToForm(excelData);
    };
    reader.readAsBinaryString(target.files[0]);
  }

  formatExcelData(excelData: any) {
    const formKeys = excelData[0];
    const formValue = [];
    for (let i = 1; i < excelData.length; i++) {
      const val = {};
      for (let j = 0; j < formKeys.length; j++) {
        val[formKeys[j]] = excelData[i][j];
      }
      if (excelData[i].length) {
        formValue.push(val);
      }
    }
    return formValue;
  }

  exportExcelData() {
    if (this.jatakLiveForm.controls['products'].status === 'INVALID') {
      this._handleError({ description: 'Udfyld venligst alle felter' });
      return;
    }
    const productData = this.formatExportData();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(productData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Produkter');
    const max_width = productData.reduce(
      (w, r) => Math.max(w, r.productTitle.length),
      10
    );
    ws['!cols'] = [{ wch: max_width }];
    XLSX.writeFile(wb, 'Ja-tak-live.xlsx');
  }

  formatExportData(): JatakLiveExcel[] {
    let itemList = [];
    this.jatakLiveForm.controls['products'].value.forEach((item) => {
      const excelItem: JatakLiveExcel = {
        productTitle: item.productTitle,
        price: item.price,
        quantity: item.quantity,
        keyword: item.keyword,
        productInfo: item.productInfo
      };
      itemList = [...itemList, excelItem];
    });
    return itemList;
  }

  toggleShouldLike() {
    const shouldLike = this.jatakLiveForm.value.shouldLike;
    this.jatakLiveForm.patchValue({
      shouldLike: !shouldLike
    });
  }

  validateNumber(event) {
    HelperService.preventInvalidNumbers(event);
  }

  private _patchProductToForm(products: JatakLiveProduct[]) {
    const productsControl = this.jatakLiveForm.get('products');
    const newFlag = this.templateMode ? 1 : 0;
    let patchData;
    let startKey;
    this.initialImport = !this.editMode && !this.templateMode;
    if (
      !productsControl.dirty &&
      productsControl.untouched &&
      this.initialImport
    ) {
      patchData = products;
      startKey = productsControl['controls'].length;
      this.initialImport = false;
    } else {
      patchData = [...productsControl.value, ...products];
      startKey = 0;
    }
    for (let i = startKey; i < products.length; i++) {
      this.addProduct(newFlag);
    }
    this.jatakLiveForm.patchValue({ products: patchData });
  }

  onDelete(): void {
    NotificationsService.confirmWithCallback('Er de sikker?', () => {
      if (!this.isProcessing) {
        this.isProcessing = true;
        this._jatakLiveService
          .deleteJatakLive(this.jatakLive.id)
          .pipe(takeWhile(() => this._subscriptionState))
          .subscribe({
            next: () => this._onSuccess(),
            error: (err) => this._handleError(err)
          });
      }
    });
  }

  toggle(e, index): void {
    const privateToggle =
      this.jatakLiveForm.get('products').value[index]['Private'];
    this.jatakLiveForm.get('products')['controls'][index].patchValue({
      Private: !privateToggle
    });
  }

  save(): void {
    console.log(this.jatakLiveForm);
    if (!this._isValid()) {
      return;
    }
    if (!this.isProcessing) {
      this.jatakLiveForm.patchValue({
        startDate: this._dateToString.transform(
          this.jatakLiveForm.getRawValue().startDate
        ),
        endDate: this._dateToString.transform(
          this.jatakLiveForm.getRawValue().endDate
        )
      });
      if (this.templateMode) {
        this.jatakLiveForm.patchValue({
          template_id: this.routeId
        });
      }
      const jatakLiveFormValue =
        JatakLiveCreateEditComponent._prepareFormValues(
          this.jatakLiveForm.getRawValue()
        );
      this._jatakLiveService
        .saveJatakLive(jatakLiveFormValue, this.editMode)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (_) => this._onSuccess(),
          error: (err) => this._handleError(err)
        });
    }
  }

  back(): void {
    this._router.navigate([this.previousUrl]).then();
  }

  togglePopup(event) {
    this.showMFLPopup = event;
  }

  private static _addOverlayContainer(): void {
    document.body.classList.add('overlay');
  }

// *** It sets fields from resolver or urlParams
  private _initComponent() {
    this.jatakLive = this._activatedRoute.snapshot.data['jatakLive'];
    this.editMode = this._activatedRoute.snapshot.data['edit'];
    this.templateMode = this._activatedRoute.snapshot.data['template'];
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    this.fromCalendar = window.history.state.origin;
    while (this.limit <= 99) {
      this.amountQuantity.data.push(this.limit);
      this.limit++;
    }

  }

// checks if
  private _routeDataHandling(): void {
    this.currentUrl = this._router.url;
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
    this._activatedRoute.url
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((urlSegment) => {
        if (urlSegment.length > 1) {
          this.routeId = urlSegment[1].path;
        } else {
          this.routeId = urlSegment[0].path;
        }
      });
    this._router.events
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((routerEvent) => {
        if (routerEvent instanceof NavigationEnd) {
          this._checkChildPage();
        }
      });
    this._checkChildPage();
    if (this.templateMode) {
      this.parentUrl =
        this._activatedRoute.snapshot.parent.parent.data['parentUrl'];
    } else {
      this.parentUrl = this._activatedRoute.snapshot.parent.data['parentUrl'];
    }
    this.successUrl = this.parentUrl + '/kalendar/ja-tak-live';
  }

  private _buildForm(): void {
    this.jatakLiveForm = this._formBuilder.group(
      {
        id: [''],
        title: ['', Validators.required],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        products: this._formBuilder.array([this.initProduct(0)]),
        shouldLike: [true],
        template_id: null
      },
      {
        validators: [checkIfEndDateIsLessThanStartDate]
      }
    );
  }

  private static _prepareFormValues(fbCommentFormValue: JatakLive) {
    const noOfProducts = fbCommentFormValue.products.length;
    for (let i = 0; i < noOfProducts; i++) {
      fbCommentFormValue.products[i].maxOrder = fbCommentFormValue.products[i]
        .maxOrder
        ? 1
        : 0;
    }
    fbCommentFormValue.shouldLike = fbCommentFormValue.shouldLike ? 1 : 0;
    return fbCommentFormValue;
  }

  private _setComponentType(): void {
    this.editMode || this.templateMode
      ? this._onEditMode()
      : this._onCreateMode();
  }

  private _onEditMode(): void {
    if (this._activatedRoute.children.length > 0) {
      this.currentUrl = '/butikker/ja-tak-live/redigere/' + this.routeId;
    }
    if (this.editMode) {
      if (this.origin === 'list') {
        this.successUrl = this.currentUrl.replace(
          'redigere/' + this.routeId + '?origin=list',
          ''
        );
        this.previousUrl = this.successUrl;
      }
      if (this.origin !== 'list') {
        this.previousUrl = this.successUrl + '/' + this.routeId;
      }
    }
    if (this.templateMode) {
      if (this.currentUrl.indexOf('sog') > 0) {
        this.previousUrl = this.currentUrl.replace('/sog/' + this.routeId, '');
      }
      if (this.currentUrl.indexOf('sog') === -1) {
        this.previousUrl = this.currentUrl.replace('/' + this.routeId, '');
      }
    }
    this.pageTitle = this.templateMode
      ? this.pageTitle
      : 'Rediger Facebook Live kampagne';
    this.jatakLive.shouldLike = this.jatakLive.shouldLike === 1;
    const noOfProducts = this.jatakLive.products?.length;
    for (let i = 1; i < noOfProducts; i++) {
      this.addProduct(0);
      this.jatakLive.products[i].maxOrder =
        this.jatakLive.products[i].maxOrder === 1;
      if (!this.jatakLive.products[i].maxOrder) {
        this.jatakLive.products[i].maxOrderCount = 1;
      }
    }
    this.jatakLiveForm.patchValue(this.jatakLive);
    this.showReport = this.editMode;
    this.isLive = this.jatakLive.isLive;
    if (!this.jatakLive.isLive && moment().isBefore(this.jatakLive.startDate)) {
      this.showReport = false;
    }
  }

  private _onCreateMode(): void {
    this._setDefaultDate();
    this.previousUrl = this.parentUrl + '/skab';
    if (this.origin === 'list') {
      this.previousUrl = this.successUrl = this.parentUrl + '/ja-tak-live';
    }
    if (this.fromCalendar) {
      this.previousUrl = this.parentUrl + '/kalendar/ja-tak-live';
    }
  }

  private _setDefaultDate(): void {
    const startDate = DateFormatService.timeIntervalFormat(moment());
    const endDate = DateFormatService.timeIntervalFormat(
      moment().add(1, 'hour')
    );
    this.jatakLiveForm.patchValue({
      startDate: startDate,
      endDate: endDate
    });
  }

  private _isValid() {
    if (this.jatakLiveForm.status === 'INVALID') {
      const errorMessage = this._checkDuplicateKeyword()
        ? {
          code: '-422'
        }
        : '';
      this._handleError(errorMessage);
      return false;
    }
    return true;
  }

  private _checkDuplicateKeyword(): boolean {
    let hasDuplicationError = false;
    const products = this.jatakLiveForm.controls['products'] as FormArray;
    for (let i = 0; i < products.length; i++) {
      const keywordError = products.get(`${i}.keyword`).errors;
      if (keywordError) {
        hasDuplicationError = keywordError.hasOwnProperty('unique');
      }
    }
    return hasDuplicationError;
  }

  private _onSuccess(): void {
    this.formSaved = true;
    this.isProcessing = false;
    this._router.navigate([this.successUrl]).then();
  }

  // Check if the route is child route or parent route
  // if child route set isParentPage as false
  private _checkChildPage(): void {
    this.showParent = this._activatedRoute.children.length
      ? 'invisible'
      : 'visible';
  }
  getStartDateTime(event: any) {
    this.jatakLiveForm.patchValue(
      {
        startDate: event.startDateTime,
        endDate: event.endDateTime,
        startNow: event.start_now,
      },
      {
        onlySelf: true,
      }
    );
  }

  private _handleError(error) {
    this._errorHandlerService.handleError(
      error || { code: -400 },
      'ja-tak-live'
    );
    this.isProcessing = false;
  }
}
