<section class="app-page page-with-fixed-header-and-tab sms-single">

  <div class="fixed-header-bug-fix" id="fixed">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isBackGroundWhite]="true"
      [isChild]="true"
      [routeTo]="'/'"
    ></coop-pageheader>

    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="flex-display tab-container-wrap">
          <ul class="tab-list">
            <li class="tab-option active">SMS</li>
          </ul>
          <div>
            Ingen andre kanaler
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page-container">
    <div class="section-wrapper">
      <div class="title">
        SMS Besked
      </div>

      <div class="sub-title">
        Emoji-tegn vil automatisk blive fjernet fra SMS'en, fordi de ikke understøttes.
      </div>

    </div>


    <div class="wrapper">
      <coop-mfl-text [offerType]="'sms'"></coop-mfl-text>
    </div>

    <div class="form-container styled-two" style="padding: 15px 0">
      <form [formGroup]="textSmsForm" (submit)="save()">
        <div class="wrapper">
          <div class="row form-group" controlErrorContainer>
            <label for="message">
              <div class="char-msg">
                {{ smsCounter }} SMS <span style="font-weight: normal">({{
                charCount
                }}
                karakterer )</span>
              </div>
            </label>
            <div class="message-edit-area">
                    <textarea
                      id="message"
                      formControlName="message"
                      (keyup)=smsConvert($event)
                      [customErrors]="validationMessages['message']"
                      [checkParent]="true"
                    ></textarea>
              <span class="static-message">{{ staticMessage }}</span>
            </div>
            <p>
              {{ client.members }} medlemmer i klubben
              <strong>(total {{ sms.price }} kr.)</strong>
            </p>
          </div>
        </div>
        <div class="hr">
        </div>

        <div class="section-wrapper" style="padding-top: 0 !important;">
          <div class="title">
            Hvornår skal beskeden sendes
          </div>

          <div class="time-wrapper  border-15">
            <div class="flex-display">
              <div class="flex-display">
                <div>
                  <ion-icon name="timer-outline"></ion-icon>
                </div>
                <div class="dispatchtime">
                  Udsendingstidspunkt - nu
                </div>
              </div>
              <div>
                <label class="switch">
                  <input formControlName="startNow" (click)="toggleSMS($event)" type="checkbox">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>


            <div *ngIf="!onStartNow" style="margin-top: 10px">
              <div
                class="col-two form-group date-pick"
                controlErrorContainer
              >
                <mbsc-datepicker
                  #myDatepicker
                  (valueChange)="getDateTime($event)"
                  [controls]="['calendar']"
                  [showRangeLabels]="false"
                  formControlName="PublishDate"
                  display="inline"></mbsc-datepicker>
                <input
                  (onChange)="onStartTimeChange($event)"
                  mbsc-datepicker
                  #starttime
                  [controls]="['time']"
                  display="bottom"
                  formControlName="PublishDate"
                  [touchUi]="false" hidden/>
              </div>
              <div class="enddate">
                <div style="font-size: 14px">
                  <p>Send Date</p>
                </div>
                <div class="timewrapper">
                  <p>{{  formatDate(startDate) }}</p>
                  <div class="enddatetime" (click)="starttime.click()">
                    <span> {{ startTime }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-intro">
            <div class="wrapper">
              <p>
                Beskeden bliver afsendt den <strong>{{  formatPublishDate(publishedDate) }}</strong>
              </p>
            </div>
          </div>

          <div style="margin-top: 32px" class="wrapper">
            <div class="row form-submit-field">
              <input
                type="submit"
                [value]="submitButton"
                class="login-btn btn-black-login"
                *ngIf="!singleSms || singleSms.sent === '0'"
              />
              <a (click)="deleteSms()" class="login-btn btn-white-login" *ngIf="editMode"
              >Slet</a
              >
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>


</section>

<coop-mfl-popup
  *ngIf="showMFLPopup && !editMode"
  (showPopup)="togglePopup($event)"
></coop-mfl-popup>
