<section class="app-page page-with-fixed-header">

  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
    ></coop-pageheader>
  </div>

  <div class="page-container">
    <div class="greeting">Hej!</div>
    <div
      class="greeting-content"
      *ngIf="userType === '1'"> Opret selv eller benyt en eksisterende skabelon.
    </div>


    <div
      class="greeting-content"
      *ngIf="userType === '2'">Opret selv eller benyt <br/> en eksisterende skabelon.
    </div>


    <div *ngIf="userType === '1'" class="template-option">
      <div (click)="navigate('template')" class="templates-create" style="margin-right: 10px">
        <ion-icon name="folder" class="template-icon"></ion-icon>
        <p>Skabeloner</p>
      </div>
      <div (click)="navigate('create')" class="templates-create create-new">
        <ion-icon name="add-outline" style="font-size: 19px" class="template-icon"></ion-icon>
        <p>Opret selv</p>
      </div>
    </div>

    <div *ngIf="alert" class="notifications">
      <div class="notification-title">
        <div class="notification-title">
          <div>
            <ion-icon name="notifications-outline"></ion-icon>
          </div>
          <div style="font-size: 16px ; font-weight: 700; letter-spacing: -0.4px; line-height: 22px;margin-left: 5px">
            {{ alert?.title }}
          </div>
        </div>
        <div>
          <ion-icon name="close-circle" (click)="closeAlert()"></ion-icon>
        </div>
      </div>

      <div [innerHTML]="alert?.description | safehtml" class="message">
      </div>

<!--      <div class="message">-->
<!--        Læs mere om de forskellige opdateringer her:-->
<!--        <a-->
<!--          href="https://coopnetdk.intra.coop/Nyheder/Sider/Prismarkedsf%C3%B8ring-&#45;&#45;servicekontor.aspx"-->
<!--          target="_blank"-->
<!--        >🔗Opdatering i QuickCoop fra 10/6 (intra.coop)</a>-->
<!--      </div>-->

    </div>


    <div *ngIf="userType === '1'" class="result-dropdown">
      <div class="results-wrapper">
        <p
          class="result-count">{{ jatakList?.length }} ud af {{ this.totalActiveJatak }} aktive JA TAK</p>
        <p class="template-view-option" (click)="viewAllActiveJatak()">Se alle</p>

      </div>
<!--      <div class="response-hr"></div>-->
      <div *ngIf="jatakList?.length > 0">
        <div *ngFor="let item of jatakList; let last=last">
          <div class="result-list" (click)="viewJatakDetail(item.id)" [class.noborder]="last" >
            <div style="display: flex">
              <img
                [alt]=""
                (load)="onImageLoad($event)"
                [src]="item.image"
                class="result-img"
              />
              <div class="result-content">
                <p class="result-subtitle">{{ item.title }}</p>
                <div class="channel-list">
                  <div *ngIf="item.shared.coop" class="channel">
                    <ion-icon class="channel-icon" name="phone-portrait"></ion-icon>
                    <div class="channel-name">Coop App</div>
                  </div>
                  <div *ngIf="item.shared.facebook" class="channel">
                    <ion-icon class="channel-icon" name="logo-facebook"></ion-icon>
                    <div class="channel-name">Facebook</div>
                  </div>
                  <div *ngIf="item.shared.sms" class="channel">
                    <ion-icon class="channel-icon" name="chatbubble-ellipses"></ion-icon>
                    <div class="channel-name">SMS</div>
                  </div>
                  <div *ngIf="item.shared.web" class="channel">
                    <ion-icon class="channel-icon" name="globe"></ion-icon>
                    <div class="channel-name">Web</div>
                  </div>

                </div>
              </div>
            </div>
            <div style="width: 24px;">
              <ion-icon name="chevron-forward-outline" class="result-right-icon"></ion-icon>
            </div>
          </div>
<!--          <div *ngIf="!last" class="result-separator"></div>-->
        </div>

      </div>
      <div class="no-active-jatak" *ngIf="jatakList?.length === 0">
        <img src="assets/images/no-jatak-img.svg" alt="" class="no-active-jatak-img">
      </div>

    </div>

    <div *ngIf="userType === '1'" (click)="createJatak()" class="row" style="margin:25px 0">
      <a
        class="login-btn btn-white-login"
      >Opret JA TAK</a
      >
    </div>


    <ng-container *ngIf="userType === '2'">
      <div class="create-options ">
        <div class="flex-display option-list" (click)="navigate('create')">
          <div class="flex-display">
            <div>
              <ion-icon name="add-outline"></ion-icon>
            </div>
            <div style="margin-left: 10px">
              <div class="option-title">
                Opret
              </div>
              <span>
               Nyhed / afstemning /arrangement
             </span>
            </div>
          </div>
          <div>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>


        <div class="flex-display option-list" (click)="navigate('template')">
          <div class="flex-display">
            <div>
              <ion-icon name="folder-outline"></ion-icon>
            </div>
            <div style="margin-left: 10px">
              <div class="option-title">
                Benyt skabelon
              </div>
              <span>
             Nyhed / afstemning / årsmøde /generalforsamling
             </span>
            </div>
          </div>
          <div>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </div>
        </div>

      </div>
    </ng-container>


  </div>

</section>
