<section
  class="app-page jatak-live-create-edit page-with-fixed-header-and-tab"
  [@parentAnimate]="showParent"
>
  <div
    class="fixed-header-bug-fix"
    id="fixed"
    [class.remove-fixed-header]="showParent === 'invisible'"
  >
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isBackGroundWhite]="true"
      [isChild]="true"
      [routeTo]="previousUrl"
    ></coop-pageheader>
    <div class="fixed-header-content">
      <div class="tab-container">
        <div class="flex-display tab-container-wrap">
          <ul class="tab-list">
            <li class="tab-option active">
              Facebook
            </li>
          </ul>
          <div>
            Ingen andre kanaler
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page-container">
    <div class="form-container styled-two" *ngIf="jatakLiveForm">
      <form [formGroup]="jatakLiveForm" (submit)="save()">
        <div class="wrapper">
          <div class="fb">
            <div style="margin-top: 10px" class="section-wrapper">
              <div class="title">
                Post tekst
              </div>
              <coop-mfl-text [offerType]="'jatak-live'"></coop-mfl-text>
              <div class="row form-group" controlErrorContainer>
                <label for="fb-title" class="margin-bottom-8">Overskrift*</label>
                <input
                  id="fb-title"
                  type="text"
                  formControlName="title"
                  placeholder="Indsæt kampagnenavn"
                  [customErrors]="validationMessages['title']"
                />
              </div>
            </div>

            <div class="hr"></div>

            <div class="section-wrapper">
              <div class="flex-display">
                <div class="title">
                  Produkter
                </div>

                <div  *ngIf="!this.editMode">
                  <input
                    type="file"
                    #excelInput
                    (change)="onFileChange($event)"
                    (click)="excelInput.value = null"
                    hidden
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <div class="jatak-setting cursor-pointer">
                    <a
                      (click)="excelInput.click()"
                    >
                      Import fra excel
                    </a>
                  </div>
                </div>
<!--                <div class="jatak-setting cursor-pointer" >-->
<!--                  Import fra excel-->
<!--                </div>-->
              </div>


              <div>
                <div formArrayName="products" class="product-list" [@list]>
                  <div
                    *ngFor="
                    let product of jatakLiveForm.controls['products'][
                      'controls'
                    ];
                    let i = index
                  "
                    class="product"
                    [@items]
                  >
                    <div class="flex-display product-title-wrapper">
                      <div class="product-title">
                        Product {{ i + 1 }}
                      </div>
                      <div
                        class="close"
                        (click)="removeProduct(i)"
                        *ngIf="numberOfProducts > 1"
                      >
                        <ion-icon name="close-outline"></ion-icon>
                      </div>
                    </div>

                    <div class="product-wrapper">
                      <div
                        class="row form-group"
                        [formGroupName]="i"
                        controlErrorContainer
                      >
                        <label [for]="'product-title' + i" class="margin-bottom-8 product-info">Produkt overskrift</label>
                        <input
                          [id]="'product-title' + i"
                          type="text"
                          formControlName="productTitle"
                          placeholder="Indsæt produktnavn"
                          [customErrors]="validationMessages['productTitle']"
                        />
                      </div>

                       <div
                         class="row form-group unik-variant"
                         [formGroupName]="i"
                         controlErrorContainer
                       >
                        <div class="flex  width-100 justify-content-space-between">
                          <label [for]="'keyword' + i" class="margin-bottom-8 product-info">Unik variant søgeord</label>
                          <div class="tooltip-icon">
                            <span
                            tooltip="Kunden skal skrive ja tak [nøgleord] i kommentarfeltet under livevideoen for at bestille varen fx ja tak A"
                            placement="left"
                            delay="500">
                            <ion-icon name="information-circle" class="font-22" style="color: #C5C5C7"> </ion-icon>
                           </span>
                          </div>
                        </div>
                         <input
                           [id]="'keyword' + i"
                           type="text"
                           formControlName="keyword"
                           placeholder="unikke nøgleord"
                           [customErrors]="validationMessages['Keyword']"
                         />
                         <span class="jatak-prefix">JA TAK</span>
                       </div>
                      <div
                        class="row form-group"
                        [formGroupName]="i"
                        controlErrorContainer
                      >
                       <div class="flex width-100 justify-content-space-between">
                         <label [for]="'product-info' + i" class="margin-bottom-8 product-info">Produkt info (valgfrit)</label>
                         <div class="tooltip-icon">
                            <span
                              tooltip="Kunden modtager denne info i Messenger, når kunden skriver  info [nøgleord] i kommentarfeltet under livevideoen"
                              placement="left"
                              delay="500">
                            <ion-icon name="information-circle" class="font-22" style="color: #C5C5C7"> </ion-icon>
                           </span>
                         </div>
                       </div>
                        <input
                          [id]="'product-info' + i"
                          type="text"
                          formControlName="productInfo"
                          placeholder="Indtast produktinfo"
                          [customErrors]="validationMessages['productInfo']"
                        />
                      </div>
                      <div
                        class="row form-group"
                        [formGroupName]="i"
                        controlErrorContainer
                      >
                        <div class="flex width-100 justify-content-space-between">
                          <label [for]="'price' + i" class="margin-bottom-8 product-info">Pris pr. vare</label>
                        </div>
                        <input
                          [id]="'price' + i"
                          type="text"
                          formControlName="price"
                          placeholder="Pris ville ikke fremgå af indlægget"
                          (keydown)="validateNumber($event)"
                          [customErrors]="validationMessages['price']"
                        />
                      </div>
                      <div
                        class="row form-group"
                        [formGroupName]="i"
                        controlErrorContainer
                      >
                        <div class="flex width-100 justify-content-space-between">
                          <label [for]="'quantity' + i" class="margin-bottom-8 product-info">Antal</label>
                        </div>
                        <input
                          [id]="'quantity' + i"
                          type="number"
                          formControlName="quantity"
                          placeholder="Indtast lagerbeholdning, stk."
                          [customErrors]="validationMessages['quantity']"
                        />
                      </div>
                      <div class="fixed-quantity">
                        <div [formGroupName]="i" class = "flex justify-content-space-between">
                          <div class="product-info">Fast antal pr. ordre</div>
                          <label class="switch">
                            <input
                              type="checkbox"
                              formControlName="maxOrder"
                              (click)="changeMaxOrderValidation(i)"
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                        <div *ngIf="product.get('maxOrder').value" class="divider"></div>
                        <div [formGroupName]="i" *ngIf="product.get('maxOrder').value" class="flex justify-content-space-between align-item-center">
                          <div class="product-info" >Mængden vil altid være</div>
                          <div
                            class="limit mbsc-box"
                          >
                            <select
                              mbsc-select
                              [mbscOptions]="amountQuantity"
                              formControlName="maxOrderCount"
                              id="min-order"
                              [customErrors]="
                                    validationMessages['maxOrderCount']
                                  "
                              [checkParent]="true"
                              class="form-select"
                            >
                            </select>
                            <div class="dropdown-icon">
                              <ion-icon name="chevron-down" class="font-13"></ion-icon>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
<!--                    <div class="row">-->
<!--                                            <div-->
<!--                                              class="col-two form-group"-->
<!--                                              [formGroupName]="i"-->
<!--                                              controlErrorContainer-->
<!--                                            >-->
<!--                                              <label [for]="'price' + i">Pris</label>-->
<!--                                              <input-->
<!--                                                [id]="'price' + i"-->
<!--                                                type="text"-->
<!--                                                formControlName="price"-->
<!--                                                placeholder="Indtast pris"-->
<!--                                                (keydown)="validateNumber($event)"-->
<!--                                                [customErrors]="validationMessages['price']"-->
<!--                                              />-->
<!--                                            </div>-->
<!--                      </div>-->


                    <!--                    <div class="row">-->
                    <!--                      <div-->
                    <!--                        class="col-two form-group"-->
                    <!--                        [formGroupName]="i"-->
                    <!--                        controlErrorContainer-->
                    <!--                      >-->
                    <!--                        <label [for]="'price' + i">Pris</label>-->
                    <!--                        <input-->
                    <!--                          [id]="'price' + i"-->
                    <!--                          type="text"-->
                    <!--                          formControlName="price"-->
                    <!--                          placeholder="Indtast pris"-->
                    <!--                          (keydown)="validateNumber($event)"-->
                    <!--                          [customErrors]="validationMessages['price']"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                      <div-->
                    <!--                        class="col-two form-group"-->
                    <!--                        [formGroupName]="i"-->
                    <!--                        controlErrorContainer-->
                    <!--                      >-->
                    <!--                        <label [for]="'quantity' + i">Antal</label>-->
                    <!--                        <input-->
                    <!--                          [id]="'quantity' + i"-->
                    <!--                          type="number"-->
                    <!--                          formControlName="quantity"-->
                    <!--                          min="0"-->
                    <!--                          placeholder="Indtast lagerbeholdning, stk."-->
                    <!--                          [customErrors]="validationMessages['quantity']"-->
                    <!--                        />-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="push-container row">-->
                    <!--                      <div-->
                    <!--                        class="row form-group col-two switches"-->
                    <!--                        [formGroupName]="i"-->
                    <!--                      >-->
                    <!--                        <div>-->
                    <!--                          <h4 class="stk"></h4>-->
                    <!--                          <label class="switch">-->
                    <!--                            <input-->
                    <!--                              type="checkbox"-->
                    <!--                              formControlName="maxOrder"-->
                    <!--                              (click)="changeMaxOrderValidation(i)"-->
                    <!--                            />-->
                    <!--                            <span class="toggle round"></span>-->
                    <!--                          </label>-->
                    <!--                        </div>-->
                    <!--                        <label-->
                    <!--                        >Fast antal pr. ordre.<span-->
                    <!--                          style="font-weight: 400"-->
                    <!--                          *ngIf="product.get('maxOrder').value"-->
                    <!--                        >-->
                    <!--                          Mængden vil altid være:</span-->
                    <!--                        ></label-->
                    <!--                        >-->
                    <!--                      </div>-->
                    <!--                      <div-->
                    <!--                        class="col-two"-->
                    <!--                        [@fade]="'in'"-->
                    <!--                        *ngIf="product.get('maxOrder').value"-->
                    <!--                      >-->
                    <!--                        <div class="row form-group" [formGroupName]="i">-->
                    <!--                          <div class="max-order-form-group">-->
                    <!--                            <div class="max-order-input">-->
                    <!--                              <div class="max-order-picker">-->
                    <!--                                <a class="flex-item" (click)="subtractItems(i)">-->
                    <!--                                  <img src="assets/icons/minus-icon-green.png"-->
                    <!--                                       alt=""-->
                    <!--                                  />-->
                    <!--                                </a>-->
                    <!--                                <div class="max-order flex-item maxOrderCount">-->
                    <!--                                  <input-->
                    <!--                                    type="number"-->
                    <!--                                    id="min-order"-->
                    <!--                                    formControlName="maxOrderCount"-->
                    <!--                                    readonly-->
                    <!--                                    [customErrors]="-->
                    <!--                                    validationMessages['maxOrderCount']-->
                    <!--                                  "-->
                    <!--                                    [checkParent]="true"-->
                    <!--                                  />-->
                    <!--                                </div>-->
                    <!--                                <a class="flex-item" (click)="addItems(i)">-->
                    <!--                                  <img src="assets/icons/plus-icon-green.png"-->
                    <!--                                       alt=""-->
                    <!--                                  />-->
                    <!--                                </a>-->
                    <!--                              </div>-->
                    <!--                            </div>-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <!--                    <div-->
                    <!--                      class="row form-group"-->
                    <!--                      [formGroupName]="i"-->
                    <!--                      controlErrorContainer-->
                    <!--                    >-->
                    <!--                      <label [for]="'keyword' + i"-->
                    <!--                      >Nøgleord<br/><span style="font-weight: 400"-->
                    <!--                      >Kunden skal skrive-->
                    <!--                        <span style="font-weight: 700">ja tak [nøgleord]</span>-->
                    <!--                        i kommentarfeltet under livevideoen for at bestille-->
                    <!--                        varen fx-->
                    <!--                        <span style="font-weight: 700">ja tak 1A</span></span-->
                    <!--                      ></label-->
                    <!--                      >-->
                    <!--                      <coop-input-mask-->
                    <!--                        [id]="'keyword' + i"-->
                    <!--                        formControlName="keyword"-->
                    <!--                        [maxOrder]="product.get('maxOrder').value"-->
                    <!--                        [customErrors]="validationMessages['Keyword']"-->
                    <!--                      ></coop-input-mask>-->
                    <!--                    </div>-->
                    <!--                    <div-->
                    <!--                      class="row form-group"-->
                    <!--                      [formGroupName]="i"-->
                    <!--                      controlErrorContainer-->
                    <!--                    >-->
                    <!--                      <label [for]="'product-info' + i"-->
                    <!--                      >Produkt info (valgfrit)<br/><span-->
                    <!--                        style="font-weight: 400"-->
                    <!--                      >Kunden modtager denne info i Messenger, når kunden-->
                    <!--                        skriver-->
                    <!--                        <span style="font-weight: 700">info [nøgleord]</span> i-->
                    <!--                        kommentarfeltet under livevideoen.</span-->
                    <!--                      >-->
                    <!--                      </label>-->
                    <!--                      <textarea-->
                    <!--                        [id]="'product-info' + i"-->
                    <!--                        formControlName="productInfo"-->
                    <!--                        placeholder="Indtast produktinfo"-->
                    <!--                        [customErrors]="validationMessages['productInfo']"-->
                    <!--                      ></textarea>-->
                    <!--                    </div>-->
                  </div>
                  <!--                  <div class="row my-7" *ngIf="!isLive">-->
                  <!--                    <div class="col-two">-->
                  <!--                      <a-->
                  <!--                        (click)="addProduct(templateMode ? 1 : 0)"-->
                  <!--                        class="btn btn-red add-product-btn"-->
                  <!--                      >-->
                  <!--                        Tilføj produkt-->
                  <!--                      </a>-->
                  <!--                    </div>-->
                  <!--                    <div class="import col-two" *ngIf="!this.editMode">-->
                  <!--                      <input-->
                  <!--                        type="file"-->
                  <!--                        #excelInput-->
                  <!--                        (change)="onFileChange($event)"-->
                  <!--                        (click)="excelInput.value = null"-->
                  <!--                        hidden-->
                  <!--                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"-->
                  <!--                      />-->
                  <!--                      <div class="import-btn">-->
                  <!--                        <a-->
                  <!--                          (click)="excelInput.click()"-->
                  <!--                          class="btn btn-border-red"-->
                  <!--                        >-->
                  <!--                          <i class="ti-export" style="margin-right: 5px"></i>-->
                  <!--                          Importer fra Excel-->
                  <!--                        </a>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div>
                <div class="row form-submit-field">
<!--                  <input type="submit" value="Tilføj produkt" class="login-btn btn-black-login"/>-->
                  <button  (click)="addProduct(templateMode ? 1 : 0)" class="login-btn btn-black-login justify-content-center align-item-center">
                    <ion-icon name="add-circle" class="margin-right-10" style="color:#FFFFFF"></ion-icon>
                    Tilføj produkt
                  </button>
                  <div  *ngIf="!this.editMode" style="width: 100%">
                    <input
                      type="file"
                      #excelInput
                      (change)="onFileChange($event)"
                      (click)="excelInput.value = null"
                      hidden
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    <div class="login-btn btn-white-login justify-content-center">
                      <a
                        class="flex align-item-center"
                        (click)="excelInput.click()"
                      >
                        <ion-icon name="document-text" class="margin-right-10" style="color:#000000"></ion-icon>
                        Import fra excel
                      </a>
                    </div>
                  </div>
<!--                  <button  (click)="excelInput.click()" class="login-btn btn-white-login justify-content-center align-item-center">-->
<!--                    <ion-icon name="document-text" class="margin-right-10" style="color:#000000"></ion-icon>-->
<!--                    Importer fra Excel-->
<!--                  </button>-->
                </div>
              </div>


            </div>
            <div class="hr"></div>

            <div >
              <div class="title">Komentarer</div>
              <div class="flex commenter justify-content-center align-item-center">
                <div class="product-info">'Like' automatisk kommentarer</div>
                <label class="switch">
                  <input
                    type="checkbox"
                    formControlName="shouldLike"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="margin-top-15 sub-text">SLÅ TIL for at robotten automatisk liker alle ja-tak kommentarer i kampagneperioden, når ordren er registreret af robotten</div>
            </div>
            <div class="hr"></div>
            <div>
              <div class="title">Planlægning</div>
              <div class="sub-title">Kampagneperiode</div>
              <div class="sub-text">
                Vælg <span>start</span>- og <span>sluttidspunkt</span> for din FB Live kampagne inkl. deadline for reservationer. Livevideoen på Facebook skal starte efter <span>Gyldig fra</span> og slutte før <span>Gyldig til</span>.<br/><br/>
                I den valgte periode vil ja-tak robotten være tilknyttet kampagnen og svare kunder,
                der skriver i livevideoens kommentarfelt på Facebook.<br/><br/>
                Efter <span>Gyldig til</span> svarer robotten ikke længere på kommentarer.
              </div>
            </div>
            <div class="form-group margin-top-15" controlErrorContainer>
              <coop-date-time-range-picker
                [datePickerData]="startEndPickerData"
                [templateMode]="this.templateMode"
                (onDateTimeChange)="getStartDateTime($event)"
              ></coop-date-time-range-picker>
            </div>


            <!--            <div class="row">-->
            <!--              <h2 class="form-subtitle">Kampagneperiode</h2>-->
            <!--              <p class="live-date-info">-->
            <!--                Vælg <b>start-</b> og <b>sluttidspunkt</b> for din FB Live-->
            <!--                kampagne inkl. deadline for reservationer. Livevideoen på-->
            <!--                Facebook skal starte efter <b><i>Gyldig fra</i></b> og slutte-->
            <!--                før <b><i>Gyldig til.</i></b>-->
            <!--              </p>-->
            <!--              <p class="live-date-info">-->
            <!--                I den valgte periode vil ja-tak robotten være tilknyttet-->
            <!--                kampagnen og svare kunder, der skriver i livevideoens-->
            <!--                kommentarfelt på Facebook.-->
            <!--              </p>-->
            <!--              <p class="live-date-info">-->
            <!--                Efter <b><i>Gyldig til</i></b> svarer robotten ikke længere på-->
            <!--                kommentarer.-->
            <!--              </p>-->
            <!--              <div class="col-two form-group" controlErrorContainer>-->
            <!--                <label>Gyldig fra</label>-->
            <!--                <div class="date-time-picker">-->
            <!--                  <input-->
            <!--                    id="startscreen"-->
            <!--                    mbsc-datepicker-->
            <!--                    [mbscOptions]="datePickerSettings"-->
            <!--                    formControlName="startDate"-->
            <!--                    type="text"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="col-two form-group" controlErrorContainer>-->
            <!--                <label>Gyldig til</label>-->
            <!--                <div class="date-time-picker">-->
            <!--                  <input-->
            <!--                    id="endscreen"-->
            <!--                    type="text"-->
            <!--                    mbsc-datepicker-->
            <!--                    [mbscOptions]="datePickerSettings"-->
            <!--                    formControlName="endDate"-->
            <!--                    [customErrors]="validationMessages['endDate']"-->
            <!--                  />-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="push-container automatic-like-toggle">-->
            <!--              <div class="row form-group switches">-->
            <!--                <label><br/>'Like' automatisk kommentarer</label>-->
            <!--                <div>-->
            <!--                  <h4 class="stk mt-4"></h4>-->
            <!--                  <label class="switch">-->
            <!--                    <input-->
            <!--                      type="checkbox"-->
            <!--                      formControlName="shouldLike"-->
            <!--                      (click)="toggleShouldLike()"-->
            <!--                    />-->
            <!--                    <span class="toggle round"></span>-->
            <!--                  </label>-->
            <!--                </div>-->
            <!--                <p style="margin-bottom: 0">-->
            <!--                  SLÅ TIL for at robotten automatisk liker alle ja-tak-->
            <!--                  kommentarer i kampagneperioden, når ordren er registreret af-->
            <!--                  robotten-->
            <!--                </p>-->
            <!--              </div>-->
            <!--            </div>-->
           <div class="btn-wrapper">
             <div class="row form-submit-field">
               <input type="submit" value="Aktivér" class="login-btn btn-black-login"/>
             </div>
             <div class="row">
               <a (click)="exportExcelData()" class="login-btn btn-white-login justify-content-center">
<!--                 <i class="ti-download" style="margin-right: 5px"></i>-->
                 Download Excel liste
               </a>
             </div>
             <div class="row">
               <a
                 (click)="onOpenReporting()"
                 class="login-btn btn-white-login "
                 *ngIf="this.showReport"
               >
                 Vis rapport</a
               >
             </div>
             <div class="row">
               <a
                 (click)="onDelete()"
                 class="login-btn btn-white-login justify-content-center"
                 *ngIf="this.editMode && !jatakLive.isLive"
               >
                 Slet</a
               >
             </div>
           </div>
            <!--            <div class="row">-->
            <!--              <a (click)="back()" class="btn btn-border-red btn-delete"-->
            <!--                >Tilbage</a-->
            <!--              >-->
            <!--            </div>-->
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<coop-mfl-popup
  *ngIf="showMFLPopup && !editMode"
  (showPopup)="togglePopup($event)"
></coop-mfl-popup>
<router-outlet></router-outlet>
