import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { finalize, take, takeWhile } from 'rxjs/operators';

import {
  AllowedChannelValue,
  OrderTab,
  SharedNames,
} from 'app/core/data/defaultValues';
import {
  AppEventsService,
  ChannelDataService,
  CreateOptionDataService,
  ErrorhandlerService,
  NotificationsService,
  TokenService,
} from 'app/core/services';
import { animateParent, slideAnimation } from 'app/shared/animations';
import {
  ChannelSelectionModes,
  CreateOption,
  Modes,
  SelectedChannels,
  Shared,
  ValidationMessage,
} from 'app/shared/interface';
import { DateToString } from 'app/shared/pipes';
import {
  EmailFormComponent,
  ScreenFormComponent,
  SmsFormComponent,
  INITIAL_FORM_DATA,
  Offer_Validation_Messages,
  OfferService,
  OfferStateService,
  TemplateService,
  NyhedTilbud,
  Promo,
  PromoTemplate,
  ScreenList,
} from 'app/features/offer';
import { ColorThiefService } from '../../../../core/services';
import { IonContent } from '@ionic/angular';
import { duration } from 'moment';

declare let ColorThief: any;

@Component({
  selector: 'coop-good-price-form',
  templateUrl: './good-price-form.component.html',
  styleUrls: ['./good-price-form.component.scss'],
  animations: [animateParent, slideAnimation],
  providers: [DateToString],
})
export class GoodPriceFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(EmailFormComponent) emailFormComp: EmailFormComponent;
  @ViewChild(SmsFormComponent) smsFormComp: SmsFormComponent;
  @ViewChild(ScreenFormComponent) screenFormComp: ScreenFormComponent;
  @Input() formData?: NyhedTilbud;
  @Input() editMode = false;
  @Input() templateMode = false;
  templateParam: NyhedTilbud;
  showParent = 'visible';
  tab = 'coop';
  orderTab = OrderTab;
  pageTitle = 'Opret God pris';
  previousUrl = '';
  promoForm: FormGroup;
  tabName = SharedNames;
  validateModes = [];
  tempValidateModes = [];
  nextTabArray = [];
  currentTabIndex = 0;
  shared: Shared = {
    coop: false,
    facebook: false,
    sms: false,
    mail: false,
    web: false,
    screen: false,
    dit: false,
  };
  tempShared: Shared;
  createOption: Shared;
  selectedChannels: SelectedChannels;
  createSettings: CreateOption;
  channelSelectionModes: ChannelSelectionModes;
  allowedChannels: Shared = AllowedChannelValue;
  showChannelSelection = false;
  tempTitle: string;
  modes: Modes;
  emojiError = false;
  showEmojiError = false;
  isValidityChecked = false;
  isProcessing = false;
  userType: string;
  availablePushCount: number;
  guid: string = '';
  origin: string;
  shopName: string;
  currentUrl: string;
  parentUrl: string;
  routeId: string;
  parentId: string;
  validationMessages: ValidationMessage = Offer_Validation_Messages;
  showPreview = false;
  submitForm = false;
  addPromoFlag = false;
  ownTemplate = false;
  formSaved = false;
  isCoop365User = false;
  fromTabChange = false;
  nextTab = 'coop';
  initScreenList: ScreenList[] = [];
  showMFLPopup = false;
  showPricePopup = false;
  saveWithNoPrice = false;
  offertype = "GOD PRIS"
  private _subscriptionState = true;

  constructor(
    private _formBuilder: FormBuilder,
    private _dateToString: DateToString,
    private _formStateService: OfferStateService,
    private _createOptionService: CreateOptionDataService,
    private _channelDataService: ChannelDataService,
    private _offerService: OfferService,
    private _templateService: TemplateService,
    private _errorHandlerService: ErrorhandlerService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _appEvents: AppEventsService
  ) {}

  private static s4(): string {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  ngOnInit() {
    document.body.classList.add('overlay');
    this._initComponent();
    this._setUrl();
    this._setupGoodPriceForm();
    if (this.editMode || this.templateMode) {
      this._editModeSetup();
    } else {
      this._createModeSetup();
    }
  }

  ngAfterViewInit(): void {
    // GoodPriceFormComponent._setPaddingTop();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // changing top padding on resize
    // GoodPriceFormComponent._setPaddingTop();
  }

  ngOnDestroy() {
    this._formStateService.setFormState(INITIAL_FORM_DATA);
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  addChildForm(name: string, group: FormGroup) {
    this.promoForm.addControl(name, group);
  }

  checkEmojiError(event) {
    this.emojiError = event;
  }

  onTabChange(tab: string) {
    this.nextTab = tab;
    this.fromTabChange = true;
    document.getElementById('next-tab').click();
  }

  shareOptionSelect(tab: string) {
    this.onFormSubmit();
    if (!this.shared[tab] || !this._isValid(this._getForm(this.tab))) {
      this.nextTab = this.tab;
      return;
    }
    this.tab = tab;
    this.nextTab = tab;
    if (this.editMode || this.templateMode) {
      this._formStateService.setFormState({
        ...this.formData,
        ...this.promoForm.value,
      });
    } else {
      if (this.promoForm.value[this.tab]) {
        this.promoForm.value[this.tab]['isSet'] = true;
      }
      this._formStateService.setFormState({
        ...this.promoForm.value,
        isSet: true,
      });
    }
    this.currentTabIndex = this.validateModes.indexOf(tab);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  toggleChannelSelection() {
    if (!this.showChannelSelection) {
      this.tempValidateModes = this._setUpValidModes(this.shared);
      this.tempTitle = this.pageTitle;
      this.pageTitle = 'God Pris - Vælg kanaler';
    }
    if (this.showChannelSelection) {
      this.pageTitle = this.tempTitle;
    }
    this.showChannelSelection = !this.showChannelSelection;
  }

  // ** toggle for showing preview
  openPreview() {
    this.onFormSubmit();
    if (!this._isValid(this._getForm(this.tab))) {
      return;
    }
    this.showPreview = true;
  }

  closePreview(preview: boolean) {
    this.showPreview = preview;
  }

  updateChannelState(channelState: Shared): void {
    this.tempShared = channelState;
    this.tempValidateModes = this._setUpValidModes(channelState);
  }

  confirmedShareOptions(confirmShares: Shared) {
    if (this.shared !== confirmShares) {
      this.shared = JSON.parse(JSON.stringify(confirmShares));
      this._setTabAndValidationModes(confirmShares);
      // GoodPriceFormComponent._setPaddingTop();
      this.showChannelSelection = false;
      this.pageTitle = this.tempTitle;
    }
  }

  onPrevTab(tab: string) {
    this._appEvents.scrollToTop(true);
    this.fromTabChange = false;
    this.shareOptionSelect(tab);
  }

  onNextTab(tab: string) {
    this._appEvents.scrollToTop(true);
    this.fromTabChange = false;
    this.shareOptionSelect(tab);
  }

  getTabIndex(currentTab: string, direction: 'next' | 'prev'): string {
    const currentIndex = this.nextTabArray.indexOf(currentTab);
    let newIndex;
    if (direction === 'next') {
      newIndex =
        currentIndex + 1 < this.nextTabArray.length
          ? currentIndex + 1
          : currentIndex;
    } else {
      newIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : currentIndex;
    }
    return this.nextTabArray[newIndex];
  }

  back() {
    this._router.navigate([this.previousUrl]).then();
  }

  deleteChannelOnCreate(tab: string) {
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback('Er du sikker?', (accept) => {
        if (accept) {
          this.isProcessing = false;
          this.shared[tab] = false;
          this._patchSharedToForm();
          this._setTabAndValidationModes(this.shared);
        } else {
          this.isProcessing = false;
        }
      });
    }
  }

  deleteChannel(tab: string) {
    let notification = 'Er du sikker?';
    if (this.tab === 'sms') {
      if (this.formData.id === this.formData.offerGroup) {
        notification =
          'Hvis du sletter dette, bliver sms-fanen i andre linkede god pris, hvis der findes, også slettet. Er du sikker?';
      }
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback(notification, (accept) => {
        if (accept) {
          this._deletePromoChannel(tab, this.formData.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => {
                this.isProcessing = false;
                NotificationsService.notify(
                  'Kanalen blev slettet!',
                  'success',
                  'top'
                );
                this._refreshPage();
              },
              error: (err) => this._handleError(err),
            });
        } else {
          this.isProcessing = false;
        }
      });
    }
  }

  deletePromo() {
    let notification = 'Er du sikker?';
    if (this.tab === 'sms') {
      if (this.formData.id === this.formData.offerGroup) {
        notification =
          'Hvis du sletter dette, bliver sms-fanen i andre linkede god pris, hvis der findes, også slettet. Er du sikker?';
      }
    }
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback(notification, (accept) => {
        if (accept) {
          this._offerService
            .deletePromo(this.formData.id)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => this._success(),
              error: (err) => this._handleError(err),
            });
        } else {
          this.isProcessing = false;
        }
      });
    }
  }

  saveTemplate(edit: boolean) {
    let mailForm;
    let smsForm;
    mailForm = this._getForm('mail');
    smsForm = this._getForm('sms');
    // to bypass validation for saving template
    if (this.shared['mail']) {
      mailForm.get('mailId').setValidators(null);
      mailForm.get('mailId').updateValueAndValidity();
    }
    if (!this._isFullFormValid()) {
      return;
    }
    // resetting the validators
    if (this.shared['mail']) {
      mailForm.get('mailId').setValidators(Validators.required);
      mailForm.get('mailId').updateValueAndValidity();
    }
    if (this.tab === 'sms') {
      if (this.smsFormComp.showSmsTextSection) {
        smsForm.patchValue({
          smsText: this.smsFormComp.sendSmsForm.getSmsText(),
        });
      }
    }
    const templateData = this.createTemplateData();
    if (!edit) {
      this.saveTemplateData(templateData);
    }
    if (edit) {
      this.updateTemplateData(templateData);
    }
  }

  togglePopup(event) {
    this.showMFLPopup = event;
  }

  togglePricePopup(event) {
    this.showPricePopup = event;
    this.saveWithNoPrice = false;
  }

  saveWithoutPrice(event) {
    this.saveWithNoPrice = event;
    this.save();
  }

  createTemplateData(): PromoTemplate {
    let boardVal;
    this.templateParam = JSON.parse(
      JSON.stringify(this._prepareFormValues(this.promoForm))
    );
    // Change back the Dates to string in Format 'YYYY-MM-DD hh:mm'
    this._prepareTemplateData();
    boardVal = 0;
    return {
      shared: this.shared,
      coop: this.shared['coop'] ? this.templateParam['coop'] : null,
      facebook: this.shared['facebook'] ? this.templateParam['facebook'] : null,
      sms: this.shared['sms'] ? this.templateParam['sms'] : null,
      mail: this.shared['mail'] ? this.templateParam['mail'] : null,
      web: this.shared['web'] ? this.templateParam['web'] : null,
      screen: this.shared['screen'] ? this.templateParam['screen'] : null,
      dit: this.shared['dit'] ? this.templateParam['dit'] : null,
      kardex: TokenService.getUserCode(),
      active: '1',
      board: boardVal,
      activeCount: '0',
      type: '3',
    };
  }

  saveTemplateData(templateData) {
    this._templateService
      .saveTemplate(templateData, false)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () =>
          NotificationsService.notify(
            'Skabelon er blevet oprettet',
            'success',
            'top'
          ),
        error: (err) => this._handleError(err),
      });
  }

  updateTemplateData(templateData) {
    templateData.id = this.routeId;
    this._templateService
      .saveTemplate(templateData, true)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => {
          NotificationsService.notify('Med succes afsluttet', 'success', 'top');
          this.promoForm.markAsPristine();
          this._router.navigate([this.previousUrl]).then();
        },
        error: (err) => this._handleError(err),
      });
  }

  deleteTemplate() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      NotificationsService.confirmWithCallback('Er du sikker?', (accept) => {
        if (accept) {
          this._templateService
            .deleteTemplate(this.routeId)
            .pipe(takeWhile(() => this._subscriptionState))
            .subscribe({
              next: () => {
                this.isProcessing = false;
                this.formSaved = true;
                NotificationsService.notify(
                  'Slettet med succes',
                  'success',
                  'top'
                );
                this._router.navigate([this.previousUrl]).then();
              },
              error: (err) => this._handleError(err),
            });
        }
      });
    }
  }

  save() {
    this.onFormSubmit();
    if (this.shared['mail']) {
      if (
        this.emailFormComp.mailList.length === 0 &&
        !this.emailFormComp.mailSent
      ) {
        const notification =
          'Der er ingen mailskabelon tilgængelig på denne dato. Skift udløbsdato for at finde en anden mail-skabelon eller slette denne kanal for at gemme god pris.';
        NotificationsService.notify(notification, 'info', 'top');
        return;
      }
    }
    if (!this._isFullFormValid()) {
      return;
    }
    if (this._checkIfPriceEmpty() && !this.saveWithNoPrice) {
      this.showPricePopup = true;
      return;
    }
    if (this.shared['mail']) {
      if (!this.emailFormComp.emailSave) {
        NotificationsService.confirmWithCallback(
          'Da e-mail-kampagne ikke er tilgængelig, gemmes alle andre kanaler undtagen mailkanal. Vil du gemme?',
          (accept) => {
            if (accept) {
              this.shared['mail'] = false;
              // if only mail was selected and after setting it to false, we have nothing to save so redirecting to kalendar
              if (this._checkIfSharedAllEmpty()) {
                this._createOptionService.clearCreateOption();
                this.formSaved = true;
                NotificationsService.notify(
                  'God pris blev ikke gemt',
                  'info',
                  'top'
                );
                this._router
                  .navigate([`/${this.parentUrl}/kalendar/god-pris`])
                  .then();
              }
              if (!this._checkIfSharedAllEmpty()) {
                this.saveLogic();
              }
            }
          }
        );
      }
      if (this.emailFormComp.emailSave) {
        this.saveLogic();
      }
    } else {
      this.saveLogic();
    }
  }

  saveLogic() {
    const promoForm = this._prepareFormValues(this.promoForm);
    const isSmsOnly =
      this.addPromoFlag &&
      this.validateModes.length === 1 &&
      this.validateModes[0] === 'sms';
    if (!this.editMode && isSmsOnly) {
      this._savePromo(promoForm);
    }
    if ((!this.editMode && !isSmsOnly) || this.editMode) {
      if (this.userType === '2') {
        // for old board member user
        NotificationsService.confirmWithCallback(
          'Er du sikker?',
          (accept) => {
            if (accept) {
              this._savePromo(promoForm);
            }
          },
          'Ja',
          'Nej'
        );
      }
      if (this.userType === '1') {
        this._savePromo(promoForm);
      }
    }
  }

  onFormSubmit() {
    this.submitForm = !this.submitForm;
  }

  private _initComponent() {
    this.modes = this._activatedRoute.snapshot.data['modes'];
    this.channelSelectionModes = {
      create: !this.templateMode && !this.editMode,
      edit: this.editMode,
      template: this.templateMode,
    };
    this.createSettings = this._activatedRoute.snapshot.data['createOption'];
    this.initScreenList = this._activatedRoute.snapshot.data['screenList']
      ? this._activatedRoute.snapshot.data['screenList']
      : [];
    this.selectedChannels = this._activatedRoute.snapshot.data['channels'];
    if (this.createSettings) {
      this.createOption = this.createSettings.share as Shared;
    }
    this.availablePushCount = TokenService.getUserDetail().availablePushCount;
    this.guid = GoodPriceFormComponent._getGuid();
    this.userType = TokenService.getUserType();
    // to see if user can give out push notification
    this.shopName = TokenService.getUserDetail().name;
    this.isCoop365User = TokenService.getUserDetail().coop365;
    if (this.templateMode) {
      this.parentUrl =
        this._activatedRoute.snapshot.parent.parent.data['parentUrl'];
    } else {
      this.parentUrl = this._activatedRoute.snapshot.parent.data['parentUrl'];
    }
    // for board to figure out where the user is coming from
    if (this.userType === '2') {
      this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    }
  }

  private _setUrl() {
    this.currentUrl = this._router.url;
    // setting default previousUrl
    this._setPreviousUrl('/indlaeg/god-pris/skab', '/skab');
    // setting offerId and folder id and previousUrl values
    combineLatest([
      this._activatedRoute.url,
      this._activatedRoute.parent.params,
    ])
      .pipe(
        take(1),
        finalize(() => {
          this._computePreviousUrl();
        })
      )
      .subscribe({
        next: (value: [UrlSegment[], Params]) => {
          this._computeRouteAndParentId(value);
        },
        error: (err) => this._errorHandlerService.handleError(err),
      });
  }

  private _setupGoodPriceForm() {
    this.promoForm = this._formBuilder.group({
      id: [''],
      coopOriginId: [''],
      ny: [null],
      shared: this._formBuilder.group({
        facebook: [false],
        coop: [false],
        sms: [false],
        mail: [false],
        web: [false],
        screen: [false],
        dit: [false],
      }),
      color: [''],
      fromSystemId: [''],
      isTemp: [false],
      saved: [],
      firstOffer: [],
    });
  }

  // setting previous Url
  _setPreviousUrl(ut1: string, replaceWith: string) {
    if (this.modes.editMode) {
      this.previousUrl = this.currentUrl.replace(
        ut1,
        `/kalendar/god-pris/${this.routeId}`
      );
      return;
    }
    this.previousUrl = this.currentUrl.replace(ut1, replaceWith);
  }

  // get the instance of current form
  private _getForm(tab?: string) {
    if (tab) {
      return this.promoForm.controls[tab];
    }
    return this.promoForm.controls;
  }

  // to check whether the form is valid or not
  private _isValid(currentForm): boolean {
    // *** Check if Form Is Valid *** //
    if (
      currentForm.status === 'INVALID' ||
      (this.tab === 'sms' && this.emojiError)
    ) {
      if (this.tab === 'sms' && this.emojiError) {
        this.showEmojiError = true;
        this.isValidityChecked = true;
      }
      this._handleError('');
      return false;
    }
    currentForm.patchValue({
      title: this._getForm(this.tab).value.title.replace(/^\s+|\s+$/g, ''),
      longDescription: this._getForm(this.tab).value.longDescription.replace(
        /^\s+|\s+$/g,
        ''
      ),
    });
    if (this.tab === 'sms') {
      if (this.smsFormComp.showSmsTextSection) {
        const smsMessage = this.smsFormComp.sendSmsForm.getSmsText();
        currentForm.patchValue({
          smsText: smsMessage,
        });
      }
    }
    return true;
  }

  // ** setting validation modes
  private _setTabAndValidationModes(shared: Shared) {
    const prevValidModes = this.validateModes;
    const validModes = this._setUpValidModes(shared);
    //check if any channels were removed
    let removedChannels = prevValidModes.filter((e) => !validModes.includes(e));
    for (let channel of removedChannels) {
      if (this.promoForm.contains(channel)) {
        this.promoForm.removeControl(channel);
      }
    }
    // Change the shared value in the form
    const sharedControl = this.promoForm.get('shared');
    sharedControl.patchValue(shared);

    this.validateModes = this.nextTabArray = validModes;

    if (this.validateModes.indexOf(this.tab) === -1) {
      if (this.currentTabIndex > 0) {
        this.tab = this.validateModes[this.currentTabIndex - 1];
      } else {
        this.tab = this.validateModes[0];
      }
      if (this.tab === undefined) {
        this.tab = this.validateModes[0];
      }
    }
    // this.nextTab = this.validateModes[0];
    this.currentTabIndex = this.nextTabArray.indexOf(this.tab);
  }

  // setup validateModes
  private _setUpValidModes(shared): Array<string> {
    const validModes = [];
    for (const key in this.orderTab) {
      if (shared[this.orderTab[key]]) {
        validModes.push(this.orderTab[key]);
      }
    }
    return validModes;
  }

  private static _getGuid(): string {
    return (
      GoodPriceFormComponent.s4() +
      GoodPriceFormComponent.s4() +
      '-' +
      GoodPriceFormComponent.s4() +
      '-4' +
      GoodPriceFormComponent.s4().substring(0, 3) +
      '-' +
      GoodPriceFormComponent.s4() +
      '-' +
      GoodPriceFormComponent.s4() +
      GoodPriceFormComponent.s4() +
      GoodPriceFormComponent.s4()
    ).toLowerCase();
  }

  private _computePreviousUrl() {
    // setting previousUrl based on the mode
    if (this.editMode) {
      this._setPreviousUrl('/indlaeg/god-pris/redigere/' + this.routeId, '');
    }
    if (this.templateMode) {
      if (this.currentUrl.indexOf('sog') > 0) {
        this.previousUrl = this.currentUrl.replace('/sog/' + this.routeId, '');
      }
      if (this.currentUrl.indexOf('sog') === -1) {
        this.previousUrl = this.currentUrl.replace('/' + this.routeId, '');
      }
    }
  }

  private _computeRouteAndParentId(value: [UrlSegment[], Params]) {
    const urlSegment = value[0];
    if (this.templateMode || this.editMode) {
      // setting temp id
      if (urlSegment.length > 1) {
        this.routeId = urlSegment[1].path;
      } else {
        this.routeId = urlSegment[0].path;
      }
      // setting folder id
      if (this.templateMode) {
        const parentParam = value[1];
        this.parentId = parentParam['folderId'];
      }
    }
  }

  private _createModeSetup() {
    this.addPromoFlag = this.createOption['sms'];
    Object.assign(this.shared, this.createOption);
    // patch shared values to form for sms date validation
    this._patchSharedToForm();
    // to show the first selected tab
    this._setTabAndValidationModes(this.shared);
  }

  private _editModeSetup() {
    this._formStateService.setFormState(this.formData);
    Object.assign(this.shared, this.formData.shared);
    if (this.templateMode || this.modes.ownTemplate) {
      if (this.selectedChannels && this.selectedChannels.isSet) {
        Object.assign(this.shared, this.selectedChannels.selected);
      }
      this.allowedChannels = this.formData.shared;
    }
    this._setTabAndValidationModes(this.shared);
    this.promoForm.patchValue(this.formData);
    if (this.editMode) {
      this._setTitleInEditMode();
    }
    // if it is template mode adding addPromo functionality
    if (this.templateMode) {
      this.addPromoFlag = this.shared['sms'];
      this.ownTemplate = this.formData.ownTemplate;
    }
  }

  private _setTitleInEditMode() {
    if (this.modes.editMode) {
      this.pageTitle = 'Rediger God Pris';
    }
  }

  private _patchSharedToForm() {
    for (const platform in this.shared) {
      if (this.shared.hasOwnProperty(platform)) {
        this.promoForm.patchValue({
          shared: {
            [platform]: this.shared[platform],
          },
        });
      }
    }
  }

  private _isFullFormValid(): boolean {
    let valid = true;
    const promoForm = this.promoForm.controls;
    for (const key in promoForm) {
      if (key !== 'sms') {
        if (this.shared[key] && promoForm[key].status === 'INVALID') {
          valid = false;
          this._handleError('');
          NotificationsService.notify(
            'Noget gik galt i ' + key + '-kanalen',
            'error',
            'top'
          );
          break;
        }
      }
      if (key === 'sms') {
        if (
          (this.shared[key] && promoForm[key].status === 'INVALID') ||
          (this.shared[key] && this.emojiError)
        ) {
          valid = false;
          if (this.shared[key] && this.emojiError) {
            this.showEmojiError = true;
            this.isValidityChecked = true;
          }
          this._handleError('');
          NotificationsService.notify(
            'Noget gik galt i ' + key + '-kanalen',
            'error',
            'top'
          );
          break;
        }
      }
    }
    // TODO: Handle video color
    const image = document.getElementById('large-image') as HTMLImageElement;
    const color = ColorThiefService.setColor(image);
    this.promoForm.patchValue({
      color: `rgba(${color},0.4)`,
    });
    return valid;
  }

  private _checkIfPriceEmpty(): boolean {
    let isEmpty = false;

    for (const key in this.shared) {
      if (this.shared[key]) {
        const form = this._getForm(key);
        if (key !== 'dit') {
          if (
            !form.value.inAppParameters.price ||
            !form.value.inAppParameters.priceDescription
          ) {
            isEmpty = true;
          }
        }
        if (key === 'dit') {
          if (!form.value.price || !form.value.dutyText) {
            isEmpty = true;
          }
        }
      }
    }
    return isEmpty;
  }

  // check if all shared channels are false
  private _checkIfSharedAllEmpty(): boolean {
    for (const key in this.shared) {
      if (this.shared[key]) {
        return false;
      }
    }
    return true;
  }

  private _savePromo(promoForm) {
    this._saveOffer(promoForm)
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe({
        next: () => this._success(),
        error: (err) => {
          this._disableSmsSendDate();
          this._handleError(err);
        },
      });
  }

  // perform after successful operation
  private _success() {
    this._createOptionService.clearCreateOption();
    this.formSaved = true;
    NotificationsService.notify('Gemt med succes', 'success', 'top');
    this.isProcessing = false;
    this._channelDataService.clearChannel();
    this._router.navigate([`/${this.parentUrl}/kalendar/god-pris`]).then();
  }

  // ** save the promo/ offer **
  private _saveOffer(promoForm: Promo): Observable<any> {
    if (this.templateMode) {
      if (!this.formData.ownTemplate) {
        promoForm.isTemp = this.modes.templateMode;
        promoForm.tempId = this.routeId;
      } else {
        promoForm.isTemp = false;
      }
    }
    return this._offerService.savePromo(promoForm, this.editMode);
  }

  private _disableSmsSendDate() {
    if (this.shared['sms']) {
      if (!this.smsFormComp.showSmsTextSection) {
        this.promoForm.get('sms.sendDate').disable();
      }
    }
  }

  // ** prepare form values for saving
  private _prepareFormValues(promoForm: FormGroup): object {
    // patch temp images from imageUrlArray to the form
    if (this.shared['sms']) {
      this.promoForm.get('sms.sendDate').enable();
    }
    if (this.shared['screen']) {
      const isCoop365User = this.isCoop365User ? 1 : 0;
      promoForm.controls['screen'].patchValue({
        coop365: isCoop365User,
      });
    }
    for (const platform in this.shared) {
      if (this.shared.hasOwnProperty(platform)) {
        promoForm.patchValue({
          shared: {
            [platform]: this.shared[platform],
          },
        });
      }
    }
    for (const key in promoForm.controls) {
      if (this.shared[key]) {
        // change the date format
        if (key !== 'sms') {
          promoForm.controls[key].value.publishDate =
            this._dateToString.transform(
              promoForm.controls[key].value.publishDate
            );
          promoForm.controls[key].value.expirationDate =
            this._dateToString.transform(
              promoForm.controls[key].value.expirationDate
            );
          promoForm.controls[key].value.startDate =
            this._dateToString.transform(
              promoForm.controls[key].value.startDate
            );
          promoForm.controls[key].value.endDate = this._dateToString.transform(
            promoForm.controls[key].value.endDate
          );
          continue;
        }

        const startDate = this._dateToString.transform(
          promoForm.controls[key].value.startDate
        );
        const endDate = this._dateToString.transform(
          promoForm.controls[key].value.endDate
        );
        if (this.editMode && !this.smsFormComp.showSmsTextSection) {
          promoForm.controls[key].patchValue({
            sendDate: this._dateToString.transform(this.formData.sms?.sendDate),
            startDate,
            endDate,
          });
        }
        if (this.editMode && this.smsFormComp.showSmsTextSection) {
          promoForm.controls[key].patchValue({
            sendDate: this._dateToString.transform(
              promoForm.controls[key].value.sendDate
            ),
            startDate,
            endDate,
          });
        }
        if (!this.editMode) {
          promoForm.controls[key].patchValue({
            sendDate: this._dateToString.transform(
              promoForm.controls[key].value.sendDate
            ),
            solutions: this.smsFormComp.clients[0].id,
            startDate,
            endDate,
          });
        }
      }
    }
    // patch the current smstext in sms box to the form
    if (this.tab === 'sms') {
      if (this.smsFormComp.showSmsTextSection) {
        const smsMessage = this.smsFormComp.sendSmsForm.getSmsText();
        promoForm.controls['sms'].patchValue({
          smsText: smsMessage,
        });
      }
    }
    // deleting solutions if edit mode
    if (this.editMode && this.shared['sms']) {
      delete promoForm.value['sms'].solutions;
    }
    if (!this.editMode) {
      delete promoForm.value['id'];
    }
    // Add a GUID to CoopOriginID if not already present
    if (!promoForm.value.coopOriginId) {
      promoForm.value.coopOriginId = this.guid;
    }
    // determining the system id
    // determining the system id
    if (!this.modes.editMode) {
      promoForm.value.fromSystemId = '4';
      promoForm.value.board = '0';
    }
    // for dev saved value as 1
    promoForm.value.saved = 1;
    this._deleteUnwantedMediaValues(promoForm);
    return promoForm.value;
  }

  private _deleteUnwantedMediaValues(promoForm) {
    if (this.shared['facebook']) {
      GoodPriceFormComponent._deleteMediaValuesFor('facebook', promoForm);
    }
    if (this.shared['web']) {
      GoodPriceFormComponent._deleteMediaValuesFor('web', promoForm);
    }
    if (this.shared['sms']) {
      GoodPriceFormComponent._deleteMediaValuesFor('sms', promoForm);
    }
    if (this.shared['screen']) {
      GoodPriceFormComponent._deleteMediaValuesFor('screen', promoForm);
    }
    if (this.shared['coop']) {
      GoodPriceFormComponent._deleteMediaValuesFor('coop', promoForm);
    }
  }

  private static _deleteMediaValuesFor(channel: string, promoForm) {
    if (promoForm.value[channel].video?.thumbnail) {
      if (channel !== 'sms') {
        delete promoForm.value[channel].largeImage;
      }
      if (channel === 'coop' || channel === 'sms') {
        delete promoForm.value[channel].thumbnail;
      }
    }
    if (!promoForm.value[channel].video?.thumbnail) {
      delete promoForm.value[channel].video;
    }
  }

  private _deletePromoChannel(tab: string, promoId): Observable<any> {
    return this._offerService.deleteChannel(tab, promoId);
  }

  private _refreshPage() {
    if (!this.isProcessing) {
      this.isProcessing = true;
      this._offerService
        .getPromo(this.routeId)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: (promo) => {
            this.isProcessing = false;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.formData = promo;
            this._editModeSetup();
          },
          error: (err) => this._handleError(err),
        });
    }
  }

  private _prepareTemplateData() {
    for (const key in this.validateModes) {
      const type = this.validateModes[key];
      const params = this.templateParam[type];
      if (type !== 'sms') {
        this._dateComputationForTemplate(params);
        if (type !== 'screen' && type !== 'dit') {
          if (params.inAppParameters.price === null) {
            delete params.inAppParameters.price;
          }
          if (params.inAppParameters.priceDescription === null) {
            delete params.inAppParameters.priceDescription;
          }
        }
        if (type === 'screen' || type === 'dit') {
          if (params.price === null) {
            delete params.price;
          }
        }
        continue;
      }
      if (this.editMode && !this.smsFormComp.showSmsTextSection) {
        params.sendDate = this._dateToString.transform(
          this.formData.sms.sendDate
        );
      }
      if (this.editMode && this.smsFormComp.showSmsTextSection) {
        params.sendDate = this._dateToString.transform(params.sendDate);
      }
      if (!this.editMode) {
        params.sendDate = this._dateToString.transform(params.sendDate);
      }
    }
    // deleting not required properties for template
    if (this.shared['sms']) {
      delete this.templateParam['sms']['solutions'];
    }
    if (this.shared['coop']) {
      delete this.templateParam['coop'].push;
    }
    if (this.shared['mail']) {
      delete this.templateParam['mail'].mailId;
    }
    this._deletingMediaBasedOnType('facebook');
    this._deletingMediaBasedOnType('web');
  }

  private _dateComputationForTemplate(params) {
    if (
      moment(params.publishDate).isBefore(moment.now()) &&
      moment(params.expirationDate).isBefore(moment.now())
    ) {
      params.publishDate = this._dateToString.transform(
        moment(moment.now()).hour(8).minute(0).toDate()
      );
      params.expirationDate = this._dateToString.transform(
        moment(moment.now()).add(7, 'days').hour(20).minute(0).toDate()
      );
      params.startDate = this._dateToString.transform(
        moment(moment.now()).hour(8).minute(0).toDate()
      );
      params.endDate = this._dateToString.transform(
        moment(moment.now()).add(7, 'days').hour(20).minute(0).toDate()
      );
    } else {
      params.publishDate = this._dateToString.transform(params.publishDate);
      params.expirationDate = this._dateToString.transform(
        params.expirationDate
      );
      params.startDate = this._dateToString.transform(params.startDate);
      params.endDate = this._dateToString.transform(params.endDate);
    }
  }

  private _deletingMediaBasedOnType(channel: string) {
    // deleting media based on media type
    if (this.shared[channel]) {
      if (this.templateParam[channel].video?.thumbnail) {
        delete this.templateParam[channel].largeImage;
      }
      if (!this.templateParam[channel].video?.thumbnail) {
        delete this.templateParam[channel].video;
      }
    }
  }

  private static _setPaddingTop(): void {
    const appHeader = document.getElementById('fixed');
    const pageContainer = document.getElementById('page-content');
    pageContainer.style.paddingTop = appHeader.offsetHeight + 'px';
  }

  private _handleError(error): void {
    this._errorHandlerService.handleError(error || { code: -400 }, 'promo');
    this.isProcessing = false;
  }
}
