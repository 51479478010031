import { Injectable } from '@angular/core';
import { ApiService, TokenService } from 'app/core/services';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Jatak, JatakList } from '../interface';
import { TemplateFolderData, TemplateListData } from 'app/shared/interface';

@Injectable({
  providedIn: 'root',
})
export class JatakService {
  constructor(private _apiService: ApiService) {}

  // *** Gets the jatak lists within a date range ***
  // @params [startDate: string]
  // @params [endDate: string]
  // @returns [Observable<JatakList[]>] => Basic Jatak value of all jatak items
  getJatak(startDate?: any, endDate?: any): Observable<JatakList[]> {
    let url = `jatak?userType=${TokenService.getUserType()}`;
    if (startDate) {
      url = url + `&startDate=${startDate}&endDate=${endDate}`;
    }
    return this._apiService.get(url).pipe(map((res) => res['offers']));
  }
// *** Gets all jatakLists from server with given page limit and current page ***
  // @params [page] => Page number increases while Loading More
  // @params [limit: number] => total jatak lists to be loaded on particular page
  // @params [status: string] => '1' active
  //                             '2' Fremtidige (future)
  //                              '0' Archive
  // @returns [jatakList: Array<JatakList>] => Basic Jatak value of all jatak items
  getJatakListing(
    page: number,
    limit: number,
    status: string,
    sortBY:number,
    searchString? : string,
    startDate?:string,
    endDate?:string,
  ): Observable<JatakList[]> {

    if(searchString){
      const url = `jatak/offers?page=${page}&limit=${limit}&status=${status}&sortBy=${sortBY}&search=${searchString}&start_date=${startDate}&end_date=${endDate}`;
      return this._apiService.get(url).pipe(map((res) => res));
    }
    else if(startDate && endDate){
      const url = `jatak/offers?page=${page}&limit=${limit}&status=${status}&sortBy=${sortBY}&startDate=${startDate}&endDate=${endDate}`;
      return this._apiService.get(url).pipe(map((res) => res));
    }
    else {
      const url = `jatak/offers?page=${page}&limit=${limit}&status=${status}&sortBy=${sortBY}`;
      return this._apiService.get(url).pipe(map((res) => res));
    }
  }

  // *** gets a single Jatak post ***
  // @param [id: number] => Jatak_id
  // @returns [jatak: Jatak] => Jatak detail of particular jatak id
  getSingleFbPost(id: number) {
    const url = `jatak/${id}`;
    return this._apiService.get(url).pipe(map((res) => res['data']));
  }

  // *** Saves the Jatak ***
  // @param [params: Jatak] => Jatak Item to be saved
  // @param [edit: boolean] => Is edit mode?
  //                           true: if editing active or future jatak
  //                            false: if new jatak is to be created or reusing archive jatak as template
  saveJatak(params: Jatak, edit: boolean) {
    if (edit) {
      return this._apiService.put(
        `jatak/${params.id}`,
        ApiService.CreateFormData(params)
      );
    } else {
      return this._apiService.post('jatak', ApiService.CreateFormData(params));
    }
  }
  // *** Saves the template ***
  // @param [params: Jatak] => Jatak item to be saved
  // @param [edit: boolean] => true while editing jatak templates
  saveJatakTemplate(params: Jatak, edit: boolean) {
    if (edit) {
      // called while editing the same jatak template
      return this._apiService.put(
        `jatak/templates/own/${params.id}`,
        ApiService.CreateFormData(params)
      );
    }
    // called while saving jatak as template
    return this._apiService.post(
      'jatak/templates/own',
      ApiService.CreateFormData(params)
    );
  }

  // *** Deletes Jatak ***
  // @param [id:string] => id of Jatak to be deleted
  deleteJatak(id: string): Observable<any> {
    return this._apiService.del(`jatak/${id}`);
  }

  // *** Deletes jatak template ***
  // @param [id: string] => id of Jatak template to be deleted
  deleteJatakTemplate(id: string): Observable<any> {
    return this._apiService.del(`jatak/templates/own/${id}`);
  }

// *** It determines whether the current user is connected to facebook or not ***
  // @returns [status: boolean] => true: if token matched and is within 3 months
  //                               false: otherwise
  getFbTokenStatus() {
    const url = 'access/token';
    return this._apiService
      .getFacebook(url)
      .pipe(map((res) => res['access_token']));
  }

  // ** Template Services ** //

  // *** gets all the Jatak template folders ***
  //@returns [templateFolderData: TemplateFolderData] => all active folders
  getJatakTemplatesFolder(): Observable<TemplateFolderData> {
    const url = 'jatak/folders';
    return this._apiService.get(url);
  }
// *** gets all the TemplateListData from current page to page limit usually set to 10 ***
// @params [clientId: number] => kardex_id for own templates
  //                             0 for store-template and folder-template
  // @params [store: number] => kardex_id for template created by store
  //                            0 for own templates and folder-template
  // @param [folderId: number] => folder_id for template created in folder
  //                              '' empty string for own templates and store-created
  // @param [page: number] => start page
  // @param [limit: number] => maximum templates to fetch at a time
  // @returns [template: Array<Template>] => Templates belonging to particular folder
  getJatakTemplates(
    store: string,
    folderId: string,
    page: number,
    limit: number,
    searchQuery?: string
  ): Observable<TemplateListData> {
    let url = `jatak/templates?mode=${store}&folderId=${folderId}&page=${page}&limit=${limit}`;
    url = searchQuery ? `${url}&search=${searchQuery}` : url;
    return this._apiService.get(url);
  }

  // *** Gets single template ***
  // @param [id:number] => template_id
  // @returns [template: Template] => Details of the template
  getJatakSingleTemplate(id: number) {
    const url = `jatak/templates/${id}`;
    return this._apiService.get(url).pipe(map((res) => res['template']));
  }
// *** It takes the search string and searches using api ***
  // @param [searchTerms: string] => the searchTerms from templates-folder-list.component.ts
  // @returns [jatak: Array<JatakList>] => Those templates as JatakList found in the server
  searchJatakTemplate(searchTerms: string) {
    const url = `jatak/templates?search=${searchTerms}`;
    return this._apiService.get(url);
  }

  // *** Gets all the orderLists for a particular Jatak item ***
  // @param [id: string] => Jatak id
  // @returns [res: OrderData] => All the orderLists data
  getOrderList(id: string) {
    const userType = TokenService.getUserType();
    const url = `jatak/${id}/order-list`;
    return this._apiService.get(url).pipe(map((res) => res.data));
  }

  // *** It marks as picked up to all the collected jatak offers in the order list for a particular customer ***
  // @param [jatakId: string] => id of jatak item whose data is to be approved
  // @param [ orderData: object] => object {
  //                                        fromId: number [represents id of particular user]
  //                                        system: number [represents current Tab(0,1,2)]
  //                                       }
  updateJatakOrderCollected(jatak: string, from:string) {
    return this._apiService.post(
      `jatak/${jatak}/order-list/${from}/picked-up`,
      {}
    );
  }

  // *** To approve the selected order ***
  // @param [jatakId: string]=>id of jatak whose order is to be approved by user
  // @param [orderId: string] => order id of the particular order
  // @param [orderData: OrderData] => OrderData after manually updating
  updateOrderList(jatakId: string, orderId: string, orderData) {
    return this._apiService.post(
      `jatak/${jatakId}/order-list/${orderId}/approve`,
      ApiService.CreateFormData(orderData)
    );
  }

  // to reject the selected order
  // @param [jatakId: string]=>id of jatak whose order is to be rejected by user
  // @param [orderId: string] => order id of the particular order
  rejectOrder(jatakId: string, orderId: string) {
    return this._apiService.post(
      `jatak/${jatakId}/order-list/${orderId}/reject`,
      {}
    );
  }

  retryOrder(jatakId:string,orderId:number){
    return this._apiService.post(`jatak/${jatakId}/order-list/${orderId}/retry-reply`,
      {})
  }

  // *** To cancel all the order from the orderlist for a particular user ***
  // @param [jatakId: string] => id of jatak item whose data is to be cancelled
  // @param [ orderData: object] => object {
  //                                        fromId: number [represents id of particular user]
  //                                        system: number [represents current Tab(0,1,2)]
  //                                       }
  cancelOrder(jatak: string, from:string) {
    return this._apiService.post(
      `jatak/${jatak}/order-list/${from}/reject-all`,
      {}
    );
  }
// *** It gets Jatak Order Lists information in a pdf file from server ***
  // @param [offerId: string] => Jatak id of which orderLists is to be downloaded
  getFbCommentOrderlistPdf(offerId: string): Observable<any> {
    const url = `jatak/${offerId}/order-list/pdf`;
    return this._apiService.get(url, { responseType: 'blob' });
  }

  getJatakDefaultText(){
    const url = 'jatak/settings'
    return this._apiService.get(url).pipe(map((res) => res.data));
  }

  sendJatakDefault(defaultData){
    const url = 'jatak/settings'
    return this._apiService.post(url, ApiService.CreateFormData(defaultData))
  }
}
