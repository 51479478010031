<section
  class="app-page  page-with-fixed-header default-page "
>
  <div class="fixed-header">
    <coop-pageheader
      [pageTitle]="pageTitle"
      [isBackGroundWhite]="isBackGroundWhite"
      [isChild]="true"
      [routeTo]="parentUrl"
    ></coop-pageheader>
  </div>
  <div class="page-container">
    <div class="form-container">

      <form [formGroup]="jatakDefaultForm" (submit)="save()">
        <div class="methodlist">
          <div *ngFor="let list  of methodList" (click)="onMethodClick(list.id)"
               [class.active]="activeMethod === list.id" class="method">
            {{list.name }}
          </div>
        </div>


        <div class="methodsection">
          <div id="response" class="responsemethod" #response>
            <h2>Svarmetode</h2>
            <p>Vælg, hvordan robotten vil svare til
              hver enkelt Facebook-kunde</p>

            <div class="replymethods">
              <div (click)="onPublicPrivateReply()"
                   style="flex-basis: 50%"
                   [class.activereply]=" jatakDefault && jatakDefault.doPrivateReply">
                <div
                  class="reply">
                  <p>Offentligt kommentar </p>
                  <span>(ordrebekræftelse)</span> <br>
                  <span>+</span>
                  <p>Privat besked</p>
                  <span>(med ordrenummer)</span>
                </div>
              </div>

              <div
                style="flex-basis: 50% ;"
                (click)="onPublicReplyOnly()"
                [class.activereply]=" jatakDefault && !jatakDefault.doPrivateReply">
                <div class="publicreply">
                  <p>Kun offentligt kommentar</p>
                  <span>(med ordrenummer)</span>
                </div>
              </div>
            </div>

            <div class="publicprivatereplysection">
              <div (click)="toggleReply()" class="title-wrapper">
                <div class="reply-title">
                  <p>Eksempel på Facebook svaret</p>
                </div>
                <div class="arrow">
                  <ion-icon *ngIf="showReply" name="chevron-up-outline"></ion-icon>
                  <ion-icon *ngIf="!showReply" name="chevron-down-outline"></ion-icon>
                </div>
              </div>
              <div *ngIf="showReply" class="seperater"></div>
              <div *ngIf="showReply">
                <div class="public-reply" style="margin-bottom:  12px">
                  <div class="pubic-reply-title">
                    <p>Offentlig</p>
                  </div>
                  <div class="reply-message">
                    <p>Hej Lærke,</p>
                    <p>Tak for din bestilling i 😍😍‼️‼️ JA TAK ‼️‼️ 🧼🧼 5 PAK MIKROFIBERKLUDE. Vi reserverer 1 til
                      dig.</p>
                    <p
                      *ngIf="this.jatakDefault.doPrivateReply">Dit ordrenummer vil snart modtages i en privat
                      besked.</p>

                    <p
                      *ngIf="!this.jatakDefault.doPrivateReply">Dit ordrenummer er: F1001</p>
                  </div>
                </div>
                <div *ngIf="this.jatakDefault.doPrivateReply">
                  <div class="pubic-reply-title">
                    <p>Privat</p>
                  </div>
                  <div class="reply-message">
                    <p>Hej Lærke,</p>
                    <p>Tak for din bestilling i 😍😍‼️‼️ JA TAK ‼️‼️ 🧼🧼 5 PAK MIKROFIBERKLUDE. Vi reserverer 1 til
                      dig.</p>
                    <p>Dit ordrenummer er: F1001</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--          <div class="seperater"></div>-->

          <div id="standard" class="standardmethod" #standard>
            <h2>Standard tekster</h2>
            <p>Her kan du indstille de standardoplysninger,
              der vil være tilgængelige i felterne, når du opretter hver JA TAK.</p>
            <span>Alle disse kan redigeres, hver gang du opretter et JA TAK, undtagen e-mail-adresserne.</span>
            <div class="sold-out-title">
              <h3>Overskrift ved udsolgt JA TAK</h3>
              <p>Bliver tilføjet i starten af indlægget.</p>
              <input
                type="text"
                formControlName="soldOutTitle"
                placeholder="Dette JA TAK er desværre udsolgt."

              />
            </div>

            <div class="sold-out-title">
              <h3>Besvarelse ved udsolgt JA TAK</h3>
              <input
                type="text"
                formControlName="soldOutReply"
                placeholder="Dette JA TAK er desværre udsolgt."
              />
            </div>

          </div>

          <!--          <div class="seperater"></div>-->


          <div id="restriction" class="restrictionsection" #restriction>
            <h2>Restriktioner</h2>

            <div class="wrappersection">
              <div class="togglesection">
                <div class="toggle-title">
                  Indstil max. antal styk pr. kunde
                </div>
                <div class="toggle-btn">
                  <label class="switch">
                    <input
                      formControlName="hasCustomerLimit"
                      (click)="onSetMaxOrderToggle($event)"
                      type="checkbox">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="showMaxOrder" class="seperater"></div>
              <div *ngIf="showMaxOrder" class="showorder">
                <div class="toggle-title">
                  Hver kunde kan bestille max.
                </div>
                <!--                <div (click)="customerLimit.click()" class="limit">-->
                <!--                  <div>-->
                <!--                    {{ jatakDefaultForm.get('customerLimit').value }}-->
                <!--                  </div>-->
                <!--                  <ion-icon name="chevron-down-outline"></ion-icon>-->
                <!--                  <input-->
                <!--                    #customerLimit-->
                <!--                    hidden-->
                <!--                    mbsc-select-->
                <!--                    [data]="alertQuantitySelectSettings.data"-->
                <!--                    readonly-->
                <!--                    [buttons]="[]"-->
                <!--                    formControlName="customerLimit"-->
                <!--                    display="bubble"-->
                <!--                    class="date-input"/>-->
                <!--                </div>-->


                <div class="maxlimit">
                  <mbsc-select
                    [data]="alertQuantitySelectSettings.data"
                    display="anchored"
                    formControlName="customerLimit"
                    [buttons]="[]"
                    [touchUi]="false">
                  </mbsc-select>
                </div>
              </div>

            </div>

            <div class="wrappersection">
              <div class="togglesection">
                <div class="toggle-title">
                  Godkend ordrer manuelt
                </div>
                <div class="toggle-btn">
                  <label class="switch">
                    <input formControlName="hasAlertQuantity" (click)="onManualApproval($event)" type="checkbox">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>


              <div *ngIf="showMaualApproval" class="seperater"></div>
              <div *ngIf="showMaualApproval" class="showorder margin-bottom-20">
                <div class="toggle-title">
                  Når en ordre overstiger (stk.)
                </div>
                <div class="maxlimit">
                  <mbsc-select
                    [data]="alertQuantitySelectSettings.data"
                    display="anchored"
                    formControlName="alertQuantity"
                    [touchUi]="false">
                  </mbsc-select>
                </div>

              </div>

              <div *ngIf="showMaualApproval" class="showorder">
                <div class="toggle-title">
                  Dette nr. modtaget underretning.
                </div>
                <div class="row phonewraper">
                  <input
                    id="phone"
                    type="text"
                    [placeholder]="priceFormat.format === 'DK' ? '8-cifret nr. ' : '6-cifret nr.'  "
                    formControlName="phone"
                    [customErrors]="validationMessage['phone']"
                    (keydown)="validateNumber($event)"
                    (keyup)="formatPhoneNumber($event)"
                    style="padding-left: 120px"
                  />
                  <div (click)="toggleOptions()" class="phone-prefix">
                    <div style="display: flex;align-items: center;cursor: pointer">
                      <span>{{ priceFormat.format }}</span>
                      <ion-icon *ngIf="!showPhoneOptionList" name="chevron-down-outline"></ion-icon>
                      <ion-icon *ngIf="showPhoneOptionList" name="chevron-up-outline"></ion-icon>
                    </div>
                    <span>+{{ priceFormat.code }}</span>
                  </div>
                  <div *ngIf="showPhoneOptionList" class="phone-options">
                    <div (click)="onSelect({format : 'DK',code : 44})" class="phone-list">
                      <div class="name">
                        DK
                      </div>
                      <div>
                        +44
                      </div>
                    </div>

                    <div (click)="onSelect({format : 'GL',code : 299})"  class="phone-list">
                      <div class="name">
                        GL
                      </div>
                      <div>
                        +299
                      </div>
                    </div>

                    <div (click)="onSelect({format : 'FO',code : 298})" class="phone-list">
                      <div class="name">
                        FO
                      </div>
                      <div>
                        +299
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="error-message" *ngIf="jatakDefaultForm.controls['phone'].invalid &&
                                                      (jatakDefaultForm.controls['phone'].dirty
                                                                              ||
                                                                              jatakDefaultForm.controls['phone'].touched
                                                                               || formSaved )">
                <div *ngIf="jatakDefaultForm.controls['phone'].errors?.['required']">
                  <span>Udfyld venligst feltet</span>
                </div>
                <div *ngIf="jatakDefaultForm.controls['phone'].errors?.['invalid']">
                  <span>Brug venligst et gyldigt telefonnummer.</span>
                </div>
              </div>

            </div>


          </div>

          <!--          <div class="seperater"></div>-->

          <div id="notification" class="notificationsection" #notification>
            <h2> Meddelelser</h2>
            <p>Modtag en oversigt og hele ordrelisten for
              hver JA TAK, når den udløber eller er udsolgt.</p>
            <div class="wrappersection">
              <div class="togglesection">
                <div class="toggle-title">
                  Aktiver SMS-oversigt
                </div>
                <div class="toggle-btn">
                  <label class="switch">
                    <input
                      formControlName="smsNotification"
                      (click)="onSmsToggle($event)"
                      type="checkbox">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="showSmsSection">
                <div class="seperater"></div>
                <p class="margin-top-20" style="margin-bottom: 12px">
                  Når hver JA TAK-kampagne slutter
                </p>
                <div class="smsnotificationsection">

                  <div class=" row phone-field">
                    <input
                      id="phone"
                      type="text"
                      [placeholder]="smsPriceFormat.format === 'DK' ? '8-cifret nr. ' : '6-cifret nr.'  "
                      formControlName="notificationPhone"
                      [customErrors]="validationMessage['phone']"
                      (keydown)="validateNumber($event)"
                      (keyup)="formatSMSPhoneNumber($event)"
                      style="padding-left: 140px"
                    />

                    <div (click)="toggleSmsOption()" class="phone-prefix">
                      <div style="display: flex;align-items: center;cursor: pointer">
                        <span>{{ smsPriceFormat.format }}</span>
                        <ion-icon *ngIf="!showSmsPhoneOptionList" name="chevron-down-outline"></ion-icon>
                        <ion-icon *ngIf="showSmsPhoneOptionList" name="chevron-up-outline"></ion-icon>
                      </div>
                      <span>+{{ smsPriceFormat.code }}</span>
                    </div>

                    <div *ngIf="showSmsPhoneOptionList" class="phone-options">

                      <div (click)="onSelectSmsPhone({format : 'DK',code : 44})" class="phone-list">
                        <div class="name">
                          DK
                        </div>
                        <div>
                          +44
                        </div>
                      </div>

                      <div (click)="onSelectSmsPhone({format : 'GL',code : 299})"  class="phone-list">
                        <div class="name">
                          GL
                        </div>
                        <div>
                          +299
                        </div>
                      </div>

                      <div (click)="onSelectSmsPhone({format : 'FO',code : 298})" class="phone-list">
                        <div class="name">
                          FO
                        </div>
                        <div>
                          +299
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>

              <div class="error-message" *ngIf="jatakDefaultForm.controls['notificationPhone'].invalid &&
                                                      (jatakDefaultForm.controls['notificationPhone'].dirty
                                                                              ||
                                                                              jatakDefaultForm.controls['notificationPhone'].touched
                                                                               || formSaved )">
                <div *ngIf="jatakDefaultForm.controls['notificationPhone'].errors?.['required']">
                  <span>Udfyld venligst feltet</span>
                </div>
                <div *ngIf="jatakDefaultForm.controls['notificationPhone'].errors?.['invalid']">
                  <span>Brug venligst et gyldigt telefonnummer.</span>
                </div>
              </div>

            </div>

            <div class="wrappersection" style="margin-bottom:32px">
              <div class="togglesection">
                <div class="toggle-title">
                  Aktiver e-mailoversigt
                </div>
                <div class="toggle-btn">
                  <label class="switch">
                    <input formControlName="emailNotification" (click)="onEmailToggle($event)" type="checkbox">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="showEmailSection">
                <div class="seperater"></div>
                <p class="margin-top-20" style="margin-bottom: 12px">Meddele maks. 3 e-mailadresser på hver JA
                  TAK-afslutning</p>

                <div class="emailsection">
                  <div class="email">
                    <input
                      [class.disabled]="disabled"
                      type="text"
                      #emailInput
                      placeholder="Indtast e-mailadresse her "
                    />
                  </div>
                  <div [class.disabled]="disabled" (click)="addEmail()" class="addemail">
                    <ion-icon name="add-outline"></ion-icon>
                  </div>
                </div>
                <div class="invaliderror" *ngIf="invalidEmail">
                  <p>Indsæt venligst en gyldig e-mailadresse.</p>
                </div>
                <div class="invaliderror" *ngIf="sameEmail">
                  <p>Du har valgt den samme e-mailadresse mere end én gang.</p>
                </div>

                <div *ngFor="let email of emailList ; let i = index" class="emaillist">
                  <div>
                    {{email}}
                  </div>
                  <div (click)="deleteEmail(i)" class="delete">
                    <ion-icon name="close-outline"></ion-icon>
                  </div>
                </div>
              </div>
            </div>

            <div (click)="save()" class="row">
              <a class="login-btn btn-black-login">Gem</a>
            </div>

          </div>
        </div>
      </form>
    </div>
  </div>
</section>
