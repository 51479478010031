import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { mergeMap, takeWhile } from 'rxjs/operators';

import {
  ErrorhandlerService,
  NotificationsService,
  TokenService,
  CreateOptionDataService,
  DateFormatService,
  FormFunctionsService
} from 'app/core/services';
import {
  checkIfEndDateIsLessThanStartDate, checkMaxLength,
  checkWhiteSpace
} from 'app/core/validators';
import { animateParent, slideAnimation } from 'app/shared/animations';
import { DatepickerData, ValidationMessage } from 'app/shared/interface';
import { DateToString } from 'app/shared/pipes';
import { Poll_Validation_Messages, Poll, PollService } from 'app/features/poll';
import { COMMON_DATETIMEPICKER_SETTINGS } from 'app/shared/data';
import { MbscDatepickerOptions } from '@mobiscroll/angular';
import { DatepickerBase } from '@mobiscroll/angular/dist/js/core/components/datepicker/datepicker';

@Component({
  selector: 'coop-poll-create-edit',
  templateUrl: './poll-create-edit.component.html',
  styleUrls: ['./poll-create-edit.component.scss'],
  providers: [DateToString],
  animations: [animateParent, slideAnimation]
})
export class PollCreateEditComponent implements OnInit, OnDestroy {
  showLengthCounter: boolean = false;
  poll: Poll;
  pageTitle: string = 'Opret ny Afstemning';
  isProcessing: boolean = false;
  isEditable: boolean = true;
  pollForm: FormGroup;
  formSaved: boolean = false;
  editMode: boolean = false;
  previousUrl: string;
  successUrl: string;
  routeId: string;
  imageUrl = {
    largeImage: 'assets/images/camera@3x.jpg',
    thumbnail: 'assets/images/camera@3x.jpg'
  };
  hasImageUrl: boolean = false;
  colors = ['shade1', 'shade2', 'shade3', 'shade4', 'shade5'];
  answerCount: number = 1;
  isTemplate: boolean;
  parentUrl: string;
  currentUrl: string;
  userType: string;
  origin = '';
  fromCalendar = false;
  datePickerSettings: MbscDatepickerOptions = {
    ...COMMON_DATETIMEPICKER_SETTINGS, ...{
      dateWheels: 'YYYY MMMM DD',
      dateFormat: 'YYYY-MM-DD',
      stepMinute: 15,
      onOpen: (_, inst) => this._setupDateTime(inst)
    }
  };
  validationMessages: ValidationMessage = Poll_Validation_Messages;

  private _subscriptionState: boolean = true;
  startEndDatePicker: DatepickerData;
  showPreview =false;
  // offertype='AFSTEMNING'
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _dateToString: DateToString,
    private _pollService: PollService,
    private _createOptionService: CreateOptionDataService,
    private _router: Router,
    private _errorHandlerService: ErrorhandlerService
  ) {
  }

  ngOnInit() {
    this._activatedRoute.parent.data
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((url) => (this.parentUrl = url['parentUrl']));
    this._activatedRoute.url
      .pipe(takeWhile(() => this._subscriptionState))
      .subscribe((urlSegment) => {
        if (urlSegment.length > 1) {
          this.routeId = urlSegment[1].path;
        } else {
          this.routeId = urlSegment[0].path;
        }
      });

    document.body.classList.add('overlay');
    this.poll = FormFunctionsService.lowerCaseKeys(
      this._activatedRoute.snapshot.data['poll']
    );
    this.isTemplate = this._activatedRoute.snapshot.data['template'];
    this.origin = this._activatedRoute.snapshot.queryParams['origin'];
    this.fromCalendar = window.history.state.origin;
    this.currentUrl = this._router.url;
    this.previousUrl = this.parentUrl + '/skab';
    this.userType = TokenService.getUserType();
    this.successUrl = this.parentUrl + '/kalendar/afstemninger';
    if (this.fromCalendar) {
      this.previousUrl = this.successUrl;
    }
    if (this.poll) {
      this.imageUrl = this.poll.imageUrl;
    }
    this.pollForm = this._formBuilder.group(
      {
        id: [''],
        title: ['', [Validators.required, checkWhiteSpace, checkMaxLength]],
        imageUrl: this._formBuilder.group({
          largeImage: ['', Validators.required],
          thumbnail: ['']
        }),
        startNow : [],
        startDate: ['', Validators.required],
        endDate: ['', Validators.required],
        isMultiple: [false],
        description: ['', [Validators.required, checkWhiteSpace]],
        answers: this._formBuilder.array([]),
        web: [false]
      },
      { validators: [checkIfEndDateIsLessThanStartDate] }
    );

    if (this.poll) {
      this.editMode = true;
      this.isEditable = this.poll.isEditable;
      if (!this.isEditable) {
        this.pageTitle = 'Afstemning';
        this._buildPollResults();
      }
      if (this.isEditable) {
        this.pageTitle = 'Rediger Afstemning';
        this.editMode = this.isTemplate ? false : this.editMode;
      }
      if (this.isTemplate) {
        this.previousUrl = this.currentUrl.replace(this.poll.id, '');
      }
      if (this.editMode) {
        /*this.previousUrl = this.currentUrl.replace('redigere/' + this.poll.Id, '');*/
        if (this.origin === 'list') {
          this.previousUrl = '/bestyrelse/afstemninger/';
          this.successUrl = this.previousUrl;
        }
        if (this.origin !== 'list') {
          this.previousUrl =
            this.parentUrl + '/kalendar/afstemninger/' + this.routeId;
        }
      }
      this._buildEditForm();
    } else {
      for (let i = 0; i < 2; i++) {
        this.addAnswer('');
      }
      this._setDefaultTimeOnCreate();
    }
    this.startEndDatePicker = {
      titles: {
        start: 'Gyldig fra - nu',
        end: 'Gyldig til',
      },
      startEndDateTime: {
        start: this.pollForm.get('startDate').value,
        end: this.pollForm.get('endDate').value,
      },
      disable: {
        end: false,
        start: false,
      },
      modes: {
        isTemplate: false,
        isEditMode: this.editMode,
        isDuplicate: false,
      },
    };
  }

  ngOnDestroy(): void {
    document.body.classList.remove('overlay');
    this._subscriptionState = false;
  }

  imageProcessing(e) {
    this.isProcessing = e;
  }

  showCounter() {
    this.showLengthCounter = !this.showLengthCounter;
  }

  getImagesUrl(images) {
    this.pollForm.patchValue({
      imageUrl: {
        largeImage: images.largeImage,
        thumbnail: images.thumbnail
      }
    });
  }

  initAnswer(answer?: string): FormGroup {
    return this._formBuilder.group({
      answer: [answer, [Validators.required, checkWhiteSpace]]
    });
  }

  addAnswer(answer: string) {
    const answers = this.pollForm.controls['answers'] as FormArray;
    const aa = this.initAnswer(answer);
    answers.push(aa);
    this.answerCount += 1;
  }

  removeAnswer(i: number) {
    const answer = this.pollForm.controls['answers'] as FormArray;
    answer.removeAt(i);
    this.answerCount -= 1;
  }

  toggleWebChannel() {
    const web = this.pollForm.value.web;
    this.pollForm.patchValue({
      web: !web
    });
  }

  save() {
    if (!this._isValid()) {
      return;
    }

    if (!this.isProcessing) {
      this.isProcessing = true;
      this.pollForm.value.startDate = this._dateToString.transform(
        this.pollForm.value.startDate
      );
      this.pollForm.value.endDate = this._dateToString.transform(
        this.pollForm.value.endDate
      );
      this.pollForm.value.web = this.pollForm.value.web ? 1 : 0;
      this.pollForm.value.isMultiple = this.pollForm.value.isMultiple ? 1 : 0;
      this._pollService
        .savePoll(this.pollForm.value, this.editMode, this.isEditable)
        .pipe(takeWhile(() => this._subscriptionState))
        .subscribe({
          next: () => {
            const notificationMessage = this.editMode
              ? 'Afstemning er opdateret'
              : 'Afstemningen er oprettet';
            NotificationsService.notify(notificationMessage, 'success', 'top');
            this._success();
          },
          error: (err) => {
            if (err.code === -121) {
              const errMsg = 'Det valgte tidsinterval er allerede brugt.';
              NotificationsService.notify(errMsg, 'error', 'top');
              this.isProcessing = false;
            } else {
              this._handleError(err);
            }
          }
        });
    }
  }

  private _setDefaultTimeOnCreate() {
    const startDate = DateFormatService.timeIntervalFormat(moment());
    const endDate = DateFormatService.timeIntervalFormat(
      moment().add(7, 'days')
    );
    const currForm = this.pollForm;
    currForm.patchValue({
      publishDate: startDate,
      expirationDate: endDate,
      startDate: startDate,
      endDate: endDate
    });
  }

  deletePoll(id: string): void {
    NotificationsService.confirm(`Er du sikker?`)
      .pipe(
        takeWhile(() => this._subscriptionState),
        mergeMap((state) => this._delete(state, id))
      )
      .subscribe({
        next: () => {
          NotificationsService.notify(
            'Afstemning blev slettet',
            'success',
            'top'
          );
          this._success();
        },
        error: (err) => this._handleError(err)
      });
  }

  // go back button using setPreviousUrl
  back() {
    this._router.navigate([this.previousUrl]).then();
  }

  private _buildEditForm() {
    // Date Conversion to be used in DateTimePicker
    const answers = this.poll.answers;
    for (const answer of answers) {
      this.addAnswer(answer.answer);
    }

    this.pollForm.patchValue(this.poll);
    this.imageUrl = this.poll.imageUrl;
    this.hasImageUrl = true;
  }

  private _buildPollResults() {
    const answers = this.poll.answers;
    for (let i = 0; i < answers.length; i++) {
      answers[i].percentage = parseFloat(
        (
          (parseInt(answers[i].count, 10) / this.poll.totalVoteCount) *
          100
        ).toString()
      ).toFixed(0);
      answers[i].shade = this.colors[i];
      for (const answer of answers) {
        if (answers[i].count === answer.count && answer.shade) {
          answers[i].shade = answer.shade;
        }
      }
    }
  }

  private _setupDateTime(inst: DatepickerBase) {
    // *** Create Mode ***
    if (!this.editMode) {
      // Set Start Date at Today time 8:00
      if (inst._el.id === 'startDate' && !this.pollForm.value.startDate) {
        this.pollForm.patchValue({
          startDate: moment().hour(8).minute(0).toDate()
        });
      }
      // EndDate picker clicked
      // If no Startdate set it date to Today time 20:00
      if (
        inst._el.id === 'expiry' &&
        !this.pollForm.value.endDate &&
        !this.pollForm.value.startDate
      ) {
        this.pollForm.patchValue({
          endDate: moment().hour(20).minute(0).toDate()
        });
      }
      // EndDate picker clicked
      // If Startdate set it to StartDate and time at 20:00
      if (
        inst._el.id === 'expiry' &&
        this.pollForm.value.startDate &&
        !this.pollForm.value.endDate
      ) {
        const startDate = moment(this.pollForm.value.startDate);
        this.pollForm.patchValue({
          endDate: startDate.hour(20).minute(0).toDate()
        });
      }
    }

    // *** Edit Mode ***
  }

  // ** Prepare Form Data and check Validation ** //
  // @return [boolean] => return false if form is invalid
  private _isValid() {
    if (this.pollForm.status === 'INVALID') {
      this._handleError('');
      return false;
    }
    return true;
  }

  // *** Poll create/edit process completion *** //
  // Route to Poll Lost
  // @return [void]
  private _success(): void {
    this._createOptionService.clearCreateOption();
    this.formSaved = true;
    this.isProcessing = false;
    this._router.navigate([this.successUrl]).then();
  }

  private _delete(state: boolean, pollId: string): Observable<any> {
    if (state) {
      return this._pollService.deletePoll(pollId);
    }
    return EMPTY;
  }

  getStartDateTime(event: any) {
    this.pollForm.patchValue({
      startDate: event.startDateTime,
      endDate: event.endDateTime,
      startNow: event.start_now
    }, {
      onlySelf: true
    })
  }
  openPreview() {
    if (!this._isValid()) {
      return;
    }
    this.showPreview = true;
    event.preventDefault();
  }
  closePreview(preview: boolean) {
    this.showPreview = preview;
  }


  private _handleError(error): void {
    this.isProcessing = false;
    this._errorHandlerService.handleError(error || { code: -400 }, 'poll');
  }
}
